var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"install-app-social install-app-android novisible",class:{
      visible_flex:
        _vm.$device.android &&
        _vm.User.no_interesting_in_social_profile &&
        _vm.User.raves_count > 3
    }},[_c('div',{staticClass:"install-app-android-wrapper"},[_c('a',{attrs:{"href":_vm.gpUrl,"target":"_blank"}},[_c('img',{attrs:{"alt":"Доступно в Google Play","src":"https://static.human-design.space/widget/images/mobile-banners/ru_badge_generic.png","width":"90%"}})]),_c('v-btn',{staticClass:"close-icon",attrs:{"icon":"","absolute":"","right":""},on:{"click":_vm.noInteresting}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('div',{staticClass:"install-app-social novisible",class:{
      visible_flex:
        _vm.$device.ios &&
        _vm.User.no_interesting_in_social_profile &&
        _vm.User.raves_count > 3
    }},[_c('a',{attrs:{"href":_vm.pwaUrl,"target":"_blank"}},[_c('img',{attrs:{"alt":"Приложение для iOS","src":"https://static.human-design.space/widget/images/mobile-banners/ru_badge_generic.png","height":"90"}})]),_c('v-btn',{staticClass:"close-icon",attrs:{"icon":"","absolute":"","right":""},on:{"click":_vm.noInteresting}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }