<template>
  <div v-if="method === 'myfavouritesSticky'">
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click="getPosts"
          class="mx-2"
          fab
          text
          dark
          color="amber lighten-2"
        >
          <v-icon dark class="sticky-menu-button-icon">
            mdi-account-heart-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Избранное</span>
    </v-tooltip>
    <div class="sticky-menu-button-desc">Избранное</div>
    <v-dialog
      v-model="postsDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title v-if="method === 'myfavouritesSticky'">
                    Избранное
                  </v-toolbar-title>
                  <v-toolbar-title v-else>
                    Посты пользователя
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <Posts :userId="userId" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="getPosts"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon
            v-if="method === 'favourites' || method === 'myfavourites'"
            center
            >mdi-bookmark-outline</v-icon
          >
          <v-icon v-else center>mdi-note-multiple-outline</v-icon>
          <span
            v-if="method === 'myposts'"
            class="d-flex"
            style="font-size:11px;margin-top:5px"
          >
            Мои посты
          </span>
          <span
            v-else-if="method === 'favourites' || method === 'myfavourites'"
            class="d-flex"
            style="font-size:11px;margin-top:5px"
          >
            Избранное
          </span>
          <span v-else class="d-flex" style="font-size:11px;margin-top:5px">
            Посты
          </span>
        </v-btn>
      </template>
      <span v-if="method === 'myposts'">Мои посты</span>
      <span v-else-if="method === 'favourites' || method === 'myfavourites'"
        >Избранное</span
      >
      <span v-else>Посты</span>
    </v-tooltip>
    <v-dialog
      v-model="postsDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title v-if="method === 'myposts'">
                    Мои посты
                  </v-toolbar-title>
                  <v-toolbar-title v-else-if="method === 'myfavourites'">
                    Избранное
                  </v-toolbar-title>
                  <v-toolbar-title v-else-if="method === 'favourites'">
                    Избранное пользователя
                  </v-toolbar-title>
                  <v-toolbar-title v-else>
                    Посты пользователя
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <Posts :userId="userId" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Posts from "@/components/community/posts/posts";

export default {
  components: {
    Posts
  },
  props: {
    method: {
      type: String,
      default: ""
    },
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      postsDialog: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp
    })
  },
  methods: {
    ...mapMutations({ set_posts_method: "account/set_posts_method" }),
    ...mapActions({
      get_posts_from_api: "account/get_posts_from_api"
    }),
    getPosts() {
      this.set_posts_method(this.method);
      this.get_posts_from_api({ user_id: this.userId });
      this.postsDialog = true;
    },
    getMyFavoritePosts(options) {
      this.$store.dispatch("account/getSocialFavPosts", options);
    },
    closeDialog() {
      this.postsDialog = false;
      this.set_posts_method("posts");
      this.get_posts_from_api({ user_id: this.userId });
    }
  }
};
</script>
