<template>
  <v-card flat d-flex pa-2 class="card-md">
    <v-card-text style="padding:0">
      <v-row>
        <v-col
          class="mx-auto padding-top-0 mob-padding-428-0"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          outlined
        >
          <v-card class="mx-auto padding-15-mob-15-0" max-width="950">
            <div class="d-flex flex-no-wrap mob-column justify-space-between">
              <StickyMenu />
              <Posts />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import Posts from "@/components/community/posts/posts";
import StickyMenu from "@/components/community/posts/sticky-menu";

export default {
  components: {
    Posts,
    StickyMenu
  },
  mounted() {
    setTimeout(() => {
      this.set_posts_method("posts");
    }, 0);
  },
  methods: {
    ...mapMutations({ set_posts_method: "account/set_posts_method" })
  }
};
</script>
