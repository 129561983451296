<template>
  <v-card flat d-flex pa-2 class="card-md card-md-filled">
    <v-card-text>
      <v-row>
        <v-col
          v-for="(product, i) in products"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="4"
          xl="4"
          class="product-item"
        >
          <v-card
            :loading="loading"
            v-bind:class="{
              service_item_desc: i === 2,
              service_item_desc2: i === 5
            }"
            class="mx-auto my-12 width-100-mob box-shadow-0-4"
            max-width="374"
          >
            <div
              v-if="DarkTheme.enable"
              v-html="product.html"
              class="inproduct product-tooltip-dark"
            ></div>
            <div v-else v-html="product.html" class="inproduct"></div>
            <v-card-text class="padding-top-0 padding-bottom-0">
              <div class="text-center service-item-detail-head">
                <span
                  @click="detailModal(product.description)"
                  class="service-item-detail"
                  >Подробнее</span
                >
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <span
                  v-if="i === 2"
                  @click="detailModal(product.html_tooltip)"
                  class="service-item-detail"
                >
                  Дополнения
                </span>
                <span v-else>Объем {{ product.pages_amount }}+ стр.</span>
              </div>
              <div v-if="product.is_discount" class="my-1 service-item-price">
                <del class="product-old-price">
                  <span v-if="product.price_regular !== product.price">
                    {{ product.price_regular }}
                  </span>
                </del>
                {{ product.price }}<v-icon>mdi-currency-rub</v-icon>
              </div>
              <div v-else class="my-1 service-item-price">
                {{ product.price }}<v-icon>mdi-currency-rub</v-icon>
              </div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <a
                @click="reserve"
                :href="productExternalUrl(product.external_url, product.id)"
                target="_blank"
                class="btn-buy"
              >
                заказать
              </a>
            </v-card-actions>
            <div v-if="i !== 2 && i !== 5" class="service-item-border"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="productdescdialog"
      scrollable
      max-width="900px"
      content-class="custom-border-right-bottom"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="productdescdialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <br />
                <div v-html="pdoductDescHtml" class="product-desc-dialog"></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="productdescdialog = false"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Понятно</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoginDialog :showDialog.sync="showDialog" @close="closeDialog" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";

export default {
  name: "Products",
  components: { LoginDialog },
  data() {
    return {
      loading: false,
      productdescdialog: false,
      pdoductDescHtml: "",
      token: "",
      showDialog: false
    };
  },
  computed: {
    ...mapState({
      Products: (state) => state.account.Products,
      User: (state) => state.account.User,
      DarkTheme: (state) => state.conf.DarkTheme
    }),
    products: {
      get() {
        return this.Products;
      },
      set(v) {
        this.$store.commit("account/set_products", v);
      }
    },
    reportidPro: {
      get() {
        return this.User.reportidPro;
      },
      set(num) {
        this.$store.commit("UPDATE_REPORT_ID_PRO", num);
      }
    },
    haveIsUsedUserProducts: {
      get() {
        return this.User.haveIsUsedUserProducts;
      },
      set(v) {
        this.$store.commit("HAVE_IS_USED_USER_PRODUCTS", v);
      }
    },
    haveIsUsedUserComposites: {
      get() {
        return this.User.haveIsUsedUserComposites;
      },
      set(v) {
        this.$store.commit("HAVE_IS_USED_USER_COMPOSITES", v);
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    async getData() {
      this.$store.dispatch("account/getProducts");
    },
    productExternalUrl(url, id) {
      const COMPOSITE_ID = 7;
      if (
        this.reportidPro &&
        this.User.ext_reference_code &&
        id !== COMPOSITE_ID
      ) {
        const prodUrl = url.indexOf("?");
        if (prodUrl !== -1) {
          return (
            url +
            "&tag=r" +
            this.reportidPro +
            "~rf" +
            this.User.ext_reference_code
          );
        } else {
          return (
            url +
            "?tag=r" +
            this.reportidPro +
            "~rf" +
            this.User.ext_reference_code
          );
        }
      } else if (this.reportidPro && id !== COMPOSITE_ID) {
        const prodUrl = url.indexOf("?");
        if (prodUrl !== -1) {
          return url + "&tag=r" + this.reportidPro;
        } else {
          return url + "?tag=r" + this.reportidPro;
        }
      } else if (this.User.ext_reference_code) {
        const prodUrl = url.indexOf("?");
        if (prodUrl !== -1) {
          return url + "&tag=rf" + this.User.ext_reference_code;
        } else {
          return url + "?tag=rf" + this.User.ext_reference_code;
        }
      } else {
        return url;
      }
    },
    detailModal(html) {
      this.productdescdialog = true;
      this.pdoductDescHtml = html;
      this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
    },
    closeDialog() {
      this.showDialog = false;
    }
  }
};
</script>
<style scoped>
.product-old-price {
  font-size: 0.9em;
}
.theme--light .product-old-price {
  color: #0000005e;
}
.theme--dark .product-old-price {
  color: #0596dc;
}
</style>
