<template>
  <div class="conversation-body">
    <v-card
      class="mx-auto"
      style="height:100%;display:flex;flex-direction:column;justify-content:space-between;"
    >
      <v-list v-if="messages" three-line>
        <!-- <v-subheader>Сегодня:</v-subheader> -->
        <div
          v-for="(message, index) in messages"
          :key="index"
          class="conversation-item"
        >
          <div v-if="message.fromSelf">
            <v-list-item style="flex-direction:row-reverse">
              <v-list-item-avatar style="margin: 16px 0 16px 24px">
                <v-img
                  v-if="User.community.avatar"
                  :alt="User.firstname"
                  :src="User.community.avatar"
                ></v-img>
                <v-img v-else :alt="User.firstname" :src="defaultAva"></v-img>
              </v-list-item-avatar>

              <v-list-item-content style="justify-content:flex-end">
                <v-list-item-title style="text-align:right">
                  <button
                    v-if="!message.isDeleted && message.isEditable"
                    @click="editMessage(message)"
                    type="button"
                  >
                    <v-icon color="primary">
                      mdi-comment-edit-outline
                    </v-icon>
                  </button>
                  <button
                    v-if="!message.isDeleted && message.isDeletable"
                    @click="onMessageDeleteConfirm(message.messageId)"
                    type="button"
                  >
                    <v-icon color="primary">
                      mdi-forum-remove-outline
                    </v-icon>
                  </button>
                  Вы
                  <span class="conversation-time">
                    {{ formatCommDate(message.sentAt) }}
                    <span v-if="message.isEdited && !message.isDeleted">
                      [изменено]
                    </span>
                    <span v-if="message.isDeleted">
                      [удалено]
                    </span>
                  </span>
                  <v-icon v-if="message.status === 1">mdi-timer-sand</v-icon>
                  <v-icon v-if="message.status === 2">mdi-check</v-icon>
                  <v-icon v-if="message.status === 3">mdi-check-all</v-icon>
                  <v-icon
                    v-if="message.status === 4"
                    color="light-blue accent-3"
                    >mdi-check-all</v-icon
                  >
                </v-list-item-title>
                <div
                  v-if="message.description && !message.isDeleted"
                  v-html="transformText(message.description)"
                  class="conversation-text-you"
                ></div>
                <div v-else class="conversation-text-you">
                  <i>это сообщение было удалено...</i>
                </div>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else>
            <v-list-item>
              <a
                :class="{ 'premium-avatar5': user.has_products }"
                style="cursor:pointer"
              >
                <v-list-item-avatar @click="getUserProfileDialog(user.id)">
                  <v-img
                    v-if="user.avatar"
                    :alt="user.first_name"
                    :src="user.avatar"
                  ></v-img>
                  <v-img
                    v-else
                    :alt="user.first_name"
                    :src="defaultAva"
                  ></v-img>
                </v-list-item-avatar>
              </a>
              <v-list-item-content>
                <v-list-item-title style="text-align:left">
                  <a @click="getUserProfileDialog(user.id)" class="user-link">
                    {{ user.first_name }}
                  </a>
                  <status-icon :connected="isUserConnected(user.id)" />
                  <span class="conversation-time">
                    {{ formatCommDate(message.sentAt) }}
                    <span v-if="message.isEdited && !message.isDeleted">
                      [изменено]
                    </span>
                    <span v-if="message.isDeleted">
                      [удалено]
                    </span>
                  </span>
                  <v-icon v-if="message.status === 1">mdi-timer-sand</v-icon>
                  <v-icon v-if="message.status === 2">mdi-check</v-icon>
                  <v-icon v-if="message.status === 3">mdi-check-all</v-icon>
                  <v-icon
                    v-if="message.status === 4"
                    color="light-blue accent-3"
                    >mdi-check-all</v-icon
                  >
                </v-list-item-title>
                <div
                  v-if="message.description && !message.isDeleted"
                  v-html="transformText(message.description)"
                  class="conversation-text-user"
                ></div>
                <div v-else class="conversation-text-user">
                  <i>это сообщение было удалено...</i>
                </div>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
      <v-list>
        <div id="chatfooter" style="height:102px"></div>
      </v-list>
      <v-footer class="position-fixed-bottom comments-footer">
        <div
          style="width:100%;display:flex;align-items:center;justify-content:center"
        >
          <v-col
            cols="12"
            sm="12"
            md="10"
            lg="10"
            xl="10"
            style="padding:12px 0 0"
          >
            <v-form ref="formMessage" class="form-comm">
              <v-textarea
                ref="messageDesc"
                v-model="messageDesc"
                :counter="256"
                :rules="messageRules"
                @focus="onWriting"
                outlined
                name="input-7-4"
                rows="1"
                row-height="10"
                auto-grow
                label="Ваше сообщение:"
                class="form-comm-textarea"
              ></v-textarea>
              <v-btn
                @click="validateMessage()"
                small
                text
                absolute
                right
                class="form-comm-btn-send"
              >
                <v-icon style="color:#5f36ad">mdi-send</v-icon>
              </v-btn>
            </v-form>
          </v-col>
        </div>
      </v-footer>
    </v-card>
    <v-dialog
      v-model="сonversationDeleteToConfirmDialog"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn
            @click="сonversationDeleteToConfirmDialog = false"
            icon
            absolute
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-bottom-padding">
          <v-container class="no-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-bottom-padding display-0-24-no-line-h"
              >
                <span style="display:block;margin-bottom:33px">
                  Вы точно хотите удалить это сообщение?
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="onMessageDelete(messageId)"
                color="success"
                class="padding-0-20 hd-gradient"
                >Удалить</v-btn
              >
              <v-btn
                @click="сonversationDeleteToConfirmDialog = false"
                color="success"
                class="padding-0-20 hd-gradient"
                >Отмена</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import StatusIcon from "./status-icon";

export default {
  components: { StatusIcon },
  props: {
    user: {
      type: Object,
      default: null
    },
    userSelf: {
      type: Object,
      default: null
    }
  },
  mixins: [commonInitAppMethods, commonReportingMethods],
  data() {
    return {
      defaultAva:
        "https://static.human-design.space/widget/images/social/socialprofile-preview.png",
      messageDesc: "",
      messageId: null,
      input: "",
      messageRules: [
        (v) => !!v || "Введите текст.",
        (v) =>
          (v && v.length <= 256) ||
          "Текст сообщения не должен быть более 256 символов."
      ],
      сonversationDeleteToConfirmDialog: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp
    }),
    isValid() {
      return this.input.length > 0;
    },
    messages() {
      return this.User.community.chats.selectedUserMessages;
    }
  },
  mounted() {
    const chatfooter = document.getElementById("chatfooter");
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_scroll_chat_to_footer":
          chatfooter.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
          });
          this.call_recount_total_new_messages_count();
          break;
        case "account/call_clear_chat_edit_message":
          this.clearEditMessage();
          break;
      }
    });
    this.addHandler();
  },
  destroyed() {
    this.removeHandler();
  },
  methods: {
    ...mapMutations({
      call_user_view_dialog: "account/call_user_view_dialog",
      push_to_selected_user_messages: "account/push_to_selected_user_messages",
      call_scroll_chat_to_footer: "account/call_scroll_chat_to_footer",
      call_recount_total_new_messages_count:
        "account/call_recount_total_new_messages_count"
    }),
    setStatus(status) {
      this.status = status;
    },
    validateMessage() {
      if (this.$refs.formMessage.validate()) {
        if (this.messageId) {
          this.onMessageEdit(this.messageId);
        } else {
          this.sendMessage();
        }
      }
    },
    sendMessage() {
      const messageHash =
        this.user.id +
        "_" +
        new Date().getTime() +
        "_" +
        Math.random()
          .toString(36)
          .slice(-5);
      const message = {
        messageHash,
        description: this.messageDesc,
        fromSelf: true,
        sentAt: Math.floor(Date.now() / 1000), // Math.round(new Date().getTime() / 1000)
        userRecipientId: this.user.id,
        userSenderId: this.User.id,
        status: 1,
        isDeletable: true,
        isDeleted: false,
        isEditable: true,
        isEdited: false
      };
      this.$emit("input", message);
      this.push_to_selected_user_messages(message);
      this.$refs.formMessage.reset();
      setTimeout(() => this.call_scroll_chat_to_footer(), 250);
    },
    onWriting() {
      this.$emit("writing");
    },
    editMessage(message) {
      this.messageDesc = message.description;
      this.messageId = message.messageId;
      this.$refs.messageDesc.focus();
    },
    onMessageEdit(messageId) {
      this.$emit("messageEdit", messageId, this.messageDesc);
      this.messages.map((message) => {
        if (message.messageId === messageId) {
          message.description = this.messageDesc;
          message.isEdited = true;
        }
      });
      this.clearEditMessage();
    },
    addHandler() {
      window.addEventListener("keyup", this.handleKeyEvent);
    },
    removeHandler() {
      window.removeEventListener("keyup", this.handleKeyEvent);
    },
    handleKeyEvent(event) {
      if (event.keyCode === 38) {
        this.messages
          .slice()
          .reverse()
          .some((message) => {
            if (message.fromSelf) {
              this.editMessage(message);
              return true;
            }
          });
      }
    },
    clearEditMessage() {
      this.messageDesc = "";
      this.messageId = null;
      this.$refs.formMessage.reset();
    },
    onMessageDeleteConfirm(messageId) {
      this.messageId = messageId;
      this.сonversationDeleteToConfirmDialog = true;
    },
    onMessageDelete(messageId) {
      this.$emit("messageDetele", messageId);
      this.messages.map((message) => {
        if (message.messageId === messageId) {
          message.description = "";
          message.isDeleted = true;
        }
      });
      this.сonversationDeleteToConfirmDialog = false;
    },
    displaySender(message, index) {
      return (
        index === 0 ||
        this.user.messages[index - 1].fromSelf !==
          this.user.messages[index].fromSelf
      );
    },
    getUserProfileDialog(id) {
      this.call_user_view_dialog(id);
    }
  }
};
</script>
<style scoped>
.conversation-text-user,
.conversation-text-you {
  max-width: 50%;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 0 10px 10px;
}
.conversation-text-you {
  background-color: #b0c7ff;
  border-radius: 10px 0 10px 10px;
}
.theme--dark .conversation-text-user {
  background-color: #19394e;
}
.theme--dark .conversation-text-you {
  background-color: #10486d;
}
.conversation-time {
  font-size: 0.8rem;
  color: #00000099;
}
.theme--dark .conversation-time {
  font-size: 0.8rem;
  color: #ffffff99;
}
@media screen and (min-width: 320px) and (max-width: 419px) {
  .conversation-text-user,
  .conversation-text-you {
    max-width: 100%;
  }
}
.form-comm-textarea textarea {
  margin-bottom: 20px;
  padding-right: 30px !important;
}
.conversation-body {
  height: 100% !important;
  padding: 120px 0 106px;
}
</style>
