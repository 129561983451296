<template>
  <div>
    <v-list-item
      v-if="User.isLogged && User.is_admin"
      @click="postDialogBlock(id, status)"
    >
      <v-list-item-title v-if="status === 1">
        <v-icon>mdi-cancel</v-icon>
        Заблокировать
      </v-list-item-title>
      <v-list-item-title v-if="status === 2">
        <v-icon>mdi-undo</v-icon>
        Разблокировать
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    userId: {
      type: Number,
      default: null
    },
    status: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp
    })
  },
  methods: {
    ...mapMutations({
      call_block_post: "account/call_block_post"
    }),
    postDialogBlock(id, status) {
      this.call_block_post({ id, status });
    }
  }
};
</script>
