<template>
  <div class="bt-mn-cont">
    <div class="bottom-menu text-center">
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="showDialog = true"
              v-bind:class="{
                btn_visible: !User.isLogged
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_login"
              dark
              text
            >
              <v-icon dark class="btn-icon-share">mdi-login</v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Вход</span>
            </v-btn>
          </template>
          <span>Регистрация / Вход</span>
        </v-tooltip>
        <div
          @click="showDialog = true"
          v-bind:class="{
            hd_visible: !User.isLogged
          }"
          class="mob-menu-item-header hd_novisible"
        >
          <v-icon dark class="fs30 login">mdi-login</v-icon>
          Вход
        </div>
      </div>
      <!-- <div
        @click="shareReport(User.reportid, User.is_user_product_hash)"
        v-bind:class="{
          hd_visible:
            !User.isLogged && User.report_hash && $route.path === '/bodygraph'
        }"
        class="mob-menu-item-header hd_novisible"
      >
        <v-icon dark class="fs30 actions">mdi-share-variant</v-icon>
        Поделиться
      </div>
      <div
        @click="shareReport($store.state.account.Composite.report_id)"
        v-bind:class="{
          hd_visible:
            !User.isLogged &&
            $store.state.Composite.composite_hash &&
            $route.path === '/composite'
        }"
        class="mob-menu-item-header hd_novisible"
      >
        <v-icon dark class="fs30 actions">mdi-share-variant</v-icon>
        Поделиться
      </div>
      <div
          @click="shareReport($store.state.Transit.report_id, false)"
          v-bind:class="{
            hd_visible:
              $store.state.Transit.report_id && ConfApp.tab === 3
          }"
          class="mob-menu-item-header hd_novisible"
        >
          <v-icon dark class="fs30 actions">mdi-share-variant</v-icon>
          Поделиться
        </div>
        <div
          @click="shareReport($store.state.Return.report_id, false)"
          v-bind:class="{
            hd_visible:
              $store.state.Return.report_id && ConfApp.tab === 4
          }"
          class="mob-menu-item-header hd_novisible"
        >
          <v-icon dark class="fs30 actions">mdi-share-variant</v-icon>
          Поделиться
        </div> -->
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="shareReport(User.reportid, User.is_user_product_hash)"
              v-bind:class="{
                btn_visible:
                  $route.path === '/bodygraph' &&
                  User.reportid &&
                  User.report_hash
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_share"
              dark
              text
              color="accent-4"
            >
              <v-icon dark class="btn-icon-share">mdi-share-variant</v-icon>
              <span>&nbsp;&nbsp;Поделиться</span>
            </v-btn>
          </template>
          <span>Поделиться расчетом</span>
        </v-tooltip>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="
                shareReport(User.user_products_id, User.is_user_product_hash)
              "
              v-bind:class="{
                btn_visible:
                  $route.path === '/bodygraph' &&
                  User.user_products_id &&
                  User.is_user_product_hash
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_share"
              dark
              text
              color="accent-4"
            >
              <v-icon dark class="btn-icon-share">mdi-share-variant</v-icon>
              <span>&nbsp;&nbsp;Поделиться</span>
            </v-btn>
          </template>
          <span>Поделиться</span>
        </v-tooltip>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="
                shareReport($store.state.account.Transit.report_id, false)
              "
              v-bind:class="{
                btn_visible:
                  $route.path === '/transit' &&
                  $store.state.account.Transit.report_id
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_share"
              dark
              text
              color="accent-4"
            >
              <v-icon dark class="btn-icon-share">mdi-share-variant</v-icon>
              <span>&nbsp;&nbsp;Поделиться</span>
            </v-btn>
          </template>
          <span>Поделиться транзитом</span>
        </v-tooltip>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="shareReport($store.state.account.Return.report_id, false)"
              v-bind:class="{
                btn_visible:
                  $route.path === '/returns' &&
                  $store.state.account.Return.report_id
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_share"
              dark
              text
              color="accent-4"
            >
              <v-icon dark class="btn-icon-share">mdi-share-variant</v-icon>
              <span>&nbsp;&nbsp;Поделиться</span>
            </v-btn>
          </template>
          <span>Поделиться возвратом</span>
        </v-tooltip>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="actionReport(User.reportid, 'add')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/bodygraph' &&
                  User.isLogged &&
                  User.reportid &&
                  !User.user_products_id &&
                  User.report_hash &&
                  !User.is_stored &&
                  User.is_subscribed_to_justclick
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_save"
              dark
              text
              color="accent-4"
            >
              <v-icon dark class="btn-icon-share">
                mdi-text-box-plus-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Сохранить</span>
            </v-btn>
          </template>
          <span>Сохранить расчет в кабинет</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="goToPath('/profile')"
              v-bind:class="{
                btn_visible:
                  ConfApp.tab === 1 &&
                  User.isLogged &&
                  User.reportid &&
                  !User.user_products_id &&
                  !User.is_stored &&
                  !User.is_subscribed_to_justclick
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_save"
              dark
              text
              color="accent-4"
            >
              <v-icon dark class="btn-icon-share">
                mdi-text-box-plus-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Сохранить</span>
            </v-btn>
          </template>
          <span>Подтвердите Email</span>
        </v-tooltip>
        <div
          @click="goToPath('/profile')"
          v-bind:class="{
            hd_visible:
              ConfApp.tab === 1 &&
              User.isLogged &&
              User.reportid &&
              !User.user_products_id &&
              !User.is_stored &&
              !User.is_subscribed_to_justclick
          }"
          class="mob-menu-item-header hd_novisible"
        >
          <v-icon dark class="btn-icon-share">mdi-text-box-plus-outline</v-icon>
          <span class="no-mobile">&nbsp;&nbsp;Сохранить</span>
        </div>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="actionReport(User.reportid, 'remove')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/bodygraph' &&
                  User.isLogged &&
                  User.is_can_delete_report &&
                  User.reportid &&
                  !User.user_products_id &&
                  User.report_hash &&
                  User.is_stored &&
                  User.is_subscribed_to_justclick
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_delete"
              dark
              text
              color="error"
            >
              <v-icon dark class="btn-icon-community">
                mdi-text-box-minus-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Удалить</span>
            </v-btn>
          </template>
          <span>Удалить расчет из кабинета</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="goToPath('/profile')"
              v-bind:class="{
                btn_visible:
                  ConfApp.tab === 1 &&
                  User.isLogged &&
                  User.reportid &&
                  User.is_stored &&
                  !User.is_subscribed_to_justclick
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile"
              dark
              color="error"
            >
              <v-icon dark class="btn-icon-community">
                mdi-text-box-minus-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Удалить</span>
            </v-btn>
          </template>
          <span>Подтвердите Email</span>
        </v-tooltip> -->
        <!-- <div
          @click="goToPath('/profile')"
          v-bind:class="{
            hd_visible:
              ConfApp.tab === 1 &&
              User.isLogged &&
              User.reportid &&
              User.is_stored &&
              !User.is_subscribed_to_justclick
          }"
          class="mob-menu-item-header hd_novisible"
        >
          <v-icon>mdi-text-box-minus-outline</v-icon> Удалить
        </div> -->
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="shareReport($store.state.account.Composite.report_id)"
              v-bind:class="{
                btn_visible:
                  $route.path === '/composite' &&
                  $store.state.account.Composite.composite_hash
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_share"
              dark
              text
              color="accent-4"
            >
              <v-icon dark class="btn-icon-share">mdi-share-variant</v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Поделиться</span>
            </v-btn>
          </template>
          <span>Поделиться совместимостью</span>
        </v-tooltip>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="actionComposite(Composite.report_id, 'add')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/composite' &&
                  User.isLogged &&
                  Composite.report_id &&
                  !Composite.is_stored &&
                  User.is_subscribed_to_justclick
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_save"
              dark
              text
              color="accent-4"
            >
              <v-icon dark class="btn-icon-share">
                mdi-text-box-plus-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Сохранить</span>
            </v-btn>
          </template>
          <span>Сохранить совместимость в кабинет</span>
        </v-tooltip>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="actionComposite(Composite.report_id, 'remove')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/composite' &&
                  User.isLogged &&
                  Composite.report_id &&
                  Composite.is_stored &&
                  User.is_subscribed_to_justclick
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_delete"
              dark
              text
              color="error"
            >
              <v-icon>mdi-text-box-minus-outline</v-icon> Удалить
            </v-btn>
          </template>
          <span>Удалить совместимость из кабинета</span>
        </v-tooltip>
      </div>
      <div>
        <v-menu :offset-y="true" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              v-bind:class="{
                hd_visible:
                  User.isLogged &&
                  $route.path === '/composite' &&
                  $store.state.account.Composite.report_id &&
                  $store.state.account.Composite.composite_hash,
                mob_menu_item_active: $route.path === '/composite'
              }"
              :style="{ padding: 0 }"
              class="mob-menu-item-header hd_novisible"
              text
            >
              <v-icon dark class="fs30 actions">
                mdi-account-multiple-plus-outline
              </v-icon>
              Поделиться
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="shareReport($store.state.account.Composite.report_id)"
              v-bind:class="{
                hd_visible: $store.state.account.Composite.composite_hash
              }"
              :style="{ minHeight: 'auto' }"
              class="hd_novisible"
            >
              <v-list-item-title style="line-height:48px;width:100%">
                <v-icon>mdi-share-variant</v-icon> Поделиться
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="actionComposite(Composite.report_id, 'remove')"
              v-bind:class="{
                hd_visible: User.isLogged && Composite.is_stored
              }"
              :style="{ minHeight: 'auto' }"
              class="hd_novisible"
            >
              <v-list-item-title style="line-height:48px;width:100%">
                <v-icon>mdi-text-box-minus-outline</v-icon> Удалить
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="actionComposite(Composite.report_id, 'add')"
              v-bind:class="{
                hd_visible: User.isLogged && !Composite.is_stored
              }"
              :style="{ minHeight: 'auto' }"
              class="hd_novisible"
            >
              <v-list-item-title style="line-height:48px;width:100%">
                <v-icon>mdi-text-box-plus-outline</v-icon> Сохранить
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-bind:class="{
                btn_visible:
                  !$store.state.account.User.isLogged &&
                  $route.path === '/reset'
              }"
              @click="goToPath('/')"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_profile"
              dark
              text
              color="primary light-blue lighten-1"
            >
              <img
                src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                height="24"
              />
              <span class="no-mobile">&nbsp;&nbsp;Расчет</span>
            </v-btn>
          </template>
          <span>Расчет карты</span>
        </v-tooltip>
        <v-btn
          v-bind:class="{
            hd_visible:
              !$store.state.account.User.isLogged && $route.path === '/reset'
          }"
          @click="goToPath('/')"
          :style="{ padding: 0 }"
          class="mob-menu-item-header hd_novisible"
          text
        >
          <img
            src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
            class="mob-bodygraph-icon"
          />
          Расчет карты
        </v-btn>
      </div>
      <div>
        <v-menu :offset-y="true" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              v-bind:class="{
                hd_visible:
                  User.isLogged &&
                  $route.path === '/bodygraph' &&
                  User.reportid &&
                  User.report_hash,
                mob_menu_item_active: $route.path === '/bodygraph'
              }"
              :style="{ padding: 0 }"
              class="mob-menu-item-header hd_novisible"
              text
            >
              <v-icon dark class="fs30 actions">
                mdi-account-plus-outline
              </v-icon>
              Поделиться
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                shareReport(
                  $store.state.account.User.reportid,
                  $store.state.account.User.is_user_product_hash
                )
              "
              v-bind:class="{
                hd_visible: User.report_hash
              }"
              :style="{ minHeight: 'auto' }"
              class="hd_novisible"
            >
              <v-list-item-title style="line-height:48px;width:100%">
                <v-icon>mdi-share-variant</v-icon> Поделиться
              </v-list-item-title>
            </v-list-item>
            <!-- <v-list-item
              @click="actionReport(User.reportid, 'remove')"
              v-bind:class="{
                hd_visible:
                  $route.path === '/bodygraph' &&
                  User.isLogged &&
                  User.is_can_delete_report &&
                  User.is_stored &&
                  !User.user_products_id
              }"
              :style="{ minHeight: 'auto' }"
              class="hd_novisible"
            >
              <v-list-item-title style="line-height:48px;width:100%">
                <v-icon>mdi-text-box-minus-outline</v-icon> Удалить
              </v-list-item-title>
            </v-list-item> -->
            <v-list-item
              @click="actionReport(User.reportid, 'add')"
              v-bind:class="{
                hd_visible:
                  $route.path === '/bodygraph' &&
                  User.isLogged &&
                  !User.is_stored &&
                  !User.user_products_id
              }"
              :style="{ minHeight: 'auto' }"
              class="hd_novisible"
            >
              <v-list-item-title style="line-height:48px;width:100%">
                <v-icon>mdi-text-box-plus-outline</v-icon> Сохранить
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu :offset-y="true" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              v-bind:class="{
                hd_visible:
                  User.isLogged &&
                  $route.path === '/bodygraph' &&
                  User.user_products_id &&
                  User.is_user_product_hash,
                mob_menu_item_active: $route.path === '/bodygraph'
              }"
              :style="{ padding: 0 }"
              class="mob-menu-item-header hd_novisible"
              text
            >
              <v-icon dark class="fs30 actions">
                mdi-account-plus-outline
              </v-icon>
              Поделиться
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                shareReport(
                  $store.state.account.User.user_products_id,
                  $store.state.account.User.is_user_product_hash
                )
              "
              v-bind:class="{
                hd_visible:
                  $route.path === '/bodygraph' && User.is_user_product_hash
              }"
              :style="{ minHeight: 'auto' }"
              class="hd_novisible"
            >
              <v-list-item-title style="line-height:48px;width:100%">
                <v-icon>mdi-share-variant</v-icon> Поделиться
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="actionReport(User.reportid, 'add')"
              v-bind:class="{
                hd_visible:
                  $route.path === '/bodygraph' &&
                  User.isLogged &&
                  !User.is_stored &&
                  !User.user_products_id
              }"
              :style="{ minHeight: 'auto' }"
              class="hd_novisible"
            >
              <v-list-item-title style="line-height:48px;width:100%">
                <v-icon>mdi-text-box-plus-outline</v-icon> Сохранить
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div>
        <v-menu :offset-y="true" :nudge-top="10" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              v-bind:class="{
                hd_visible:
                  $route.path !== '/reset' &&
                  $route.path !== '/feed' &&
                  $route.path !== '/chats' &&
                  $route.path !== '/dating' &&
                  $route.path !== '/socialprofile' &&
                  $route.path !== '/post'
              }"
              :style="{ padding: 0 }"
              class="mob-menu-item-header hd_novisible"
              text
            >
              <v-icon dark class="fs30 reports">
                mdi-file-table-box-multiple-outline
              </v-icon>
              Расчеты
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="$store.state.account.User.reportid"
              @click="newReport"
            >
              <v-list-item-title>
                <v-icon>mdi-plus</v-icon> Новый расчет
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-else @click="newReport">
              <v-list-item-title>
                <v-icon>mdi-account-plus-outline</v-icon> Новый расчет
              </v-list-item-title>
            </v-list-item>
            <v-list-group
              v-if="User.reportid"
              :value="true"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Карта:</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-if="
                  $route.path !== '/bodygraph' ||
                    ($route.path === '/bodygraph' &&
                      $store.state.account.User.isHarmoniousGates) ||
                    ($route.path === '/bodygraph' &&
                      $store.state.account.User.isLoveGates) ||
                    ($route.path === '/bodygraph' &&
                      $store.state.account.User.isFearsGates) ||
                    ($route.path === '/bodygraph' &&
                      $store.state.account.User.isMelancholyGates)
                "
                @click="closeMenu('all', false)"
              >
                <v-list-item-title>
                  <v-icon>mdi-text-box-search-outline</v-icon> Карта (Бодиграф)
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  $route.path !== '/bodygraph' ||
                    ($route.path === '/bodygraph' &&
                      !$store.state.account.User.isHarmoniousGates)
                "
                @click="closeMenu('harmonious', true)"
              >
                <v-list-item-title>
                  <v-icon>mdi-account-switch-outline</v-icon>
                  Гармоничные ворота
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  $route.path !== '/bodygraph' ||
                    ($route.path === '/bodygraph' &&
                      !$store.state.account.User.isLoveGates)
                "
                @click="closeMenu('love', true)"
              >
                <v-list-item-title>
                  <v-icon>mdi-head-heart-outline</v-icon>
                  Ворота любви
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  $route.path !== '/bodygraph' ||
                    ($route.path === '/bodygraph' &&
                      !$store.state.account.User.isFearsGates)
                "
                @click="closeMenu('fears', true)"
              >
                <v-list-item-title>
                  <v-icon>mdi-alert-octagon-outline</v-icon>
                  Ворота страха
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="
                  $route.path !== '/bodygraph' ||
                    ($route.path === '/bodygraph' &&
                      !$store.state.account.User.isMelancholyGates)
                "
                @click="closeMenu('melancholy', true)"
              >
                <v-list-item-title>
                  <v-icon>mdi-emoticon-sad-outline</v-icon>
                  Меланхолия
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item @click="goToPath('/composite')">
              <v-list-item-title>
                <v-icon>mdi-account-multiple-plus-outline</v-icon> Совместимость
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToPath('/transit')">
              <v-list-item-title>
                <v-icon>mdi-approximately-equal</v-icon> Транзит
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="goToPath('/returns')">
              <v-list-item-title>
                <v-icon class="deg-160">mdi mdi-circle-off-outline</v-icon>
                Возвраты
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="informationDialog()">
              <v-list-item-title>
                <v-icon>mdi-information-outline</v-icon> О системе
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="goToPath('/profile')"
              v-bind:class="{
                btn_visible:
                  User.isLogged &&
                  $route.path !== '/reset' &&
                  $route.path !== '/feed' &&
                  $route.path !== '/chats' &&
                  $route.path !== '/dating' &&
                  $route.path !== '/socialprofile' &&
                  $route.path !== '/post',
                color_gradient_profile_delete:
                  ConfApp.tab === 1 &&
                  User.isLogged &&
                  User.reportid &&
                  User.is_stored &&
                  User.is_subscribed_to_justclick,
                color_gradient_profile_comp_delete:
                  ConfApp.tab === 2 &&
                  User.isLogged &&
                  $store.state.Composite.is_stored &&
                  User.is_subscribed_to_justclick
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_profile"
              dark
              text
              color="primary light-blue lighten-1"
            >
              <v-icon dark class="btn-icon-profile">
                mdi-account-search-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Кабинет</span>
            </v-btn>
          </template>
          <span>Личный кабинет</span>
        </v-tooltip>
        <div
          @click="goToPath('/profile')"
          v-bind:class="{
            hd_visible:
              $store.state.account.User.isLogged &&
              $route.path !== '/feed' &&
              $route.path !== '/chats' &&
              $route.path !== '/dating' &&
              $route.path !== '/socialprofile' &&
              $route.path !== '/post',
            mob_menu_item_active: ConfApp.tab === 5
          }"
          class="mob-menu-item-header hd_novisible"
        >
          <v-icon dark class="fs30 profile">mdi-account-search-outline</v-icon>
          Кабинет
        </div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="
                routePath = '/profile';
                showDialog = true;
              "
              v-bind:class="{
                btn_visible:
                  !$store.state.account.User.isLogged &&
                  $route.path !== '/feed' &&
                  $route.path !== '/chats' &&
                  $route.path !== '/dating' &&
                  $route.path !== '/socialprofile' &&
                  $route.path !== '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_profile"
              dark
              text
              color="primary light-blue lighten-1"
            >
              <v-icon dark class="btn-icon-profile">
                mdi-account-search-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Кабинет</span>
            </v-btn>
          </template>
          <span>Войдите или зарегистрируйтесь</span>
        </v-tooltip>
        <div
          @click="
            routePath = '/profile';
            showDialog = true;
          "
          v-bind:class="{
            hd_visible:
              !$store.state.account.User.isLogged &&
              $route.path !== '/feed' &&
              $route.path !== '/chats' &&
              $route.path !== '/dating' &&
              $route.path !== '/socialprofile' &&
              $route.path !== '/post'
          }"
          class="mob-menu-item-header hd_novisible"
        >
          <v-icon dark class="fs30 profile">mdi-account-search-outline</v-icon>
          Кабинет
        </div>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="goToPath('/products')"
              v-bind:class="{
                btn_visible:
                  $route.path !== '/reset' &&
                  $route.path !== '/feed' &&
                  $route.path !== '/chats' &&
                  $route.path !== '/dating' &&
                  $route.path !== '/socialprofile' &&
                  $route.path !== '/post',
                color_gradient_store_delete:
                  ConfApp.tab === 1 &&
                  User.isLogged &&
                  User.reportid &&
                  User.is_stored &&
                  User.is_subscribed_to_justclick,
                color_gradient_store_comp_delete:
                  ConfApp.tab === 2 &&
                  User.isLogged &&
                  $store.state.Composite.is_stored &&
                  User.is_subscribed_to_justclick
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_store"
              dark
              text
              color="deep-purple darken-1"
            >
              <v-icon dark class="btn-icon-pdf">mdi-cart-plus</v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Магазин</span>
            </v-btn>
          </template>
          <span>расширенные отчеты в виде PDF файлов</span>
        </v-tooltip>
        <div>
          <v-menu :offset-y="true" :nudge-top="10" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                v-bind:class="{
                  hd_visible:
                    $route.path !== '/reset' &&
                    $route.path !== '/feed' &&
                    $route.path !== '/chats' &&
                    $route.path !== '/dating' &&
                    $route.path !== '/socialprofile' &&
                    $route.path !== '/post',
                  mob_menu_item_active:
                    ConfApp.tab === 4 || ConfApp.tab === 7 || ConfApp.tab === 8
                }"
                :style="{ padding: 0 }"
                class="mob-menu-item-header hd_novisible"
                text
              >
                <v-icon dark class="fs30 store">mdi-cart-plus</v-icon>
                Магазин
              </v-btn>
            </template>
            <v-list>
              <!-- <v-list-item @click="goToPath('/consultations')">
                <v-list-item-title>
                  <v-icon>mdi-account-question</v-icon> Консультация
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item @click="goToPath('/books')">
                <v-list-item-title>
                  <v-icon>mdi-book-account</v-icon> Книги
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="goToPath('/products')">
                <v-list-item-title>
                  <v-icon>mdi-cart-plus</v-icon> Расшифровка
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <!-- <div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="$goTab(6)"
                v-bind:class="{
                  btn_visible:
                    User.isLogged &&
                    $route.path !== '/reset',
                  color_gradient_settings_delete:
                    onfApp.tab === 1 &&
                    User.isLogged &&
                    User.reportid &&
                    User.is_stored &&
                    User.is_subscribed_to_justclick,
                  color_gradient_settings_comp_delete:
                    ConfApp.tab === 2 &&
                    User.isLogged &&
                    $store.state.Composite.is_stored &&
                    User.is_subscribed_to_justclick
                }"
                class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_settings"
                dark
                text
                color="orange darken-1"
              >
                <v-icon dark class="btn-icon-settings">mdi-tune-vertical</v-icon>
                <span class="no-mobile">&nbsp;&nbsp;Настройки</span>
              </v-btn>
            </template>
            <span>Настройки</span>
          </v-tooltip>
          <div
            @click="$goTab(6)"
            v-bind:class="{
              hd_visible:
                User.isLogged &&
                $route.path !== '/reset',
              mob_menu_item_active: ConfApp.tab === 6
            }"
            class="mob-menu-item-header hd_novisible"
          >
            <v-icon dark class="fs30 settings">mdi-tune-vertical</v-icon>
            Настройки
          </div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="actionLogin(6)"
                v-bind:class="{
                  btn_visible:
                    !$store.state.account.User.isLogged &&
                    $route.path !== '/reset'
                }"
                class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_settings"
                dark
                text
                color="orange darken-1"
              >
                <v-icon dark class="btn-icon-settings">mdi-tune-vertical</v-icon>
                <span class="no-mobile">&nbsp;&nbsp;Настройки</span>
              </v-btn>
            </template>
            <span>Войдите или зарегистрируйтесь</span>
          </v-tooltip>
        </div> -->
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-bind:class="{
                btn_visible:
                  $route.path !== '/feed' &&
                  $route.path !== '/chats' &&
                  $route.path !== '/dating' &&
                  $route.path !== '/socialprofile' &&
                  $route.path !== '/post'
              }"
              @click="openSocial"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_community"
              dark
              text
            >
              <v-icon dark class="btn-icon-community" color="orange darken-1"
                >mdi-forum</v-icon
              >
              <span class="no-mobile">&nbsp;&nbsp;Чат</span>
              <v-badge
                v-if="!$store.state.conf.firstOpenSocial && User.isLogged"
                right
                color="red"
                class="custom-menu-bage2 pulse-item no-mobile"
              >
                <span slot="badge">
                  +
                </span>
                <span class="no-mobile">&nbsp;</span>
              </v-badge>
              <v-badge
                v-if="newMessagesCount && User.isLogged"
                right
                color="red"
                class="custom-menu-bage2 pulse-item no-mobile"
              >
                <span slot="badge">
                  {{ newMessagesCount }}
                </span>
                <span class="no-mobile">&nbsp;</span>
              </v-badge>
            </v-btn>
          </template>
          <span>Чат и общение</span>
        </v-tooltip>
        <v-btn
          v-if="
            $route.path !== '/feed' &&
              $route.path !== '/chats' &&
              $route.path !== '/dating' &&
              $route.path !== '/socialprofile' &&
              $route.path !== '/post'
          "
          @click="openSocial"
          text
          class="mob-menu-item-header hd_visible"
          style="padding:0"
        >
          <v-icon dark class="fs30 community">mdi-forum</v-icon>
          <v-badge
            v-if="!$store.state.conf.firstOpenSocial && User.isLogged"
            right
            color="red"
            class="custom-menu-bage3 pulse-item no-desktop"
          >
            <span slot="badge" style="color:white">
              +
            </span>
          </v-badge>
          <v-badge
            v-if="newMessagesCount && User.isLogged"
            right
            color="red"
            class="custom-menu-bage3 pulse-item no-desktop"
          >
            <span slot="badge" style="color:white">
              {{ newMessagesCount }}
            </span>
          </v-badge>
          Чат
        </v-btn>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="goToPath('/')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_profile"
              dark
              text
              color="primary light-blue lighten-1"
            >
              <img
                src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                height="24"
              />
              <span class="no-mobile">&nbsp;&nbsp;расчеты</span>
            </v-btn>
          </template>
          <span>Главная</span>
        </v-tooltip>
        <v-btn
          :style="{ padding: 0 }"
          @click="goToPath('/')"
          v-bind:class="{
            hd_visible:
              $route.path === '/feed' ||
              $route.path === '/chats' ||
              $route.path === '/dating' ||
              $route.path === '/socialprofile' ||
              $route.path === '/post'
          }"
          class="mob-menu-item-header hd_novisible"
          text
        >
          <img
            src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
            class="mob-bodygraph-icon"
          />
          Расчеты
        </v-btn>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="User.isLogged"
              v-on="on"
              @click="goToPath('/dating')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_search"
              dark
              text
              color="deep-purple darken-1"
            >
              <v-icon dark class="btn-icon-pdf"
                >mdi-account-multiple-outline</v-icon
              >
              <span class="no-mobile">&nbsp;&nbsp;Поиск</span>
            </v-btn>
            <v-btn
              v-else
              v-on="on"
              @click="showDialog = true"
              v-bind:class="{
                btn_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_search"
              dark
              text
              color="deep-purple darken-1"
            >
              <v-icon dark class="btn-icon-pdf"
                >mdi-account-multiple-outline</v-icon
              >
              <span class="no-mobile">&nbsp;&nbsp;Поиск</span>
            </v-btn>
          </template>
          <span>Поиск</span>
        </v-tooltip>
        <v-btn
          v-if="User.isLogged"
          @click="goToPath('/dating')"
          :style="{ padding: 0 }"
          v-bind:class="{
            hd_visible:
              $route.path === '/feed' ||
              $route.path === '/chats' ||
              $route.path === '/dating' ||
              $route.path === '/socialprofile' ||
              $route.path === '/post'
          }"
          class="mob-menu-item-header hd_novisible"
          text
        >
          <v-icon dark class="fs30 store2">mdi-account-multiple-outline</v-icon>
          Поиск
        </v-btn>
        <v-btn
          v-else
          @click="showDialog = true"
          :style="{ padding: 0 }"
          v-bind:class="{
            hd_visible:
              $route.path === '/feed' ||
              $route.path === '/chats' ||
              $route.path === '/dating' ||
              $route.path === '/socialprofile' ||
              $route.path === '/post'
          }"
          class="mob-menu-item-header hd_novisible"
          text
        >
          <v-icon dark class="fs30 store2">mdi-account-multiple-outline</v-icon>
          Поиск
        </v-btn>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="goToPath('/feed')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_feed"
              dark
              text
              color="deep-purple darken-1"
            >
              <v-icon dark color="teal accent-4">mdi-post-outline</v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Чат</span>
            </v-btn>
          </template>
          <span>Чат</span>
        </v-tooltip>
        <v-btn
          @click="goToPath('/feed')"
          :style="{ padding: 0 }"
          v-bind:class="{
            hd_visible:
              $route.path === '/feed' ||
              $route.path === '/chats' ||
              $route.path === '/dating' ||
              $route.path === '/socialprofile' ||
              $route.path === '/post'
          }"
          class="mob-menu-item-header hd_novisible"
          text
        >
          <v-icon dark color="teal accent-4" class="fs30"
            >mdi-post-outline</v-icon
          >
          Чат
        </v-btn>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="User.isLogged"
              v-on="on"
              @click="goToPath('/chats')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_settings"
              dark
              text
              color="deep-purple darken-1"
            >
              <v-badge
                v-if="newMessagesCount"
                right
                color="red"
                class="custom-menu-bage2 no-mobile"
              >
                <span slot="badge">
                  {{ newMessagesCount }}
                </span>
                <v-icon dark class="btn-icon-community" color="amber lighten-2">
                  mdi-chat-processing-outline
                </v-icon>
              </v-badge>
              <v-icon
                v-else
                dark
                class="btn-icon-community"
                color="amber lighten-2"
              >
                mdi-chat-processing-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Сообщения</span>
            </v-btn>
            <v-btn
              v-else
              v-on="on"
              @click="showDialog = true"
              v-bind:class="{
                btn_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_settings"
              dark
              text
              color="deep-purple darken-1"
            >
              <v-icon dark class="btn-icon-community" color="amber lighten-2">
                mdi-chat-processing-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Сообщения</span>
            </v-btn>
          </template>
          <span>Сообщения</span>
        </v-tooltip>
        <div v-if="User.isLogged">
          <v-badge
            v-if="newMessagesCount"
            right
            color="red"
            class="custom-menu-bage no-desktop hd_novisible"
            v-bind:class="{
              hd_visible:
                $route.path === '/feed' ||
                $route.path === '/chats' ||
                $route.path === '/dating' ||
                $route.path === '/socialprofile' ||
                $route.path === '/post'
            }"
          >
            <span slot="badge">
              {{ newMessagesCount }}
            </span>
            <v-btn
              @click="goToPath('/chats')"
              :style="{ padding: 0 }"
              v-bind:class="{
                hd_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mob-menu-item-header hd_novisible"
              text
            >
              <v-icon dark class="fs30" color="amber lighten-2"
                >mdi-chat-processing-outline</v-icon
              >
              Сообщения
            </v-btn>
          </v-badge>
          <v-btn
            v-else
            @click="goToPath('/chats')"
            :style="{ padding: 0 }"
            v-bind:class="{
              hd_visible:
                $route.path === '/feed' ||
                $route.path === '/chats' ||
                $route.path === '/dating' ||
                $route.path === '/socialprofile' ||
                $route.path === '/post'
            }"
            class="mob-menu-item-header hd_novisible"
            text
          >
            <v-icon dark class="fs30" color="amber lighten-2"
              >mdi-chat-processing-outline</v-icon
            >
            Сообщения
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            @click="showDialog = true"
            :style="{ padding: 0 }"
            v-bind:class="{
              hd_visible:
                $route.path === '/feed' ||
                $route.path === '/chats' ||
                $route.path === '/dating' ||
                $route.path === '/socialprofile' ||
                $route.path === '/post'
            }"
            class="mob-menu-item-header hd_novisible"
            text
          >
            <v-icon dark class="fs30" color="amber lighten-2"
              >mdi-chat-processing-outline</v-icon
            >
            Сообщения
          </v-btn>
        </div>
      </div>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="User.isLogged"
              v-on="on"
              @click="goToPath('/socialprofile')"
              v-bind:class="{
                btn_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_settings"
              dark
              text
              color="deep-purple darken-1"
            >
              <v-icon dark class="btn-icon-settings">
                mdi-account-circle-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Профиль</span>
            </v-btn>
            <v-btn
              v-else
              v-on="on"
              @click="showDialog = true"
              v-bind:class="{
                btn_visible:
                  $route.path === '/feed' ||
                  $route.path === '/chats' ||
                  $route.path === '/dating' ||
                  $route.path === '/socialprofile' ||
                  $route.path === '/post'
              }"
              class="mx-1_5 min-width-165 font-s-0-75r btn_novisible no-mobile color-gradient color_gradient_settings"
              dark
              text
              color="deep-purple darken-1"
            >
              <v-icon dark class="btn-icon-settings">
                mdi-account-circle-outline
              </v-icon>
              <span class="no-mobile">&nbsp;&nbsp;Профиль</span>
            </v-btn>
          </template>
          <span>Ваш профиль</span>
        </v-tooltip>
        <v-btn
          v-if="User.isLogged"
          @click="goToPath('/socialprofile')"
          :style="{ padding: 0 }"
          v-bind:class="{
            hd_visible:
              $route.path === '/feed' ||
              $route.path === '/chats' ||
              $route.path === '/dating' ||
              $route.path === '/socialprofile' ||
              $route.path === '/post'
          }"
          class="mob-menu-item-header hd_novisible"
          text
        >
          <v-icon dark class="fs30 settings">mdi-account-circle-outline</v-icon>
          Профиль
        </v-btn>
        <v-btn
          v-else
          @click="showDialog = true"
          :style="{ padding: 0 }"
          v-bind:class="{
            hd_visible:
              $route.path === '/feed' ||
              $route.path === '/chats' ||
              $route.path === '/dating' ||
              $route.path === '/socialprofile' ||
              $route.path === '/post'
          }"
          class="mob-menu-item-header hd_novisible"
          text
        >
          <v-icon dark class="fs30 settings">mdi-account-circle-outline</v-icon>
          Профиль
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="dialogsavereport"
      max-width="500px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="dialogsavereport = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <span style="display:block;margin-bottom:33px">
                  {{ dialogSaveReportText }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="dialogsavereport = false"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Понятно</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="socialDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      width="auto"
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text
          :class="{
            'no-top-bottom-padding': $vuetify.breakpoint.xsOnly,
            nopadding: $vuetify.breakpoint.xlOnly
          }"
        >
          <v-container
            :class="{
              'no-top-bottom-padding': $vuetify.breakpoint.xsOnly,
              nopadding: $vuetify.breakpoint.xlOnly
            }"
            style="height:100%"
          >
            <v-row style="height:100%">
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto;height:100%"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Социальная сеть
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="socialDialog = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <!-- <div class="pre-posting">
                  <v-list-item>
                    <v-list-item-content class="text-center">
                      <v-img
                        v-if="$device.isIos || $device.isAndroid"
                        src="/img/social/social_04_1.png"
                        class="stepper-image"
                      ></v-img>
                      <v-img
                        v-else
                        src="/img/social/social_04_1.png"
                        class="stepper-image-small"
                      ></v-img>
                      <div class="text-center color-0-8">
                        <h3 v-html="$getMessage('social_button_title')"></h3>
                        <span
                          v-if="$device.isIos || $device.isAndroid"
                          v-html="$getMessage('social_button_text')"
                          style="display:inline-block"
                        ></span>
                        <span
                          v-else
                          v-html="$getMessage('social_button_text2')"
                          style="display:inline-block"
                        ></span>
                      </div>
                      <a
                        href="https://human-design.space/social_networks/"
                        target="_blank"
                        class="flex-align-valign-center d-none d-sm-flex"
                      >
                        Подробнее...
                      </a>
                    </v-list-item-content>
                  </v-list-item>
                  <div class="text-center">
                    <v-btn
                      v-if="$device.isIos"
                      :href="pwaUrl"
                      target="_blank"
                      class="mx-2 min-width-120 margin-btm-5 hd-gradient"
                      large
                      color="primary"
                      style="margin:5px 0 25px"
                    >
                      Установить
                    </v-btn>
                    <v-btn
                      v-else-if="$device.isAndroid"
                      :href="gpUrl"
                      target="_blank"
                      class="mx-2 min-width-120 margin-btm-5 hd-gradient"
                      large
                      color="primary"
                      style="margin:5px 0 25px"
                    >
                      Установить
                    </v-btn>
                    <v-btn
                      @click="closeSocialDialog"
                      target="_blank"
                      class="mx-2 min-width-120 margin-btm-5 hd-gradient"
                      large
                      color="primary"
                      style="margin:5px 0 25px"
                    >
                      Регистрация
                    </v-btn>
                    <a
                      href="https://human-design.space/social_networks/"
                      target="_blank"
                      class="flex-align-valign-center d-flex d-sm-none"
                      style="margin-top:7px"
                    >
                      Подробнее...
                    </a>
                  </div>
                </div> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmEmailDialog"
      max-width="500px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="confirmEmailDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col cols="12" class="text-center no-top-bottom-padding">
                Вы вошли в систему, но не подтвердили свой email, соцсеть пока
                недоступна. Не пришло письмо с подтверждением подписки?
                <br />
                <v-btn
                  @click="reSendMail"
                  class="mx-2 margin-top-10-mob margin-btm-5"
                  small
                  color="deep-purple darken-1"
                  style="margin:15px 0 55px;color:#fff"
                >
                  <v-icon left>mdi-email-multiple-outline</v-icon> Выслать
                  повторно
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LoginDialog
      :showDialog.sync="showDialog"
      :routePath.sync="routePath"
      @close="closeDialog"
    />
    <!-- <progress-dialog :progress="progressdialog" /> -->
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
// import YandexShare from "@cookieseater/vue-yandex-share";
// import ProgressDialog from "~/components/loaders/progress-dialog";
// import iconBg from "~/static/svg/bodygraph-icon.svg";

export default {
  name: "MenuBottom",
  components: { LoginDialog },
  // components: { YandexShare, ProgressDialog },
  mixins: [commonReportingMethods, commonErrorsMethods],
  data() {
    return {
      showDialog: false,
      // iconBg,
      dialogSaveReportText: "",
      dialogsavereport: false,
      dialogPartner: false,
      confirmEmailDialog: false,
      socialDialog: false,
      progressdialog: false,
      gpUrl: process.env.GP_URL,
      pwaUrl: process.env.PWA_URL,
      pwaSocUrl: process.env.PWA_SOC_URL,
      clipped: false,
      drawer: false,
      routePath: "",
      items: [
        {
          icon: "mdi-apps",
          title: "Главная",
          to: "/",
          visible: true
        },
        {
          icon: "mdi-account",
          title: "Регистрация/Вход",
          to: "/login",
          visible: true
        },
        {
          icon: "mdi-exit-run",
          title: "Выход",
          to: "/logout",
          visible: true
        }
      ]
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      Composite: (state) => state.account.Composite,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    newMessagesCount() {
      return this.User.community.newMessagesCount;
    }
  },
  mounted() {
    // console.log(this.$route.path)
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "CALL_SHARE_REPORT":
          if (state.User.is_user_product) {
            this.shareReport(state.User.share_reportid, true);
          } else {
            this.shareReport(state.User.share_reportid, false);
          }
          break;
        case "account/confirm_email_dialog":
          this.confirmEmailDialog = true;
          break;
        case "account/confirm_email_dialog_close":
          this.confirmEmailDialog = false;
          break;
      }
    });
  },
  methods: {
    ...mapMutations([
      "CALL_CHART_COMPOSITE_REPORT",
      "CALL_UPDATE_PROFILE_FULL_TOOLTIPS_COUNT"
    ]),
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    actionLogin(n) {
      if (localStorage.getItem("regTab")) {
        this.$store.commit("conf/update_reg_tab", 1);
      } else {
        this.$store.commit("conf/update_reg_tab", 0);
      }
      this.update_login_dialog(true);
      this.$store.commit("UPDATE_CONF_APP_T_TAB", n);
      this.$store.commit("UPDATE_CLEAR_TOOLTIPS", 1);
    },
    async actionReport(id, method) {
      try {
        let apiMethod;
        method === "add"
          ? (apiMethod = Api.storeRaveReport)
          : (apiMethod = Api.unstoreRaveReport);
        const reportParams = {
          report_id: id
        };
        const result = await apiMethod(reportParams, this.HttpOptions);
        if (result.success) {
          localStorage.setItem("is_stored", result.report.is_stored);
          this.$store.commit(
            "account/update_is_stored",
            result.report.is_stored
          );
          if (result.report.is_stored) {
            this.dialogSaveReportText =
              "Вы добавили текущий расчет в личный кабинет";
          } else {
            this.dialogSaveReportText =
              "Вы удалили текущий расчет из личного кабинета";
          }
          this.dialogsavereport = true;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async actionComposite(id, method) {
      try {
        let apiMethod;
        method === "add"
          ? (apiMethod = Api.storeCompositeReport)
          : (apiMethod = Api.unstoreCompositeReport);
        const reportParams = {
          report_id: id
        };
        const result = await apiMethod(reportParams, this.HttpOptions);
        if (result.success) {
          this.$store.commit(
            "account/update_composite_is_stored",
            result.report.is_stored
          );
          if (result.report.is_stored) {
            this.dialogSaveReportText =
              "Вы добавили текущую совместимость в личный кабинет";
          } else {
            this.dialogSaveReportText =
              "Вы удалили текущий совместимость из личного кабинета";
          }
          this.dialogsavereport = true;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    shareReport(id, userProduct) {
      const data = { id, userProduct };
      this.$store.dispatch("account/shareReport", data);
    },
    async reSendMail() {
      try {
        const result = await Api.getJustclickSubscription(this.HttpOptions);
        if (result.success) {
          this.$store.commit("account/update_justclick", result.justclick);
          this.confirmEmailDialog = false;
          this.$store.commit("conf/update_service_modal", {
            message: result.justclick
              ? "Спасибо! Подписка активирована.<br />"
              : "Письмо отправлено на вашу почту. Проверьте все папки.<br />"
          });
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    copyToClipboard() {
      this.copied = true;
      setTimeout(() => (this.copied = false), 1500);
      this.$refs.targetUrl.select();
      document.execCommand("copy");
    },
    openSocial() {
      // firstOpenSocial
      this.$store.commit("account/reports_new_messages_count", 0);
      if (!localStorage.getItem("firstOpenSocial") && this.User.isLogged) {
        localStorage.setItem("firstOpenSocial", true);
        setTimeout(() => {
          this.$store.commit("conf/update_first_open_social", true);
        }, 0);
      }
      if (this.$route.path !== "/feed") {
        this.goToPath("/feed");
      }
    },
    closeSocialDialog() {
      this.socialDialog = false;
      this.update_login_dialog(true);
    },
    callChartCompositeReport() {
      this.CALL_CHART_COMPOSITE_REPORT();
    },
    closeDialog(val) {
      // console.log("emitReg: ", val);
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
      this.routePath = "";
    },
    informationDialog() {
      this.$store.commit("conf/update_service_close_modal", {
        message: this.getMessage("intro")
      });
    }
  }
};
</script>
<style scoped>
.bt-mn-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-menu {
  height: 50px;
  min-width: 320px;
  max-width: 980px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ya-share2__list {
  padding: 0 !important;
}
.partner-info-text p {
  font-size: 15px;
  line-height: 17px;
  text-align: left;
  margin-top: 15px;
  text-align: center;
}
.theme--dark .partner-info-text p {
  color: #fb8c00;
}
.partner-info-text2 {
  color: #398296;
}
.theme--dark .partner-info-text2 {
  color: #4bacc6;
}
</style>
