<template>
  <v-card
    v-if="!$store.state.account.User.isLogged"
    flat
    d-flex
    pa-2
    class="card-md-small login-reg-tabs-bg"
  >
    <v-card-title
      class="headline padding-bottom-0 headline-login-reg-height"
      style="font-size: 1rem !important;line-height:1.3rem"
    >
      Введите своё email и имя
    </v-card-title>
    <v-form ref="form" @keyup.native.enter="validateReg" lazy-validation>
      <v-card-text class="padding-top-0 padding-bottom-0">
        <v-row>
          <v-col cols="12" class="text-center">
            <div class="log-sucsess">{{ regSuccess }}</div>
            <div class="log-errors">{{ regErrors }}</div>
          </v-col>
          <v-col
            class="padding-top-0 padding-bottom-0"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-text-field
              v-model="email1"
              :rules="[rules.required, rules.emailReg]"
              validate-on-blur
              label="E-mail"
              required
            ></v-text-field>
          </v-col>
          <v-col
            class="padding-top-0 padding-bottom-0"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-text-field
              v-model="firstname"
              :rules="[rules.required, rules.min2, rules.counter]"
              :counter="35"
              validate-on-blur
              label="Имя"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="nopadding height-28"></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex mob-button-login-dialog justify-center">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
            class="text-center margin-top-action-btn"
          >
            <v-btn
              :loading="loading"
              :disabled="loading"
              @click="validateReg"
              rounded
              large
              color="primary"
              class="v-btn-custom hd-gradient min-width-170"
            >
              Поехали&nbsp;&nbsp;<v-icon>mdi-rocket</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-card>
  <v-card
    v-else
    flatd-flex
    pa-2
    class="card-md-small card-md-small-flex login-reg-tabs-bg mob-login-reg-tabs-flex-al-center"
  >
    <!-- <div class="card-md-small-gradient"></div> -->
    <div
      v-if="$store.state.account.User.registered"
      class="display-0-24-no-line-h text-center padding-16"
      style="margin: 0 auto"
    >
      <img
        src="https://static.human-design.space/widget/images/bgs/profil.png"
        width="120"
        class="padding-top-100-nodesc padding-bottom-20"
      />
      <center class="login-confirm-header">
        Здравствуйте {{ User.name }}, вы вошли в систему.
      </center>
      <v-btn
        @click="closeDialog()"
        class="mx-2 margin-top-25 margin-btm-45 hd-gradient min-width-145"
        large
        color="primary accent-4"
        style="font-size: 0.85rem"
      >
        Чудесно&nbsp;&nbsp;<v-icon small>mdi-magic-staff</v-icon>
      </v-btn>
    </div>
    <div v-else class="display-0-24-no-line-h text-center padding-16 width-100">
      <img
        src="https://static.human-design.space/widget/images/bgs/access_02.png"
        width="120"
        class="padding-top-100-nodesc padding-bottom-20"
      />
      <center class="login-confirm-header">
        Мы только что отправили вам письмо с доступами
      </center>
      <div>
        <div style="padding:24px 0">адрес: {{ User.email }}</div>
        <v-btn
          @click="logout(User.name, 0)"
          class="mx-2 margin-top-10-mob margin-btm-45 hd-gradient"
          large
          color="primary accent-4"
          style="font-size: 0.85rem"
        >
          изменить почту&nbsp;&nbsp;<v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
// import Api from "@/api/api";
import { mapState } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";

export default {
  name: "Login",
  mixins: [commonInitAppMethods, commonReportingMethods],
  props: {
    closeButton: {
      type: Boolean,
      default: true
    },
    email: {
      type: String,
      default: ""
    },
    activeNameTab: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    knowledge: 75,
    show: false,
    loader: null,
    loading: false,
    loading2: false,
    rules: {
      required: (value) => !!value || "Обязательный параметр.",
      min: (v) => {
        v = v.trim();
        // this.password = v;
        if (v) {
          const s =
            v.length >= 6 ? true : "Минимальное количество символов — 6";
          return s;
        } else {
          return false;
        }
      },
      min2: (v) => {
        v = v.trim();
        // this.firstname = v;
        if (v) {
          const s =
            v.length >= 3 ? true : "Минимальное количество символов — 3";
          return s;
        } else {
          return false;
        }
      },
      counter: (v) => {
        if (v) {
          const s =
            v.length <= 35 ? true : "Максимальное количество символов — 35";
          return s;
        } else {
          return false;
        }
      },
      emailMatch: () => "Неверный email или пароль",
      emailReg: (value) => {
        if (value) value = value.trim();
        // this.email1 = value;
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Неверный e-mail.";
      }
    }
  }),
  computed: {
    ...mapState({
      User: (state) => state.account.User
    }),
    firstname: {
      get() {
        return this.$store.state.account.User.name;
      },
      set(value) {
        this.$store.commit("account/update_name", value);
      }
    },
    email1: {
      get() {
        return this.$store.state.account.User.email;
      },
      set(value) {
        this.$store.commit("account/update_email", value);
      }
    },
    email2: {
      get() {
        return this.$store.state.account.User.email;
      },
      set(value) {
        this.$store.commit("account/update_email", value);
      }
    },
    regSuccess: {
      get() {
        return this.$store.state.forms.RegForm.regSuccess;
      },
      set(v) {
        this.$store.commit("forms/setRegSuccess", v);
      }
    },
    regErrors: {
      get() {
        return this.$store.state.forms.RegForm.regErrors;
      },
      set(v) {
        this.$store.commit("forms/setRegErrors", v);
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "forms/closeRegDialog") {
        this.closeDialog();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 5000);
      this.loader = null;
    }
  },
  methods: {
    async validateReg() {
      if (this.$refs.form.validate()) {
        // console.log('email: ', this.email1)
        // console.log('имя: ', this.firstname)
        // this.regSuccess = ''
        // console.log(this.regSuccess);
        this.loader = "loading";
        this.regSuccess = "";
        this.regErrors = "";
        if (this.email1) this.email1 = this.email1.trim();
        const regUrl = {
          email: this.email1,
          first_name: this.firstname
        };
        this.$store.dispatch("account/getRegistration", regUrl);
      }
    },
    closeDialog() {
      this.regSuccess = "";
      this.regErrors = "";
      this.$emit("close");
      setTimeout(() => {
        this.scrollToTop();
      }, 100);
    }
  }
};
</script>
