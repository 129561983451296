<template>
  <v-card id="userHead" flat d-flex pa-2 class="card-md">
    <v-card-text style="padding:0">
      <v-row v-if="preloading">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-auto padding-15-mob-15-0" max-width="950">
            <v-skeleton-loader
              class="mx-auto"
              max-width="100%"
              height="500"
              type="image, image"
            ></v-skeleton-loader>
            <v-list>
              <v-list-item>
                <v-list-item-content
                  :class="{ 'flex-center': id === User.id }"
                  style="padding:0"
                >
                  <v-col class="text-center" cols="12">
                    <div style="padding-top:5px">
                      <v-skeleton-loader
                        class="mx-auto"
                        max-width="195"
                        type="text"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        class="mx-auto"
                        max-width="95"
                        type="text"
                      ></v-skeleton-loader>
                    </div>
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
            </v-list>
            <v-sheet class="pa-3" style="max-width:160px">
              <v-skeleton-loader
                class="mx-auto"
                max-width="150"
                type="text"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="mx-auto"
                max-width="50"
                type="text"
              ></v-skeleton-loader>
            </v-sheet>
            <v-sheet class="pa-3" style="max-width:160px">
              <v-skeleton-loader
                class="mx-auto"
                max-width="150"
                type="text"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="mx-auto"
                max-width="50"
                type="text"
              ></v-skeleton-loader>
            </v-sheet>
            <v-sheet class="pa-3" style="max-width:160px">
              <v-skeleton-loader
                class="mx-auto"
                max-width="150"
                type="text"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="mx-auto"
                max-width="50"
                type="text"
              ></v-skeleton-loader>
            </v-sheet>
            <v-sheet class="pa-3" style="max-width:160px">
              <v-skeleton-loader
                class="mx-auto"
                max-width="150"
                type="text"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="mx-auto"
                max-width="50"
                type="text"
              ></v-skeleton-loader>
            </v-sheet>
            <v-sheet class="pa-3" style="max-width:160px">
              <v-skeleton-loader
                class="mx-auto"
                max-width="150"
                type="text"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="mx-auto"
                max-width="50"
                type="text"
              ></v-skeleton-loader>
            </v-sheet>
            <v-sheet class="pa-3" style="max-width:160px">
              <v-skeleton-loader
                class="mx-auto"
                max-width="150"
                type="text"
              ></v-skeleton-loader>
              <v-skeleton-loader
                class="mx-auto"
                max-width="50"
                type="text"
              ></v-skeleton-loader>
            </v-sheet>

            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-auto padding-15-mob-15-0" max-width="950">
            <div
              v-if="imagesControl + 1 === profileImages.length"
              class="text-center community-request-image-block"
            >
              <span
                v-html="getMessage('social_text_request_more_photos')"
              ></span>
              <br />
              <v-btn
                @click="requestImage"
                class="hd-gradient margin-top-15"
                color="primary"
                >Отправить&nbsp;<v-icon>mdi-send</v-icon></v-btn
              >
            </div>
            <v-carousel
              v-if="profileImages.length"
              v-model="imagesControl"
              hide-delimiters
              hide-delimiter-background
              progress
              progress-color="#fff"
              class="custom-carusel"
            >
              <v-carousel-item
                v-for="(item, j) in profileImages"
                :key="j"
                :src="item.src"
              ></v-carousel-item>
            </v-carousel>
            <div v-else class="bg-size-40">
              <v-img
                src="https://static.human-design.space/widget/images/social/socialprofile-preview.png"
                height="350px"
              ></v-img>
            </div>
            <v-list>
              <v-list-item>
                <v-list-item-content
                  :class="{ 'flex-center': id === User.id }"
                  style="padding:0"
                >
                  <!-- <v-col :class="{ 'text-center': id === User.id }" cols="12"> -->
                  <v-col class="text-center" cols="12">
                    <div style="padding-top:5px">
                      <span style="font-size:19px;font-weight:400">
                        {{ firstname }}<span v-if="age"> {{ age }},</span
                        ><br /><span v-if="chart_type"
                          >{{ chart_type }} {{ chart_profile }}</span
                        >
                      </span>
                    </div>
                    {{ cityname }}<br />
                    <a
                      v-if="User.is_admin"
                      :href="adminUserUrl"
                      target="_blank"
                    >
                      Редактировать профиль
                    </a>
                  </v-col>
                  <v-col
                    v-if="+id !== +User.id"
                    cols="12"
                    class="flex-center"
                    style="flex-direction:column"
                  >
                    <send-message />
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list two-line subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Настроение сейчас</v-list-item-title>
                    <v-list-item-subtitle style="white-space:initial">
                      <span v-if="slogan">{{ slogan }}</span>
                      <span v-else>не указано.</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="targets.length">
                  <v-list-item-content>
                    <v-list-item-title>Цель знакомства</v-list-item-title>
                    <v-list-item-subtitle>
                      <span v-for="(item, i) in showTargets(targets)" :key="i">
                        {{ item
                        }}<span v-if="i < showTargets(targets).length - 1"
                          >,</span
                        >
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>О себе</v-list-item-title>
                    <v-list-item-subtitle style="white-space:initial">
                      <span v-if="about">{{ about }}</span>
                      <span v-else>не указано.</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Зачем я здесь?</v-list-item-title>
                    <v-list-item-subtitle style="white-space:initial">
                      <span v-if="why_am_i_here">{{ why_am_i_here }}</span>
                      <span v-else>не указано.</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Познания в HD</v-list-item-title>
                    <v-list-item-subtitle style="white-space:initial">
                      {{ hd_level }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="height || weight">
                  <v-list-item-content class="flex">
                    <v-col
                      v-if="height"
                      cols="2"
                      md="1"
                      lg="1"
                      xl="1"
                      style="padding:0"
                    >
                      <v-list-item-title>Рост</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ height }}
                      </v-list-item-subtitle>
                    </v-col>
                    <v-col
                      v-if="weight"
                      cols="10"
                      md="11"
                      lg="11"
                      xl="11"
                      style="padding:0"
                    >
                      <v-list-item-title>Вес</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ weight }}
                      </v-list-item-subtitle>
                    </v-col>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="sex">
                  <v-list-item-content>
                    <v-list-item-title>Пол</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ sex }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="maritalStatus">
                  <v-list-item-content>
                    <v-list-item-title>Семейное положение</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ maritalStatus.text }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Дети</v-list-item-title>
                    <v-list-item-subtitle v-if="children.length">
                      <span
                        v-for="(item, i) in showChildren(children)"
                        :key="i"
                      >
                        {{ item
                        }}<span v-if="i < showChildren(children).length - 1"
                          >,</span
                        >
                      </span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      нет
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="visitedСountries">
                  <v-list-item-content>
                    <v-list-item-title
                      >Я был(а) в этих странах</v-list-item-title
                    >
                    <v-list-item-subtitle style="white-space:initial">
                      {{ visitedСountries }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="iUnderstand">
                  <v-list-item-content>
                    <v-list-item-title>Я разбираюсь</v-list-item-title>
                    <v-list-item-subtitle style="white-space:initial">
                      {{ iUnderstand }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <!-- <v-divider></v-divider>
  
                <v-list-item>
                  <v-list-item-content>
                    <v-col
                      cols="6"
                      class="flex-center"
                      style="border-right: 1px solid #0000001f"
                    >
                      <bodygraph />
                    </v-col>
                    <v-col cols="6" class="flex-center">
                      <compatibility />
                    </v-col>
                  </v-list-item-content>
                </v-list-item> -->

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content>
                  <v-col
                    cols="4"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <Followers :id="id" />
                  </v-col>
                  <v-col
                    cols="4"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <Subscriptions :id="id" />
                  </v-col>
                  <v-col cols="4" class="flex-center">
                    <Subscribe
                      :id="id"
                      :isFollowing="isFollowing"
                      @select="getProfile"
                    />
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="padding-top-0 padding-bottom-0">
                  <v-col
                    cols="6"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <user-posts :method="'userposts'" :userId="id" />
                  </v-col>
                  <v-col
                    cols="6"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <user-posts :method="'favourites'" :userId="id" />
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content
                  class="nopadding mob-nopadding mob-column-reverse"
                >
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="12"
                    class="flex-center"
                  >
                    <Blocking
                      :id="id"
                      :isBlocked="isBlocked"
                      @clicked="getProfile"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="12"
                    class="flex-center"
                  >
                    <promo-code />
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-text v-if="maintenanceWork">
              <div>
                <v-alert
                  type="warning"
                  class="darken-orange-custm-1"
                  color="lighten-3"
                >
                  Внимание! Проводятся технические работы, некоторые функции
                  личного кабинета могут не работать!
                </v-alert>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="dialogMyCard"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Рейв-карта пользователя:
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="dialogMyCard = false" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-col cols="12">
                  <v-img :src="bodygraph"></v-img>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="successRequestImage" top color="success">
      <div class="d-flex">
        <v-icon color="white">mdi-check-circle-outline</v-icon>
        <div>&nbsp;Ваше сообщение успешно отправлено!</div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successRequestImage = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import Followers from "@/components/community/profile/followers.vue";
import Subscriptions from "@/components/community/profile/subscriptions.vue";
import Subscribe from "@/components/community/profile/subscribe.vue";
import SendMessage from "@/components/community/profile/send-message.vue";
import Blocking from "@/components/community/profile/blocking.vue";
import UserPosts from "@/components/community/posts/user-posts";
import PromoCode from "@/components/community/profile/promo-code";
// import compatibility from '~/components/community/profile/compatibility'
// import bodygraph from '~/components/community/profile/bodygraph'

export default {
  components: {
    Subscribe,
    Subscriptions,
    Followers,
    SendMessage,
    UserPosts,
    Blocking,
    PromoCode
    // compatibility,
    // bodygraph
  },
  props: {
    userId: {
      type: Number,
      default: null
    }
  },
  mixins: [commonReportingMethods],
  data() {
    return {
      preloading: true,
      selection: 1,
      dialogMyCard: false,
      maintenanceWork: false,
      images: [],
      imagesSources: [],
      profileImages: [],
      imagesControl: 0,
      bodygraph: null,
      isImageValidation: true,
      isFollowing: false,
      isBlocked: false,
      isLikedByMe: false,
      isDislikedByMe: false,
      likesMe: false,
      successRequestImage: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    firstname: {
      get() {
        return this.User.another_name;
      },
      set(value) {
        this.$store.commit("account/update_another_name", value);
      }
    },
    chart_type: {
      get() {
        return this.User.community.chart_type;
      },
      set(value) {
        this.$store.commit("account/set_comm_chart_type", value);
      }
    },
    chart_profile: {
      get() {
        return this.User.community.chart_profile;
      },
      set(value) {
        this.$store.commit("account/set_comm_chart_profile", value);
      }
    },
    city: {
      get() {
        return this.User.community.cityid;
      },
      set(value) {
        this.$store.commit("update_comm_city_id", value);
        this.mathCitiName(value);
      }
    },
    cityname: {
      get() {
        return this.User.community.city;
      }
    },
    age: {
      get() {
        return this.User.age;
      },
      set(value) {
        this.$store.commit("update_user_age", value);
      }
    },
    slogan: {
      get() {
        return this.User.community.slogan;
      },
      set(value) {
        this.$store.commit("update_comm_slogan", value);
      }
    },
    about: {
      get() {
        return this.User.community.about;
      },
      set(value) {
        this.$store.commit("update_comm_about", value);
      }
    },
    why_am_i_here: {
      get() {
        return this.User.community.why_am_i_here;
      },
      set(value) {
        this.$store.commit("update_comm_why_am_i_here", value);
      }
    },
    hd_level: {
      get() {
        return this.User.community.hd_level;
      },
      set(v) {
        this.$store.commit("update_comm_hd_level", v);
      }
    },
    height: {
      get() {
        return this.User.community.height;
      },
      set(val) {
        this.$store.commit("SET_COMM_HRIGHT", val);
      }
    },
    weight: {
      get() {
        return this.User.community.weight;
      },
      set(val) {
        this.$store.commit("account/set_comm_weight", val);
      }
    },
    sex: {
      get() {
        return this.User.community.sex;
      },
      set(value) {
        this.$store.commit("account/set_user_sex", value);
      }
    },
    maritalStatus: {
      get() {
        return this.User.community.marital_status;
      },
      set(value) {
        this.$store.commit("set_user_marital_status", value);
      }
    },
    visitedСountries: {
      get() {
        return this.User.community.visited_countries;
      },
      set(value) {
        this.$store.commit("account/set_user_visited_countries", value);
      }
    },
    iUnderstand: {
      get() {
        return this.User.community.my_profile.i_understand;
      },
      set(value) {
        this.$store.commit("account/set_user_i_understand", value);
      }
    },
    sexSelect: {
      get() {
        return this.User.community.sexselect;
      },
      set(value) {
        this.$store.commit("account/set_user_sexselect", value);
      }
    },
    maritalStatuses: {
      get() {
        return this.User.community.marital_statuses;
      },
      set(value) {
        this.$store.commit("account/set_user_marital_statuses", value);
      }
    },
    children: {
      get() {
        return this.User.community.children;
      },
      set(value) {
        this.$store.commit("account/set_user_children", value);
      }
    },
    childrenSelect: {
      get() {
        return this.User.community.children_select;
      },
      set(value) {
        this.$store.commit("account/set_user_children_select", value);
      }
    },
    targets: {
      get() {
        return this.User.community.targets;
      },
      set(value) {
        this.$store.commit("account/set_user_targets", value);
      }
    },
    targetSelect: {
      get() {
        return this.User.community.target_select;
      },
      set(value) {
        this.$store.commit("account/set_user_target_select", value);
      }
    },
    id() {
      if (this.userId) {
        return this.userId;
      } else {
        return +this.$route.query.id;
      }
    },
    adminUserUrl() {
      return "https://admin.human-design.space/users/index?id=" + this.userId;
    }
  },
  mounted() {
    const id = this.id;
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "CALL_UPDATE_PROFILE":
          // console.log(state.User.community.callUserId)
          this.imagesControl = 0;
          if (this.User.community.callUserId) {
            this.getProfile(this.User.community.callUserId);
          } else {
            this.getProfile(id);
          }
          break;
        case "account/call_user_view_dialog":
          this.imagesControl = 0;
          this.getProfile(this.User.community.viewId);
          break;
      }
    });
    setTimeout(() => this.getProfile(id), 0);
  },
  methods: {
    ...mapMutations({
      call_followers_update: "account/call_followers_update",
      call_subscriptions_update: "account/call_subscriptions_update",
      set_promo_code_url: "account/set_promo_code_url"
    }),
    mathCitiName(id) {
      for (const item of this.cities) {
        if (item.id === id) {
          this.$store.commit("update_comm_city_name", item.title);
          break;
        }
      }
    },
    async getProfile(id) {
      try {
        this.preloading = true;
        const result = await Api.getUserSocial(id, this.HttpOptions);
        if (result.success) {
          // this.socialProfile = this.objectNormalization(result.social_profile)
          if (result.social_profile) {
            this.$store.commit(
              "account/set_user_selected",
              result.social_profile
            );
          }
          if (result.social_profile.chart_type) {
            this.$store.commit(
              "account/set_comm_chart_type",
              result.social_profile.chart_type.title
            );
          } else {
            this.$store.commit("account/set_comm_chart_type", "");
          }
          if (result.social_profile.chart_profile) {
            this.$store.commit(
              "account/set_comm_chart_profile",
              result.social_profile.chart_profile.id
            );
          } else {
            this.$store.commit("account/set_comm_chart_profile", "");
          }
          if (result.social_profile.slogan) {
            this.$store.commit(
              "account/update_comm_slogan",
              result.social_profile.slogan
            );
          } else {
            this.$store.commit("account/update_comm_slogan", "");
          }
          if (result.social_profile.about) {
            this.$store.commit(
              "account/update_comm_about",
              result.social_profile.about
            );
          } else {
            this.$store.commit("account/update_comm_about", "");
          }
          if (result.social_profile.hd_level) {
            const hdLevel = result.social_profile.hd_level;
            this.$store.commit("account/update_comm_hd_level", hdLevel);
          }
          if (result.social_profile.why_am_i_here) {
            this.$store.commit(
              "account/update_comm_why_am_i_here",
              result.social_profile.why_am_i_here
            );
          } else {
            this.$store.commit("account/update_comm_why_am_i_here", "");
          }
          if (result.social_profile.first_name) {
            this.$store.commit(
              "account/update_another_name",
              result.social_profile.first_name
            );
          } else {
            this.$store.commit("account/update_another_name", "");
          }
          if (result.social_profile.age) {
            this.$store.commit(
              "account/update_user_age",
              result.social_profile.age.toString()
            );
          } else {
            this.$store.commit("account/update_user_age", "");
          }
          if (result.social_profile.images) {
            this.profileImages = [];
            result.social_profile.images.forEach((item) => {
              this.profileImages.push({
                id: item.id,
                name: item.src.toString().match(/.*\/(.+?)\.*$/)[1],
                src: item.src
              });
            });
          }
          if (
            result.social_profile.bodygraph &&
            result.social_profile.images.length
          ) {
            this.profileImages.splice(1, 0, {
              id: "bg999",
              name: "Боди-граф",
              src: result.social_profile.bodygraph
            });
            this.bodygraph = result.social_profile.bodygraph;
          }
          if (
            result.social_profile.bodygraph &&
            !result.social_profile.images.length
          ) {
            this.profileImages.push({
              id: 1,
              name: "Default ava",
              src:
                "https://static.human-design.space/widget/images/social/socialprofile-preview.png"
            });
            this.profileImages.push({
              id: 2,
              name: "Боди-граф",
              src: result.social_profile.bodygraph
            });
            this.bodygraph = result.social_profile.bodygraph;
          }
          // Add fake image to array end
          this.profileImages.push({
            id: this.profileImages.length + 2,
            name: "fake image",
            src: ""
          });
          if (result.social_profile.city) {
            this.$store.commit(
              "account/update_comm_city_id",
              result.social_profile.city.id
            );
            this.$store.commit(
              "account/update_comm_city_name",
              result.social_profile.city.title
            );
            this.cities = [result.social_profile.city];
          } else {
            this.$store.commit("account/update_comm_city_id", null);
            this.$store.commit("account/update_comm_city_name", "");
          }
          if (result.social_profile.followers_count >= 0) {
            this.$store.commit(
              "account/set_comm_followers",
              result.social_profile.followers_count
            );
          } else {
            this.$store.commit("account/set_comm_followers", 0);
          }
          if (result.social_profile.following_count >= 0) {
            this.$store.commit(
              "account/set_comm_following",
              result.social_profile.following_count
            );
          } else {
            this.$store.commit("account/set_comm_following", 0);
          }
          if (result.social_profile._meta.is_followed_by_me) {
            this.isFollowing = true;
          } else {
            this.isFollowing = false;
          }
          if (result.social_profile._meta.is_liked_by_me) {
            this.isLikedByMe = true;
          } else {
            this.isLikedByMe = false;
          }
          if (result.social_profile._meta.is_disliked_by_me) {
            this.isDislikedByMe = true;
          } else {
            this.isDislikedByMe = false;
          }
          if (result.social_profile._meta.is_blocked_by_me) {
            this.isBlocked = true;
          } else {
            this.isBlocked = false;
          }
          if (result.social_profile._meta.likes_me) {
            this.likesMe = true;
          } else {
            this.likesMe = false;
          }
          // console.log(this.isFollowing, this.isLikedByMe, this.likesMe)
          this.call_followers_update();
          this.call_subscriptions_update();
          if (result.social_profile.height) {
            this.$store.commit(
              "account/set_comm_height",
              result.social_profile.height.toString()
            );
          } else {
            this.$store.commit("account/set_comm_height", "");
          }

          if (result.social_profile.weight) {
            this.$store.commit(
              "account/set_comm_weight",
              result.social_profile.weight.toString()
            );
          } else {
            this.$store.commit("account/set_comm_weight", "");
          }
          if (result.social_profile.sex) {
            this.sexSelect.forEach((item, idx) => {
              if (+result.social_profile.sex === +idx) {
                this.$store.commit("account/set_user_sex", item);
              }
            });
          } else {
            this.$store.commit("account/set_user_sex", null);
          }
          if (result.social_profile.marital_status) {
            this.maritalStatuses.forEach((item) => {
              if (+result.social_profile.marital_status === +item.value) {
                this.$store.commit("account/set_user_marital_status", item);
              }
            });
          } else {
            this.$store.commit("account/set_user_marital_status", null);
          }
          if (result.social_profile.targets) {
            this.$store.commit(
              "account/set_user_targets",
              result.social_profile.targets
            );
          } else {
            this.$store.commit("account/set_user_targets", []);
          }
          if (result.social_profile.children) {
            this.$store.commit(
              "account/set_user_children",
              result.social_profile.children
            );
          } else {
            this.$store.commit("account/set_user_children", []);
          }
          if (result.social_profile.visited_countries) {
            this.$store.commit(
              "account/set_user_visited_countries",
              result.social_profile.visited_countries
            );
          } else {
            this.$store.commit("account/set_user_visited_countries", "");
          }
          if (result.social_profile.i_understand) {
            this.$store.commit(
              "account/set_user_i_understand",
              result.social_profile.i_understand
            );
          } else {
            this.$store.commit("account/set_user_i_understand", "");
          }
          if (result.social_profile.social_network_product_url) {
            this.set_promo_code_url(
              result.social_profile.social_network_product_url
            );
          } else {
            this.set_promo_code_url("");
          }
          this.preloading = false;
          this.scrollUp();
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async requestImage() {
      try {
        const request = {
          user_id: this.userId
        };
        const result = await Api.requestUserImage(request, this.HttpOptions);
        if (result.success) {
          this.successRequestImage = true;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    scrollUp() {
      const userHead = document.getElementById("userHead");
      userHead.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },
    showChildren(arr) {
      const children = [];
      arr.forEach((num) => {
        this.childrenSelect.forEach((child) => {
          if (num === +child.value) {
            children.push(child.text);
          }
        });
      });
      return children;
    },
    showTargets(arr) {
      const targets = [];
      arr.forEach((num) => {
        this.targetSelect.forEach((target) => {
          if (num === +target.value) {
            targets.push(target.text);
          }
        });
      });
      return targets;
    }
  }
};
</script>
<style scoped>
.bg-size-40 .v-image__image--cover {
  background-size: 40% !important;
}
.custom-photos-input {
  flex-direction: column !important;
  align-items: center !important;
}
.custom-photos-input .v-input__prepend-outer {
  margin: 0 !important;
}
.custom-photos-input .v-input__prepend-outer i {
  font-size: 66px !important;
}
.custom-photos-input .v-input__icon--prepend {
  width: 66px;
  height: 66px;
}
.custom-photos-input .v-input__slot {
  display: none !important;
}
.custom-photos-btn-close {
  color: #e53935 !important;
  z-index: 1;
  background-color: #fff !important;
  top: -5px !important;
  right: -3px !important;
}
.sticky-panel {
  position: sticky;
  top: 0;
  bottom: 0;
  height: 100px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
}
.theme--dark .sticky-panel {
  border-top: 1px solid #143950;
  background-color: #143950;
}
.theme--dark.v-btn.v-btn--disabled.disabled,
.theme--dark.v-btn.v-btn--disabled.disabled .v-icon {
  color: rgba(24, 118, 209, 0.6) !important;
}
.theme--dark.v-btn.v-btn--disabled.disabled-like,
.theme--dark.v-btn.v-btn--disabled.disabled-like .v-icon {
  color: rgba(234, 128, 250, 0.6) !important;
}
</style>
