<template>
  <i :class="{ connected: connected }" class="icon"></i>
</template>

<script>
export default {
  name: "StatusIcon",
  props: {
    connected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.icon {
  display: inline-block;
  margin-bottom: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e38968;
}
.icon.connected {
  background-color: #73ff00;
}
</style>
