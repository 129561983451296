<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="goToProfile"
          v-on="on"
          class="mx-2"
          fab
          text
          dark
          color="primary accent-4"
        >
          <v-icon dark class="sticky-menu-button-icon">
            mdi-account-search-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Кабинет</span>
    </v-tooltip>
    <div class="sticky-menu-button-desc">Кабинет</div>
  </div>
</template>

<script>
export default {
  methods: {
    goToProfile() {
      this.$router.push("/profile");
    }
  }
};
</script>
