<template>
  <v-card flat d-flex pa-2 class="card-md card-md-filled">
    <v-card-text>
      <v-row>
        <v-col
          class="mx-auto"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          outlined
        >
          <v-card
            class="mx-auto"
            max-width="950"
            style="padding: 15px !important"
          >
            <div v-if="preloading">
              <v-skeleton-loader
                height="64"
                type="paragraph"
                class="mx-auto flex-v-center"
              >
              </v-skeleton-loader>
            </div>
            <div v-else>
              <v-card v-if="!pinRequest" flat d-flex pa-2 class="card-md-small">
                <v-card-title
                  class="headline"
                  style="font-size: 1rem !important"
                >
                  Укажите свой регистрационный Email, на него будет отправлен
                  Pin-код для сброса пароля
                </v-card-title>
                <v-form ref="form1" lazy-validation>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <div class="log-sucsess">{{ resetSuccess }}</div>
                        <div class="log-errors">{{ resetErrors }}</div>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="email"
                          :rules="[rules.required, rules.emailReset]"
                          validate-on-blur
                          label="E-mail"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" class="text-center">
                        <v-btn
                          @click="validateRequestPin"
                          color="primary accent-4"
                          class="v-btn-custom hd-gradient min-width-170"
                        >
                          Запросить Пин-код&nbsp;&nbsp;<v-icon left
                            >mdi-safe-square</v-icon
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-form>
              </v-card>
              <div v-else>
                <v-card v-if="validPin" flat d-flex pa-2 class="card-md-small">
                  <v-form ref="form3" lazy-validation>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <div class="log-sucsess">{{ psswdSuccess }}</div>
                          <div class="log-errors">{{ psswdErrors }}</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            @click:append="show2 = !show2"
                            v-model="newpassword"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show2 ? 'text' : 'password'"
                            validate-on-blur
                            label="Новый"
                            hint="Введите новый пароль"
                            counter
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                          <v-text-field
                            @click:append="show3 = !show3"
                            v-model="repeatpassword"
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[
                              rules.required,
                              rules.min,
                              rules.psswdсonfirm
                            ]"
                            :type="show3 ? 'text' : 'password'"
                            validate-on-blur
                            label="Повторите"
                            hint="Повторите новый пароль"
                            counter
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          class="text-center"
                        >
                          <v-btn
                            @click="validateResetPassword"
                            color="primary"
                            class="v-btn-custom min-width-170"
                          >
                            Сменить пароль
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </v-card>
                <v-card v-else flat d-flex pa-2 class="card-md-small">
                  <v-card-title
                    class="headline"
                    style="font-size: 1rem !important"
                  >
                    Проверьте почту и введите сюда Пин-код из письма
                  </v-card-title>
                  <v-form ref="form2" lazy-validation>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <div class="log-sucsess">{{ resetSuccess }}</div>
                          <div class="log-errors">{{ resetErrors }}</div>
                        </v-col>
                        <v-col cols="4"></v-col>
                        <v-col cols="4">
                          <v-text-field
                            ref="form2Pin"
                            v-model="pin"
                            @keypress="isNumber($event)"
                            @keyup.native.enter="$refs.form2Pin.blur()"
                            @blur="validateResetPin"
                            :rules="[rules.required, rules.pinCode]"
                            validate-on-blur
                            label="Пин-код"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4"></v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-row class="d-flex justify-center">
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="6"
                          xl="6"
                          class="text-center"
                        >
                          <v-btn
                            @click="validateResetPin"
                            color="primary accent-4"
                            class="v-btn-custom hd-gradient min-width-170"
                          >
                            Сбросить пароль&nbsp;&nbsp;<v-icon left
                              >mdi-lock-reset</v-icon
                            >
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="12"
                          lg="6"
                          xl="6"
                          class="text-center"
                        >
                          <v-btn
                            @click="requestPin"
                            color="primary accent-4"
                            class="v-btn-custom hd-gradient min-width-235"
                          >
                            Нет Пин-кода&nbsp;&nbsp;<v-icon left
                              >mdi-safe-square</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { mapState } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";

export default {
  mixins: [commonInitAppMethods, commonErrorsMethods],
  data() {
    return {
      preloading: true,
      show: false,
      show2: false,
      show3: false,
      pin: "",
      validPin: false,
      newpassword: "",
      repeatpassword: "",
      rules: {
        required: (value) => !!value || "Обязательный параметр.",
        min: (v) => v.length >= 6 || "Минимальное количество символов — 6",
        psswdсonfirm: () =>
          this.repeatpassword === this.newpassword || "Пароли не совпадают",
        emailReset: (value) => {
          value = value.trim();
          this.email = value;
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Неверный e-mail.";
        },
        pinCode: (value) => {
          value = value.trim();
          if (value.length < 6) {
            return "Пин-код состоит из 6 цифр";
          } else {
            return true;
          }
        }
      },
      resetData: {},
      resetSuccess: "",
      resetErrors: "",
      psswdSuccess: "",
      psswdErrors: ""
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options
    }),
    firstname: {
      get() {
        return this.User.name;
      },
      set(value) {
        this.$store.commit("account/update_name", value);
      }
    },
    email: {
      get() {
        return this.User.email;
      },
      set(value) {
        this.$store.commit("account/update_email", value);
      }
    },
    pinRequest: {
      get() {
        return this.User.pin_request;
      },
      set(value) {
        this.$store.commit("account/update_pin", value);
      }
    }
  },
  mounted() {
    this.queryPin();
  },
  methods: {
    queryPin() {
      if (localStorage.getItem("pinRequest")) {
        this.getItemFromLocalStorage("pinRequest", "account/update_pin");
        this.$store.commit(
          "account/update_email",
          localStorage.getItem("requestPinEmail")
        );
      }
      this.preloading = false;
    },
    async validateRequestPin() {
      if (this.$refs.form1.validate()) {
        try {
          this.resetSuccess = "";
          this.resetErrors = "";
          const emailParam = {
            email: this.email
          };
          const result = await Api.requestPasswordResetPin(
            emailParam,
            this.HttpOptions
          );
          if (result.success) {
            this.resetData = result;
            if (this.resetData.success) {
              this.resetSuccess = "На ваш email был направлен Пин-код";
              localStorage.setItem("pinRequest", this.resetData.success);
              this.$store.commit("account/update_pin", true);
              localStorage.setItem("requestPinEmail", this.email);
              this.$store.commit("account/update_email", this.email);
            } else {
              this.resetErrors = "Email не найден";
            }
          }
        } catch (e) {
          this.resetErrors = e.response.data.errors.email[0];
          // this.$errorHandler(e)
        }
        // console.log(this.resetSuccess)
      }
    },
    requestPin() {
      this.resetSuccess = "";
      this.resetErrors = "";
      localStorage.removeItem("pinRequest");
      this.$store.commit("account/update_pin", false);
      localStorage.removeItem("requestPinEmail");
      this.$store.commit("account/update_email", "");
    },
    async validateResetPin() {
      if (this.$refs.form2.validate()) {
        try {
          this.resetSuccess = "";
          this.resetErrors = "";
          const resetPin = {
            reset_pin: this.pin,
            email: this.email
          };
          const result = await Api.validatePasswordResetPin(
            resetPin,
            this.HttpOptions
          );
          if (result.success) {
            this.resetData = result;
            if (this.resetData.success) {
              this.validPin = true;
            } else {
              this.validPin = false;
              this.resetErrors = "Неправильный Pin-код";
            }
          }
        } catch (e) {
          this.resetErrors = e.response.data.errors.reset_pin[0];
          // this.$errorHandler(e)
        }
      }
    },
    async validateResetPassword() {
      const SUCCESS_REDIRECT_URL = "/profile";
      if (this.$refs.form3.validate()) {
        try {
          this.resetSuccess = "";
          this.resetErrors = "";
          const resetPasswordParams = {
            reset_pin: this.pin,
            email: this.email,
            password_new: this.newpassword,
            password_new_repeat: this.repeatpassword
          };
          const result = await Api.validateResetPassword(
            resetPasswordParams,
            this.HttpOptions
          );
          this.resetData = result;
          if (this.resetData.success) {
            this.psswdSuccess = "Пароль успешно изменен!";
            localStorage.setItem("authToken", this.resetData.access_token);
            this.$store.commit(
              "account/update_token",
              this.resetData.access_token
            );
            localStorage.setItem("email", this.email);
            this.$store.commit("account/update_email", this.email);
            localStorage.setItem("email", this.email);
            this.$store.commit(
              "account/update_name",
              this.resetData.profile.first_name
            );
            localStorage.setItem("namereg", this.resetData.profile.first_name);
            localStorage.setItem("name", this.resetData.profile.first_name);
            if (this.resetData.profile.justclick_status) {
              this.$store.commit("account/update_justclick", true);
              localStorage.setItem("is_subscribed_to_justclick", true);
            }
            setTimeout(() => this.requestPin(), 400);
            setTimeout(() => this.$router.push(SUCCESS_REDIRECT_URL), 500);
          } else {
            this.psswdErrors = this.resetData.errors.password[0];
            // console.log(this.resetErrors)
          }
        } catch (e) {
          this.psswdErrors = e.response.data.errors.password[0];
          // this.$errorHandler(e)
        }
        // console.log(this.resetSuccess)
      }
    },
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else if (charCode === 13) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
<style scoped>
.svgbg-dark {
  fill: #303030;
}
.card-md-small {
  max-width: 800px !important;
  min-width: 600px;
  min-height: 300px;
}
.theme--dark .card-md-small {
  background-color: #102c3e !important;
}
.theme--dark .v-tabs-slider-wrapper {
  color: #177a9b;
}
.theme--dark .v-card__title {
  color: #85a3b1;
}
.theme--dark .v-card {
  background-color: #0b1f2c !important;
}
.theme--dark .v-messages__message,
.theme--dark .v-counter,
.theme--dark .v-label,
.theme--dark .v-tab.v-tab {
  color: #177a99 !important;
}
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #85a3b1 !important;
}
.theme--dark .v-input:not(.v-input--is-disabled) input,
.theme--dark .v-select .v-select__selections {
  color: #85a3b1 !important;
}
.theme--dark.mdi-menu-down::before {
  color: #628b9e !important;
}
.theme--dark .svg_bg,
.theme--dark .card-md,
.theme--dark .card-md-small {
  background-color: #102c3e !important;
}
.theme--dark .card-md-small ~ .v-card__actions {
  background-color: #102c3e;
}
.headline {
  word-break: break-word;
}
@media screen and (min-width: 320px) and (max-width: 419px) {
  .card-md-small {
    min-width: 290px;
  }
}
.v-btn-custm {
  border-radius: 15px;
}
.log-sucsess {
  color: #6cd844;
}
.log-errors {
  color: #fe001e;
}
</style>
