<template>
  <div>
    <v-subheader>Взаимный интерес</v-subheader>
    <v-slide-group v-if="users.length" multiple>
      <v-slide-item v-for="user in users" :key="user.id">
        <div class="text-center" style="margin: 0 15px">
          <div>
            <a
              @click="getUserProfileDialog(user.id)"
              :class="{ 'premium-avatar3': user.has_products }"
            >
              <v-avatar size="56px">
                <v-img
                  v-if="user.avatar"
                  :alt="user.first_name"
                  :src="user.avatar"
                ></v-img>
                <v-img v-else :alt="user.first_name" :src="defaultAva"></v-img>
              </v-avatar>
            </a>
          </div>
          <a @click="getUserProfileDialog(user.id)" class="user-link">
            <v-badge
              v-if="!relativeLikeDialogShowOnce(user.id)"
              color="purple accent-1"
              dot
              class="pulse-like"
            >
              {{ user.first_name }}
            </v-badge>
            <span v-else>{{ user.first_name }}</span>
          </a>
        </div>
      </v-slide-item>
    </v-slide-group>
    <div
      v-else
      v-html="getMessage('social_text_4_top_section')"
      class="text-4-relyative-like-block"
    ></div>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  mixins: [commonInitAppMethods],
  data() {
    return {
      defaultAva:
        "https://static.human-design.space/widget/images/social/socialprofile-preview.png",
      preloading: true,
      users: []
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    })
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_re_render_mutual_interest":
          this.getMutualInterest();
          break;
      }
    });
    setTimeout(() => this.getMutualInterest(), 0);
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      call_user_view_dialog: "account/call_user_view_dialog"
    }),
    async getMutualInterest() {
      try {
        const result = await Api.getMutualFavouriteUsers(this.HttpOptions);
        if (result.success) {
          this.users = result.users;
          this.preloading = false;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    getUserProfileDialog(id) {
      if (!this.User.isLogged) {
        this.update_login_dialog(true);
      } else {
        this.call_user_view_dialog(id);
      }
    },
    relativeLikeDialogShowOnce(id) {
      let result;
      const equal = (el) => +el === +id;
      const arr = localStorage.getItem("relativeLikeArray")
        ? JSON.parse(localStorage.getItem("relativeLikeArray"))
        : [];
      if (arr.length) {
        result = arr.some(equal);
      } else {
        result = false;
      }
      return result;
    }
  }
};
</script>
<style scoped>
.text-4-relyative-like-block {
  margin-top: 15px;
  padding: 10px;
  text-align: center;
}
.theme--dark .text-4-relyative-like-block {
  color: rgba(255, 255, 255, 0.7);
}
</style>
