<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step :complete="step > 1" step="1">
      <span v-html="getMessage('social_title_wellcome_slide_1')"></span>
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-img
        src="https://static.human-design.space/widget/images/social/social_01_2.png"
        class="stepper-image"
      ></v-img>
      <v-card
        v-html="getMessage('social_text_wellcome_slide_1')"
        class="mb-12 padding-15 social-steppers"
      >
      </v-card>
      <v-btn @click="step = 2" color="primary" class="hd-gradient">
        Далее
      </v-btn>
      <v-btn
        @click="closeSteppers"
        color="primary"
        class="hd-gradient"
        style="margin-left:10px"
      >
        Закрыть
      </v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="step > 2" step="2">
      <span v-html="getMessage('social_title_wellcome_slide_2')"></span>
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-img
        src="https://static.human-design.space/widget/images/social/social_02_3.png"
        class="stepper-image"
      ></v-img>
      <v-card
        v-html="getMessage('social_text_wellcome_slide_2')"
        class="mb-12 padding-15 social-steppers"
      >
      </v-card>
      <v-btn @click="step = 3" color="primary" class="hd-gradient">
        Далее
      </v-btn>
      <v-btn
        @click="closeSteppers"
        color="primary"
        class="hd-gradient"
        style="margin-left:10px"
      >
        Закрыть
      </v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="step > 3" step="3">
      <span v-html="getMessage('social_title_wellcome_slide_3')"></span>
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-img
        src="https://static.human-design.space/widget/images/social/social_03_1.png"
        class="stepper-image"
      ></v-img>
      <v-card
        v-html="getMessage('social_text_wellcome_slide_3')"
        class="mb-12 padding-15 social-steppers"
      >
      </v-card>
      <v-btn
        @click="closeSteppers"
        color="primary"
        class="hd-gradient"
        style="margin-right:10px"
      >
        Понятно
      </v-btn>
      <v-btn @click="step = 1" color="primary" class="hd-gradient">
        Еще раз...
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>
<script>
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  mixins: [commonInitAppMethods],
  data() {
    return {
      step: 1
    };
  },
  methods: {
    closeSteppers() {
      this.$emit("closeSocialSteppers");
    }
  }
};
</script>
<style scoped>
.padding-15 {
  padding: 15px;
}
.stepper-image {
  max-height: 300px;
}
.stepper-image .v-image__image--cover {
  background-size: contain !important;
}
</style>
