<template>
  <v-card flat d-flex pa-2 class="card-md">
    <v-card-text style="padding:0">
      <v-row v-if="preloading">
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="avatar"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="mob-padding-428-0"
        >
          <v-card class="mx-auto padding-15-mob-15-0" max-width="950">
            <v-card-title class="community-head flex-center">
              <span class="headline white--text">
                Мой профиль
              </span>
            </v-card-title>
            <div v-if="userAva" class="flex-center profile-img-round">
              <v-img :src="userAva" height="350px"></v-img>
            </div>
            <div v-else class="bg-size-40">
              <v-img
                src="/img/social/social/socialprofile-preview.png"
                height="350px"
              ></v-img>
            </div>
            <div class="headline profile-name text-center">
              {{ firstname }} {{ age === "0" ? "" : age }}
            </div>
            <div v-if="chart_type" class="text-center">
              {{ chart_type }}, {{ chart_profile }}
            </div>
            <v-list>
              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content class="padding-top-0 padding-bottom-0">
                  <v-col
                    cols="6"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <Preview />
                  </v-col>
                  <v-col cols="6" class="flex-center">
                    <Myguests />
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content class="padding-top-0 padding-bottom-0">
                  <v-col
                    cols="6"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <Followers :id="+id" />
                  </v-col>
                  <v-col cols="6" class="flex-center">
                    <Subscriptions :id="+id" />
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="padding-top-0 padding-bottom-0">
                  <v-col cols="4" class="flex-center">
                    <Settings />
                  </v-col>
                  <v-col cols="4" class="flex-center">
                    <EditPhotos :id="+id" />
                  </v-col>
                  <v-col cols="4" class="flex-center">
                    <EditProfile :id="+id" />
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content class="padding-top-0 padding-bottom-0">
                  <v-col
                    cols="6"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <user-posts :method="'myposts'" :userId="+User.id" />
                  </v-col>
                  <v-col cols="6" class="flex-center">
                    <user-posts :method="'myfavourites'" :userId="+User.id" />
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content class="padding-top-0 padding-bottom-0">
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    class="flex-center border-right-bottom"
                  >
                    <error-reports />
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    class="flex-center border-right-bottom"
                  >
                    <rules />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    class="flex-center"
                  >
                    <promo-code />
                  </v-col>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item>
                <v-list-item-content style="padding:0">
                  <v-list-item-title class="flex-center">
                    <Partners />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content style="padding:0">
                  <v-list-item-title class="flex-center">
                    <InstallMobileApp />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="dialogMyCard"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Добавьте Рейв-карту с помощью:
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="dialogMyCard = false" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <ChoiseRaveCard />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import { validationMixin } from 'vuelidate'
import { mapState, mapMutations } from "vuex";
import Followers from "@/components/community/profile/followers.vue";
import Subscriptions from "@/components/community/profile/subscriptions.vue";
import EditPhotos from "@/components/community/profile/edit-profile-photos";
import EditProfile from "@/components/community/profile/edit-profile";
import ErrorReports from "@/components/community/profile/error-reports";
import Rules from "@/components/community/profile/rules";
import ChoiseRaveCard from "@/components/community/profile/choise-rave-card";
import Myguests from "@/components/community/profile/myguests";
import Partners from "@/components/community/profile/partners";
import Preview from "@/components/community/profile/preview";
import PromoCode from "@/components/community/profile/my-promo-code";
import Settings from "@/components/community/profile/settings";
import UserPosts from "@/components/community/posts/user-posts";
import InstallMobileApp from "@/components/community/profile/install-mobile-app";

export default {
  components: {
    UserPosts,
    ChoiseRaveCard,
    Subscriptions,
    Followers,
    Preview,
    Myguests,
    Settings,
    EditProfile,
    EditPhotos,
    Partners,
    ErrorReports,
    Rules,
    PromoCode,
    InstallMobileApp
  },
  // mixins: [validationMixin],
  data() {
    return {
      preloading: true,
      selection: 1,
      dialogMyCard: false,
      maintenanceWork: false,
      months: [
        { month: "Январь (1)", id: "01" },
        { month: "Февраль (2)", id: "02" },
        { month: "Март (3)", id: "03" },
        { month: "Апрель (4)", id: "04" },
        { month: "Май (5)", id: "05" },
        { month: "Июнь (6)", id: "06" },
        { month: "Июль (7)", id: "07" },
        { month: "Август (8)", id: "08" },
        { month: "Сентябрь (9)", id: "09" },
        { month: "Октябрь (10)", id: "10" },
        { month: "Ноябрь (11)", id: "11" },
        { month: "Декабрь (12)", id: "12" }
      ],
      targets: [],
      children: []
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options,
      ConfApp: (state) => state.conf.ConfApp
    }),
    chart_type: {
      get() {
        return this.User.community.my_profile.chart_type;
      },
      set(value) {
        this.$store.commit("account/set_comm_my_chart_type", value);
      }
    },
    chart_profile: {
      get() {
        return this.User.community.my_profile.chart_profile;
      },
      set(value) {
        this.$store.commit("account/set_comm_my_chart_profile", value);
      }
    },
    id() {
      return this.User.id;
    },
    firstname: {
      get() {
        return this.User.community.my_profile.name;
      },
      set(value) {
        this.$store.commit("account/update_my_name", value);
      }
    },
    age: {
      get() {
        return this.User.community.my_profile.age;
      },
      set(value) {
        this.$store.commit("account/update_user_my_age", value);
      }
    },
    userAva: {
      get() {
        return this.User.community.my_profile.avatar;
      },
      set(value) {
        this.$store.commit("account/update_comm_my_avatar", value);
      }
    },
    sexSelect: {
      get() {
        return this.User.community.my_profile.sexselect;
      },
      set(value) {
        this.$store.commit("account/set_user_my_sexselect", value);
      }
    },
    maritalStatuses: {
      get() {
        return this.User.community.marital_statuses;
      },
      set(value) {
        this.$store.commit("account/set_user_marital_statuses", value);
      }
    },
    childrenSelect: {
      get() {
        return this.User.community.children_select;
      },
      set(value) {
        this.$store.commit("account/set_user_children_select", value);
      }
    },
    targetSelect: {
      get() {
        return this.User.community.target_select;
      },
      set(value) {
        this.$store.commit("account/set_user_target_select", value);
      }
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/update_is_stored":
          this.getProfile();
          break;
        case "account/call_update_profile":
          this.getProfile();
          break;
      }
    });
    setTimeout(() => {
      this.getProfile();
    }, 0);
  },
  methods: {
    ...mapMutations(["SET_POSTS_METHOD", "SET_PROMO_CODE_URL"]),
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    async getProfile() {
      if (this.User.isLogged) {
        this.preloading = true;
        this.$store.dispatch("account/getSocialProfile");
        this.preloading = false;
      } else {
        setTimeout(() => {
          if (this.User.isLogged) {
            this.preloading = true;
            this.$store.dispatch("account/getSocialProfile");
            this.preloading = false;
          } else {
            this.getProfile();
            // this.update_login_dialog(true);
          }
        }, 50);
      }
      /* if (this.User.isLogged) {
        try {
          this.preloading = true;
          const result = await this.$getSocialProfile.get({});
          if (result.success) {
            // console.log('result: ', result)
            if (result.social_profile.chart_type)
              this.$store.commit(
                "SET_COMM_MY_CHART_TYPE",
                result.social_profile.chart_type.title
              );
            if (result.social_profile.chart_profile)
              this.$store.commit(
                "SET_COMM_MY_CHART_PROFILE",
                result.social_profile.chart_profile.id
              );
            if (result.social_profile.slogan)
              this.$store.commit(
                "UPDATE_COMM_MY_SLOGAN",
                result.social_profile.slogan
              );
            if (result.social_profile.about)
              this.$store.commit(
                "UPDATE_COMM_MY_ABOUT",
                result.social_profile.about
              );
            if (result.social_profile.hd_level) {
              const hdLevel = result.social_profile.hd_level;
              this.$store.commit("UPDATE_COMM_MY_HD_LEVEL", hdLevel);
            }
            if (result.social_profile.why_am_i_here)
              this.$store.commit(
                "UPDATE_COMM_MY_WHY_AM_I_HERE",
                result.social_profile.why_am_i_here
              );
            if (result.social_profile.first_name)
              this.$store.commit(
                "UPDATE_MY_NAME",
                result.social_profile.first_name
              );
            if (result.social_profile.age >= 0)
              this.$store.commit(
                "UPDATE_USER_MY_AGE",
                result.social_profile.age.toString()
              );
            if (result.social_profile.birthday) {
              this.$store.commit("UPDATE_COMM_MY_DAY", {
                day: result.social_profile.birthday.slice(8, 10)
              });
              this.$store.commit(
                "UPDATE_COMM_MY_MONTH",
                this.months[result.social_profile.birthday.slice(5, 7) - 1]
              );
              this.$store.commit("UPDATE_COMM_MY_YEAR", {
                year: result.social_profile.birthday.slice(0, 4)
              });
              this.$store.commit("UPDATE_COMM_MY_HOUR", {
                hour: result.social_profile.birthday.slice(11, 13)
              });
              this.$store.commit("UPDATE_COMM_MY_MINUTE", {
                minute: result.social_profile.birthday.slice(14, 16)
              });
            }
            if (result.social_profile.images.length) {
              this.$store.commit(
                "UPDATE_COMM_MY_AVATAR",
                result.social_profile.images[0].src
              );
            }
            if (result.social_profile.city_of_birth) {
              this.$store.commit(
                "UPDATE_COMM_MY_CITY_ID",
                result.social_profile.city_of_birth.id
              );
              this.$store.commit(
                "UPDATE_COMM_MY_CITY_NAME",
                result.social_profile.city_of_birth.title
              );
              this.$store.commit(
                "UPDATE_COMM_MY_LAST_CITIES",
                result.social_profile.city_of_birth
              );
            }
            if (result.social_profile.city) {
              this.$store.commit(
                "UPDATE_COMM_MY_CITY2_ID",
                result.social_profile.city.id
              );
              this.$store.commit(
                "UPDATE_COMM_MY_CITY2_NAME",
                result.social_profile.city.title
              );
              this.$store.commit(
                "UPDATE_COMM_MY_LAST_CITIES2",
                result.social_profile.city
              );
            }
            if (result.social_profile.followers_count)
              this.$store.commit(
                "SET_COMM_FOLLOWERS",
                result.social_profile.followers_count
              );
            if (result.social_profile.following_count)
              this.$store.commit(
                "SET_COMM_FOLLOWING",
                result.social_profile.following_count
              );
            if (result.social_profile.height)
              this.$store.commit(
                "SET_COMM_MY_HEIGHT",
                result.social_profile.height.toString()
              );
            if (result.social_profile.weight)
              this.$store.commit(
                "SET_COMM_MY_WEIGHT",
                result.social_profile.weight.toString()
              );
            if (result.social_profile.targets) {
              this.targets = [];
              result.social_profile.targets.forEach((value) => {
                this.targetSelect.forEach((item) => {
                  if (+value === +item.value) {
                    this.targets.push(item);
                  }
                });
                this.$store.commit("SET_USER_MY_TARGETS", this.targets);
              });
            }
            if (result.social_profile.social_network_product_url) {
              this.SET_PROMO_CODE_URL(
                result.social_profile.social_network_product_url
              );
            } else {
              this.SET_PROMO_CODE_URL("");
            }
            if (result.social_profile.sex > 0) {
              this.sexSelect.forEach((item) => {
                if (+result.social_profile.sex === +item.value) {
                  this.$store.commit("SET_USER_MY_SEX", item);
                }
              });
            } else {
              this.$store.commit("SET_USER_MY_SEX", {
                text: "Мужской",
                value: "1"
              });
            }
            if (result.social_profile.marital_status) {
              this.maritalStatuses.forEach((item) => {
                if (+result.social_profile.marital_status === +item.value) {
                  this.$store.commit("SET_USER_MY_MARITAL_STATUS", item);
                }
              });
            } else {
              this.$store.commit("SET_USER_MY_MARITAL_STATUS", null);
            }
            if (result.social_profile.children) {
              this.children = [];
              result.social_profile.children.forEach((value) => {
                this.childrenSelect.forEach((item) => {
                  if (+value === +item.value) {
                    this.children.push(item);
                  }
                });
              });
              this.$store.commit("SET_USER_MY_CHILDREN", this.children);
            }
            if (result.social_profile.visited_countries) {
              this.$store.commit(
                "SET_USER_MY_VISITED_COUNTRIES",
                result.social_profile.visited_countries
              );
            } else {
              this.$store.commit("SET_USER_MY_VISITED_COUNTRIES", "");
            }
            if (result.social_profile.i_understand) {
              this.$store.commit(
                "SET_USER_I_UNDERSTAND",
                result.social_profile.i_understand
              );
            } else {
              this.$store.commit("SET_USER_I_UNDERSTAND", "");
            }
            if (result.social_profile.guests_count) {
              this.$store.commit(
                "SET_GUESTS_COUNT",
                result.social_profile.guests_count
              );
            }
            if (result.social_profile.can_add_content_to_social_network) {
              this.$store.commit(
                "account/can_add_content_to_social_network",
                result.social_profile.can_add_content_to_social_network
              );
            }
            this.preloading = false;
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      } */
    }
  }
};
</script>
<style scoped>
.profile-name {
  margin-top: 15px;
}
.bg-size-40 .v-image__image--cover {
  background-size: 40% !important;
}
.custom-photos-input {
  flex-direction: column !important;
  align-items: center !important;
}
.custom-photos-input .v-input__prepend-outer {
  margin: 0 !important;
}
.custom-photos-input .v-input__prepend-outer i {
  font-size: 66px !important;
}
.custom-photos-input .v-input__icon--prepend {
  width: 66px;
  height: 66px;
}
.custom-photos-input .v-input__slot {
  display: none !important;
}
.custom-phts-btn-close {
  color: #e53935 !important;
  z-index: 100;
  background-color: #fff !important;
  top: -1px !important;
  right: -1px !important;
}
.profile-img-round {
  margin-top: 15px;
}
.profile-img-round > div {
  height: 350px !important;
  width: 350px !important;
  overflow: hidden;
  flex: inherit;
  border-radius: 50%;
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .my-prof-controls-flex span {
    display: flex;
    flex-direction: column;
  }
  .my-prof-controls-icon {
    flex-direction: row !important;
    align-items: center;
  }
  .profile-img-round > div {
    height: 250px !important;
    width: 250px !important;
  }
}
</style>
