<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="id === User.id"
          :class="{ disabled: id === User.id }"
          @click="subscribeToUser(id)"
          color="primary"
          class="my-prof-controls-flex"
          x-large
          fab
          text
          dark
        >
          <span v-if="!typeSubscribe">
            <v-icon v-if="!isFollowing">
              mdi-heart-plus-outline
            </v-icon>
            <v-icon v-else>mdi-heart-minus-outline</v-icon>
          </span>
          <span v-else>
            <v-icon v-if="!isFollowingLocal">
              mdi-heart-plus-outline
            </v-icon>
            <v-icon v-else>mdi-heart-minus-outline</v-icon>
          </span>
          <span v-if="!typeSubscribe">
            <span
              v-if="!isFollowing"
              class="d-flex"
              style="font-size:11px;margin-top:5px"
            >
              Подписаться
            </span>
            <span v-else class="d-flex" style="font-size:11px;margin-top:5px"
              >Отписаться</span
            >
          </span>
          <span v-else>
            <span
              v-if="!isFollowingLocal"
              class="d-flex"
              style="font-size:11px;margin-top:5px"
            >
              Подписаться
            </span>
            <span v-else class="d-flex" style="font-size:11px;margin-top:5px"
              >Отписаться</span
            >
          </span>
        </v-btn>
      </template>
      <span v-if="!typeSubscribe">
        <span v-if="!isFollowing">Подписаться</span>
        <span v-else>Отписаться</span>
      </span>
      <span v-else>
        <span v-if="!isFollowingLocal">Подписаться</span>
        <span v-else>Отписаться</span>
      </span>
    </v-tooltip>
    <v-snackbar v-model="successSubscribeToUser" top color="success">
      <v-icon color="white">mdi-check-circle-outline</v-icon>
      &nbsp;Вы успешно {{ subscribeToUserAction }} пользователя.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successSubscribeToUser = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    isFollowing: {
      type: Boolean,
      default: false
    },
    typeSubscribe: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      successSubscribeToUser: false,
      subscribeToUserAction: "",
      isFollowingLocal: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    })
  },
  created() {
    this.isFollowingLocal = this.isFollowing;
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    ...mapMutations(["CALL_FOLLOWERS_UPDATE"]),
    async subscribeToUser(id) {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        try {
          switch (this.typeSubscribe) {
            case "search_user":
              if (this.isFollowingLocal) {
                const data = { following_id: id };
                const result = await Api.unfollowUser(data, this.HttpOptions);
                if (result.success) {
                  this.successSubscribeToUser = true;
                  this.subscribeToUserAction = "отписались от";
                  this.isFollowingLocal = false;
                }
              } else {
                const data = { following_id: id };
                const result = await Api.followUser(data, this.HttpOptions);
                if (result.success) {
                  this.successSubscribeToUser = true;
                  this.subscribeToUserAction = "подписались на";
                  this.isFollowingLocal = true;
                }
              }
              break;
            default:
              if (this.isFollowing) {
                const data = { following_id: id };
                const result = await Api.unfollowUser(data, this.HttpOptions);
                if (result.success) {
                  // this.$getProfile(id)
                  this.$emit("select", id);
                  this.successSubscribeToUser = true;
                  this.subscribeToUserAction = "отписались от";
                  this.CALL_FOLLOWERS_UPDATE();
                }
              } else {
                const data = { following_id: id };
                const result = await Api.followUser(data, this.HttpOptions);
                if (result.success) {
                  // this.getProfile(id)
                  this.$emit("select", id);
                  this.successSubscribeToUser = true;
                  this.subscribeToUserAction = "подписались на";
                  this.CALL_FOLLOWERS_UPDATE();
                }
              }
              break;
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      } else {
        this.update_login_dialog(true);
      }
    }
  }
};
</script>
