<template>
  <div>
    <v-list-item v-if="users.length">
      <div style="position:relative">
        <a @click="callSomeoneLikedYou" class="like-quantity">
          <v-list-item-avatar size="56px" style="margin:0">
            <v-img
              src="https://static.human-design.space/widget/images/social/social-liked-me-users.jpg"
              alt="Вас лайкнули"
            ></v-img>
          </v-list-item-avatar>
        </a>
        <a @click="callSomeoneLikedYou" class="like-counter">
          <v-icon color="white">mdi-heart</v-icon>
          {{ users.length }} Смотреть
        </a>
      </div>
      <v-list-item-content>
        <v-list-item-title>
          Ты нравишься этим людям!
        </v-list-item-title>
        <v-list-item-subtitle>
          Это возможность завести удачное знакомство прямо сейчас
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-dialog
      v-model="PeopleLikedYouDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Ты нравишься этим людям
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn
                    @click="PeopleLikedYouDialog = false"
                    icon
                    absolute
                    right
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container class="mb-6">
                  <v-row v-if="users.length" justify="space-around">
                    <v-col
                      v-for="(user, i) in users"
                      :key="i"
                      cols="6"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div>
                        <a
                          @click.stop="getUserProfileDialog(user.id)"
                          :class="{ 'premium-avatar4': user.has_products }"
                        >
                          <v-avatar size="96px">
                            <v-img
                              :src="user.avatar"
                              :alt="user.first_name"
                            ></v-img>
                          </v-avatar>
                        </a>
                      </div>
                      <a
                        @click.stop="getUserProfileDialog(user.id)"
                        class="user-link"
                      >
                        {{ user.first_name }}
                      </a>
                      <div v-if="user.chart_type.title" class="user-type">
                        {{ user.chart_type.title }}, {{ user.chart_profile.id }}
                      </div>
                    </v-col>
                  </v-row>
                  <div
                    v-else
                    v-html="getMessage('social_text_liked_dialog')"
                    class="text-4-relyative-like-block"
                  ></div>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  mixins: [commonInitAppMethods],
  data() {
    return {
      preloading: true,
      PeopleLikedYouDialog: false,
      users: []
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    })
  },
  mounted() {
    setTimeout(() => this.getLikedMeUsers(), 0);
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      call_user_view_dialog: "account/call_user_view_dialog"
    }),
    async getLikedMeUsers() {
      try {
        const result = await Api.getLikedMeUsers(this.HttpOptions);
        if (result.success) {
          this.users = result.users;
          this.preloading = false;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    callSomeoneLikedYou() {
      this.PeopleLikedYouDialog = true;
    },
    getUserProfileDialog(id) {
      if (!this.User.isLogged) {
        this.update_login_dialog(true);
      } else {
        this.call_user_view_dialog(id);
      }
    }
  }
};
</script>
