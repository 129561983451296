<template>
  <div
    v-if="Object.keys(post).length !== 0"
    ref="toposts"
    class="padding-top-posts"
  >
    <div>
      <div
        :class="{
          'hidden-post': post.status_id === 2 && !User.is_admin,
          'hidden-post-admin': post.status_id === 2 && User.is_admin
        }"
      >
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="post.user.first_image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <!-- <a :href="`/user/?id=${post.user.id}`" class="user-link"> -->
                <a
                  @click="getUserProfileDialog(post.user.id)"
                  class="user-link"
                >
                  {{ post.user.first_name }}
                </a>
                <v-list-item-subtitle v-if="post.user.chart_type">
                  {{ post.user.chart_type.title }}
                  {{ post.user.chart_profile.id }}
                </v-list-item-subtitle>
              </v-list-item-title>
              <v-list-item-subtitle
                v-html="post.user.city_title"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content style="max-width:40px">
              <v-menu bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" fab small text>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <SharePostButton :id="post.id" />
                  <SpamReportButton :id="post.id" :userId="post.user.id" />
                  <BlockPostButton
                    :id="post.id"
                    :userId="post.user.id"
                    :status="post.status_id"
                  />
                </v-list>
              </v-menu>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <h3 class="h-post-title text-center">
          {{ post.title }}
        </h3>
        <v-carousel
          v-if="post.images.length > 1"
          hide-delimiters
          hide-delimiter-background
          progress
          progress-color="#fff"
          class="custom-carusel"
        >
          <v-carousel-item
            v-for="(item, j) in post.images"
            :key="j"
            :src="item.src"
          ></v-carousel-item>
        </v-carousel>
        <v-img
          v-else-if="post.images.length === 1"
          :src="post.images[0]"
          class="custom-image"
          height="500px"
          style="margin-bottom:7px"
        ></v-img>
        <v-card-subtitle
          v-text="post.description"
          style="white-space:break-spaces"
        ></v-card-subtitle>
        <div v-if="post._meta.is_voteable">
          <v-select
            v-if="post.post_type === 2 && post.poll_type === 1"
            v-model="pollItems"
            :items="post.pollItems"
            :item-text="'content'"
            :item-value="'id'"
            :menu-props="{ auto: true, top: true, offsetY: true }"
            label="Выберите ваш ответ"
            class="form-control"
            filled
            persistent-hint
            return-object
            single-line
          >
            <template slot="item" slot-scope="data">
              <template>
                <div
                  v-html="data.item.content"
                  class="v-custom-select-item"
                ></div>
              </template>
            </template>
          </v-select>
          <v-select
            v-if="post.post_type === 2 && post.poll_type === 2"
            v-model="pollItems"
            :items="post.pollItems"
            :item-text="'content'"
            :item-value="'id'"
            :menu-props="{ auto: true, top: true, offsetY: true }"
            label="Выберите ваш ответ"
            class="form-control"
            filled
            persistent-hint
            return-object
            single-line
            multiple
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <span class="v-custom-select-item">{{
                        item.content
                      }}</span>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
          <div v-if="post.post_type === 2" class="text-center">
            <v-btn
              @click="postVoting(post.id, post.poll_type)"
              small
              color="primary"
              class="hd-gradient"
            >
              Голосовать
            </v-btn>
          </div>
        </div>
        <div v-else style="padding:15px">
          <div
            v-for="(item, g) in post.pollItems"
            :key="g"
            :set="(percent = getPercent(item.votes_count, post.votes_count))"
          >
            {{ g + 1 }}) {{ item.content }} — {{ percent }}%
            <sup
              v-if="
                isYourAnswer(i, post.pollItems[g].id) === +post.pollItems[g].id
              "
            >
              <v-icon color="teal accent-4" style="font-size:14px">
                mdi-check-decagram
              </v-icon>
            </sup>
            <v-progress-linear
              v-model="percent"
              :color="'blue lighten-' + (g + 1)"
            ></v-progress-linear>
            <br />
          </div>
          Всего голосов: {{ post.votes_count }}<br />
          <span v-if="!post._meta.is_voteable">
            <sup>
              <v-icon color="teal accent-4" style="font-size:14px">
                mdi-check-decagram
              </v-icon>
            </sup>
            — ваш голос
          </span>
        </div>
        <div class="d-flex">
          <LikePostButton
            :id="post.id"
            :isLikedIncome="post.is_liked"
            :counterIncome="post.likes_count"
          />
          <CommentsPostButton
            :id="post.id"
            :title="post.title"
            :counterIncome="post.comments_count"
          />
          <v-spacer></v-spacer>
          <BookmarkPostButton
            :id="post.id"
            :isFavouriteIncome="post.is_favourite"
          />
        </div>
      </div>
      <v-divider v-if="post.status_id === 1 || User.is_admin"></v-divider>
    </div>
    <v-snackbar v-model="needChoisePollItems" top color="deep-orange">
      <v-icon color="white">mdi-alert-outline</v-icon>
      &nbsp;Выберите вариант(ы) ответа.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="needChoisePollItems = false"
          color="grey darken-3"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations, mapActions } from "vuex";
import BlockPostButton from "@/components/community/posts/block-post-button";
import BookmarkPostButton from "@/components/community/posts/bookmark-post-button";
import CommentsPostButton from "@/components/community/posts/comments-post-button";
import LikePostButton from "@/components/community/posts/like-post-button";
import SharePostButton from "@/components/community/posts/share-post-button";
import SpamReportButton from "@/components/community/posts/spam-report-button";

export default {
  components: {
    SharePostButton,
    SpamReportButton,
    BlockPostButton,
    LikePostButton,
    BookmarkPostButton,
    CommentsPostButton
  },
  props: {
    type: {
      type: String,
      default: ""
    },
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      postId: null,
      pollItems: [],
      needChoisePollItems: false,
      moreLoaDialog: false,
      progressdialog: false,
      delay: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    post() {
      return this.$store.getters["account/post"];
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_social_post":
          this.$store.dispatch(
            "account/getSocialPost",
            this.$route.query.post_id
          );
          break;
        case "CALL_UPDATE_LIKE":
          this.$store.dispatch(
            "account/getSocialPost",
            this.$route.query.post_id
          );
          break;
        case "CALL_UPDATE_BOOKMARK":
          this.$store.dispatch(
            "account/getSocialPost",
            this.$route.query.post_id
          );
          break;
      }
    });
  },
  methods: {
    ...mapMutations({
      set_post: "account/set_post",
      call_user_view_dialog: "account/call_user_view_dialog",
      update_login_dialog: "conf/update_login_dialog"
    }),
    ...mapActions({
      get_posts_from_api: "account/get_posts_from_api"
    }),
    getUserProfileDialog(id) {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        this.call_user_view_dialog(id);
      } else {
        this.update_login_dialog(true);
      }
    },
    async postVoting(id, pollType) {
      let data;
      if (pollType === 1) {
        data = { post_id: id, poll_item_ids: [this.pollItems.id] };
      } else {
        data = {
          post_id: id,
          poll_item_ids: this.pollItems.map((item) => {
            return item.id;
          })
        };
      }
      if (
        (pollType === 1 && this.pollItems.id) ||
        (pollType === 2 && this.pollItems.length > 0)
      ) {
        try {
          const result = await Api.voteForPost(data, this.HttpOptions);
          if (result.success) {
            // console.log(result)
            this.pollItems = [];
            this.get_posts_from_api({ page: this.page, id: null });
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      } else {
        this.needChoisePollItems = true;
      }
    },
    getPercent(itemCount, allCount) {
      return Math.round((itemCount * 100) / allCount);
    },
    isYourAnswer(postIdx, pollId) {
      let result;
      const post = this.posts[postIdx];
      post._meta.selected_user_poll_item_ids.forEach((id) => {
        if (+id === +pollId) result = +id;
      });
      return result;
    }
  }
};
</script>
<style scoped>
.bg-size-40 .v-image__image--cover {
  background-size: 40% !important;
}
.v-carousel__progress {
  margin: 3px !important;
  top: 0 !important;
  bottom: auto !important;
  width: 99% !important;
  box-shadow: 0 1px 3px #c1c1c1;
}
.v-progress-linear__background {
  border-color: #102b3c !important;
  background-color: #102b3c !important;
}
.form-comm {
  display: flex;
  position: relative;
}
.form-comm-textarea {
  margin: 0 15px;
}
.form-comm-textarea .v-input__slot {
  border-radius: 15px !important;
}
.form-comm-textarea textarea {
  max-height: 240px !important;
}
.form-comm-btn-send {
  bottom: 47px;
  right: -3px !important;
}
.form-comm-btn-del-replay {
  right: 0 !important;
}
.comm-description {
  text-align: justify;
  font-family: "Roboto", sans-serif !important;
  font-size: 0.875rem;
  color: rgb(88, 88, 88);
  white-space: pre-wrap;
}
.theme--dark .comm-description {
  /* color: rgb(180, 180, 180); */
  color: rgba(255, 255, 255, 0.85);
}
.comment-item > div,
.comment-subitem > div,
.comment-sub2item > div,
.comment-sub3item > div,
.comment-sub4item > div,
.comment-sub5item > div {
  position: relative;
}
.comment-subitem,
.comment-sub2item {
  margin-left: 40px;
}
.comment-sub3item {
  margin-left: 60px;
}
.comment-sub4item {
  margin-left: 80px;
}
.comment-sub5item {
  margin-left: 100px;
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .comment-subitem,
  .comment-sub2item {
    margin-left: 10px !important;
  }
  .comment-sub3item {
    margin-left: 10px !important;
  }
  .comment-sub4item {
    margin-left: 10px !important;
  }
  .comment-sub5item {
    margin-left: 20px !important;
  }
}
button.comm-btn-replay {
  bottom: -5px;
}
button.comm-btn-block {
  top: 15px;
}
.user-link {
  color: #2196f3;
  text-decoration: none;
}
.user-link:hover {
  color: #4ca8f3;
}
.hidden-post {
  display: none;
}
.hidden-post-admin {
  opacity: 0.5;
}
</style>
