<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="getMySubscriptionsDialog"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <span class="my-prof-controls-icon">
            <v-icon center style="margin-right:10px">
              mdi-account-star-outline
            </v-icon>
            {{ following_count }}
          </span>
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Подписки
          </span>
        </v-btn>
      </template>
      <span>Подписки</span>
    </v-tooltip>
    <v-dialog
      v-model="mySubscriptionsDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row v-if="preloading">
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <v-skeleton-loader
                  height="64"
                  type="paragraph"
                  class="mx-auto flex-v-center"
                >
                </v-skeleton-loader>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <v-skeleton-loader
                  height="64"
                  type="paragraph"
                  class="mx-auto flex-v-center"
                >
                </v-skeleton-loader>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <v-skeleton-loader
                  height="64"
                  type="paragraph"
                  class="mx-auto flex-v-center"
                >
                </v-skeleton-loader>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                <v-skeleton-loader
                  height="64"
                  type="avatar"
                  class="mx-auto flex-v-center"
                >
                </v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Подписки
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn
                    @click="mySubscriptionsDialog = false"
                    icon
                    absolute
                    right
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container class="mb-6">
                  <v-row v-if="users.length" justify="space-around">
                    <v-col
                      v-for="(user, i) in users"
                      :key="i"
                      cols="6"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div>
                        <a
                          @click.stop="getUserProfileDialog(user.id)"
                          :class="{ 'premium-avatar4': user.has_products }"
                        >
                          <v-avatar size="96px">
                            <v-img
                              :src="user.avatar"
                              :alt="user.first_name"
                            ></v-img>
                          </v-avatar>
                        </a>
                      </div>
                      <a
                        @click.stop="getUserProfileDialog(user.id)"
                        class="user-link"
                      >
                        {{ user.first_name }}
                      </a>
                      <div v-if="user.chart_type.title" class="user-type">
                        {{ user.chart_type.title }}, {{ user.chart_profile.id }}
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    count: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      mySubscriptionsDialog: false,
      users: [],
      preloading: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    following_count() {
      // console.log(this.count)
      return this.count ? this.count : this.User.community.following_count;
    }
  },
  mounted() {
    // this.getFollowing(this.id)
  },
  methods: {
    ...mapMutations({ call_user_view_dialog: "account/call_user_view_dialog" }),
    async getFollowing(id) {
      this.preloading = true;
      const result = await Api.getFollowing(id, this.HttpOptions);
      if (result.success) {
        this.users = result.users;
        this.preloading = false;
      }
      // console.log(result)
    },
    getUserProfileDialog(id) {
      this.call_user_view_dialog(id);
    },
    getMySubscriptionsDialog() {
      if (this.users.length) {
        this.mySubscriptionsDialog = true;
      } else {
        if (this.count > 0 || this.following_count > 0)
          this.getFollowing(this.id);
        this.mySubscriptionsDialog = true;
      }
    }
  }
};
</script>
