<template>
  <div>
    <v-list-item @click="sharePost(id)">
      <v-list-item-title>
        <v-icon>mdi-share-variant-outline</v-icon>
        Поделиться
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp
    })
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    async sharePost(id) {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        const data = { post_id: id };
        this.$store.dispatch("account/sharePost", data);
      } else {
        this.update_login_dialog(true);
      }
    }
  }
};
</script>
