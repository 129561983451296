<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="sendReportDialog = true"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon center>mdi-alert-circle-outline</v-icon>
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Поддержка
          </span>
        </v-btn>
      </template>
      <span>Поддержка</span>
    </v-tooltip>
    <v-dialog
      v-model="sendReportDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Сообщение в поддержку
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container class="mb-6">
                  <v-card flat d-flex pa-2 class="card-md">
                    <v-form>
                      <v-card-text class="card-md">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="subject"
                              :error-messages="subjectErrors"
                              @input="$v.subject.$touch()"
                              @blur="$v.subject.$touch()"
                              counter="64"
                              label="Тема сообщения"
                              filled
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="message"
                              :error-messages="messageErrors"
                              name="input-7-1"
                              filled
                              label="Опишите ситуацию"
                              auto-grow
                              required
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text>
                        <v-row>
                          <v-row
                            v-if="images.length"
                            style="max-width:100%;margin:0"
                          >
                            <v-col
                              v-for="(image, i) in images"
                              :key="i"
                              cols="4"
                              style="padding:2px"
                            >
                              <v-card>
                                <v-card-title style="padding:2px">
                                  <span
                                    class="headline"
                                    style="font-size: 1.3rem !important;display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width: 90%;"
                                    >{{ image.name }}</span
                                  >
                                  <v-btn
                                    @click="imageDel($event, i)"
                                    icon
                                    small
                                    absolute
                                    right
                                    class="custom-phts-btn-close"
                                  >
                                    <v-icon>mdi-close-circle</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-img
                                  :src="sortArr(imagesSources)[i]"
                                  :title="image.name"
                                  height="150"
                                  class="grey darken-4"
                                ></v-img>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-col cols="12">
                            <v-file-input
                              ref="screenshotsSelect"
                              v-model="images"
                              @change="onScreenshotsChange($event)"
                              @click.stop
                              :error-messages="imageErrors"
                              accept="image/png, image/jpeg, image/bmp"
                              prepend-icon="mdi-camera-plus-outline"
                              label="Фотографии"
                              multiple
                              class="custom-photos-input"
                            ></v-file-input>
                            Прикрепите скрин, если это поможет нам лучше понять
                            вас
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-row class="d-flex justify-center">
                          <v-col
                            cols="12"
                            sm="12"
                            md="6"
                            lg="6"
                            xl="6"
                            class="text-center"
                          >
                            <v-btn
                              @click="validate"
                              rounded
                              large
                              color="primary"
                              class="v-btn-custom hd-gradient v-btn-custom--300"
                            >
                              Отправить&nbsp;<v-icon>mdi-magnify</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="successSendMessage" top color="success">
      <v-icon color="white">mdi-check-circle-outline</v-icon>
      &nbsp;Спасибо, ваше сообщение отправлено.
      <v-btn @click="successSendMessage = false" color="red" text>
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successSendMessage = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Api from "@/api/api";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState, mapMutations } from "vuex";

export default {
  mixins: [validationMixin],
  validations: {
    subject: { required, maxLength: maxLength(64) },
    message: { required }
  },
  data() {
    return {
      sendReportDialog: false,
      subject: "",
      message: "",
      successSendMessage: false,
      images: [],
      imagesSources: [],
      imagesLimit: 9,
      imagesIds: ""
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options,
      ConfApp: (state) => state.conf.ConfApp
    }),
    subjectErrors() {
      const errors = [];
      if (!this.$v.subject.$dirty) return errors;
      !this.$v.subject.maxLength &&
        errors.push("Тема сообщения не может быть более 70 символов.");
      !this.$v.subject.required && errors.push("Введите тему сообщения.");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required &&
        errors.push("Сообщение не может быть пустым.");
      return errors;
    },
    imageErrors() {
      const errors = [];
      const tempErrs = [];
      this.images.forEach((item) => {
        if (item.size > 15728640) tempErrs.push(item.name);
      });
      if (tempErrs.length && this.images.length > this.imagesLimit) {
        errors.push(
          "Файлы: " +
            tempErrs.join(", ") +
            ` — больше 15Mb, вы можете загрузить не более ${this.imagesLimit} файлов.`
        );
        this.$store.commit("account/update_comm_images_validation", false);
      } else if (tempErrs.length) {
        errors.push("Файлы: " + tempErrs.join(", ") + " — больше 15Mb.");
        this.$store.commit("account/update_comm_images_validation", false);
      } else if (this.images.length > this.imagesLimit) {
        errors.push(`Вы можете загрузить не более ${this.imagesLimit} файлов.`);
        this.$store.commit("account/update_comm_images_validation", false);
      } else {
        this.$store.commit("account/update_comm_images_validation", true);
      }
      return errors;
    }
  },
  methods: {
    ...mapMutations({
      call_user_view_dialog: "account/call_user_view_dialog"
    }),
    async sendReport() {
      // console.log(result)
    },
    onScreenshotsChange() {
      // console.log(this.images)
      this.imagesSources = [];
      this.images.forEach((file, i) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imagesSources.push({
            id: i,
            src: e.target.result
          });
        };
        reader.readAsDataURL(file);
      });
    },
    sortArr(arr) {
      return arr.slice().sort((a, b) => {
        return a.id - b.id;
      });
    },
    imageDel(e, i) {
      const el =
        e.target.parentElement.parentElement.parentElement.parentElement;
      el.style.cssText = "transform: scale(0); transition: transform 0.2s";
      clearTimeout(this.imagesIds);
      this.imagesIds = setTimeout(() => {
        this.images.splice(i, 1);
        this.imagesSources.forEach((item, j) => {
          if (j === i) this.imagesSources.splice(j, 1);
        });
        el.style = "";
      }, 200);
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) this.sendErrorsReport();
    },
    async sendErrorsReport() {
      try {
        const formData = new FormData();
        this.images.forEach((file) => {
          formData.append("files[]", file);
        });
        formData.append("access-token", this.User.access_token);
        formData.append("partner_id", this.ConfApp.partner_id);
        formData.append("subject", this.subject);
        formData.append("message", this.message);
        const result = await Api.sendErrorsReport(formData);
        if (result.success) {
          this.sendReportDialog = false;
          this.successSendMessage = true;
          this.$v.$reset();
          this.subject = "";
          this.message = "";
          this.images = [];
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    closeDialog() {
      this.sendReportDialog = false;
      this.images = [];
    }
  }
};
</script>
