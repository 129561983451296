<template>
  <div>
    <v-btn @click="validateProfileRules(id, title)" text rounded color="blue">
      <v-icon>
        mdi-comment-multiple-outline
      </v-icon>
      {{ counter }}
    </v-btn>
    <v-dialog
      ref="topcomm"
      v-model="dialogComments"
      fullscreen
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-snackbar v-model="spamReportSent" top absolute>
        Ваше сообщение отправлено
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            @click="spamReportSent = false"
            color="red"
            x-small
            text
          >
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h mob-padding-428-0"
              >
                <v-card class="mx-auto">
                  <v-toolbar class="hd-gradient position-sticky" dark>
                    <v-spacer></v-spacer>

                    <v-toolbar-title class="mob-max-width-307">
                      {{ postNameComm }}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn @click="closeComments" icon absolute right>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-list v-if="isComments" three-line>
                    <v-subheader>
                      Комментарии:
                    </v-subheader>
                    <template v-for="(item, i) in comments">
                      <div :key="item.id" class="comment-item">
                        <v-list-item
                          :class="{
                            'hidden-comment':
                              +item.status_id === 2 && !User.is_admin,
                            'hidden-comment-admin':
                              +item.status_id === 2 && User.is_admin
                          }"
                        >
                          <span
                            :class="{
                              'premium-avatar5': item.user.has_products
                            }"
                          >
                            <v-list-item-avatar>
                              <v-img :src="item.user.first_image"></v-img>
                            </v-list-item-avatar>
                          </span>

                          <v-list-item-content>
                            <v-list-item-title style="text-align:left">
                              <a
                                @click="getUserProfileDialog(item.user.id)"
                                class="user-link"
                              >
                                {{ item.user.first_name }}
                              </a>
                              <v-list-item-subtitle
                                v-if="item.user.chart_type"
                                class="margin-bottom-8"
                              >
                                {{ item.user.chart_type.title }}
                                {{ item.user.chart_profile.id }}
                              </v-list-item-subtitle>
                              <div class="comment-datetime">
                                {{ formatCommDate(item.created_at) }}
                              </div>
                            </v-list-item-title>
                            <div
                              v-html="transformText(item.description)"
                              class="comm-description"
                            ></div>
                          </v-list-item-content>

                          <LikeCommButton
                            :id="item.id"
                            :isLikedIncome="item.is_liked"
                            :counterIncome="item.likes_count"
                          />

                          <v-btn
                            v-if="User.isLogged"
                            @click="commReplayTo(item.id, item.user.first_name)"
                            x-small
                            text
                            color="primary"
                            class="comm-btn-replay"
                            absolute
                            right
                            dark
                          >
                            <span v-if="+User.id !== +item.user.id">
                              ответить
                            </span>
                            <span v-else>
                              уточнить
                            </span>
                          </v-btn>

                          <v-menu bottom absolute right>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="
                                  (User.isLogged && User.id !== item.user.id) ||
                                    User.is_admin
                                "
                                v-bind="attrs"
                                v-on="on"
                                fab
                                absolute
                                right
                                small
                                text
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-item
                                v-if="
                                  User.isLogged && +User.id === +item.user.id
                                "
                                @click="editReport(item.id, item.description)"
                              >
                                <v-list-item-title>
                                  <v-icon
                                    >mdi-file-document-edit-outline</v-icon
                                  >
                                  Редактировать
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="
                                  User.isLogged && +User.id !== +item.user.id
                                "
                                @click="commentSpamReport(item.id)"
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-alert-octagon-outline</v-icon>
                                  Пожаловаться
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                v-if="User.isLogged && User.is_admin"
                                @click="commBlock(item.id, item.status_id)"
                              >
                                <v-list-item-title v-if="item.status_id === 1">
                                  <v-icon>mdi-cancel</v-icon>
                                  Заблокировать
                                </v-list-item-title>
                                <v-list-item-title v-if="item.status_id === 2">
                                  <v-icon>mdi-undo</v-icon>
                                  Разблокировать
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item>

                        <v-divider
                          v-if="
                            i < comments.length - 1 &&
                              (item.status_id === 1 || User.is_admin)
                          "
                          inset
                          style="margin-left:72px !important"
                        ></v-divider>
                        <div v-if="item.children.length">
                          <template v-for="subitem in item.children">
                            <div :key="subitem.id" class="comment-subitem">
                              <v-list-item
                                :class="{
                                  'hidden-comment':
                                    +subitem.status_id === 2 && !User.is_admin,
                                  'hidden-comment-admin':
                                    +subitem.status_id === 2 && User.is_admin
                                }"
                              >
                                <span
                                  :class="{
                                    'premium-avatar6': subitem.user.has_products
                                  }"
                                >
                                  <v-list-item-avatar size="35px">
                                    <v-img
                                      :src="subitem.user.first_image"
                                    ></v-img>
                                  </v-list-item-avatar>
                                </span>

                                <v-list-item-content>
                                  <v-list-item-title style="text-align:left">
                                    <a
                                      @click="
                                        getUserProfileDialog(subitem.user.id)
                                      "
                                      class="user-link"
                                    >
                                      {{ subitem.user.first_name }}
                                    </a>
                                    <v-list-item-subtitle
                                      v-if="subitem.user.chart_type"
                                      class="margin-bottom-8"
                                    >
                                      {{ subitem.user.chart_type.title }}
                                      {{ subitem.user.chart_profile.id }}
                                    </v-list-item-subtitle>
                                    <div class="comment-datetime">
                                      {{ formatCommDate(subitem.created_at) }}
                                    </div>
                                  </v-list-item-title>
                                  <div
                                    v-html="transformText(subitem.description)"
                                    class="comm-description"
                                  ></div>
                                </v-list-item-content>

                                <LikeCommButton
                                  :id="subitem.id"
                                  :isLikedIncome="subitem.is_liked"
                                  :counterIncome="subitem.likes_count"
                                />

                                <v-btn
                                  v-if="User.isLogged"
                                  @click="
                                    commReplayTo(
                                      subitem.id,
                                      subitem.user.first_name
                                    )
                                  "
                                  x-small
                                  text
                                  color="primary"
                                  class="comm-btn-replay"
                                  absolute
                                  right
                                  dark
                                >
                                  <span v-if="+User.id !== +subitem.user.id">
                                    ответить
                                  </span>
                                  <span v-else>
                                    уточнить
                                  </span>
                                </v-btn>

                                <v-menu bottom absolute right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-if="
                                        (User.isLogged &&
                                          User.id !== subitem.user.id) ||
                                          User.is_admin
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                      fab
                                      absolute
                                      right
                                      small
                                      text
                                    >
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list>
                                    <v-list-item
                                      v-if="
                                        User.isLogged &&
                                          +User.id === +subitem.user.id
                                      "
                                      @click="
                                        editReport(
                                          subitem.id,
                                          subitem.description
                                        )
                                      "
                                    >
                                      <v-list-item-title>
                                        <v-icon
                                          >mdi-file-document-edit-outline</v-icon
                                        >
                                        Редактировать
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                      v-if="
                                        User.isLogged &&
                                          +User.id !== +subitem.user.id
                                      "
                                      @click="commentSpamReport(subitem.id)"
                                    >
                                      <v-list-item-title>
                                        <v-icon
                                          >mdi-alert-octagon-outline</v-icon
                                        >
                                        Пожаловаться
                                      </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                      v-if="User.isLogged && User.is_admin"
                                      @click="
                                        commBlock(subitem.id, subitem.status_id)
                                      "
                                    >
                                      <v-list-item-title
                                        v-if="subitem.status_id === 1"
                                      >
                                        <v-icon>mdi-cancel</v-icon>
                                        Заблокировать
                                      </v-list-item-title>
                                      <v-list-item-title
                                        v-if="subitem.status_id === 2"
                                      >
                                        <v-icon>mdi-undo</v-icon>
                                        Разблокировать
                                      </v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </v-list-item>

                              <v-divider
                                v-if="subitem.status_id === 1 || User.is_admin"
                                inset
                                style="margin-left:72px !important"
                              ></v-divider>
                              <div v-if="subitem.children.length">
                                <template v-for="sub2item in subitem.children">
                                  <div
                                    :key="sub2item.id"
                                    class="comment-sub2item"
                                  >
                                    <v-list-item
                                      :class="{
                                        'hidden-comment':
                                          +sub2item.status_id === 2 &&
                                          !User.is_admin,
                                        'hidden-comment-admin':
                                          +sub2item.status_id === 2 &&
                                          User.is_admin
                                      }"
                                    >
                                      <span
                                        :class="{
                                          'premium-avatar7':
                                            sub2item.user.has_products
                                        }"
                                      >
                                        <v-list-item-avatar size="30px">
                                          <v-img
                                            :src="sub2item.user.first_image"
                                          ></v-img>
                                        </v-list-item-avatar>
                                      </span>

                                      <v-list-item-content>
                                        <v-list-item-title
                                          style="text-align:left"
                                        >
                                          <a
                                            @click="
                                              getUserProfileDialog(
                                                sub2item.user.id
                                              )
                                            "
                                            class="user-link"
                                          >
                                            {{ sub2item.user.first_name }}
                                          </a>
                                          <v-list-item-subtitle
                                            v-if="sub2item.user.chart_type"
                                            class="margin-bottom-8"
                                          >
                                            {{ sub2item.user.chart_type.title }}
                                            {{ sub2item.user.chart_profile.id }}
                                          </v-list-item-subtitle>
                                          <div class="comment-datetime">
                                            {{
                                              formatCommDate(
                                                sub2item.created_at
                                              )
                                            }}
                                          </div>
                                        </v-list-item-title>
                                        <div
                                          v-html="
                                            transformText(sub2item.description)
                                          "
                                          class="comm-description"
                                        ></div>
                                      </v-list-item-content>

                                      <LikeCommButton
                                        :id="sub2item.id"
                                        :isLikedIncome="sub2item.is_liked"
                                        :counterIncome="sub2item.likes_count"
                                      />

                                      <v-btn
                                        v-if="User.isLogged"
                                        @click="
                                          commReplayTo(
                                            sub2item.id,
                                            sub2item.user.first_name
                                          )
                                        "
                                        x-small
                                        text
                                        color="primary"
                                        class="comm-btn-replay"
                                        absolute
                                        right
                                        dark
                                      >
                                        <span
                                          v-if="+User.id !== +sub2item.user.id"
                                        >
                                          ответить
                                        </span>
                                        <span v-else>
                                          уточнить
                                        </span>
                                      </v-btn>

                                      <v-menu bottom absolute right>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            v-if="
                                              (User.isLogged &&
                                                User.id !== sub2item.user.id) ||
                                                User.is_admin
                                            "
                                            v-bind="attrs"
                                            v-on="on"
                                            fab
                                            absolute
                                            right
                                            small
                                            text
                                          >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                          </v-btn>
                                        </template>

                                        <v-list>
                                          <v-list-item
                                            v-if="
                                              User.isLogged &&
                                                +User.id === +sub2item.user.id
                                            "
                                            @click="
                                              editReport(
                                                sub2item.id,
                                                sub2item.description
                                              )
                                            "
                                          >
                                            <v-list-item-title>
                                              <v-icon
                                                >mdi-file-document-edit-outline</v-icon
                                              >
                                              Редактировать
                                            </v-list-item-title>
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              User.isLogged &&
                                                +User.id !== +sub2item.user.id
                                            "
                                            @click="
                                              commentSpamReport(sub2item.id)
                                            "
                                          >
                                            <v-list-item-title>
                                              <v-icon
                                                >mdi-alert-octagon-outline</v-icon
                                              >
                                              Пожаловаться
                                            </v-list-item-title>
                                          </v-list-item>
                                          <v-list-item
                                            v-if="
                                              User.isLogged && User.is_admin
                                            "
                                            @click="
                                              commBlock(
                                                sub2item.id,
                                                sub2item.status_id
                                              )
                                            "
                                          >
                                            <v-list-item-title
                                              v-if="sub2item.status_id === 1"
                                            >
                                              <v-icon>mdi-cancel</v-icon>
                                              Заблокировать
                                            </v-list-item-title>
                                            <v-list-item-title
                                              v-if="sub2item.status_id === 2"
                                            >
                                              <v-icon>mdi-undo</v-icon>
                                              Разблокировать
                                            </v-list-item-title>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                    </v-list-item>

                                    <v-divider
                                      v-if="
                                        sub2item.status_id === 1 ||
                                          User.is_admin
                                      "
                                      inset
                                      style="margin-left:72px !important"
                                    ></v-divider>
                                    <div v-if="sub2item.children.length">
                                      <template
                                        v-for="sub3item in sub2item.children"
                                      >
                                        <div
                                          :key="sub3item.id"
                                          class="comment-sub3item"
                                        >
                                          <v-list-item
                                            :class="{
                                              'hidden-comment':
                                                +sub3item.status_id === 2 &&
                                                !User.is_admin,
                                              'hidden-comment-admin':
                                                +sub3item.status_id === 2 &&
                                                User.is_admin
                                            }"
                                          >
                                            <span
                                              :class="{
                                                'premium-avatar7':
                                                  sub3item.user.has_products
                                              }"
                                            >
                                              <v-list-item-avatar size="30px">
                                                <v-img
                                                  :src="
                                                    sub3item.user.first_image
                                                  "
                                                ></v-img>
                                              </v-list-item-avatar>
                                            </span>

                                            <v-list-item-content>
                                              <v-list-item-title
                                                style="text-align:left"
                                              >
                                                <a
                                                  @click="
                                                    getUserProfileDialog(
                                                      sub3item.user.id
                                                    )
                                                  "
                                                  class="user-link"
                                                >
                                                  {{ sub3item.user.first_name }}
                                                </a>
                                                <v-list-item-subtitle
                                                  v-if="
                                                    sub3item.user.chart_type
                                                  "
                                                  class="margin-bottom-8"
                                                >
                                                  {{
                                                    sub3item.user.chart_type
                                                      .title
                                                  }}
                                                  {{
                                                    sub3item.user.chart_profile
                                                      .id
                                                  }}
                                                </v-list-item-subtitle>
                                                <div class="comment-datetime">
                                                  {{
                                                    formatCommDate(
                                                      sub3item.created_at
                                                    )
                                                  }}
                                                </div>
                                              </v-list-item-title>
                                              <div
                                                v-html="
                                                  transformText(
                                                    sub3item.description
                                                  )
                                                "
                                                class="comm-description"
                                              ></div>
                                            </v-list-item-content>

                                            <LikeCommButton
                                              :id="sub3item.id"
                                              :isLikedIncome="sub3item.is_liked"
                                              :counterIncome="
                                                sub3item.likes_count
                                              "
                                            />

                                            <v-btn
                                              v-if="User.isLogged"
                                              @click="
                                                commReplayTo(
                                                  sub3item.id,
                                                  sub3item.user.first_name
                                                )
                                              "
                                              x-small
                                              text
                                              color="primary"
                                              class="comm-btn-replay"
                                              absolute
                                              right
                                              dark
                                            >
                                              <span
                                                v-if="
                                                  +User.id !== +sub3item.user.id
                                                "
                                              >
                                                ответить
                                              </span>
                                              <span v-else>
                                                уточнить
                                              </span>
                                            </v-btn>

                                            <v-menu bottom absolute right>
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <v-btn
                                                  v-if="
                                                    (User.isLogged &&
                                                      User.id !==
                                                        sub3item.user.id) ||
                                                      User.is_admin
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  fab
                                                  absolute
                                                  right
                                                  small
                                                  text
                                                >
                                                  <v-icon
                                                    >mdi-dots-vertical</v-icon
                                                  >
                                                </v-btn>
                                              </template>

                                              <v-list>
                                                <v-list-item
                                                  v-if="
                                                    User.isLogged &&
                                                      +User.id ===
                                                        +sub3item.user.id
                                                  "
                                                  @click="
                                                    editReport(
                                                      sub3item.id,
                                                      sub3item.description
                                                    )
                                                  "
                                                >
                                                  <v-list-item-title>
                                                    <v-icon
                                                      >mdi-file-document-edit-outline</v-icon
                                                    >
                                                    Редактировать
                                                  </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                  v-if="
                                                    User.isLogged &&
                                                      +User.id !==
                                                        +sub3item.user.id
                                                  "
                                                  @click="
                                                    commentSpamReport(
                                                      sub3item.id
                                                    )
                                                  "
                                                >
                                                  <v-list-item-title>
                                                    <v-icon
                                                      >mdi-alert-octagon-outline</v-icon
                                                    >
                                                    Пожаловаться
                                                  </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                  v-if="
                                                    User.isLogged &&
                                                      User.is_admin
                                                  "
                                                  @click="
                                                    commBlock(
                                                      sub3item.id,
                                                      sub3item.status_id
                                                    )
                                                  "
                                                >
                                                  <v-list-item-title
                                                    v-if="
                                                      sub3item.status_id === 1
                                                    "
                                                  >
                                                    <v-icon>mdi-cancel</v-icon>
                                                    Заблокировать
                                                  </v-list-item-title>
                                                  <v-list-item-title
                                                    v-if="
                                                      sub3item.status_id === 2
                                                    "
                                                  >
                                                    <v-icon>mdi-undo</v-icon>
                                                    Разблокировать
                                                  </v-list-item-title>
                                                </v-list-item>
                                              </v-list>
                                            </v-menu>
                                          </v-list-item>

                                          <v-divider
                                            v-if="
                                              sub3item.status_id === 1 ||
                                                User.is_admin
                                            "
                                            inset
                                            style="margin-left:72px !important"
                                          ></v-divider>
                                          <div v-if="sub3item.children.length">
                                            <template
                                              v-for="sub4item in sub3item.children"
                                            >
                                              <div
                                                :key="sub4item.id"
                                                class="comment-sub4item"
                                              >
                                                <v-list-item
                                                  :class="{
                                                    'hidden-comment':
                                                      +sub4item.status_id ===
                                                        2 && !User.is_admin,
                                                    'hidden-comment-admin':
                                                      +sub4item.status_id ===
                                                        2 && User.is_admin
                                                  }"
                                                >
                                                  <span
                                                    :class="{
                                                      'premium-avatar7':
                                                        sub4item.user
                                                          .has_products
                                                    }"
                                                  >
                                                    <v-list-item-avatar
                                                      size="30px"
                                                    >
                                                      <v-img
                                                        :src="
                                                          sub4item.user
                                                            .first_image
                                                        "
                                                      ></v-img>
                                                    </v-list-item-avatar>
                                                  </span>

                                                  <v-list-item-content>
                                                    <v-list-item-title
                                                      style="text-align:left"
                                                    >
                                                      <a
                                                        @click="
                                                          getUserProfileDialog(
                                                            sub4item.user.id
                                                          )
                                                        "
                                                        class="user-link"
                                                      >
                                                        {{
                                                          sub4item.user
                                                            .first_name
                                                        }}
                                                      </a>
                                                      <v-list-item-subtitle
                                                        v-if="
                                                          sub4item.user
                                                            .chart_type
                                                        "
                                                        class="margin-bottom-8"
                                                      >
                                                        {{
                                                          sub4item.user
                                                            .chart_type.title
                                                        }}
                                                        {{
                                                          sub4item.user
                                                            .chart_profile.id
                                                        }}
                                                      </v-list-item-subtitle>
                                                      <div
                                                        class="comment-datetime"
                                                      >
                                                        {{
                                                          formatCommDate(
                                                            sub4item.created_at
                                                          )
                                                        }}
                                                      </div>
                                                    </v-list-item-title>
                                                    <div
                                                      v-html="
                                                        transformText(
                                                          sub4item.description
                                                        )
                                                      "
                                                      class="comm-description"
                                                    ></div>
                                                  </v-list-item-content>

                                                  <LikeCommButton
                                                    :id="sub4item.id"
                                                    :isLikedIncome="
                                                      sub4item.is_liked
                                                    "
                                                    :counterIncome="
                                                      sub4item.likes_count
                                                    "
                                                  />

                                                  <v-btn
                                                    v-if="User.isLogged"
                                                    @click="
                                                      commReplayTo(
                                                        sub4item.id,
                                                        sub4item.user.first_name
                                                      )
                                                    "
                                                    x-small
                                                    text
                                                    color="primary"
                                                    class="comm-btn-replay"
                                                    absolute
                                                    right
                                                    dark
                                                  >
                                                    <span
                                                      v-if="
                                                        +User.id !==
                                                          +sub4item.user.id
                                                      "
                                                    >
                                                      ответить
                                                    </span>
                                                    <span v-else>
                                                      уточнить
                                                    </span>
                                                  </v-btn>

                                                  <v-menu bottom absolute right>
                                                    <template
                                                      v-slot:activator="{
                                                        on,
                                                        attrs
                                                      }"
                                                    >
                                                      <v-btn
                                                        v-if="
                                                          (User.isLogged &&
                                                            User.id !==
                                                              sub4item.user
                                                                .id) ||
                                                            User.is_admin
                                                        "
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        fab
                                                        absolute
                                                        right
                                                        small
                                                        text
                                                      >
                                                        <v-icon
                                                          >mdi-dots-vertical</v-icon
                                                        >
                                                      </v-btn>
                                                    </template>

                                                    <v-list>
                                                      <v-list-item
                                                        v-if="
                                                          User.isLogged &&
                                                            +User.id ===
                                                              +sub4item.user.id
                                                        "
                                                        @click="
                                                          editReport(
                                                            sub4item.id,
                                                            sub4item.description
                                                          )
                                                        "
                                                      >
                                                        <v-list-item-title>
                                                          <v-icon
                                                            >mdi-file-document-edit-outline</v-icon
                                                          >
                                                          Редактировать
                                                        </v-list-item-title>
                                                      </v-list-item>
                                                      <v-list-item
                                                        v-if="
                                                          User.isLogged &&
                                                            +User.id !==
                                                              +sub4item.user.id
                                                        "
                                                        @click="
                                                          commentSpamReport(
                                                            sub4item.id
                                                          )
                                                        "
                                                      >
                                                        <v-list-item-title>
                                                          <v-icon
                                                            >mdi-alert-octagon-outline</v-icon
                                                          >
                                                          Пожаловаться
                                                        </v-list-item-title>
                                                      </v-list-item>
                                                      <v-list-item
                                                        v-if="
                                                          User.isLogged &&
                                                            User.is_admin
                                                        "
                                                        @click="
                                                          commBlock(
                                                            sub4item.id,
                                                            sub4item.status_id
                                                          )
                                                        "
                                                      >
                                                        <v-list-item-title
                                                          v-if="
                                                            sub4item.status_id ===
                                                              1
                                                          "
                                                        >
                                                          <v-icon
                                                            >mdi-cancel</v-icon
                                                          >
                                                          Заблокировать
                                                        </v-list-item-title>
                                                        <v-list-item-title
                                                          v-if="
                                                            sub4item.status_id ===
                                                              2
                                                          "
                                                        >
                                                          <v-icon
                                                            >mdi-undo</v-icon
                                                          >
                                                          Разблокировать
                                                        </v-list-item-title>
                                                      </v-list-item>
                                                    </v-list>
                                                  </v-menu>
                                                </v-list-item>

                                                <v-divider
                                                  v-if="
                                                    sub4item.status_id === 1 ||
                                                      User.is_admin
                                                  "
                                                  inset
                                                  style="margin-left:72px !important"
                                                ></v-divider>
                                                <div
                                                  v-if="
                                                    sub4item.children.length
                                                  "
                                                >
                                                  <template
                                                    v-for="sub5item in sub4item.children"
                                                  >
                                                    <div
                                                      :key="sub5item.id"
                                                      class="comment-sub5item"
                                                    >
                                                      <v-list-item
                                                        :class="{
                                                          'hidden-comment':
                                                            +sub5item.status_id ===
                                                              2 &&
                                                            !User.is_admin,
                                                          'hidden-comment-admin':
                                                            +sub5item.status_id ===
                                                              2 && User.is_admin
                                                        }"
                                                      >
                                                        <span
                                                          :class="{
                                                            'premium-avatar7':
                                                              sub5item.user
                                                                .has_products
                                                          }"
                                                        >
                                                          <v-list-item-avatar
                                                            size="30px"
                                                          >
                                                            <v-img
                                                              :src="
                                                                sub5item.user
                                                                  .first_image
                                                              "
                                                            ></v-img>
                                                          </v-list-item-avatar>
                                                        </span>

                                                        <v-list-item-content>
                                                          <v-list-item-title
                                                            style="text-align:left"
                                                          >
                                                            <a
                                                              @click="
                                                                getUserProfileDialog(
                                                                  sub5item.user
                                                                    .id
                                                                )
                                                              "
                                                              class="user-link"
                                                            >
                                                              {{
                                                                sub5item.user
                                                                  .first_name
                                                              }}
                                                            </a>
                                                            <v-list-item-subtitle
                                                              v-if="
                                                                sub5item.user
                                                                  .chart_type
                                                              "
                                                              class="margin-bottom-8"
                                                            >
                                                              {{
                                                                sub5item.user
                                                                  .chart_type
                                                                  .title
                                                              }}
                                                              {{
                                                                sub5item.user
                                                                  .chart_profile
                                                                  .id
                                                              }}
                                                            </v-list-item-subtitle>
                                                            <div
                                                              class="comment-datetime"
                                                            >
                                                              {{
                                                                formatCommDate(
                                                                  sub5item.created_at
                                                                )
                                                              }}
                                                            </div>
                                                          </v-list-item-title>
                                                          <div
                                                            v-html="
                                                              transformText(
                                                                sub5item.description
                                                              )
                                                            "
                                                            class="comm-description"
                                                          ></div>
                                                        </v-list-item-content>

                                                        <LikeCommButton
                                                          :id="sub5item.id"
                                                          :isLikedIncome="
                                                            sub5item.is_liked
                                                          "
                                                          :counterIncome="
                                                            sub5item.likes_count
                                                          "
                                                        />

                                                        <v-menu
                                                          bottom
                                                          absolute
                                                          right
                                                        >
                                                          <template
                                                            v-slot:activator="{
                                                              on,
                                                              attrs
                                                            }"
                                                          >
                                                            <v-btn
                                                              v-if="
                                                                (User.isLogged &&
                                                                  User.id !==
                                                                    sub5item
                                                                      .user
                                                                      .id) ||
                                                                  User.is_admin
                                                              "
                                                              v-bind="attrs"
                                                              v-on="on"
                                                              fab
                                                              absolute
                                                              right
                                                              small
                                                              text
                                                            >
                                                              <v-icon
                                                                >mdi-dots-vertical</v-icon
                                                              >
                                                            </v-btn>
                                                          </template>

                                                          <v-list>
                                                            <v-list-item
                                                              v-if="
                                                                User.isLogged &&
                                                                  +User.id ===
                                                                    +sub5item
                                                                      .user.id
                                                              "
                                                              @click="
                                                                editReport(
                                                                  sub5item.id,
                                                                  sub5item.description
                                                                )
                                                              "
                                                            >
                                                              <v-list-item-title>
                                                                <v-icon
                                                                  >mdi-file-document-edit-outline</v-icon
                                                                >
                                                                Редактировать
                                                              </v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item
                                                              v-if="
                                                                User.isLogged &&
                                                                  +User.id !==
                                                                    +sub5item
                                                                      .user.id
                                                              "
                                                              @click="
                                                                commentSpamReport(
                                                                  sub5item.id
                                                                )
                                                              "
                                                            >
                                                              <v-list-item-title>
                                                                <v-icon
                                                                  >mdi-alert-octagon-outline</v-icon
                                                                >
                                                                Пожаловаться
                                                              </v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item
                                                              v-if="
                                                                User.isLogged &&
                                                                  User.is_admin
                                                              "
                                                              @click="
                                                                commBlock(
                                                                  sub5item.id,
                                                                  sub5item.status_id
                                                                )
                                                              "
                                                            >
                                                              <v-list-item-title
                                                                v-if="
                                                                  sub5item.status_id ===
                                                                    1
                                                                "
                                                              >
                                                                <v-icon
                                                                  >mdi-cancel</v-icon
                                                                >
                                                                Заблокировать
                                                              </v-list-item-title>
                                                              <v-list-item-title
                                                                v-if="
                                                                  sub5item.status_id ===
                                                                    2
                                                                "
                                                              >
                                                                <v-icon
                                                                  >mdi-undo</v-icon
                                                                >
                                                                Разблокировать
                                                              </v-list-item-title>
                                                            </v-list-item>
                                                          </v-list>
                                                        </v-menu>
                                                      </v-list-item>

                                                      <v-divider
                                                        v-if="
                                                          sub5item.status_id ===
                                                            1 || User.is_admin
                                                        "
                                                        inset
                                                        style="margin-left:72px !important"
                                                      ></v-divider>
                                                    </div>
                                                  </template>
                                                </div>
                                              </div>
                                            </template>
                                          </div>
                                        </div>
                                      </template>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </v-list>
                  <v-list v-else three-line style="padding:25px">
                    Коментарии пока отсутствуют...
                  </v-list>
                  <v-list>
                    <div id="commentsfooter"></div>
                  </v-list>
                  <v-footer
                    class="comments-footer"
                    absolute
                    style="position:sticky;z-index:4"
                  >
                    <div
                      style="width:100%;display:flex;align-items:center;justify-content:center"
                    >
                      <v-col
                        cols="12"
                        sm="12"
                        md="10"
                        lg="10"
                        xl="10"
                        style="padding:12px 0 0"
                      >
                        <v-form ref="formComm" class="form-comm">
                          <v-textarea
                            ref="commDesc"
                            v-model="commentDesc"
                            :counter="1000"
                            :rules="commRules"
                            outlined
                            name="input-7-4"
                            rows="1"
                            row-height="10"
                            auto-grow
                            label="Добавьте свой комментарий..."
                            class="form-comm-textarea"
                          ></v-textarea>
                          <v-btn
                            v-if="commentParentId"
                            @click="clearCommForm"
                            small
                            text
                            absolute
                            right
                            color="deep-orange darken-2"
                            class="form-comm-btn-del-replay"
                          >
                            <v-icon>
                              mdi-close
                            </v-icon>
                          </v-btn>
                          <v-btn
                            @click="validateComment(postIdComm, postNameComm)"
                            :disabled="commBtnDisabled"
                            small
                            text
                            absolute
                            right
                            class="form-comm-btn-send"
                          >
                            <v-icon class="comm-btn-send">mdi-send</v-icon>
                          </v-btn>
                        </v-form>
                      </v-col>
                    </div>
                  </v-footer>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSpamReport"
      fullscreen
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <v-card class="mx-auto">
                  <v-toolbar class="hd-gradient position-sticky" dark>
                    <v-toolbar-title style="flex-grow:1">
                      Пожаловаться на спам
                    </v-toolbar-title>

                    <v-btn @click="closeSpamReport" icon absolute right>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-footer absolute style="position:sticky;z-index:4">
                    <div
                      style="width:100%;display:flex;align-items:center;justify-content:center"
                    >
                      <v-col
                        cols="12"
                        sm="12"
                        md="10"
                        lg="10"
                        xl="10"
                        style="padding:12px 0 0"
                      >
                        <v-subheader style="justify-content:center">
                          Размещен нежелательный контент?
                        </v-subheader>
                        <v-form
                          ref="formSpam"
                          class="form-comm"
                          style="flex-direction:column"
                        >
                          <v-textarea
                            ref="commDesc"
                            v-model="spamReportMsg"
                            :counter="500"
                            :rules="spamRules"
                            outlined
                            name="input-7-4"
                            rows="1"
                            row-height="10"
                            auto-grow
                            label="Хотите оставить комментарий?"
                            class="form-comm-textarea"
                          ></v-textarea>
                          <div>
                            <v-btn
                              @click="validateSpamReport(idSpamComment)"
                              small
                              color="primary"
                              class="hd-gradient"
                              style="margin-bottom:25px"
                            >
                              Подтвердить
                            </v-btn>
                          </div>
                        </v-form>
                      </v-col>
                    </div>
                  </v-footer>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEditComment"
      fullscreen
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <v-card class="mx-auto">
                  <v-toolbar class="hd-gradient position-sticky" dark>
                    <v-toolbar-title class="action-modal-title">
                      Редактирование комментария
                    </v-toolbar-title>

                    <v-btn @click="closeEditComment" icon absolute right>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-footer absolute style="position:sticky;z-index:4">
                    <div
                      style="width:100%;display:flex;align-items:center;justify-content:center"
                    >
                      <v-col
                        cols="12"
                        sm="12"
                        md="10"
                        lg="10"
                        xl="10"
                        style="padding:12px 0 0"
                      >
                        <v-form
                          ref="formEditComment"
                          class="form-comm"
                          style="flex-direction:column"
                        >
                          <v-textarea
                            ref="commEditDesc"
                            v-model="editCommentDesc"
                            :counter="1000"
                            :rules="commEditRules"
                            outlined
                            name="input-7-4"
                            rows="1"
                            row-height="10"
                            auto-grow
                            label="Комментарий не может быть пустой!"
                            class="form-comm-textarea"
                          ></v-textarea>
                          <div>
                            <v-btn
                              @click="validateEditComment(idComment)"
                              small
                              color="primary"
                              class="hd-gradient"
                              style="margin-bottom:25px"
                            >
                              Изменить
                            </v-btn>
                          </div>
                        </v-form>
                      </v-col>
                    </div>
                  </v-footer>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import LikeCommButton from "@/components/community/posts/like-comm-button";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  components: {
    LikeCommButton
  },
  mixins: [commonInitAppMethods],
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ""
    },
    counterIncome: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      postNameComm: "",
      postIdComm: null,
      commentDesc: "",
      commentParentId: null,
      dialogComments: false,
      idSpamComment: null,
      dialogSpamReport: false,
      spamReportMsg: "",
      spamReportSent: false,
      idComment: null,
      editCommentDesc: "",
      dialogEditComment: false,
      scrollToFooterId: "",
      commBtnDisabled: false,
      counter: this.counterIncome,
      spamRules: [
        (v) =>
          v.length <= 500 || "Сообщение не должено быть более 500 символов."
      ],
      err: "",
      commRules: [
        (v) => !!v || "Введите текст.",
        (v) =>
          (v && v.length <= 1000) ||
          "Текст комментария не должен быть более 1000 символов."
      ],
      commEditRules: [
        (v) => !!v || "Введите текст.",
        (v) =>
          (v && v.length <= 1000) ||
          "Текст комментария не должен быть более 1000 символов."
      ]
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    comments() {
      return this.$store.getters["account/comments"];
    },
    isComments() {
      if (this.comments) {
        return true;
      } else {
        return false;
      }
    },
    canAddContentToSocialNetwork() {
      return this.$store.getters["account/can_add_content_to_social_network"];
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_comments_counter_plus":
          this.counter++;
          break;
        case "account/call_comments_counter_minus":
          this.counter--;
          break;
      }
    });
  },
  methods: {
    ...mapMutations({
      call_comments_counter_plus: "account/call_comments_counter_plus",
      call_comments_counter_minus: "account/call_comments_counter_minus",
      call_user_view_dialog: "account/call_user_view_dialog"
    }),
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      set_post_comments: "account/set_post_comments"
    }),
    validateProfileRules(id, title) {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        /* if (
          !this.User.community.my_profile.has_avatar ||
          !this.User.community.my_profile.has_bodygraph
        ) */
        if (!this.canAddContentToSocialNetwork) {
          this.$store.commit("account/call_social_profile_check_modal");
        } else {
          this.postComments(id, title);
        }
      } else {
        this.update_login_dialog(true);
      }
    },
    async postComments(id, title) {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        try {
          const result = await Api.getComments(id, this.HttpOptions);
          if (result.success) {
            this.set_post_comments(result.comments);
            this.postNameComm = title;
            this.postIdComm = id;
            this.dialogComments = true;
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      } else {
        this.update_login_dialog(true);
      }
    },
    formatCommDate(ts) {
      const locale = "ru-Ru";
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      };
      return new Intl.DateTimeFormat(locale, options).format(+ts * 1000);
    },
    validateComment(id, title) {
      if (this.$refs.formComm.validate()) {
        this.addComment(id, title);
      }
    },
    async addComment(id, title) {
      this.commBtnDisabled = true;
      try {
        const data = {
          post_id: id,
          description: this.commentDesc,
          comment_parent_id: this.commentParentId
        };
        const result = await Api.createComment(data, this.HttpOptions);
        if (result.success) {
          this.postComments(id, title);
          this.clearCommForm();
          this.scrollToFooter();
          this.commBtnDisabled = false;
          this.call_comments_counter_plus();
        }
      } catch (e) {
        if (e.message.includes("500")) {
          this.$store.commit("conf/update_service_modal", {
            message: this.getMessage("social_smile_warring")
          });
        }
        this.commBtnDisabled = false;
      }
    },
    editReport(id, description) {
      this.idComment = id;
      this.editCommentDesc = description;
      this.dialogEditComment = true;
    },
    closeEditComment() {
      this.idComment = null;
      this.editCommentDesc = "";
      this.dialogEditComment = false;
    },
    validateEditComment(idComment) {
      if (this.$refs.formEditComment.validate()) {
        this.editComment(idComment);
      }
    },
    async editComment(idComment) {
      this.dialogEditComment = false;
      try {
        const data = {
          comment_id: idComment,
          description: this.editCommentDesc
        };
        const result = await Api.updateComment(data, this.HttpOptions);
        if (result.success) {
          this.postComments(this.postIdComm, this.postNameComm);
        }
      } catch (e) {
        if (e.message.includes("500")) {
          this.$store.commit("conf/update_service_modal", {
            message: this.getMessage("social_smile_warring")
          });
        }
      }
    },
    commReplayTo(id, name) {
      this.commentParentId = id;
      this.commentDesc = "@" + name + ", ";
      this.$refs.commDesc.focus();
    },
    clearCommForm() {
      this.commentParentId = null;
      this.commentDesc = "";
      this.$refs.formComm.resetValidation();
    },
    closeComments() {
      this.clearCommForm();
      this.dialogComments = false;
    },
    async commBlock(id, status) {
      // console.log('Done: comment ', id, 'blocked')
      const data = { comment_id: id };
      const method = status === 2 ? Api.unblockComment : Api.blockComment;
      try {
        const result = await method(data, this.HttpOptions);
        if (result.success) {
          this.postComments(this.id, this.title);
          this.call_comments_counter_minus();
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    commentSpamReport(id) {
      this.idSpamComment = id;
      this.dialogSpamReport = true;
    },
    clearSpamReportForm() {
      this.idSpamComment = null;
      this.spamReportMsg = "";
      this.$refs.formSpam.resetValidation();
    },
    closeSpamReport() {
      this.clearSpamReportForm();
      this.dialogSpamReport = false;
      this.spamReportSent = true;
    },
    callDialogSpamReport(id) {
      this.idSpamComment = id;
      this.dialogSpamReport = true;
    },
    validateSpamReport(id) {
      if (this.$refs.formSpam.validate()) {
        this.spamReport(id);
      }
    },
    async spamReport(id) {
      try {
        const data = {
          comment_id: id,
          description: this.spamReportMsg
        };
        const result = await Api.complainAboutСomment(data, this.HttpOptions);
        if (result.success) {
          this.closeSpamReport();
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    getUserProfileDialog(id) {
      if (!this.User.isLogged) {
        this.update_login_dialog(true);
      } else {
        this.call_user_view_dialog(id);
      }
    },
    scrollToFooter() {
      clearTimeout(this.scrollToFooterId);
      this.scrollToFooterId = setTimeout(() => {
        document.getElementById("commentsfooter").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 2500);
    }
  }
};
</script>
<style scoped>
.user-link {
  color: #2196f3;
  text-decoration: none;
}
.user-link:hover {
  color: #4ca8f3;
}
.hidden-comment {
  display: none !important;
}
.hidden-comment-admin {
  opacity: 0.1;
}
.comment-datetime {
  font-size: 0.8rem;
  color: #00000099;
}
.theme--dark .comment-datetime {
  color: #ffffff99;
}
.theme--dark .comm-btn-send {
  color: #b288ff !important;
}
</style>
