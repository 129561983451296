import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

require("./assets/styles/main.css");
Vue.config.productionTip = false;

/* new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app"); */

import vueCustomElement from "vue-custom-element";
import device from "vue-device-detector";
Vue.use(vueCustomElement);
Vue.use(device);
App.store = store;
App.router = router;
App.vuetify = vuetify;
Vue.customElement("vue-widget", App);
