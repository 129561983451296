import Store from "@/store/index";

export const commonErrorsMethods = {
  methods: {
    // Error Handlers
    errorHandlerToDialog: (e) => {
      if (e.response) {
        if (e.response.data.code === 100) {
          Store.commit("conf/update_service_modal", {
            message: e.response.data.message,
            authDialog: true
          });
        }
        if (e.response.status === 400) {
          const keys = e.response.data.errors;
          const ERRORS = Object.values(keys)
            .map((arrayValue) => {
              return arrayValue.map((item) => item).join("<br />");
            })
            .join("<br />");
          Store.commit("conf/update_service_modal", {
            message: "<b>" + e.response.data.message + "</b><br />" + ERRORS
          });
        }
      }
      if (e.message.includes("500")) {
        Store.commit("conf/update_error_modal", {
          message: "Something went wrong...<br /> Contact to Support!<br />"
        });
      }
    }
  }
};
