<template>
  <!-- <div id="app"> -->
  <v-app id="app" class="vuetify-styles" dark>
    <v-main v-if="preloading" class="preload-main">
      <img
        src="https://static.human-design.space/widget/images/svg/hd_logo_rgb.svg"
        width="150"
      />
    </v-main>
    <v-main
      v-else
      :class="{
        'no-mobile-max-height-510':
          $route.path === '/' && !($device.ios || $device.android),
        'no-mobile-max-height-610':
          ($route.path === '/composite' && !Composite.chartBodygraphChart) ||
          ($route.path === '/returns' &&
            !Return.chartBodygraphChart &&
            !($device.ios || $device.android))
      }"
    >
      <v-container>
        <InstallMobileApp />
        <v-tabs
          v-model="activeTab"
          :class="{
            'v-tab-custom-margin-btm-13':
              $route.path === '/feed' ||
              $route.path === '/dating' ||
              $route.path === '/chats' ||
              $route.path === '/socialprofile'
          }"
          class="custom-user-tabs"
          centered
          background-color="transparent"
          color="basil"
          grow
          show-arrows
        >
          <v-tab
            v-for="(tab, idx) in tabs"
            :key="tab.id"
            :to="tab.route"
            :class="[!isReportId && idx === 1 ? 'hide' : '', tab.class]"
            exact
          >
            {{ tab.name }}
            <v-menu v-if="idx === 1" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :class="{
                    hide: !isReportId,
                    'v-menu-custom-active': isBodyGraph
                  }"
                  text
                  large
                  class="v-tab align-self-center v-menu-custom"
                >
                  <span>Карта</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-group :value="true" no-action sub-group>
                  <!-- <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Карта:</v-list-item-title>
                    </v-list-item-content>
                  </template> -->

                  <v-list-item
                    v-if="
                      $route.path !== '/bodygraph' ||
                        ($route.path === '/bodygraph' &&
                          $store.state.account.User.isHarmoniousGates) ||
                        ($route.path === '/bodygraph' &&
                          $store.state.account.User.isLoveGates) ||
                        ($route.path === '/bodygraph' &&
                          $store.state.account.User.isFearsGates) ||
                        ($route.path === '/bodygraph' &&
                          $store.state.account.User.isMelancholyGates)
                    "
                    @click="closeMenu('all', false)"
                  >
                    <v-list-item-title>
                      <v-icon>mdi-text-box-search-outline</v-icon>
                      Карта (Бодиграф)
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $route.path !== '/bodygraph' ||
                        ($route.path === '/bodygraph' &&
                          !$store.state.account.User.isHarmoniousGates)
                    "
                    @click="closeMenu('harmonious', true)"
                  >
                    <v-list-item-title>
                      <v-icon>mdi-account-switch-outline</v-icon>
                      Гармоничные ворота
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $route.path !== '/bodygraph' ||
                        ($route.path === '/bodygraph' &&
                          !$store.state.account.User.isLoveGates)
                    "
                    @click="closeMenu('love', true)"
                  >
                    <v-list-item-title>
                      <v-icon>mdi-head-heart-outline</v-icon>
                      Ворота любви
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $route.path !== '/bodygraph' ||
                        ($route.path === '/bodygraph' &&
                          !$store.state.account.User.isFearsGates)
                    "
                    @click="closeMenu('fears', true)"
                  >
                    <v-list-item-title>
                      <v-icon>mdi-alert-octagon-outline</v-icon>
                      Ворота страха
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $route.path !== '/bodygraph' ||
                        ($route.path === '/bodygraph' &&
                          !$store.state.account.User.isMelancholyGates)
                    "
                    @click="closeMenu('melancholy', true)"
                  >
                    <v-list-item-title>
                      <v-icon>mdi-emoticon-sad-outline</v-icon>
                      Меланхолия
                    </v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-menu>
          </v-tab>
        </v-tabs>
        <!-- <v-btn
          v-if="
            historyLength &&
              $route.path !== '/feed' &&
              $route.path !== '/chats' &&
              $route.path !== '/dating' &&
              $route.path !== '/socialprofile' &&
              $route.path !== '/post'
          "
          @click="historyBack"
          text
          fab
          style="position:absolute;z-index:100;top:0"
        >
          <v-icon
            style="background: linear-gradient(#0d6dd9, #5f36ad);background-clip:text;text-fill-color:transparent"
          >
            mdi-history
          </v-icon>
        </v-btn> -->
        <router-view />
      </v-container>
    </v-main>
    <!-- <v-footer :fixed="fixed" min-height="82" app> -->
    <v-footer v-if="!preloading" min-height="82">
      <MenuBottom />
    </v-footer>
    <ServiceDialog
      :showDialog.sync="showDialog"
      @close="closeDialog"
      @loginDialog="viewLoginDialog"
    />
    <ServiceCloseDialog
      :showDialog.sync="showServiceCloceDialog"
      @closeServiceCloseDialog="closeServiceCloseDialog"
      @loginDialog="viewLoginDialog"
    />
    <UniversalDialog
      :showDialog.sync="showUniversalDialog"
      @closeUniversalDialog="closeUniversalDialog"
    />
    <ErrorDialog
      :showDialog.sync="showErrorDialog"
      @closeErrorDialog="closeErrorDialog"
    />
    <LoginDialog :showDialog.sync="showLoginDialog" @close="viewLoginDialog" />
    <LogoutDialog
      :showDialog.sync="showLogoutDialog"
      @closeLogoutDialog="closeLogoutDialog"
    />
    <ShareReportDialog
      :showDialog.sync="showShareReportDialog"
      :shareLink.sync="shareLink"
      @closeShareReportDialog="closeShareReportDialog"
    />
    <SpamReportDialog
      :dialogSpamReport.sync="dialogSpamReport"
      @closeSpamReportDialog="closeSpamReportDialog"
    />
    <DelPostDialog
      :dialogPrompt.sync="dialogDelPost"
      @closeDelPostDialog="closeDelPostDialog"
    />
    <BlockPostDialog
      :dialogPrompt.sync="dialogPrompt"
      @closeBlockPostDialog="closeBlockPostDialog"
    />
    <SocialProfileCheckDialog
      :dialogSocialProfileCheck.sync="dialogSocialProfileCheck"
      @closeSocialProfileCheckDialog="closeSocialProfileCheckDialog"
    />
    <BeforePostingDialog
      :dialogBeforePosting.sync="dialogBeforePosting"
      @closeBeforePostingDialog="closeBeforePostingDialog"
    />
    <UserViewDialog
      :dialogUserView.sync="dialogUserView"
      :userId.sync="userId"
      @closeUserViewDialog="closeUserViewDialog"
    />
    <ConversationDialog
      :dialogConversation.sync="dialogConversation"
      :selectedUser="selectedUser"
      :isWriting="isWriting"
      @onMessage="onMessage"
      @onWriting="onWriting"
      @onMessageEdit="onMessageEdit"
      @onMessageDelete="onMessageDelete"
      @closeConversationDialog="closeConversationDialog"
    />
    <BeforeEditingUserImagesDialog
      :showBEUIDialog="showBEUIDialog"
      @closeBEUIDialog="closeBEUIDialog"
    />
    <BeforeEditingProfileDialog
      :showBEPDialog="showBEPDialog"
      @closeBEPDialog="closeBEPDialog"
    />
    <PartnerInfoDialog
      :showDialog.sync="showPartnerInfoDialog"
      @closePartnerInfoDialog="closePartnerInfoDialog"
    />
    <SocialSteppersDialog
      :showSocialSteppersDialog.sync="showSocialSteppersDialog"
      @closeSocialSteppersDialog="closeSocialSteppersDialog"
    />
    <SocialBeforeSelectorDialog
      :beforeSelectorDialog.sync="beforeSelectorDialog"
      @closeBeforeSelectorDialog="closeBeforeSelectorDialog"
    />
  </v-app>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import socket from "@/plugins/community/socket";
import MenuBottom from "@/components/layouts/MenuBottom.vue";
import ServiceDialog from "@/components/dialogs/ServiceDialog.vue";
import ServiceCloseDialog from "@/components/dialogs/ServiceCloseDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import LogoutDialog from "@/components/dialogs/LogoutDialog.vue";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import UniversalDialog from "@/components/dialogs/UniversalDialog.vue";
import ShareReportDialog from "@/components/dialogs/ShareReportDialog.vue";
import PartnerInfoDialog from "@/components/dialogs/PartnerInfoDialog";
import SpamReportDialog from "@/components/community/posts/spam-report-dialog";
import BlockPostDialog from "@/components/community/posts/block-post-dialog";
import DelPostDialog from "@/components/community/posts/del-post-dialog";
import SocialProfileCheckDialog from "@/components/community/posts/social-profile-check-dialog";
import SocialSteppersDialog from "@/components/community/posts/social-steppers-dialog";
import SocialBeforeSelectorDialog from "@/components/community/search/social-before-selector-dialog";
import BeforePostingDialog from "@/components/community/posts/before-posting-dialog";
import BeforeEditingUserImagesDialog from "@/components/community/profile/before-editing-user-images-dialog";
import BeforeEditingProfileDialog from "@/components/community/profile/before-editing-profile-dialog";
import UserViewDialog from "@/components/community/posts/user-view-dialog";
import ConversationDialog from "@/components/community/chat/conversation-dialog";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import InstallMobileApp from "@/components/layouts/InstallMobileApp";

export default {
  components: {
    MenuBottom,
    ServiceDialog,
    ServiceCloseDialog,
    LogoutDialog,
    LoginDialog,
    ErrorDialog,
    UniversalDialog,
    ShareReportDialog,
    PartnerInfoDialog,
    SpamReportDialog,
    BlockPostDialog,
    DelPostDialog,
    SocialProfileCheckDialog,
    SocialSteppersDialog,
    SocialBeforeSelectorDialog,
    BeforePostingDialog,
    BeforeEditingUserImagesDialog,
    BeforeEditingProfileDialog,
    UserViewDialog,
    ConversationDialog,
    InstallMobileApp
  },
  mixins: [commonInitAppMethods, commonReportingMethods, commonErrorsMethods],
  data() {
    return {
      fixed: false,
      activeTab: "/#/",
      tabs: [
        { id: 1, name: "Создать карту", route: `/` },
        { id: 2, name: "", route: `/bodygraph`, class: "no-padding-tab" },
        { id: 3, name: "Совместимость", route: `/composite` },
        { id: 4, name: "Транзит", route: `/transit` },
        { id: 5, name: "Возвраты", route: `/returns` },
        { id: 6, name: "Книги", route: `/books` },
        { id: 7, name: "Кабинет", route: `/profile`, class: "hide" },
        { id: 8, name: "Магазин", route: `/products`, class: "hide" },
        { id: 9, name: "Настройки", route: `/settings`, class: "hide" },
        { id: 11, name: "Поиск в соцсети", route: `/dating`, class: "hide" },
        { id: 12, name: "Посты", route: `/feed`, class: "hide" },
        { id: 13, name: "Сообщения", route: `/chats`, class: "hide" },
        { id: 14, name: "Соцпрофиль", route: `/socialprofile`, class: "hide" },
        { id: 15, name: "Сброс пароля", route: `/reset`, class: "hide" }
      ],
      showDialog: false,
      showLoginDialog: false,
      showLogoutDialog: false,
      showErrorDialog: false,
      showUniversalDialog: false,
      showShareReportDialog: false,
      showPartnerInfoDialog: false,
      showServiceCloceDialog: false,
      dialogSpamReport: false,
      dialogPrompt: false,
      dialogDelPost: false,
      dialogSocialProfileCheck: false,
      dialogBeforePosting: false,
      dialogUserView: false,
      userId: null,
      socketUsers: [],
      onlineUserIDs: [],
      selectedUser: null,
      isWriting: false,
      isSelectedUserClose: "",
      dialogConversation: false,
      totalNewMessagesCount: null,
      observer: null,
      preloading: true
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      Composite: (state) => state.account.Composite,
      Return: (state) => state.account.Return,
      HttpOptions: (state) => state.conf.http_options
    }),
    isReportId() {
      return this.$store.state.account.User.reportid;
    },
    isBodyGraph() {
      // console.log("this.$route: ", this.$route);
      return this.$route.path ? this.$route.path === "/bodygraph" : "";
    },
    historyLength() {
      return this.$store.state.account.User.pathHistory > 1;
    },
    shareLink() {
      return this.$store.getters["conf/get_share_link"];
    },
    isConversationCalledFromProfile: {
      get() {
        return this.User.community.isConversationCalledFromProfile;
      },
      set(v) {
        this.set_is_conversation_called_from_profile(v);
      }
    },
    isConversationCalledFromChats: {
      get() {
        return this.User.community.isConversationCalledFromChats;
      },
      set(v) {
        this.set_is_conversation_called_from_chats(v);
      }
    },
    ApiUsers: {
      get() {
        return this.$store.getters["account/users_from_api"];
      },
      set(val) {
        this.set_users_from_api(val);
      }
    },
    selectedUserMessages: {
      get() {
        return this.User.community.chats.selectedUserMessages;
      },
      set(arr) {
        this.set_selected_user_messages(arr);
      }
    },
    showBEUIDialog: {
      get() {
        return this.User.community.beforeEditingUserImagesDialog;
      },
      set(v) {
        this.update_before_editing_user_images_dialog(v);
      }
    },
    showBEPDialog: {
      get() {
        return this.User.community.beforeEditingProfileDialog;
      },
      set(v) {
        this.update_before_editing_profile_dialog(v);
      }
    },
    showSocialSteppersDialog: {
      get() {
        return (
          this.$route.path === "/feed" && this.User.community.socialSteppers
        );
      },
      set(v) {
        this.update_social_steppers(v);
      }
    },
    beforeSelectorDialog: {
      get() {
        return (
          this.$route.path === "/dating" &&
          this.User.community.beforeSelectorDialog
        );
      },
      set(v) {
        this.update_before_people_searching_dialog(v);
      }
    }
  },
  watch: {
    $route(/* val */) {
      // console.log("this $route is: ", val);
      this.scrollToTop();
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "conf/update_error_modal":
          this.showErrorDialog = true;
          break;
        case "conf/update_service_modal":
          this.showDialog = state.conf.serviceDialog.enable;
          this.scrollToTop();
          break;
        case "conf/update_service_close_modal":
          this.showServiceCloceDialog = state.conf.serviceDialog.enable;
          this.scrollToTop();
          break;
        case "conf/update_universal_modal":
          this.showUniversalDialog = state.conf.universalDialog.enable;
          break;
        case "conf/update_logout_dialog":
          this.getItemFromLocalStorage("messages", "conf/update_messages");
          this.showLogoutDialog = state.conf.logoutDialog;
          break;
        case "conf/update_login_dialog":
          this.showLoginDialog = state.conf.authDialog;
          break;
        case "conf/call_share_dialog":
          this.showShareReportDialog = state.conf.share_dialog;
          break;
        case "account/call_post_spam_dialog":
          this.dialogSpamReport = state.account.User.community.post_spam_dialog;
          break;
        case "account/call_block_post":
          this.dialogPrompt = true;
          break;
        case "account/call_delete_post":
          this.dialogDelPost = true;
          break;
        case "account/call_social_profile_check_modal":
          this.dialogSocialProfileCheck = true;
          break;
        case "account/update_before_posting_dialog":
          this.dialogBeforePosting =
            state.account.User.community.beforePostingDialog;
          break;
        case "account/call_user_view_dialog":
          this.userId = +state.account.User.community.viewId;
          this.dialogUserView = true;
          break;
        case "account/call_conversation":
          this.dialogUserView = false;
          this.dialogConversation = true;
          this.isSelectedUserClose = "n";
          break;
        case "account/call_conversation_outside":
          this.onSelectUser(this.User.community.selected_user);
          break;
        case "account/call_recount_total_new_messages_count":
          this.recountTotalNewMessagesCount();
          break;
        case "account/call_partner_dialog":
          this.showPartnerInfoDialog = true;
          break;
      }
    });
    this.verify();
    this.initConfApp();
    setTimeout(() => {
      this.getAndStoreUserQueries();
      this.getPartnerId();
      this.getRouterPath();
      this.getThemeColor();
      this.getSettings();
      this.scrollToTop();
    }, 100);
    setTimeout(() => {
      if (
        this.$route.path === "/" ||
        this.$route.path === "/feed" ||
        this.$route.path === "/chats" ||
        this.$route.path === "/dating" ||
        this.$route.path === "/socialprofile"
      )
        this.getChats();
    }, 2500);
    // Add Mutation Observer
    this.initObserver();
    // Код, который будет запущен только после
    // отрисовки всех представлений
    setTimeout(() => {
      this.$nextTick(function() {
        this.preloading = false;
      });
    }, 0);
  },
  beforeDestroy() {
    if (this.observer) this.observer.disconnect();
  },
  created() {
    if (this.$route.path !== "/feed") {
      // Else UserID incomm = Null
      const sessionID =
        localStorage.getItem("sessionID") !== null
          ? localStorage.getItem("sessionID")
          : "";
      /* console.log(
        "localStorage - sessionID: ",
        localStorage.getItem("sessionID")
      );
      console.log("sessionID: ", sessionID); */
      setTimeout(() => {
        if (this.User.isLogged) {
          socket.auth = { sessionID, userID: this.User.id };
          console.log("UserID: ", socket.auth, this.User.id);
          socket.connect();
          console.log("*** Socket connect ***", socket.connect());
        }
      }, 2500);
    }

    socket.on("server-to-user:session", ({ sessionID, userID }) => {
      // attach the session ID to the next reconnection attempts
      // console.log('SocketUserID: ', userID)
      socket.auth = { sessionID, userID: this.User.id };
      // store it in the localStorage
      localStorage.setItem("sessionID", sessionID);
      // save the ID of the user
      socket.userID = userID;
    });

    socket.on("connect_error", (err) => {
      if (err.message === "invalid username") {
        localStorage.removeItem("sessionID");
      }
      if (err.message === "server-to-user:invalid-user-id") {
        console.log(
          "%c server-to-user:invalid-user-id",
          "background: red; color: white"
        );
      }
    });

    socket.on("connect", () => {
      this.socketUsers.forEach((user) => {
        if (user.self) {
          user.connected = true;
        }
      });
    });

    socket.on("disconnect", () => {
      const id = this.id; // userId current user
      /* const index = this.onlineUserIDs.indexOf(id)
      if (index !== -1) {
        this.onlineUserIDs.splice(index, 1)
      } */
      this.delete_online_user_ids(id);
    });

    socket.on(
      "server-to-user:principal-user-state-sent",
      ({ onlineUserIDs, newMessagesCountPerUserMap }) => {
        this.onlineUserIDs = onlineUserIDs;
        this.set_online_user_ids(onlineUserIDs);
        // console.log('onlineUserIDs: ', onlineUserIDs)
        setTimeout(() => {
          this.totalNewMessagesCount = 0;
          for (let i = 0; i < this.ApiUsers.length; i++) {
            const existingUser = this.ApiUsers[i];
            if (
              newMessagesCountPerUserMap !== {} &&
              newMessagesCountPerUserMap[existingUser.id] !== undefined
            ) {
              existingUser.newMessagesCount =
                newMessagesCountPerUserMap[existingUser.id];
              this.totalNewMessagesCount += existingUser.newMessagesCount;
              continue;
            }
          }
          this.set_has_new_messages(this.totalNewMessagesCount);
        }, 2500);
      }
    );

    socket.on("server-to-all-users:new-user-connected", (user) => {
      this.add_online_user_ids(user.userID);
      // console.log('New user connected: ', user)
    });

    socket.on("server-to-all-users:user-disconnected", (id) => {
      this.delete_online_user_ids(id);
      // console.log(`User ${id} disconnected`)
    });

    socket.on("server-to-user:user-messages-sent", (user) => {
      user.messages.forEach((message) => {
        message.fromSelf = message.userSenderId === socket.userID;
      });
      // console.log("this.ApiUsers: ", this.ApiUsers);
      this.ApiUsers.forEach((existingUser) => {
        // console.log(existingUser.id);
        if (existingUser.id === user.userID) {
          existingUser.newMessagesCount = 0;
          existingUser.messages = user.messages;

          const messageIds = [];
          existingUser.messages.forEach((message) => {
            if (!message.fromSelf && message.status !== 4) {
              message.status = 4;
              messageIds.push(message.messageId);
            }
          });
          // console.log('messages to update status');
          // console.log(messageIds);

          if (messageIds.length > 0) {
            this.socketEmit("user-to-server:update-messages-status", {
              userSenderId: user.userID,
              userRecipientId: socket.userID,
              messageIds,
              messageStatus: 4
            });
          }
          user.self = user.userID === socket.userID;
        }
      });
      this.set_selected_user_messages(user.messages);
    });

    socket.on(
      "server-to-user:new-messages-count-sent",
      ({ userId, newMessagesCount }) => {
        this.ApiUsers.forEach((user) => {
          if (user.id === userId) {
            user.newMessagesCount = newMessagesCount;
          }
        });
      }
    );

    socket.on(
      "server-to-user:new-message-sent",
      ({
        messageId,
        description,
        userSenderId,
        userRecipientId,
        newMessagesCount,
        sentAt,
        status,
        isEdited
      }) => {
        const message = {
          messageId,
          description,
          userSenderId,
          userRecipientId,
          newMessagesCount,
          sentAt,
          status,
          isEdited
        };
        this.findUserFromApiUsers(message);
      }
    );

    socket.on("server-to-user:user-messages-updated", (messages) => {
      messages.forEach((serverMessage) => {
        if (this.selectedUserMessages) {
          this.selectedUserMessages.forEach((message) => {
            if (
              message.messageId === serverMessage.messageId ||
              message.messageHash === serverMessage.messageHash
            ) {
              if (!message.messageId) {
                message.messageId = serverMessage.messageId;
              }
              if (!message.messageHash) {
                message.messageHash = serverMessage.messageHash;
              }
              message.status = Math.max(message.status, serverMessage.status);
            }
          });
        }
      });
    });

    socket.on("server-to-all-users:writing-event-sent", ({ userSenderId }) => {
      console.log("selectedUser (writing-event-sent): ", this.selectedUser);
      if (this.selectedUser && this.selectedUser.id === userSenderId) {
        this.isWriting = true;
        setTimeout(() => (this.isWriting = false), 5000);
      }
      this.call_user_is_writing(userSenderId);
    });

    socket.on("server-to-user:validation-error", ({ event, error }) => {
      console.log(
        "%c server-to-user:validation-error",
        "background: red; color: white",
        event,
        error
      );
    });
  },
  destroyed() {
    socket.off("server-to-user:session");
    socket.off("connect_error");
    socket.off("connect");
    socket.off("disconnect");
    socket.off("server-to-user:principal-user-state-sent");
    socket.off("server-to-all-users:new-user-connected");
    // socket.off('user-to-server:get-user-messages')
    socket.off("server-to-all-users:user-disconnected");
    socket.off("server-to-user:user-messages-sent");
    socket.off("server-to-user:new-message-sent");
    socket.off("server-to-all-users:writing-event-sent");
    socket.off("server-to-user:new-messages-count-sent");
    socket.disconnect();
  },
  methods: {
    ...mapMutations({
      set_online_user_ids: "account/set_online_user_ids",
      set_is_conversation_called_from_profile:
        "account/set_is_conversation_called_from_profile",
      set_is_conversation_called_from_chats:
        "account/set_is_conversation_called_from_chats",
      call_user_view_dialog: "account/call_user_view_dialog",
      set_users_from_api: "account/set_users_from_api",
      set_selected_user_messages: "account/set_selected_user_messages",
      set_has_new_messages: "account/set_has_new_messages",
      add_online_user_ids: "account/add_online_user_ids",
      delete_online_user_ids: "account/delete_online_user_ids",
      resort_chat_users: "account/resort_chat_users",
      call_user_is_writing: "account/call_user_is_writing",
      new_list_user_unshift: "account/new_list_user_unshift",
      call_clear_chat_edit_message: "account/call_clear_chat_edit_message",
      update_before_editing_user_images_dialog:
        "account/update_before_editing_user_images_dialog",
      update_before_editing_profile_dialog:
        "account/update_before_editing_profile_dialog",
      update_social_steppers: "account/update_social_steppers",
      update_before_people_searching_dialog:
        "account/update_before_people_searching_dialog",
      call_dialog_add_post: "account/call_dialog_add_post"
    }),
    historyBack() {
      // console.log("History: ", history);
      history.back();
    },
    closeDialog() {
      this.showDialog = false;
    },
    closeServiceCloseDialog() {
      this.showServiceCloceDialog = false;
    },
    viewLoginDialog(v) {
      this.showDialog = false;
      this.showLoginDialog = v;
    },
    closeLogoutDialog() {
      this.showLogoutDialog = false;
      this.showLoginDialog = true;
    },
    closeErrorDialog() {
      this.showErrorDialog = false;
    },
    closeUniversalDialog() {
      this.showUniversalDialog = false;
    },
    getAndStoreUserQueries() {
      // Store external reference code
      if (this.$route.query.rave) {
        localStorage.setItem("ref", this.$route.query.rave);
        this.$store.commit(
          "account/update_user_ext_ref",
          this.$route.query.rave
        );
        this.$store.commit("conf/set_user_ext_ref", this.$route.query.rave);
      } else {
        const ref = localStorage.getItem("ref");
        this.$store.commit("account/update_user_ext_ref", ref);
        this.$store.commit("conf/set_user_ext_ref", ref);
      }
      // Store to state user's email
      if (
        localStorage.getItem("email") &&
        localStorage.getItem("email") !== undefined &&
        localStorage.getItem("email") !== "undefined"
      ) {
        const email = localStorage.getItem("email");
        this.$store.commit("account/update_email", email);
        this.$store.commit("conf/set_user_email", email);
      }
      // If was open add post
      if (localStorage.getItem("was_open_add_post")) {
        this.$store.commit("account/update_was_open_add_post", true);
      }
      // Store User Device Os
      // console.log("this.$device: ", this.$device);
      if (this.$device.ios)
        this.$store.commit("conf/set_user_device_os", "ios");
      if (this.$device.android)
        this.$store.commit("conf/set_user_device_os", "android");
      // Show post by ID
      if (this.$route.query.post_id) {
        localStorage.setItem("post_id", this.$route.query.post_id);
        this.$store.dispatch(
          "account/getSocialPost",
          this.$route.query.post_id
        );
        this.$store.dispatch("account/getSocialProfile");
        this.$router.push("/post");
      } else if (
        localStorage.getItem("post_id") &&
        this.$route.path === "/post"
      ) {
        this.$store.dispatch(
          "account/getSocialPost",
          localStorage.getItem("post_id")
        );
        this.$store.dispatch("account/getSocialProfile");
      }
      // Show Login/Reg form dialog
      setTimeout(() => {
        if (this.$route.query.show_login && !this.User.isLogged) {
          this.showLoginDialog = true;
          // в диалоге принудительно открываем вкладку вход
          this.$store.commit("conf/update_reg_tab", 1);
          // Пушим рауты в массив табов (this.tabs) если строка динамическая
          if (this.$route.query.show_login) {
            this.tabs.push({
              id: 16,
              name: "Кабинет псевдо с параметрами",
              route: `/profile?show_login=true`,
              class: "hide"
            });
          }
          if (this.$route.query.show_login && this.$route.query.email) {
            this.tabs.push({
              id: 17,
              name: "Кабинет псевдо с параметрами #2",
              route: `/profile?show_login=true&email=${encodeURIComponent(
                this.$route.query.email
              )}`,
              class: "hide"
            });
            this.$store.commit("account/update_email", this.$route.query.email);
          }
        }
      }, 1500);
    },
    getPartnerId() {
      // Store partner id from instance
      const instance = document.getElementById("widget");
      if (instance) {
        this.$store.commit(
          "conf/update_conf_app_partner_id",
          instance.dataset.partnerId
        );
        this.$store.commit(
          "conf/set_conf_app_partner_id",
          instance.dataset.partnerId
        );
        // console.log("instance is: ", instance.dataset.partnerId);
      }
    },
    getRouterPath() {
      // Router push url from instance
      const instance = document.getElementById("widget");
      if (instance && this.$route.path !== instance.dataset.routeUrl) {
        this.$router.push(instance.dataset.routeUrl);
        // console.log("instance is: ", instance.dataset.routeUrl);
      }
    },
    getThemeColor(mutation) {
      // Store outer color theme
      /* console.log(
        "*** Color theme is: ***",
        document.getElementsByTagName("html")[0].dataset.theme,
        mutation
      ); */
      if (mutation) {
        this.changeColorTheme(mutation);
      } else {
        this.changeColorTheme(
          document.getElementsByTagName("html")[0].dataset.theme
        );
      }
    },
    changeColorTheme(color) {
      if (color === "dark") {
        this.$vuetify.theme.dark = true;
        this.$store.commit("conf/update_dark_theme", true);
        this.$store.commit("conf/update_conf_app_theme", "dark");
      } else {
        this.$vuetify.theme.dark = false;
        this.$store.commit("conf/update_dark_theme", false);
        this.$store.commit("conf/update_conf_app_theme", "light");
      }
    },
    getSettings() {
      if (Object.keys(this.$store.state.conf.Messages).length === 0) {
        this.$store.dispatch("conf/getSettings");
      }
    },
    onSelectUser(user) {
      // console.log(user)
      this.selectedUser = user;
      this.socketEmit("user-to-server:get-user-messages", {
        userRecipientId: this.selectedUser.id
      });
      this.$store.commit("account/call_conversation");
      setTimeout(() => {
        this.$store.commit("account/call_scroll_chat_to_footer");
      }, 250);
    },
    getUserProfileDialog(id) {
      this.conversationDialog = false;
      this.isSelectedUserClose = "y";
      this.userViewDialog = true;
      this.id = id;
      this.call_user_view_dialog(id);
    },
    socketEmit(event, args) {
      console.log("--> " + event, args);
      socket.emit(event, args);
    },
    onMessage(message) {
      if (this.selectedUser) {
        // console.log(message, this.selectedUser.id)
        this.socketEmit("user-to-server:send-message", {
          messageHash: message.messageHash,
          description: message.description,
          userRecipientId: this.selectedUser.id
        });
        // this.getChats();
      }
    },
    onWriting() {
      if (this.selectedUser) {
        this.socketEmit("user-to-server:send-writing-event", {
          // userRecipientId: this.selectedUser.userID
          userRecipientId: this.selectedUser.id
        });
      }
    },
    onMessageEdit(messageId, description) {
      if (this.selectedUser) {
        this.socketEmit("user-to-server:edit-message", {
          messageId,
          description
        });
      }
    },
    onMessageDelete(messageId) {
      // console.log("message id to delete: ", messageId);
      if (this.selectedUser) {
        this.socketEmit("user-to-server:delete-message", {
          // userSenderId: socket.userID, // этот параметр и так есть
          // userRecipientId: this.selectedUser.userID, // этот параметр можно найти из БД
          messageId
        });
      }
    },
    async getChats(message) {
      if (this.User.isLogged) {
        try {
          const user_id = this.User.id;
          const result = await Api.getChatsUsers(user_id, this.HttpOptions);
          if (result.success) {
            // console.log("getChats: ", result);
            // для того чтобы не переприсваивать массив пользователей и не обнулять newMessagesCount у собеседников когда приходит новое сообщение
            if (!message) {
              this.ApiUsers = this.matchApiUsers(result.users);
            }
            this.ApiUsers.forEach((userHd) => {
              this.socketUsers.forEach((user) => {
                if (userHd.id === user.userID) {
                  userHd.connected = user.connected;
                  userHd.messages = user.messages;
                }
              });
              // console.log(userHd.id, this.$isUserConnected(userHd.id))
            });
            // console.log('ApiUsers: ', this.ApiUsers)
            // this.getBlocked()
            // this.getFavourites()
            // if (Object.keys(message).length) {
            if (message) {
              this.findUserAndPushNewMessage(message);
            }
            this.isLoading = false;
          }
        } catch (e) {
          // console.log(e)
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    findUserFromApiUsers(message) {
      const { userSenderId, newMessagesCount } = message;
      let wasFoundUser = 0;
      this.ApiUsers.forEach((user) => {
        if (user.id === userSenderId) {
          wasFoundUser = 1;
        }
      });
      // console.log("Пользователь: ", wasFoundUser);
      if (wasFoundUser) {
        // console.log("Пользователь найден: ", wasFoundUser);
        this.getChats(message);
      } else {
        // console.log("Пользователь не найден: ", wasFoundUser);
        this.new_list_user_unshift({ userSenderId, newMessagesCount });
      }
    },
    findUserAndPushNewMessage({
      messageId,
      description,
      userSenderId,
      userRecipientId,
      newMessagesCount,
      sentAt,
      status,
      isEdited
    }) {
      for (let i = 0; i < this.ApiUsers.length; i++) {
        const user = this.ApiUsers[i];
        const fromSelf = socket.userID === userSenderId;
        if (user.id === (fromSelf ? userRecipientId : userSenderId)) {
          const message = {
            messageId,
            description,
            fromSelf,
            sentAt,
            status,
            isEdited
          };
          // Если я пишу в другом браузере этому чуваку, то newMessagesCount не может поменяться
          if (userRecipientId !== user.id) {
            if (
              this.selectedUser === null ||
              user.id !== this.selectedUser.id ||
              this.isSelectedUserClose === "y"
            ) {
              user.newMessagesCount = newMessagesCount;
              message.status = Math.max(message.status, 3);
              setTimeout(() => {
                this.$store.commit("account/call_scroll_chat_to_footer");
              }, 250);
            } else if (this.isSelectedUserClose === "n") {
              message.status = 4;
              setTimeout(() => {
                this.$store.commit("account/call_scroll_chat_to_footer");
              }, 250);
            } else {
              message.status = 4;
              setTimeout(() => {
                this.$store.commit("account/call_scroll_chat_to_footer");
              }, 250);
            }
            // Если я пишу в другом браузере этому чуваку, то newMessagesCount не может поменяться
            this.socketEmit("user-to-server:update-messages-status", {
              userSenderId,
              userRecipientId,
              messageIds: [messageId],
              messageStatus: message.status
            });
            this.resort_chat_users();
          }
          // user.messages.push(message)
          if (isEdited) {
            user.messages.forEach((oldMessage) => {
              if (oldMessage.messageId === message.messageId) {
                oldMessage.messageId = message.messageId;
                oldMessage.description = message.description;
                oldMessage.fromSelf = message.fromSelf;
                oldMessage.sentAt = message.sentAt;
                oldMessage.status = message.status;
                oldMessage.isEdited = message.isEdited;
              }
            });
          } else {
            user.messages.push(message);
          }
          break;
        }
      }
      this.recountTotalNewMessagesCount();
    },
    recountTotalNewMessagesCount() {
      this.totalNewMessagesCount = 0;
      this.ApiUsers.forEach((user) => {
        this.totalNewMessagesCount += user.newMessagesCount;
      });
      this.set_has_new_messages(this.totalNewMessagesCount);
    },
    matchApiUsers(arr) {
      return arr.map((item) => {
        item.connected = false;
        item.newMessagesCount = 0;
        item.isWriting = false;
        item.is_favourite = false;
        item.is_blocked = false;
        item.messages = [];
        return item;
      });
    },
    closeShareReportDialog() {
      this.showShareReportDialog = false;
    },
    closeSpamReportDialog() {
      this.dialogSpamReport = false;
    },
    closeBlockPostDialog() {
      this.dialogPrompt = false;
    },
    closeDelPostDialog() {
      this.dialogDelPost = false;
    },
    closeSocialProfileCheckDialog() {
      this.dialogSocialProfileCheck = false;
    },
    closeBeforePostingDialog() {
      this.dialogBeforePosting = false;
      this.call_dialog_add_post();
    },
    closeUserViewDialog() {
      this.dialogUserView = false;
    },
    closeConversationDialog() {
      this.dialogConversation = false;
      this.isSelectedUserClose = "y";
      if (this.selectedUser.id && this.isConversationCalledFromProfile) {
        this.getUserProfileDialog(this.selectedUser.id);
      }
      this.call_clear_chat_edit_message();
    },
    closeBEUIDialog() {
      this.showBEUIDialog = false;
      localStorage.setItem("before_editing_user_images", JSON.stringify(false));
      this.$store.commit("account/update_before_editing_user_images", false);
      this.$store.commit(
        "account/update_before_editing_user_images_dialog",
        false
      );
      this.$store.commit("account/call_dialog_edit_user_images");
    },
    closeBEPDialog() {
      this.showBEPDialog = false;
      localStorage.setItem("before_editing_profile", JSON.stringify(false));
      this.$store.commit("account/update_before_editing_profile", false);
      this.$store.commit("account/update_before_editing_profile_dialog", false);
      this.$store.commit("account/call_dialog_edit_profile");
    },
    closePartnerInfoDialog() {
      this.showPartnerInfoDialog = false;
    },
    closeSocialSteppersDialog() {
      this.showSocialSteppersDialog = false;
      localStorage.setItem("social_steppers", JSON.stringify(false));
    },
    closeBeforeSelectorDialog() {
      this.beforeSelectorDialog = false;
      localStorage.setItem("before_people_searching", JSON.stringify(false));
      this.$store.commit("account/update_before_people_searching", false);
    },
    initObserver() {
      const config = {
        attributes: true
      };
      const vm = this;
      // create the observer
      const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          // check if the mutation is attributes and
          // console.log("*** theme is: ***", mutation.target.dataset.theme);
          vm.getThemeColor(mutation.target.dataset.theme);
        });
      });
      // observe element's specified mutations
      // const themeSwitcher = document.getElementById("themeSwitcher");
      const themeSwitcher = document.getElementsByTagName("html")[0];
      if (themeSwitcher) {
        observer.observe(themeSwitcher, config);
        // add the observer to data so we can disconnect it later
        this.observer = observer;
      }
    }
  }
};
</script>
<style scoped></style>
