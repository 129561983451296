<template>
  <v-card flat d-flex pa-2 class="card-md">
    <v-card-text>
      <v-row>
        <v-col
          v-for="(consultation, i) in consultations"
          :key="i"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <v-card
            class="mx-auto"
            max-width="950"
            style="padding: 15px !important"
          >
            <div class="d-flex flex-no-wrap books-flex justify-space-between">
              <div style="text-align:left">
                <p class="display-0-5 text--primary d-none d-sm-block">
                  {{ consultation.title }}
                </p>
                <v-card-subtitle
                  v-html="consultation.description"
                ></v-card-subtitle>
                <v-chip class="ma-2" color="cyan" label text-color="white">
                  <v-icon left>mdi-cash-100</v-icon>
                  {{ consultation.price }}
                </v-chip>
                <v-card-actions class="v-action-acc">
                  <v-btn
                    v-bind:href="consultation.external_url"
                    target="_blank"
                    color="primary"
                    small
                  >
                    {{ consultation.cta }}
                  </v-btn>
                </v-card-actions>
              </div>
              <div style="display:flex;flex-direction:column">
                <p class="display-0-5 text--primary d-flex d-sm-none">
                  {{ consultation.title }}
                </p>
                <v-avatar class="ma-3" size="250" tile>
                  <v-img :src="consultation.image" contain></v-img>
                </v-avatar>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { mapState } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  name: "Consultations",
  mixins: [commonInitAppMethods],
  data() {
    return {
      consultations: []
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    })
  },
  mounted() {
    this.verify();
    this.initConfApp();
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const result = await Api.getСonsultations(this.HttpOptions);
        if (result.success) {
          this.consultations = result.consultations;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    }
  }
};
</script>
<style scoped></style>
