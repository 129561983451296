<template>
  <v-dialog
    v-model="compositesHistoryDialogIncome"
    @click:outside="closeDialog"
    scrollable
    max-width="900px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-title>
        <span class="headline" style="font-size: 1.3rem !important"
          >&nbsp;</span
        >
        <v-btn @click="closeDialog" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="no-top-bottom-padding">
        <v-container class="no-top-bottom-padding">
          <v-row>
            <v-col
              cols="12"
              class="text-center no-top-bottom-padding display-0-24-no-line-h"
            >
              <CompositesHistory @closeCompositesDialog="closeDialog" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CompositesHistory from "@/components/layouts/CompositesHistory";

export default {
  name: "CompositesHistoryDialog",
  components: {
    CompositesHistory
  },
  props: {
    compositesHistoryDialogIncome: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // reportsHistory: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("closeCompositesDialog", true);
    }
  }
};
</script>
