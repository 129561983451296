<template>
  <div class="pre-posting">
    <v-img src="/img/social/social_05_1.png" class="stepper-image"></v-img>
    <div class="mb-6 padding-15 text-center">
      <h3 v-html="getMessage('social_title_pre_editing_images')"></h3>
      <span
        v-html="getMessage('social_text_pre_editing_images')"
        style="display:inline-block;margin-bottom:7px"
      ></span>
    </div>
    <div style="display:flex;justify-content:center">
      <v-btn
        @click="beforeEditingUserImages"
        color="primary"
        class="hd-gradient"
        style="margin-bottom:25px"
      >
        Понятно
      </v-btn>
    </div>
  </div>
</template>
<script>
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  mixins: [commonInitAppMethods],
  data() {
    return {
      step: 1
    };
  },
  methods: {
    beforeEditingUserImages() {
      localStorage.setItem("before_editing_user_images", JSON.stringify(false));
      this.$store.commit("account/update_before_editing_user_images", false);
      this.$store.commit(
        "account/update_before_editing_user_images_dialog",
        false
      );
      this.$store.commit("account/call_dialog_edit_user_images");
    }
  }
};
</script>
<style scoped>
.padding-15 {
  padding: 15px;
}
</style>
