<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="id === User.id"
          :class="{ disabled: id === User.id }"
          @click="userBan"
          color="grey lighten-1"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <span class="my-prof-controls-icon">
            <v-icon v-if="!isBlocked" center style="margin-right:10px">
              mdi-account-cancel-outline
            </v-icon>
            <v-icon v-else center style="margin-right:10px">
              mdi-account-arrow-left-outline
            </v-icon>
          </span>
          <span
            v-if="!isBlocked"
            class="d-flex"
            style="font-size:11px;margin-top:5px"
          >
            Заблокировать
          </span>
          <span v-else class="d-flex" style="font-size:11px;margin-top:5px">
            Разблокировать
          </span>
        </v-btn>
      </template>
      <span v-if="!isBlocked">Заблокировать</span>
      <span v-else>Разблокировать</span>
    </v-tooltip>
    <v-snackbar v-model="successBanUser" top color="success">
      <v-icon color="white">mdi-check-circle-outline</v-icon>
      &nbsp;Вы успешно {{ successBanUserAction }} пользователя.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successBanUser = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    isBlocked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      successBanUser: false,
      successBanUserAction: ""
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    })
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      call_update_blocked_users_chats: "account/call_update_blocked_users_chats"
    }),
    async userBan() {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        const data = { user_ids: [this.id] };
        const result = this.isBlocked
          ? await Api.deleteBlockedUsers(data, this.HttpOptions)
          : await Api.createBlockedUsers(data, this.HttpOptions);
        if (result.success) {
          // console.log(result)
          this.successBanUser = true;
          this.successBanUserAction = this.isBlocked
            ? "разблокировали"
            : "заблокировали";
          this.$emit("clicked", this.id);
          this.call_update_blocked_users_chats();
        }
      } else {
        this.update_login_dialog(true);
      }
    }
  }
};
</script>
