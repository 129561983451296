<template>
  <div style="position:absolute;right:85px;bottom:-12px">
    <v-btn @click="giveLike(id, isLiked)" text rounded color="purple accent-3">
      <v-icon v-if="!isLiked">
        mdi-heart-outline
      </v-icon>
      <v-icon v-else>
        mdi-heart
      </v-icon>
      {{ counter }}
    </v-btn>
    <v-snackbar v-model="likedStatus" top color="success">
      <v-icon color="white">mdi-check-circle-outline</v-icon>
      &nbsp;{{ likedText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="likedStatus = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    isLikedIncome: {
      type: Boolean,
      default: null
    },
    counterIncome: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      likedText: "",
      likedStatus: false,
      isLiked: this.isLikedIncome,
      counter: this.counterIncome
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    })
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      call_update_like: "account/call_update_like"
    }),
    async giveLike(id, isLiked) {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        const data = { comment_id: id };
        try {
          if (isLiked) {
            const result = await Api.unlikeСomment(data, this.HttpOptions);
            if (result.success) {
              this.isLiked = false;
              this.counter--;
              this.call_update_like();
              this.likedText = "Вам больше не нравится этот коммент.";
              this.likedStatus = true;
            }
          } else {
            const result = await Api.likeСomment(data, this.HttpOptions);
            if (result.success) {
              this.isLiked = true;
              this.counter++;
              this.call_update_like();
              this.likedText = "Автору будет приятно!";
              this.likedStatus = true;
            }
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      } else {
        this.update_login_dialog(true);
      }
    }
  }
};
</script>
