<template>
  <div class="pre-posting">
    <v-img
      src="https://static.human-design.space/widget/images/social/social_01_2.png"
      class="stepper-image"
    ></v-img>
    <div class="mb-6 padding-15 text-center">
      <h3 v-html="getMessage('social_title_pre_posting')"></h3>
      <span
        v-html="getMessage('social_text_pre_posting')"
        style="display:inline-block;margin-bottom:7px"
      ></span>
    </div>
    <div style="display:flex;justify-content:center">
      <v-btn
        @click="beforePosting"
        color="primary"
        class="hd-gradient"
        style="margin-bottom:25px"
      >
        Понятно
      </v-btn>
    </div>
  </div>
</template>
<script>
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  data() {
    return {
      step: 1
    };
  },
  mixins: [commonInitAppMethods],
  methods: {
    beforePosting() {
      localStorage.setItem("before_posting", JSON.stringify(false));
      this.$store.commit("account/update_before_posting", false);
      this.$store.commit("account/update_before_posting_dialog", false);
      this.$emit("closeDialog");
    }
  }
};
</script>
<style scoped>
.padding-15 {
  padding: 15px;
}
</style>
