<template>
  <div>
    <div
      :class="{
        visible_flex:
          $device.android &&
          User.no_interesting_in_social_profile &&
          User.raves_count > 3
      }"
      class="install-app-social install-app-android novisible"
    >
      <div class="install-app-android-wrapper">
        <a :href="gpUrl" target="_blank">
          <img
            alt="Доступно в Google Play"
            src="https://static.human-design.space/widget/images/mobile-banners/ru_badge_generic.png"
            width="90%"
          />
        </a>
        <v-btn @click="noInteresting" class="close-icon" icon absolute right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <div
      :class="{
        visible_flex:
          $device.ios &&
          User.no_interesting_in_social_profile &&
          User.raves_count > 3
      }"
      class="install-app-social novisible"
    >
      <a :href="pwaUrl" target="_blank">
        <img
          alt="Приложение для iOS"
          src="https://static.human-design.space/widget/images/mobile-banners/ru_badge_generic.png"
          height="90"
        />
      </a>
      <v-btn @click="noInteresting" class="close-icon" icon absolute right>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      gpUrl:
        "https://play.google.com/store/apps/details?id=space.humandesign.m",
      pwaUrl: "https://m.human-design.space",
      nointeresting: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User
    })
  },
  methods: {
    noInteresting() {
      localStorage.setItem(
        "no_interesting_in_social_profile",
        JSON.stringify(true)
      );
      this.$store.commit(
        "account/update_user_no_interest_in_soc_profile",
        false
      );
    }
  }
};
</script>
<style scoped>
.close-icon {
  z-index: 999;
  background-color: rgba(204, 204, 204, 0.5);
  right: 3px;
}
.install-app-social img {
  width: 85% !important;
  height: auto !important;
}
.install-app-android img {
  width: 80% !important;
}
@media screen and (min-width: 320px) and (max-width: 325px) {
  .install-app-social {
    min-width: 320px;
  }
  .close-icon {
    right: -4px;
  }
}
@media screen and (min-width: 768px) {
  .close-icon {
    right: 25px;
  }
}
</style>
