<template>
  <div>
    <v-list-item
      v-if="User.isLogged && +User.id === +userId"
      @click="postDialogDelete(id)"
    >
      <v-list-item-title>
        <v-icon>mdi-delete-outline</v-icon>
        Удалить
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    userId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp
    })
  },
  methods: {
    ...mapMutations({ call_delete_post: "account/call_delete_post" }),
    postDialogDelete(id) {
      this.call_delete_post(id);
    }
  }
};
</script>
