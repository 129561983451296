<template>
  <div>
    <div class="flex-center" style="margin-top:15px">
      <v-chip-group v-model="filterTag" mandatory active-class="primary--text">
        <v-chip
          v-for="tag in tags"
          :key="tag.title"
          @click="filtering(tag.value)"
          filter
          outlined
        >
          <v-icon v-if="tag.icon">
            {{ tag.icon }}
          </v-icon>
          {{ tag.title }}
        </v-chip>
      </v-chip-group>
    </div>

    <v-divider></v-divider>
    <div v-if="isLoading" class="flex-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div
      v-else-if="!ApiUsers.length"
      class="flex-center"
      style="flex-direction:column"
    >
      Список пуст.
      <div
        v-html="getMessage('social_text_4_messages_section')"
        class="text-4-relyative-like-block"
      ></div>
    </div>
    <div v-else v-for="(user, index) in ApiUsers" :key="user.id">
      <v-list-item
        @click.prevent="onSelectUser(user)"
        class="user-chat-list-item"
      >
        <v-badge
          v-if="chatUsersApi.length"
          v-show="chatUsersApi[index].newMessagesCount"
          right
          color="red"
          class="custom-list-bage"
        >
          <span slot="badge">
            {{ chatUsersApi[index].newMessagesCount }}
          </span>
        </v-badge>
        <a
          @click.stop="getUserProfileDialog(user)"
          :class="{ 'premium-avatar2': user.has_products }"
        >
          <v-list-item-avatar style="cursor:pointer;margin-left:0" size="56px">
            <v-img
              v-if="user.avatar"
              :alt="user.first_name"
              :src="user.avatar"
            ></v-img>
            <v-img v-else :alt="user.username" :src="defaultAva"></v-img>
          </v-list-item-avatar>
        </a>

        <v-list-item-content style="align-self:center">
          <v-list-item-title>
            <!-- <a :href="`/user/?id=${user.id}`" @click.stop class="user-link"> -->
            <a class="user-link">
              {{ user.first_name }}
            </a>
            <status-icon :connected="isUserConnected(user.id)" />
            <span v-if="user.isWriting" class="is-writing">
              пишет
              <span class="circle"></span>
              <span class="circle2"></span>
              <span class="circle3"></span>
            </span>
          </v-list-item-title>
          <v-list-item-subtitle v-if="user.chart_type">
            {{ user.chart_type.title }}
            {{ user.chart_profile.id }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon
          v-if="user.is_blocked"
          @click.stop
          @click="removeBlockedUser(user.id)"
        >
          <v-icon color="pink">
            mdi-account-cancel-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-icon
          @click.stop
          @click="addDelFavourite(user.is_favourite, user.id, user.first_name)"
        >
          <v-icon :color="user.is_favourite ? 'amber lighten-2' : 'grey'">
            mdi-star
          </v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider v-if="index !== ApiUsers.length - 1" :key="index"></v-divider>
    </div>
    <v-snackbar v-model="addUserToFav" top color="success">
      {{ addUserToFavText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="addUserToFav = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import StatusIcon from "./status-icon";

export default {
  components: {
    StatusIcon
  },
  mixins: [commonInitAppMethods],
  data() {
    return {
      defaultAva:
        "https://static.human-design.space/widget/images/social/socialprofile-preview.png",
      isLoading: true,
      selectedUser: null,
      users: [],
      ApiUsers: [],
      filterTag: null,
      tags: [
        { title: "Все", value: "all" },
        { icon: "mdi-broadcast", value: "online" },
        { title: "Избранное", value: "isfavourite" },
        { title: "Заблокированные", value: "blocked" }
      ],
      addUserToFav: false,
      addUserToFavText: ""
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    newMessagesCount() {
      return this.User.community.newMessagesCount;
    },
    chatUsersApi() {
      return this.$store.getters["account/users_from_api"];
    },
    newListUser() {
      return this.$store.getters["account/new_list_user"];
    },
    id() {
      return this.User.id;
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_update_blocked_users_chats":
          this.getChats();
          break;
        case "account/resort_chat_users":
          this.resortChats();
          break;
        case "account/new_list_user_unshift":
          this.newListUserUnshift();
          break;
        case "account/call_user_is_writing":
          this.matchUserIsWriting(
            this.$store.state.account.User.community.chats.isWritingUserId
          );
          break;
      }
    });
    setTimeout(() => {
      this.getChats();
      this.getProfile();
    }, 0);
  },
  methods: {
    ...mapMutations({
      set_chat_users: "account/set_chat_users",
      set_user_selected: "account/set_user_selected",
      set_is_conversation_called_from_chats:
        "account/set_is_conversation_called_from_chats",
      set_is_conversation_called_from_profile:
        "account/set_is_conversation_called_from_profile",
      call_user_view_dialog: "account/call_user_view_dialog",
      set_has_new_messages: "account/set_has_new_messages",
      unshift_new_user_to_users_from_api:
        "account/unshift_new_user_to_users_from_api"
    }),
    async getChats() {
      if (this.User.isLogged) {
        try {
          const user_id = this.User.id;
          const result = await Api.getChatsUsers(user_id, this.HttpOptions);
          if (result.success) {
            // console.log(result)
            this.ApiUsers = this.matchApiUsers(result.users);
            this.ApiUsers.forEach((userHd) => {
              this.users.forEach((user) => {
                if (userHd.id === user.userID) {
                  userHd.connected = user.connected;
                  userHd.messages = user.messages;
                }
              });
            });
            // console.log("Юзера из API:", this.ApiUsers);
            this.getBlocked();
            this.getFavourites();
            this.isLoading = false;
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    async newListUserUnshift() {
      // console.log('*** новый пользователь в вашем чате: ***', this.newListUser)
      if (this.User.isLogged) {
        try {
          const user_id = this.User.id;
          const result = await Api.getChatsUsers(user_id, this.HttpOptions);
          if (result.success) {
            result.users.forEach((user) => {
              if (user.id === this.newListUser.userSenderId) {
                const mapUser = this.matchNewApiUser(user);
                // добавляем нового пользователя в начало массива ApiUsers в стор
                this.unshift_new_user_to_users_from_api(mapUser);
                // добавляем нового пользователя в начало локального массива компонента ApiUsers
                //this.ApiUsers.unshift(mapUser);
                // Пересчитываем общее количество сообщений
                /* this.set_has_new_messages(
                  this.User.community.newMessagesCount + 1
                ); */
              }
            });
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    async getProfile() {
      if (this.User.isLogged) {
        this.$store.dispatch("account/getSocialProfile");
      }
    },
    async getFavourites() {
      try {
        const result = await Api.getFavouriteUsers(this.HttpOptions);
        if (result.success && this.ApiUsers.length) {
          this.ApiUsers.forEach((userHd) => {
            result.users.forEach((user) => {
              if (userHd.id === user.id) {
                userHd.is_favourite = true;
              }
            });
          });
          this.set_chat_users(this.ApiUsers);
          this.resortChats();
        }
        // console.log(this.ApiUsers)
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async getBlocked() {
      try {
        const result = await Api.getBlockedUsers(this.HttpOptions);
        if (result.success && this.ApiUsers.length) {
          this.ApiUsers.forEach((userHd) => {
            result.users.forEach((user) => {
              if (userHd.id === user.id) {
                userHd.is_blocked = true;
              }
            });
          });
          this.set_chat_users(this.ApiUsers);
          this.isNotBlockedUser();
        }
        // console.log(this.ApiUsers)
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    matchApiUsers(arr) {
      return arr.map((item) => {
        item.connected = false;
        item.newMessagesCount = 0;
        item.isWriting = false;
        item.is_favourite = false;
        item.is_blocked = false;
        item.messages = [];
        return item;
      });
    },
    matchNewApiUser(obj) {
      obj.connected = false;
      obj.newMessagesCount = 1;
      obj.isWriting = false;
      obj.is_favourite = false;
      obj.is_blocked = false;
      obj.messages = [];
      return obj;
    },
    onSelectUser(user) {
      this.set_is_conversation_called_from_chats(true);
      this.set_is_conversation_called_from_profile(false);
      this.set_user_selected(user);
      this.$emit("select", user);
    },
    async addDelFavourite(isFav, id, userName, isBlocked) {
      if (isBlocked) return;
      const arrIds = [id];
      if (isFav) {
        try {
          const data = { user_ids: arrIds };
          const result = await Api.deleteFavouriteUsers(data, this.HttpOptions);
          if (result.success) {
            this.addUserToFavText = `${userName} удален из избранного`;
            this.addUserToFav = true;
            this.ApiUsers.forEach((userHd) => {
              if (userHd.id === id) {
                userHd.is_favourite = false;
              }
            });
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      } else {
        try {
          const data = { user_ids: arrIds };
          const result = await Api.createFavouriteUsers(data, this.HttpOptions);
          if (result.success) {
            this.addUserToFavText = `${userName} добавлен в избранное`;
            this.addUserToFav = true;
            this.getFavourites();
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    resortChats() {
      const chatUsersApi = this.$store.getters["account/users_from_api"];
      const chatUsers = this.$store.getters["account/get_chat_users"];
      chatUsersApi.forEach((userHd) => {
        chatUsers.forEach((user) => {
          if (userHd.id === user.id) {
            user.newMessagesCount = userHd.newMessagesCount;
          }
        });
      });
      this.ApiUsers = chatUsers.sort((a, b) => {
        return b.newMessagesCount - a.newMessagesCount;
      });
      chatUsersApi.sort((a, b) => {
        return b.newMessagesCount - a.newMessagesCount;
      });
    },
    filtering(category) {
      const chatUsers = this.$store.getters["account/get_chat_users"];
      switch (category) {
        case "all":
          this.isNotBlockedUser("rt");
          return this.ApiUsers;
        case "isfavourite":
          this.ApiUsers = chatUsers.filter((userHd) => {
            return userHd.is_favourite === true;
          });
          return this.ApiUsers;
        case "blocked":
          this.ApiUsers = chatUsers.filter((userHd) => {
            return userHd.is_blocked === true;
          });
          return this.ApiUsers;
        case "online":
          this.ApiUsers = chatUsers.filter((userHd) => {
            return this.isUserConnected(userHd.id) === true;
          });
          return this.ApiUsers;
      }
    },
    isNotBlockedUser(rt) {
      if (rt) {
        const arr = this.$store.getters["account/get_chat_users"];
        this.ApiUsers = arr.filter((userHd) => {
          return userHd.is_blocked === false;
        });
        this.isLoading = false;
      } else {
        setTimeout(() => {
          const arr = this.$store.getters["account/get_chat_users"];
          this.ApiUsers = arr.filter((userHd) => {
            return userHd.is_blocked === false;
          });
          this.isLoading = false;
        }, 1500);
      }
    },
    async removeBlockedUser(id) {
      const data = { user_ids: [id] };
      const result = await Api.deleteBlockedUsers(data, this.HttpOptions);
      if (result.success) {
        const chatUsers = this.$store.getters["account/get_chat_users"];
        chatUsers.forEach((userHd) => {
          if (userHd.id === id) {
            userHd.is_blocked = false;
          }
        });
        this.ApiUsers = chatUsers;
        this.set_chat_users(this.ApiUsers);
        this.filtering("blocked");
      }
    },
    getUserProfileDialog(user) {
      this.set_user_selected(user);
      this.call_user_view_dialog(user.id);
    },
    matchUserIsWriting(userSenderId) {
      this.ApiUsers.forEach((user) => {
        if (user.id === userSenderId) {
          user.isWriting = true;
          if (user.writingTimeout) {
            clearTimeout(user.writingTimeout);
          }
          user.writingTimeout = setTimeout(
            () => (user.isWriting = false),
            5000
          );
        }
      });
    }
  }
};
</script>
