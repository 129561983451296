<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          v-show="guestsCount > 0"
          right
          color="teal accent-4"
          class="custom-bage"
        >
          <span slot="badge">{{ guestsCount }}</span>
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="callMyGuests"
            color="primary"
            class="my-prof-controls-flex"
            fab
            x-large
            icon
            dark
          >
            <v-icon center>mdi-account-switch</v-icon>
            <span class="d-flex" style="font-size:11px;margin-top:5px">
              Мои гости
            </span>
          </v-btn>
        </v-badge>
        <v-btn
          v-show="guestsCount === 0"
          v-bind="attrs"
          v-on="on"
          @click="callMyGuests"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon center>mdi-account-switch</v-icon>
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Мои гости
          </span>
        </v-btn>
      </template>
      <span>Мои гости</span>
    </v-tooltip>
    <v-dialog
      v-model="MyGuestsDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding" style="height:100%">
            <v-row style="height:100%">
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto;height:100%"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Мои гости
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container class="mb-6">
                  <v-row v-if="users.length" justify="space-around">
                    <v-col
                      v-for="(user, i) in users"
                      :key="i"
                      cols="6"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div>
                        <a
                          @click.stop="getUserProfileDialog(user.id)"
                          :class="{ 'premium-avatar4': user.has_products }"
                        >
                          <v-avatar size="96px">
                            <v-img
                              :src="user.avatar"
                              :alt="user.first_name"
                            ></v-img>
                          </v-avatar>
                        </a>
                      </div>
                      <a
                        @click.stop="getUserProfileDialog(user.id)"
                        class="user-link"
                      >
                        {{ user.first_name }}
                      </a>
                      <div v-if="user.chart_type.title" class="user-type">
                        {{ user.chart_type.title }}, {{ user.chart_profile.id }}
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      MyGuestsDialog: false,
      users: []
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options,
      ConfApp: (state) => state.conf.ConfApp
    }),
    guestsCount: {
      get() {
        return this.User.community.my_profile.guests_count;
      },
      set(value) {
        this.$store.commit("account/set_guests_count", value);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.getMyGuests();
    }, 0);
  },
  methods: {
    ...mapMutations({
      call_user_view_dialog: "account/call_user_view_dialog",
      update_login_dialog: "conf/update_login_dialog"
    }),
    async getMyGuests() {
      if (this.User.id) {
        try {
          const result = await Api.getGuests(this.User.id, this.HttpOptions);
          if (result.success) {
            this.users = result.users;
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      } else {
        this.getIdFromSocialProfile();
      }
    },
    async getIdFromSocialProfile() {
      if (this.User.isLogged) {
        const successResult = await this.$store.dispatch(
          "account/getSocialProfile"
        );
        if (successResult) {
          this.getMyGuests();
        }
      }
    },
    callMyGuests() {
      this.MyGuestsDialog = true;
      this.resetMyGuests();
    },
    getUserProfileDialog(id) {
      if (!this.User.isLogged) {
        this.update_login_dialog(true);
      } else {
        this.call_user_view_dialog(id);
      }
    },
    async resetMyGuests() {
      try {
        await Api.resetGuests(this.HttpOptions);
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    closeDialog() {
      this.MyGuestsDialog = false;
      this.guestsCount = 0;
      // this.users = []
    }
  }
};
</script>
