<template>
  <div>
    <div
      v-show="
        $device.isAndroid && !$store.state.User.no_interesting_in_social_profile
      "
      class="install-app-social install-app-android"
    >
      <div class="install-app-android-wrapper">
        <a :href="gpUrl" target="_blank">
          <img
            alt="Доступно в Google Play"
            src="https://static.human-design.space/widget/images/mobile-banners/ru_badge_web_generic.png"
            width="90%"
          />
        </a>
        <v-btn @click="noInteresting" icon right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <div
      v-show="
        $device.isIos && !$store.state.User.no_interesting_in_social_profile
      "
      class="install-app-social"
    >
      <a :href="pwaUrl" class="install-app-a" target="_blank">
        <img
          src="https://static.human-design.space/widget/images/svg/hd_logo_rgb.svg"
          height="50"
        />
        <span
          v-html="this.getMessage('banner_install_app')"
          style="padding:0 7px"
        ></span>
      </a>
      <v-btn @click="noInteresting" icon absolute right>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  mixins: [commonInitAppMethods],
  data() {
    return {
      gpUrl: process.env.GP_URL,
      pwaUrl: process.env.PWA_URL,
      nointeresting: false
    };
  },
  methods: {
    noInteresting() {
      localStorage.setItem(
        "no_interesting_in_social_profile",
        JSON.stringify(true)
      );
      this.$store.commit("UPDATE_USER_NO_INTERST_IN_SOC_POFILE", true);
    }
  }
};
</script>
