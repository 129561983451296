import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account";
import forms from "./modules/forms";
import conf from "./modules/conf";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Transit: {
      day: { day: "15" },
      month: { month: "Июнь (6)", id: "06" },
      year: { year: "1990" },
      hour: { hour: "12" },
      minute: { minute: "30" },
      cityid: 18009,
      lastCities: [
        {
          id: 18009,
          title: "Москва",
          country: { id: 192, iso: "RU", title: "Россия" }
        }
      ],
      utc: "+3",
      chartBodygraphTransit: null,
      chartBodygraphChart: null,
      report_id: "",
      transit_hash: "",
      infliction_hash: "",
      toolTips: {}
    },
    Composite: {
      chartBodygraphChart: null,
      chartBodygraphChart2: null,
      chartBodygraphConnection: null,
      is_stored: 0,
      method: "",
      composite_hash: "",
      report_id: ""
    },
    Return: {
      chartBodygraphChart: null,
      chartBodygraphChart2: null,
      chartBodygraphConnection: null,
      report_id: "",
      return_hash: ""
    },
    Purchases: {
      id: null
    },
    dateTime: {
      days: [
        { day: "01" },
        { day: "02" },
        { day: "03" },
        { day: "04" },
        { day: "05" },
        { day: "06" },
        { day: "07" },
        { day: "08" },
        { day: "09" },
        { day: "10" },
        { day: "11" },
        { day: "12" },
        { day: "13" },
        { day: "14" },
        { day: "15" },
        { day: "16" },
        { day: "17" },
        { day: "18" },
        { day: "19" },
        { day: "20" },
        { day: "21" },
        { day: "22" },
        { day: "23" },
        { day: "24" },
        { day: "25" },
        { day: "26" },
        { day: "27" },
        { day: "28" },
        { day: "29" },
        { day: "30" },
        { day: "31" }
      ],
      months: [
        { month: "Январь (1)", id: "01" },
        { month: "Февраль (2)", id: "02" },
        { month: "Март (3)", id: "03" },
        { month: "Апрель (4)", id: "04" },
        { month: "Май (5)", id: "05" },
        { month: "Июнь (6)", id: "06" },
        { month: "Июль (7)", id: "07" },
        { month: "Август (8)", id: "08" },
        { month: "Сентябрь (9)", id: "09" },
        { month: "Октябрь (10)", id: "10" },
        { month: "Ноябрь (11)", id: "11" },
        { month: "Декабрь (12)", id: "12" }
      ],
      years: [
        { year: "2035" },
        { year: "2034" },
        { year: "2033" },
        { year: "2032" },
        { year: "2031" },
        { year: "2030" },
        { year: "2029" },
        { year: "2028" },
        { year: "2027" },
        { year: "2026" },
        { year: "2025" },
        { year: "2024" },
        { year: "2023" },
        { year: "2022" },
        { year: "2021" },
        { year: "2020" },
        { year: "2019" },
        { year: "2018" },
        { year: "2017" },
        { year: "2016" },
        { year: "2015" },
        { year: "2014" },
        { year: "2013" },
        { year: "2012" },
        { year: "2011" },
        { year: "2010" },
        { year: "2009" },
        { year: "2008" },
        { year: "2007" },
        { year: "2006" },
        { year: "2005" },
        { year: "2004" },
        { year: "2003" },
        { year: "2002" },
        { year: "2001" },
        { year: "2000" },
        { year: "1999" },
        { year: "1998" },
        { year: "1997" },
        { year: "1996" },
        { year: "1995" },
        { year: "1994" },
        { year: "1993" },
        { year: "1992" },
        { year: "1991" },
        { year: "1990" },
        { year: "1989" },
        { year: "1988" },
        { year: "1987" },
        { year: "1986" },
        { year: "1985" },
        { year: "1984" },
        { year: "1983" },
        { year: "1982" },
        { year: "1981" },
        { year: "1980" },
        { year: "1979" },
        { year: "1978" },
        { year: "1977" },
        { year: "1976" },
        { year: "1975" },
        { year: "1974" },
        { year: "1973" },
        { year: "1972" },
        { year: "1971" },
        { year: "1970" },
        { year: "1969" },
        { year: "1968" },
        { year: "1967" },
        { year: "1966" },
        { year: "1965" },
        { year: "1964" },
        { year: "1963" },
        { year: "1962" },
        { year: "1961" },
        { year: "1960" },
        { year: "1959" },
        { year: "1958" },
        { year: "1957" },
        { year: "1956" },
        { year: "1955" },
        { year: "1954" },
        { year: "1953" },
        { year: "1952" },
        { year: "1951" },
        { year: "1950" },
        { year: "1949" },
        { year: "1948" },
        { year: "1947" },
        { year: "1946" },
        { year: "1945" },
        { year: "1944" },
        { year: "1943" },
        { year: "1942" },
        { year: "1941" },
        { year: "1940" },
        { year: "1939" },
        { year: "1938" },
        { year: "1937" },
        { year: "1936" },
        { year: "1935" },
        { year: "1934" },
        { year: "1933" },
        { year: "1932" },
        { year: "1931" },
        { year: "1930" },
        { year: "1929" },
        { year: "1928" },
        { year: "1927" },
        { year: "1926" },
        { year: "1925" },
        { year: "1924" },
        { year: "1923" },
        { year: "1922" },
        { year: "1921" },
        { year: "1920" },
        { year: "1929" },
        { year: "1928" },
        { year: "1927" },
        { year: "1926" },
        { year: "1925" },
        { year: "1924" },
        { year: "1923" },
        { year: "1922" },
        { year: "1921" },
        { year: "1920" }
      ],
      hours: [
        { hour: "00" },
        { hour: "01" },
        { hour: "02" },
        { hour: "03" },
        { hour: "04" },
        { hour: "05" },
        { hour: "06" },
        { hour: "07" },
        { hour: "08" },
        { hour: "09" },
        { hour: "10" },
        { hour: "11" },
        { hour: "12" },
        { hour: "13" },
        { hour: "14" },
        { hour: "15" },
        { hour: "16" },
        { hour: "17" },
        { hour: "18" },
        { hour: "19" },
        { hour: "20" },
        { hour: "21" },
        { hour: "22" },
        { hour: "23" }
      ],
      minutes: [
        { minute: "00" },
        { minute: "01" },
        { minute: "02" },
        { minute: "03" },
        { minute: "04" },
        { minute: "05" },
        { minute: "06" },
        { minute: "07" },
        { minute: "08" },
        { minute: "09" },
        { minute: "10" },
        { minute: "11" },
        { minute: "12" },
        { minute: "13" },
        { minute: "14" },
        { minute: "15" },
        { minute: "16" },
        { minute: "17" },
        { minute: "18" },
        { minute: "19" },
        { minute: "20" },
        { minute: "21" },
        { minute: "22" },
        { minute: "23" },
        { minute: "24" },
        { minute: "25" },
        { minute: "26" },
        { minute: "27" },
        { minute: "28" },
        { minute: "29" },
        { minute: "30" },
        { minute: "31" },
        { minute: "32" },
        { minute: "33" },
        { minute: "34" },
        { minute: "35" },
        { minute: "36" },
        { minute: "37" },
        { minute: "38" },
        { minute: "39" },
        { minute: "40" },
        { minute: "41" },
        { minute: "42" },
        { minute: "43" },
        { minute: "44" },
        { minute: "45" },
        { minute: "46" },
        { minute: "47" },
        { minute: "48" },
        { minute: "49" },
        { minute: "50" },
        { minute: "51" },
        { minute: "52" },
        { minute: "53" },
        { minute: "54" },
        { minute: "55" },
        { minute: "56" },
        { minute: "57" },
        { minute: "58" },
        { minute: "59" }
      ]
    },
    RegTab: 0
  },
  mutations: {},
  getters: {},
  actions: {
    // Error Handlers
    errorHandler({ commit }, e) {
      const link = "https://human-design.space/support/#sendmailform";
      console.log(
        "%c *** SOMETHING WRONG: ***",
        "background: #ff0020; color: #fff"
      );
      console.log(e);
      console.log(
        "%c *** ERROR RESPONSE: ***",
        "background: #ff0020; color: #fff"
      );
      console.log(e.response);
      // console.log(e.message)
      // console.log(e.request)
      if (e.message.includes("401") || e.message.includes("Network Error")) {
        localStorage.removeItem("authToken");
        commit("account/update_token", "human-design-token");
        commit("conf/set_token", "human-design-token");
        localStorage.removeItem("reportId");
        commit("account/update_report_id", "");
        commit("account/login_success", false);
        commit("conf/update_logout_dialog", true);
      }
      if (e.response) {
        if (e.response.data.code === 100) {
          commit("conf/update_service_modal", {
            message: e.response.data.message,
            authDialog: true
          });
        }
        if (e.response.status === 400) {
          // Костылёк для подавления показа сервисного диалога ошибок для окна логина/реги
          if (!e.response.request.responseURL.includes("/auth/login")) {
            // в случае ввода неверного логина лии пароля
            const keys = e.response.data.errors;
            const ERRORS = Object.values(keys)
              .map((arrayValue) => {
                return arrayValue.map((item) => item).join("<br />");
              })
              .join("<br />");
            commit("conf/update_service_modal", {
              message: "<b>" + e.response.data.message + "</b><br />" + ERRORS
            });
          }
        }
        if (e.response.status === 403 && e.response.message) {
          commit("conf/update_error_modal", {
            message: e.response.message
          });
        }
      }
      if (e.message.includes("500")) {
        commit("conf/update_error_modal", {
          message: `Что-то пошло не так...<br /> Обратитесь в <a href='${link}' target='_blank'>службу поддержки</a>!<br />`
        });
      }
    }
  },
  modules: {
    account,
    forms,
    conf
  }
});
