<template>
  <v-card flat d-flex pa-2 class="card-md">
    <v-card-text style="padding:0">
      <v-row v-if="preloading">
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="avatar"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row ref="resultscarousel" v-else>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-auto padding-15-mob-15-0" max-width="950">
            <div
              v-for="(user, i) in searchResult"
              :key="i"
              :class="{ visible: activUser === i }"
              class="hidden"
            >
              <v-carousel
                v-if="user.images.length"
                hide-delimiters
                hide-delimiter-background
                progress
                progress-color="#fff"
                class="custom-carusel"
              >
                <v-carousel-item
                  v-for="(item, j) in user.images"
                  :key="j"
                  :src="item.src"
                >
                  <div
                    v-if="item.id === user.images.length + 1"
                    class="text-center community-request-image-block"
                  >
                    <span
                      v-html="getMessage('social_text_request_more_photos')"
                    ></span>
                    <br />
                    <v-btn
                      @click="requestImage(user.id)"
                      class="hd-gradient margin-top-15"
                      color="primary"
                      >Отправить&nbsp;<v-icon>mdi-send</v-icon></v-btn
                    >
                  </div>
                </v-carousel-item>
              </v-carousel>
              <v-list>
                <v-list-item>
                  <v-list-item-content class="flex-center" style="padding:0">
                    <v-col class="text-center" cols="12">
                      <div style="padding-top:5px">
                        <span style="font-size:19px;font-weight:400">
                          {{ user.first_name
                          }}<span v-if="user.age"> {{ user.age }},</span
                          ><br /><span v-if="user.chart_type.title"
                            >{{ user.chart_type.title }}
                            {{ user.chart_profile.id }}</span
                          >
                        </span>
                      </div>
                      {{ user.city.title }}
                    </v-col>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Настроение сейчас</v-list-item-title>
                      <v-list-item-subtitle style="white-space:initial">
                        <span v-if="user.slogan">{{ user.slogan }}</span>
                        <span v-else>не указано.</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="user.targets.length">
                    <v-list-item-content>
                      <v-list-item-title>Цель знакомства</v-list-item-title>
                      <v-list-item-subtitle>
                        <span
                          v-for="(item, g) in showTargets(user.targets)"
                          :key="g"
                        >
                          {{ item
                          }}<span
                            v-if="g < showTargets(user.targets).length - 1"
                            >,</span
                          >
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>О себе</v-list-item-title>
                      <v-list-item-subtitle style="white-space:initial">
                        <span v-if="user.about">{{ user.about }}</span>
                        <span v-else>не указано.</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Зачем я здесь?</v-list-item-title>
                      <v-list-item-subtitle style="white-space:initial">
                        <span v-if="user.why_am_i_here">{{
                          user.why_am_i_here
                        }}</span>
                        <span v-else>не указано.</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Познания в HD</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ user.hd_level }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="user.height || user.weight">
                    <v-list-item-content class="flex">
                      <v-col v-if="user.height" cols="2" style="padding:0">
                        <v-list-item-title>Рост</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ user.height }}
                        </v-list-item-subtitle>
                      </v-col>
                      <v-col v-if="user.weight" cols="10" style="padding:0">
                        <v-list-item-title>Вес</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ user.weight }}
                        </v-list-item-subtitle>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="user.sex">
                    <v-list-item-content>
                      <v-list-item-title>Пол</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ user.sex ? sexSelect[user.sex].text : "" }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="user.marital_status">
                    <v-list-item-content>
                      <v-list-item-title>Семейное положение</v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          user.marital_status
                            ? maritalStatuses[user.marital_status - 1].text
                            : ""
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Дети</v-list-item-title>
                      <v-list-item-subtitle v-if="user.children.length">
                        <span
                          v-for="(item, e) in showChildren(user.children)"
                          :key="e"
                        >
                          {{ item
                          }}<span
                            v-if="i < showChildren(user.children).length - 1"
                            >,</span
                          >
                        </span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        нет
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="user.visited_countries">
                    <v-list-item-content>
                      <v-list-item-title
                        >Я был(а) в этих странах</v-list-item-title
                      >
                      <v-list-item-subtitle style="white-space:initial">
                        {{ user.visited_countries }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-list-item v-if="user.i_understand">
                  <v-list-item-content>
                    <v-list-item-title>Я разбираюсь</v-list-item-title>
                    <v-list-item-subtitle style="white-space:initial">
                      {{ user.i_understand }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-col
                      cols="4"
                      class="flex-center"
                      style="border-right: 1px solid #0000001f"
                    >
                      <Followers :id="user.id" :count="user.followers_count" />
                    </v-col>
                    <v-col
                      cols="4"
                      class="flex-center"
                      style="border-right: 1px solid #0000001f"
                    >
                      <Subscriptions
                        :id="user.id"
                        :count="user.following_count"
                      />
                    </v-col>
                    <v-col cols="4" class="flex-center">
                      <Subscribe
                        :id="user.id"
                        :isFollowing="user._meta.is_followed_by_me"
                        :typeSubscribe="'search_user'"
                      />
                    </v-col>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="padding-top-0 padding-bottom-0">
                    <v-col
                      cols="6"
                      class="flex-center"
                      style="border-right: 1px solid #0000001f"
                    >
                      <user-posts :method="'userposts'" :userId="user.id" />
                    </v-col>
                    <v-col
                      cols="6"
                      class="flex-center"
                      style="border-right: 1px solid #0000001f"
                    >
                      <user-posts :method="'favourites'" :userId="user.id" />
                    </v-col>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content class="nopadding mob-nopadding">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="12"
                      class="flex-center border-right-bottom"
                    >
                      <Blocking :id="user.id" />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="12"
                      class="flex-center"
                    >
                      <promo-code
                        :promoCode="
                          user.social_network_product_url === null
                            ? ''
                            : user.social_network_product_url
                        "
                      />
                    </v-col>
                  </v-list-item-content>
                </v-list-item>
                <div class="swipe-button">
                  <v-icon class="box bounce-3" x-large
                    >mdi-gesture-swipe-vertical</v-icon
                  >
                </div>
                <div class="sticky-panel">
                  <v-col
                    cols="4"
                    class="flex-center"
                    style="flex-direction:column"
                  >
                    <!-- <Dislike
                      @clicked="changeActiveUser"
                      :id="user.id"
                      :isDislikedByMe="user._meta.is_disliked_by_me"
                    /> -->
                    <v-btn
                      :disabled="
                        user.id === User.id || user._meta.is_disliked_by_me
                      "
                      :class="{
                        disabled:
                          user.id === User.id || user._meta.is_disliked_by_me
                      }"
                      @click="skipActiveUser(user.id)"
                      color="primary"
                      class="my-prof-controls-flex"
                      style="height:auto"
                      x-large
                      fab
                      text
                      dark
                    >
                      <v-icon
                        v-if="
                          user.id === User.id || user._meta.is_disliked_by_me
                        "
                      >
                        mdi-close-circle
                      </v-icon>
                      <v-icon v-else>
                        mdi-close-circle-outline
                      </v-icon>
                      <span class="d-flex" style="font-size:11px;margin-top:5px"
                        >Дальше</span
                      >
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="4"
                    class="flex-center"
                    style="flex-direction:column"
                  >
                    <send-message
                      @clicked="changeActiveUser"
                      :userProp="user"
                      :isSearch="true"
                    />
                    <span
                      v-if="user.id === User.id"
                      @click="changeActiveUser"
                      style="font-size:14px;margin-top:5px;cursor:pointer"
                      >Пропустить</span
                    >
                  </v-col>
                  <v-col
                    cols="4"
                    class="flex-center"
                    style="flex-direction:column"
                  >
                    <Like
                      @clicked="changeActiveUser"
                      :id="user.id"
                      :isLikedByMe="user._meta.is_liked_by_me"
                      :likesMe="user._meta.likes_me"
                    />
                  </v-col>
                </div>
              </v-list>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar v-model="successRequestImage" top color="success">
      <div class="d-flex">
        <v-icon color="white">mdi-check-circle-outline</v-icon>
        <div>&nbsp;Ваше сообщение успешно отправлено!</div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successRequestImage = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import Followers from "@/components/community/profile/followers.vue";
import Subscriptions from "@/components/community/profile/subscriptions.vue";
import Subscribe from "@/components/community/profile/subscribe.vue";
import Like from "@/components/community/profile/like.vue";
// import Dislike from '~/components/community/profile/dislike.vue'
import SendMessage from "@/components/community/profile/send-message.vue";
import Blocking from "@/components/community/profile/blocking.vue";
import UserPosts from "@/components/community/posts/user-posts";
import PromoCode from "@/components/community/profile/promo-code";

export default {
  components: {
    Subscribe,
    Subscriptions,
    Followers,
    Like,
    // Dislike,
    SendMessage,
    UserPosts,
    Blocking,
    PromoCode
  },
  layout: "community",
  mixins: [commonInitAppMethods],
  data() {
    return {
      preloading: false,
      activUser: 0,
      currentUser: 0,
      successRequestImage: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    searchResult: {
      get() {
        return this.User.community.searchResult;
      },
      set(value) {
        this.$store.commit("account/set_search_result", value);
      }
    },
    sexSelect: {
      get() {
        return this.User.community.sexselect;
      },
      set(value) {
        this.$store.commit("account/set_user_sexselect", value);
      }
    },
    maritalStatuses: {
      get() {
        return this.User.community.marital_statuses;
      },
      set(value) {
        this.$store.commit("account/set_user_marital_statuses", value);
      }
    },
    children: {
      get() {
        return this.User.community.children;
      },
      set(value) {
        this.$store.commit("account/set_user_children", value);
      }
    },
    childrenSelect: {
      get() {
        return this.User.community.children_select;
      },
      set(value) {
        this.$store.commit("account/set_user_children_select", value);
      }
    },
    targets: {
      get() {
        return this.$store.state.User.community.targets;
      },
      set(value) {
        this.$store.commit("account/set_user_targets", value);
      }
    },
    targetSelect: {
      get() {
        return this.User.community.target_select;
      },
      set(value) {
        this.$store.commit("account/set_user_target_select", value);
      }
    },
    totalCount() {
      return this.User.community.searchMetaResult.totalCount;
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_clear_active_user":
          this.activUser = 0;
          this.currentUser = 0;
          break;
        case "account/set_search_result":
          this.preloading = false;
          break;
      }
    });
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      set_search_next_page: "account/set_search_next_page",
      call_close_search_people_result_dialog:
        "account/call_close_search_people_result_dialog",
      call_search_people: "account/call_search_people"
    }),
    partnerDialog(key) {
      this.partnerInfoText = this.getMessage(key);
      this.partnerInfoDialog = true;
    },
    async skipActiveUser(id) {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        try {
          const request = {
            user_id: id
          };
          const result = await Api.skipUser(request, this.HttpOptions);
          if (result.success) {
            this.changeActiveUser();
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      } else {
        this.update_login_dialog(true);
      }
    },
    changeActiveUser(isRelativeLike) {
      // console.log(isRelativeLike)
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        if (this.currentUser <= this.totalCount) {
          this.activUser++;
          this.currentUser++;
          if (this.activUser === this.searchResult.length) {
            this.preloading = true;
            if (
              this.currentUser ===
              this.User.community.searchMetaResult.totalCount
            ) {
              this.activUser = 0;
              this.currentUser = 0;
              this.set_search_next_page(1);
              this.call_search_people();
            } else {
              this.activUser = 0;
              this.set_search_next_page(
                +this.User.community.searchMetaResult.currentPage + 1
              );
              this.call_search_people();
            }
          }
          this.$emit("currentuser", this.currentUser);
        } else {
          !isRelativeLike
            ? this.call_close_search_people_result_dialog()
            : (this.activUser = 0);
          this.activUser = 0;
          this.currentUser = 0;
          this.$emit("currentuser", this.currentUser);
        }
      } else {
        this.update_login_dialog(true);
      }
    },
    async requestImage(id) {
      try {
        const request = {
          user_id: id
        };
        const result = await Api.requestUserImage(request, this.HttpOptions);
        if (result.success) {
          this.successRequestImage = true;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    showChildren(arr) {
      const children = [];
      arr.forEach((num) => {
        this.childrenSelect.forEach((child) => {
          if (num === +child.value) {
            children.push(child.text);
          }
        });
      });
      return children;
    },
    showTargets(arr) {
      const targets = [];
      arr.forEach((num) => {
        this.targetSelect.forEach((target) => {
          if (num === +target.value) {
            targets.push(target.text);
          }
        });
      });
      return targets;
    }
  }
};
</script>
<style scoped>
.bg-size-40 .v-image__image--cover {
  background-size: 40% !important;
}
.custom-photos-input {
  flex-direction: column !important;
  align-items: center !important;
}
.custom-photos-input .v-input__prepend-outer {
  margin: 0 !important;
}
.custom-photos-input .v-input__prepend-outer i {
  font-size: 66px !important;
}
.custom-photos-input .v-input__icon--prepend {
  width: 66px;
  height: 66px;
}
.custom-photos-input .v-input__slot {
  display: none !important;
}
.custom-photos-btn-close {
  color: #e53935 !important;
  z-index: 1;
  background-color: #fff !important;
  top: -5px !important;
  right: -3px !important;
}
.sticky-panel {
  position: sticky;
  bottom: 0;
  height: 100px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.theme--dark .sticky-panel {
  border-top: 1px solid #143950;
  background-color: #143950;
}
.sticky-panel-user-search {
  position: sticky;
  bottom: 0;
  height: 100px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.theme--dark .sticky-panel-user-search {
  border-top: 1px solid rgba(20, 57, 80, 0.7);
  background-color: rgba(20, 57, 80, 0.7);
}
.hidden {
  display: none;
  opacity: 0;
}
.visible {
  display: initial !important;
  opacity: 1;
  animation: show 1.5s 1;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.swipe-button {
  display: none;
}
@media screen and (min-width: 320px) and (max-width: 419px) {
  .swipe-button {
    display: flex;
    position: sticky;
    z-index: 101;
    height: 100px;
    width: 100%;
    bottom: 115px;
    justify-content: flex-end;
    padding-right: 10px;
    opacity: 0.7;
  }
}
.box {
  align-self: flex-end;
  animation-name: bounce-3, fade;
  animation-duration: 5s, 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 3, 1;
  animation-delay: 0s, 11s;
  height: 40px;
  transform-origin: bottom;
  width: 40px;
}
.bounce-3 {
  animation-timing-function: ease;
}
@keyframes bounce-3 {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-25px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
