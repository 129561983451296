<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="rulesMessagesDialog = true"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon center>mdi-alert-outline</v-icon>
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Правила
          </span>
        </v-btn>
      </template>
      <span>Правила</span>
    </v-tooltip>
    <v-dialog
      v-model="rulesMessagesDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Помощь
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-container class="mb-6 nopadding">
                  <v-card flat d-flex pa-2 class="card-md">
                    <v-expansion-panels class="add-info-block">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h3>
                            <span>Как сообщить об ошибке</span>
                            или пожаловаться
                          </h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            v-html="getMessage('rules_error_report')"
                            class="add-info-block-head-desc"
                          ></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels class="add-info-block">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h3>
                            <span>Предложения</span>
                            по улучшению
                          </h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            v-html="getMessage('rules_suggestions')"
                            class="add-info-block-head-desc"
                          ></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels class="add-info-block">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h3>
                            <span>Ссылки на другие</span>
                            соцсети запрещены
                          </h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            v-html="getMessage('rules_linx')"
                            class="add-info-block-head-desc"
                          ></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels class="add-info-block">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h3>
                            <span>Эти картинки</span>
                            нельзя добавлять
                          </h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div
                            v-html="getMessage('rules_error_images')"
                            class="add-info-block-head-desc"
                          ></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";

export default {
  mixins: [commonInitAppMethods],
  data() {
    return {
      rulesMessagesDialog: false
    };
  },
  methods: {
    closeDialog() {
      this.rulesMessagesDialog = false;
    }
  }
};
</script>
