<template>
  <v-dialog
    v-model="dialogConversation"
    scrollable
    fullscreen
    max-width="900px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-text class="no-top-bottom-padding mob-nopadding">
        <v-container class="no-top-bottom-padding" style="height:100%">
          <v-row style="height:100%">
            <v-col
              cols="12"
              class="no-top-bottom-padding display-0-24-mob-p-d"
              style="overflow:hidden;overflow-y:auto;height:100%"
            >
              <v-toolbar
                v-if="selectedUser"
                class="position-fixed-top"
                height="120"
              >
                <div style="display:flex;margin-left:-16px">
                  <v-avatar size="84px" tile>
                    <v-img
                      v-if="selectedUser.avatar"
                      :alt="selectedUser.username"
                      :src="selectedUser.avatar"
                    ></v-img>
                  </v-avatar>
                  <div style="margin-left:12px">
                    <div class="convers-title">
                      {{ selectedUser.first_name }}, {{ selectedUser.age }}
                      <span v-if="isWriting" class="is-writing">
                        пишет
                        <span class="circle"></span>
                        <span class="circle2"></span>
                        <span class="circle3"></span>
                      </span>
                    </div>
                    <div class="convers-sub-title">
                      {{ selectedUser.chart_type.title }}
                      {{ selectedUser.chart_profile.id }}
                    </div>
                    <div class="convers-sub-title">
                      <v-btn
                        @click="gotoProfile"
                        :class="{
                          btn_novisible:
                            !isConversationCalledFromProfile &&
                            !isConversationCalledFromChats
                        }"
                        color="primary"
                        small
                        class="hd-gradient"
                      >
                        перейти в профиль
                        <v-icon>mdi-account-arrow-right-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>

                <v-spacer></v-spacer>

                <v-btn @click="closeConversationDialog" icon absolute right>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <Conversation
                :user="selectedUser"
                @input="onMessage"
                @writing="onWriting"
                @messageEdit="onMessageEdit"
                @messageDetele="onMessageDelete"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Conversation from "@/components/community/chat/conversation";

export default {
  components: {
    Conversation
  },
  props: {
    dialogConversation: {
      type: Boolean,
      default: false
    },
    selectedUser: {
      type: Object,
      default: null
    },
    isWriting: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User
    }),
    isConversationCalledFromProfile: {
      get() {
        return this.User.community.isConversationCalledFromProfile;
      },
      set(v) {
        this.set_is_conversation_called_from_profile(v);
      }
    },
    isConversationCalledFromChats: {
      get() {
        return this.User.community.isConversationCalledFromChats;
      },
      set(v) {
        this.set_is_conversation_called_from_chats(v);
      }
    }
  },
  methods: {
    ...mapMutations({
      set_is_conversation_called_from_profile:
        "account/set_is_conversation_called_from_profile",
      set_is_conversation_called_from_chats:
        "account/set_is_conversation_called_from_chats"
    }),
    onMessage(message) {
      this.$emit("onMessage", message);
    },
    onWriting() {
      this.$emit("onWriting");
    },
    onMessageEdit(messageId, messageDesc) {
      this.$emit("onMessageEdit", messageId, messageDesc);
    },
    onMessageDelete(messageId) {
      this.$emit("onMessageDelete", messageId);
    },
    gotoProfile() {
      this.isConversationCalledFromProfile = true;
      this.$emit("closeConversationDialog");
    },
    closeConversationDialog() {
      this.$emit("closeConversationDialog");
    }
  }
};
</script>
