<template>
  <div>
    <v-list-item
      v-if="
        User.isLogged && +User.id !== +userId && User.is_subscribed_to_justclick
      "
      @click="callDialogSpamReport(id)"
    >
      <v-list-item-title>
        <v-icon>mdi-alert-octagon-outline</v-icon>
        Пожаловаться
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    userId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp
    })
  },
  methods: {
    ...mapMutations({
      set_post_spam_id: "account/set_post_spam_id",
      set_post_spam_dialog: "account/set_post_spam_dialog",
      call_post_spam_dialog: "account/call_post_spam_dialog"
    }),
    callDialogSpamReport(id) {
      this.set_post_spam_id(id);
      this.set_post_spam_dialog(true);
      this.call_post_spam_dialog();
    }
  }
};
</script>
