// initial state
const state = () => ({
  LoginForm: {
    logSuccess: "",
    logErrors: ""
  },
  RegForm: {
    regSuccess: "",
    regErrors: ""
  }
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setLogSuccess(state, v) {
    state.LoginForm.logSuccess = v;
  },
  setLogErrors(state, v) {
    state.LoginForm.logErrors = v;
  },
  setRegSuccess(state, v) {
    state.RegForm.regSuccess = v;
  },
  setRegErrors(state, v) {
    state.RegForm.regErrors = v;
  },
  closeDialog() {},
  closeRegDialog() {
    console.log("closeRegDialog");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
