<template>
  <div>
    <v-dialog
      v-model="dialogBeforePosting"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding" style="height:100%">
            <v-row style="height:100%">
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto;height:100%"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Постинг
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="beforePosting" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <Posting @closeDialog="closeDialog" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Posting from "@/components/community/posts/posting";

export default {
  components: {
    Posting
  },
  props: {
    dialogBeforePosting: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    beforePosting() {
      localStorage.setItem("before_posting", JSON.stringify(false));
      this.$store.commit("account/update_before_posting", false);
      this.$store.commit("account/update_before_posting_dialog", false);
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("closeBeforePostingDialog");
    }
  }
};
</script>
