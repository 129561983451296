<template>
  <v-dialog
    v-model="showBEUIDialog"
    scrollable
    fullscreen
    max-width="900px"
    style="z-index: 9999"
  >
    <v-card class="modal-pay-report">
      <v-card-text class="no-top-bottom-padding mob-nopadding">
        <v-container class="no-top-bottom-padding" style="height:100%">
          <v-row style="height:100%">
            <v-col
              cols="12"
              class="no-top-bottom-padding display-0-24-mob-p-d"
              style="overflow:hidden;overflow-y:auto;height:100%"
            >
              <v-toolbar class="hd-gradient position-sticky" dark>
                <v-spacer></v-spacer>

                <v-toolbar-title>
                  Редактирование фотографий
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn @click="closeDialog" icon absolute right>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <EditingImages />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EditingImages from "@/components/community/profile/before-editing-images";

export default {
  name: "BeforeEditingUserImagesDialog",
  components: {
    EditingImages
  },
  props: {
    showBEUIDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // show: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("closeBEUIDialog");
    }
  }
};
</script>
