<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="id === User.id || isLikedByMe"
          :class="{ 'disabled-like': id === User.id || isLikedByMe }"
          @click="userLike(id)"
          color="purple accent-1"
          class="my-prof-controls-flex"
          style="height:auto"
          x-large
          fab
          text
          dark
        >
          <v-icon v-if="id === User.id || isLikedByMe">
            mdi-heart
          </v-icon>
          <v-icon v-else>
            mdi-heart-outline
          </v-icon>
          <span class="d-flex" style="font-size:11px;margin-top:5px">Лайк</span>
        </v-btn>
      </template>
      <span>Лайк</span>
    </v-tooltip>
    <v-snackbar v-model="successLikeToUser" top color="success">
      <v-icon color="white">mdi-check-circle-outline</v-icon>
      &nbsp;Вы поставили пользователю лайк.
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="successLikeToUser = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    isLikedByMe: {
      type: Boolean,
      default: false
    },
    likesMe: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      successLikeToUser: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options
    }),
    canAddContentToSocialNetwork() {
      return this.$store.getters["account/can_add_content_to_social_network"];
    }
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      set_profile: "account/set_profile",
      call_is_relative_like_dialog: "account/call_is_relative_like_dialog"
    }),
    async userLike(id) {
      if (this.User.isLogged && this.User.is_subscribed_to_justclick) {
        // if (!this.hasAvatar || !this.hasBodygraph) {
        if (!this.canAddContentToSocialNetwork) {
          this.socialProfileCheckDialog();
        } else {
          const data = { user_id: id };
          const result = await Api.likeUser(data, this.HttpOptions);
          if (result.success) {
            // console.log(result)
            this.successLikeToUser = true;
            this.call_is_relative_like_dialog(result.social_profile);
            // this.set_profile(id);
            this.$emit("clicked");
          }
        }
      } else {
        this.update_login_dialog(true);
      }
    },
    socialProfileCheckDialog() {
      this.$store.commit("account/call_social_profile_check_modal");
    }
  }
};
</script>
