<template>
  <div>
    <v-dialog
      :value="dialogUserView"
      @input="closeDialog()"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-bottom-padding mob-nopadding">
          <v-container class="no-bottom-padding" style="height:100%">
            <v-row style="height:100%">
              <v-col
                cols="12"
                class="no-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto;height:100%"
              >
                <v-toolbar
                  color="rgba(255, 255, 255, 0) !important"
                  style="position:absolute;width:100%;max-width:965px;z-index:10;box-shadow:none"
                >
                  <v-spacer></v-spacer>

                  <v-toolbar-title>&nbsp;</v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog" class="user-close" fab small>
                    <v-icon color="#122a3b">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <User :userId="userId" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import User from "@/components/community/user/user";

export default {
  components: {
    User
  },
  props: {
    dialogUserView: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      default: null
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeUserViewDialog");
    }
  }
};
</script>
