<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="getCode"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon
            :class="{
              'is-active': promoCodeUrl,
              'is-not-active': !promoCodeUrl
            }"
            center
            >mdi-sale</v-icon
          >
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Скидка
          </span>
        </v-btn>
      </template>
      <span>Промо-код</span>
    </v-tooltip>
    <v-dialog
      v-model="promoCodeDialog"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="promoCodeDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-bottom-padding">
          <v-container class="no-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-bottom-padding display-0-24-mob-p-d"
              >
                <span
                  v-html="promoCodeText"
                  style="display:block;margin-bottom:33px;font-size:16px;line-height:20px"
                >
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                v-if="promoCodeUrl"
                @click="promoCodeDialog = false"
                :href="promoCodeUrl"
                target="_blank"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Подробнее</v-btn
              >
              <v-btn
                v-else
                @click="promoCodeDialog = false"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Понятно</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";

export default {
  props: {
    promoCode: {
      type: String,
      default: ""
    }
  },
  mixins: [commonReportingMethods],
  data() {
    return {
      promoCodeDialog: false,
      promoCodeText: ""
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    promoCodeUrl: {
      get() {
        if (this.promoCode) {
          return this.promoCode;
        } else {
          return this.User.community.promoCodeUrl;
        }
      },
      set(value) {
        this.$store.commit("SET_PROMO_CODE_URL", value);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.promoCodeUrl) {
        this.promoCodeText = this.getMessage("social_text_promo_code_active");
      } else {
        this.promoCodeText = this.getMessage("social_text_promo_code_noactive");
      }
    }, 0);
  },
  methods: {
    ...mapMutations({
      call_user_view_dialog: "account/call_user_view_dialog"
    }),
    getCode() {
      if (this.promoCodeUrl) {
        this.promoCodeText = this.getMessage("social_text_promo_code_active");
      } else {
        this.promoCodeText = this.getMessage("social_text_promo_code_noactive");
      }
      this.promoCodeDialog = true;
    }
  }
};
</script>
<style scoped>
.is-active {
  color: #00ba89 !important;
}
.is-not-active {
  color: #f5dd8d !important;
}
</style>
