<template>
  <v-card flat d-flex pa-2 class="card-md card-md-filled">
    <v-card-text>
      <v-row>
        <v-col
          v-for="(book, i) in books"
          :key="i"
          class="mx-auto"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          outlined
        >
          <v-card
            class="mx-auto book-item"
            max-width="950"
            style="padding: 15px !important"
          >
            <div class="d-flex flex-no-wrap books-flex justify-space-between">
              <div style="text-align:left">
                <p class="display-0-5 text--primary">
                  {{ book.title }}
                </p>
                <v-card-subtitle
                  v-html="book.description"
                  class="mob-padding-15-0"
                ></v-card-subtitle>
                <v-chip
                  class="ma-2 mob-margin-8-8-8-0"
                  color="cyan"
                  label
                  text-color="white"
                >
                  <v-icon left>mdi-cash-100</v-icon>
                  {{ book.price ? book.price : "Бесплатно" }}
                </v-chip>
                <v-card-actions class="v-action-acc">
                  <v-btn
                    v-if="!book.has_book"
                    v-bind:href="book.external_url"
                    target="_blank"
                    color="primary"
                    small
                  >
                    {{ book.cta }}
                  </v-btn>
                  <v-btn
                    v-if="book.has_book && isLogged"
                    @click="getBook(book.id)"
                    color="primary purple accent-3"
                    small
                  >
                    скачать книгу
                  </v-btn>
                  <v-btn
                    v-else-if="book.has_book && !isLogged"
                    @click="showDialog = true"
                    color="primary purple accent-3"
                    small
                  >
                    скачать книгу
                  </v-btn>
                </v-card-actions>
              </div>
              <v-avatar class="ma-3" size="250" tile>
                <v-img :src="book.image" contain></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <LoginDialog :showDialog.sync="showDialog" @close="closeDialog" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";

export default {
  name: "Books",
  components: { LoginDialog },
  data() {
    return {
      err: "",
      showDialog: false
    };
  },
  mixins: [commonInitAppMethods, commonErrorsMethods],
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp
    }),
    isLogged: {
      get() {
        return this.$store.getters["account/is_logged"];
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    books: {
      get() {
        return this.$store.getters["account/books"];
      },
      set(value) {
        this.$store.commit("account/set_books", value);
      }
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/login_success":
          this.getData();
          break;
      }
    });
    this.getSettings();
    this.getData();
  },
  methods: {
    getSettings() {
      if (Object.keys(this.$store.state.conf.Messages).length === 0) {
        this.$store.dispatch("conf/getSettings");
      }
    },
    async getData() {
      this.$store.dispatch("account/getBooks");
    },
    getBook(id) {
      /* this.$store.commit("conf/update_service_modal", {
        message: this.getMessage("book_text_message_download")
      }); */
      Api.downloadBook(id, this.User.access_token, this.ConfApp.partner_id);
    },
    closeDialog(val) {
      // console.log("emitReg: ", val);
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
    }
  }
};
</script>
<style scoped></style>
