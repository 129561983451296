<template>
  <div>
    <v-dialog
      :value="dialogSocialProfileCheck"
      @input="closeDialog()"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="closeDialog()" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <img
                  src="https://static.human-design.space/widget/images/social/social_09_1.png"
                  class="stepper-image"
                />
                <br />
                <span style="display:block;margin-bottom:33px">
                  Писать комментарии, создавать посты, общаться и знакомиться
                  могут пользователи только с заполненным аккаунтом с фото...
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="callEditProfile()"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Заполнить</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    dialogSocialProfileCheck: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations({
      call_dialog_edit_profile: "account/call_dialog_edit_profile"
    }),
    closeDialog() {
      this.$emit("closeSocialProfileCheckDialog");
    },
    callEditProfile() {
      this.closeDialog();
      this.$router.push("/socialprofile");
      this.call_dialog_edit_profile(true);
    }
  }
};
</script>
