<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="profileSettingsDialog = true"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon center>mdi-cog-outline</v-icon>
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Настройки
          </span>
        </v-btn>
      </template>
      <span>Настройки профиля</span>
    </v-tooltip>
    <v-dialog
      v-model="profileSettingsDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Настройки вашего профиля
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn
                    @click="profileSettingsDialog = false"
                    icon
                    absolute
                    right
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card class="mx-auto" max-width="950">
                  <Settings />
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Settings from "@/views/Settings";

export default {
  components: { Settings },
  data() {
    return {
      profileSettingsDialog: false
    };
  }
};
</script>
