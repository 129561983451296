<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="+user.id !== +User.id"
          v-bind="attrs"
          v-on="on"
          @click="sendMessage"
          color="primary"
          class="my-prof-controls-flex"
          style="height:auto"
          x-large
          fab
          text
          dark
        >
          <v-icon class="is-active">
            mdi-chat-processing-outline
          </v-icon>
          <span class="d-flex is-active" style="font-size:11px;margin-top:5px"
            >Сообщение</span
          >
        </v-btn>
      </template>
      <span>Сообщение</span>
    </v-tooltip>
    <v-dialog
      v-model="isRelativeLikeDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title
                    v-if="isRelativeLike"
                    v-html="getMessage('social_title_relative_like')"
                  ></v-toolbar-title>
                  <v-toolbar-title
                    v-else
                    v-html="getMessage('social_title_no_send_message')"
                  >
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn
                    @click="isRelativeLikeDialog = false"
                    icon
                    absolute
                    right
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-col
                  cols="12"
                  class="flex-column align-self-center"
                  style="padding-top:70px;height:93vh"
                >
                  <v-img
                    v-if="!isRelativeLike"
                    src="/img/social/social_07_1.png"
                    class="stepper-image"
                  ></v-img>
                  <v-img
                    v-else
                    src="/img/social/social_08_1.png"
                    class="stepper-image"
                  ></v-img>
                  <span
                    v-if="isRelativeLike"
                    v-html="getMessage('social_text_relative_like')"
                  ></span>
                  <span
                    v-else
                    v-html="getMessage('social_text_no_send_message')"
                  >
                  </span>
                  <br /><br />
                  <v-btn
                    v-if="isRelativeLike"
                    @click="sendMessage"
                    color="primary accent-4"
                    class="small-med mx-2 hd-gradient"
                  >
                    Написать
                  </v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="underConstructions" top color="warning">
        <v-icon color="white">mdi-alert-outline</v-icon>
        &nbsp;Данная функция в разработке.
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            @click="underConstructions = false"
            color="red"
            x-small
            text
          >
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
// import socket from '../../../plugins/community/socket'
import { commonReportingMethods } from "@/mixins/common-reporting-methods";

export default {
  props: {
    userProp: {
      type: Object,
      default: null
    },
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  mixins: [commonReportingMethods],
  data() {
    return {
      isRelativeLikeDialog: false,
      underConstructions: false,
      users: []
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    user() {
      if (this.userProp) {
        return this.userProp;
      } else {
        return this.User.community.selected_user;
      }
    },
    isRelativeLike() {
      if (Object.keys(this.user).length !== 0) {
        return this.user._meta.is_liked_by_me && this.user._meta.likes_me;
      } else {
        return false;
      }
    },
    isConversationCalledFromProfile: {
      get() {
        return this.User.community.isConversationCalledFromProfile;
      },
      set(v) {
        this.set_is_conversation_called_from_profile(v);
      }
    },
    /* hasAvatar() {
      return this.$store.getters.HAS_AVATAR
    },
    hasBodygraph() {
      return this.$store.getters.HAS_BODYGRAPH
    }, */
    canAddContentToSocialNetwork() {
      return this.$store.getters["account/can_add_content_to_social_network"];
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "CALL_IS_RELATIVE_LIKE_DIALOG":
          this.isRelativeLikeCheck();
          break;
      }
    });
    setTimeout(() => this.isRelativeLikeCheck(), 0);
    if (this.User.isLogged) {
      this.preloading = false;
    } else {
      this.update_login_dialog(true);
    }
  },
  methods: {
    ...mapMutations({
      set_is_conversation_called_from_profile:
        "account/set_is_conversation_called_from_profile"
    }),
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    sendMessage() {
      // if ((!this.hasAvatar || !this.hasBodygraph) && this.user.id !== 15) {
      if (!this.canAddContentToSocialNetwork && this.user.id !== 15) {
        this.$store.commit("account/call_social_profile_check_modal");
      } else {
        const userObj = this.objectNormalization(this.user);
        this.$store.commit("account/call_conversation_outside", userObj);
        if (!this.isSearch) this.isConversationCalledFromProfile = true;
      }
    },
    isRelativeLikeCheck() {
      if (this.isRelativeLike && !this.relativeLikeDialogShowOnce()) {
        this.isRelativeLikeDialog = true;
        // this.$emit('clicked', this.isRelativeLike)
        if (localStorage.getItem("relativeLikeArray")) {
          const arr = JSON.parse(localStorage.getItem("relativeLikeArray"));
          arr.push(this.user.id);
          localStorage.setItem("relativeLikeArray", JSON.stringify(arr));
        } else {
          const arr = [this.user.id];
          localStorage.setItem("relativeLikeArray", JSON.stringify(arr));
        }
        this.$store.commit("account/call_re_render_mutual_interest");
      }
    },
    objectNormalization(obj) {
      obj.connected = false;
      obj.newMessagesCount = 0;
      obj.isWriting = false;
      obj.is_favourite = false;
      obj.is_blocked = false;
      obj.messages = [];
      return obj;
    },
    relativeLikeDialogShowOnce() {
      let result;
      const equal = (el) => +el === +this.user.id;
      const arr = localStorage.getItem("relativeLikeArray")
        ? JSON.parse(localStorage.getItem("relativeLikeArray"))
        : [];
      if (arr.length) {
        result = arr.some(equal);
      } else {
        result = false;
      }
      return result;
    },
    isChatUser(id) {
      let res;
      this.$store.state.User.community.chats.ApiUsers.forEach((user) => {
        if (+user.id === +id) res = true;
      });
      return res;
    }
  }
};
</script>
<style scoped>
.is-active {
  color: #00ba89 !important;
}
.is-not-active {
  color: #f5dd8d !important;
}
</style>
