<template>
  <div>
    <v-dialog
      v-model="showSocialSteppersDialog"
      scrollable
      fullscreen
      max-width="900px"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding" style="height:100%">
            <v-row style="height:100%">
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto;height:100%"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Добро пожаловать!
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeSocialSteppers" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <Steppers @closeSocialSteppers="closeSocialSteppers" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Steppers from "@/components/community/chat/steppers";

export default {
  components: { Steppers },
  props: {
    showSocialSteppersDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations({
      call_dialog_edit_profile: "account/call_dialog_edit_profile"
    }),
    closeSocialSteppers() {
      this.$emit("closeSocialSteppersDialog");
    }
  }
};
</script>
