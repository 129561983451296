<template>
  <div>
    <v-list-item
      v-if="User.isLogged && +User.id === +userId && isEditable"
      @click="postEdit(postIdx)"
    >
      <v-list-item-title>
        <v-icon>mdi-file-document-edit-outline</v-icon>
        Редактировать
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    postIdx: {
      type: Number,
      default: null
    },
    userId: {
      type: Number,
      default: null
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    })
  },
  methods: {
    ...mapMutations({ call_edit_post: "account/call_edit_post" }),
    postEdit(idx) {
      this.call_edit_post(idx);
    }
  }
};
</script>
