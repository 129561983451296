<template>
  <v-card flat d-flex pa-2 class="card-md">
    <v-card-text class="nopadding">
      <v-row v-if="!User.isLogged">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="no-bottom-padding"
        >
          <v-card
            class="mx-auto"
            max-width="989"
            style="padding: 15px !important"
          >
            <v-card-text>
              <div>
                <div>
                  <v-alert
                    type="warning"
                    class="darken-orange-custm-1"
                    color="lighten-3"
                  >
                    Вы не вошли в систему, настройки будут доступны после
                    авторизации
                    <v-btn
                      @click="showDialog = true"
                      class="mx-2 margin-top-10-mob margin-btm-5"
                      small
                      color="primary light-blue lighten-1"
                    >
                      <v-icon left>mdi-account-search-outline</v-icon>
                      Войти заново
                    </v-btn>
                  </v-alert>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="no-bottom-padding"
        >
          <v-card
            class="mx-auto"
            max-width="950"
            style="padding: 15px !important"
          >
            <v-card-text>
              <v-tooltip v-if="$route.path === '/settings'" right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="goBack"
                    class="mx-2 margin-top-10-mob margin-btm-5 min-width-165"
                    small
                    color="primary accent-4"
                  >
                    <v-icon left>mdi-arrow-left-bold-box-outline</v-icon>
                    обратно
                  </v-btn>
                </template>
                <span>Вернуться обратно</span>
              </v-tooltip>
              <p
                v-if="ConfApp.controls.btn_theme"
                class="display-0-1 margin-top-25"
              >
                <span class="settings-header margin-rt-40-nomob">
                  Выбор темы:
                </span>
              </p>
              <BtnThemeSwitcher />
              <p class="display-0-1 margin-top-25">
                <span class="settings-header margin-rt-40-nomob">
                  Уведомления:
                </span>
              </p>
              <v-switch
                v-model="emailNotifications"
                @change="sendEmailNotifications"
                :label="eNotificationsLabel"
                color="primary"
                inset
              ></v-switch>
              <p class="display-0-1">
                <span class="settings-header margin-rt-40-nomob">
                  Партнерская программа:
                </span>
              </p>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="partnerdialog = true"
                    class="mx-2 margin-top-10-mob margin-btm-5 min-width-165"
                    small
                    color="primary accent-4"
                  >
                    <v-icon left>mdi-account-group-outline</v-icon>
                    подробнее
                  </v-btn>
                </template>
                <span>Узнать о партнерской программе</span>
              </v-tooltip>
              <p class="display-0-1 margin-top-25">
                <span class="settings-header margin-rt-40-nomob">
                  Смена пароля:
                </span>
              </p>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="changePassword"
                    class="mx-2 margin-top-10-mob margin-btm-5 min-width-165"
                    small
                    color="primary accent-4"
                  >
                    <v-icon left>mdi-lock-reset</v-icon>
                    сменить
                  </v-btn>
                </template>
                <span>Сменить пароль на новый</span>
              </v-tooltip>
              <p class="display-0-1 margin-top-25">
                <span class="settings-header margin-rt-40-nomob">
                  Выход из системы:
                </span>
              </p>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="logout"
                    class="mx-2 margin-top-10-mob margin-btm-5 min-width-165"
                    small
                    color="primary accent-4"
                  >
                    <v-icon left>mdi-logout</v-icon>
                    выйти
                  </v-btn>
                </template>
                <span>Выход из системы</span>
              </v-tooltip>
              <p class="display-0-1 margin-top-25">
                <span class="settings-header margin-rt-40-nomob">
                  Удаление аккаунта:
                </span>
              </p>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="removedialog = true"
                    class="mx-2 margin-top-10-mob margin-btm-5 min-width-165"
                    small
                    color="primary accent-4"
                  >
                    <v-icon left>mdi-account-remove</v-icon>
                    удалить
                  </v-btn>
                </template>
                <span>Удалить аккаунт из системы</span>
              </v-tooltip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="passworddialog" max-width="900px" style="z-index: 9999">
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >Смена пароля</span
          >
          <v-btn @click="passworddialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <div class="log-sucsess">{{ psswdSuccess }}</div>
                    <div class="log-errors">{{ psswdErrors }}</div>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      @click:append="show = !show"
                      v-model="oldpassword"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show ? 'text' : 'password'"
                      validate-on-blur
                      label="Старый"
                      hint="Введите старый пароль"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      @click:append="show2 = !show2"
                      v-model="newpassword"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="show2 ? 'text' : 'password'"
                      validate-on-blur
                      label="Новый"
                      hint="Введите новый пароль"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      @click:append="show3 = !show3"
                      v-model="repeatpassword"
                      :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min, rules.psswdсonfirm]"
                      :type="show3 ? 'text' : 'password'"
                      validate-on-blur
                      label="Повторите"
                      hint="Повторите новый пароль"
                      counter
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row class="d-flex justify-center">
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    class="text-center"
                  >
                    <v-btn
                      @click="passworddialog = false"
                      color="primary darken-1"
                      class="margin-right-3"
                      >Закрыть</v-btn
                    >
                    <v-btn @click="validatePassword" color="primary">
                      Сменить пароль
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="partnerdialog"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="partnerDialog()" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <div
                  v-html="getMessage('partners')"
                  style="display:block;text-align:left;margin:10px 0 33px 0"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="removedialog"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="removedialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <div
                  v-html="getMessage('remove')"
                  style="display:block;text-align:left;margin:10px 0 33px 0"
                ></div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-row class="d-flex justify-center">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="text-center"
                >
                  <v-btn
                    @click="removedialog = false"
                    color="primary darken-1"
                    class="margin-right-3"
                  >
                    Закрыть
                  </v-btn>
                  <v-btn
                    @click="(removedialog = false), remove()"
                    color="primary"
                  >
                    Удалить
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LoginDialog :showDialog.sync="showDialog" @close="closeDialog" />
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { mapState } from "vuex";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import BtnThemeSwitcher from "@/components/parts/BtnThemeSwitcher";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";

export default {
  name: "Settings",
  components: { LoginDialog, BtnThemeSwitcher },
  mixins: [commonInitAppMethods, commonReportingMethods, commonErrorsMethods],
  data() {
    return {
      passworddialog: false,
      partnerdialog: false,
      removedialog: false,
      preloading: true,
      rules: {
        required: (value) => !!value || "Обязательный параметр.",
        min: (v) => v.length >= 6 || "Минимальное количество символов — 6",
        psswdсonfirm: (v) => v === this.newpassword || "Пароли не совпадают"
      },
      psswdSuccess: "",
      psswdErrors: "",
      psswdData: {},
      show: false,
      show2: false,
      show3: false,
      oldpassword: "",
      newpassword: "",
      repeatpassword: "",
      showDialog: false,
      eNotificationsLabel: "на электронную почту"
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options,
      ConfApp: (state) => state.conf.ConfApp,
      DarkTheme: (state) => state.conf.DarkTheme
    }),
    emailNotifications: {
      get() {
        return this.User.is_subscriber;
      },
      set(value) {
        this.$store.commit("account/email_notifications", value);
      }
    }
  },
  mounted() {
    this.verify();
    this.getSettings();
    this.initConfApp();
    setTimeout(() => this.getGetProfile(), 3500);
  },
  methods: {
    changePassword() {
      this.passworddialog = !this.passworddialog;
      this.psswdSuccess = "";
      this.psswdErrors = "";
    },
    validatePassword() {
      if (this.$refs.form.validate()) {
        this.getChangePassword();
      }
    },
    async getChangePassword() {
      try {
        this.psswdErrors = "";
        const changePasswordParams = {
          password: this.oldpassword,
          password_new: this.newpassword,
          password_new_repeat: this.repeatpassword
        };
        const result = await Api.changePassword(
          changePasswordParams,
          this.HttpOptions
        );
        if (result.success) {
          this.psswdData = result;
          if (this.psswdData.success) {
            this.psswdSuccess = "Пароль успешно изменен!";
            localStorage.setItem("authToken", this.psswdData.access_token);
            this.$store.commit(
              "account/update_token",
              this.psswdData.access_token
            );
            setTimeout(() => (this.passworddialog = false), 500);
          } else {
            this.psswdErrors = this.psswdData.errors.password[0];
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
        this.psswdErrors = e.response.data.errors.password[0];
      }
    },
    async remove() {
      try {
        const result = await Api.reMove({}, this.HttpOptions);
        if (result.success) {
          this.logout();
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async sendEmailNotifications() {
      try {
        const data = { is_subscriber: this.emailNotifications ? 1 : 0 };
        const result = await Api.setNotifications(data, this.HttpOptions);
        if (result.success) {
          // console.log(result)
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async getGetProfile() {
      if (this.User.isLogged) {
        await this.$store.dispatch("account/getProfile");
      }
    },
    partnerDialog() {
      this.getSettings();
      this.partnerdialog = false;
    },
    getSettings() {
      if (Object.keys(this.$store.state.conf.Messages).length === 0) {
        this.$store.dispatch("conf/getSettings");
      }
    },
    goBack() {
      this.$router.push("/profile");
    },
    closeDialog(val) {
      // console.log("emitReg: ", val);
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
    }
  }
};
</script>
<style scoped>
.margin-right-3 {
  margin-right: 3px;
}
</style>
