<template>
  <v-card flat d-flex pa-2 class="card-md">
    <v-card-text style="padding:0">
      <v-row v-if="preloading">
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="paragraph"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-skeleton-loader
            height="64"
            type="avatar"
            class="mx-auto flex-v-center"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-auto padding-15-mob-15-0" max-width="950">
            <mutual-interest :id="User.id" />
            <v-list three-line>
              <people-liked-you :id="User.id" />

              <v-divider></v-divider>

              <users @select="onSelectUser" />
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import MutualInterest from "@/components/community/chat/mutual-interest";
import PeopleLikedYou from "@/components/community/chat/people-liked-you";
import Users from "@/components/community/chat/users";

export default {
  components: {
    Users,
    MutualInterest,
    PeopleLikedYou
  },
  mixins: [commonInitAppMethods],
  data() {
    return {
      preloading: true
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    id() {
      return this.User.id;
    }
  },
  mounted() {
    this.verify();
    setTimeout(() => {
      this.getPreloading();
    }, 0);
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    callConversation() {
      this.$store.commit("account/call_conversation");
    },
    onSelectUser(user) {
      /* socket.emit('user-to-server:get-user-messages', {
        userRecipientId: user.id
      }) */
      this.$store.commit("account/call_conversation_outside", user);
      this.$store.commit("account/call_conversation");
      setTimeout(() => {
        this.$store.commit("account/call_scroll_chat_to_footer");
      }, 250);
    },
    getPreloading() {
      if (this.User.isLogged) {
        this.preloading = false;
      } else {
        setTimeout(() => {
          if (this.User.isLogged) {
            this.preloading = false;
          } else {
            this.getPreloading();
            // this.update_login_dialog(true);
          }
        }, 50);
      }
    }
  }
};
</script>
<style scoped>
.profile-name {
  margin-top: 15px;
}
.bg-size-40 .v-image__image--cover {
  background-size: 40% !important;
}
.custom-photos-input {
  flex-direction: column !important;
  align-items: center !important;
}
.custom-photos-input .v-input__prepend-outer {
  margin: 0 !important;
}
.custom-photos-input .v-input__prepend-outer i {
  font-size: 66px !important;
}
.custom-photos-input .v-input__icon--prepend {
  width: 66px;
  height: 66px;
}
.custom-photos-input .v-input__slot {
  display: none !important;
}
.custom-phts-btn-close {
  color: #e53935 !important;
  z-index: 100;
  background-color: #fff !important;
  top: -1px !important;
  right: -1px !important;
}
.profile-img-round {
  margin-top: 15px;
}
.profile-img-round > div {
  height: 350px !important;
  width: 350px !important;
  overflow: hidden;
  flex: inherit;
  border-radius: 50%;
}
.like-quantity {
  display: block;
  margin: 16px;
  border-radius: 50%;
  padding: 4px;
  border: solid 2px #fca6f0;
}
.like-counter {
  color: #fff !important;
  font-size: 11px;
  text-align: center;
  background-color: #fca6f0;
  padding: 0 3px;
  border-radius: 15px;
  position: absolute;
  bottom: 15px;
  right: 10px;
  white-space: nowrap;
}
.like-counter i {
  font-size: 14px !important;
}
@media screen and (min-width: 320px) and (max-width: 430px) {
  .my-prof-controls-flex span {
    display: flex;
    flex-direction: column;
  }
  .my-prof-controls-icon {
    flex-direction: row !important;
    align-items: center;
  }
  .profile-img-round > div {
    height: 250px !important;
    width: 250px !important;
  }
}
.convers-title {
  font-size: 16px;
}
.convers-sub-title {
  font-size: 14px;
}
</style>
