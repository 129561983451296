<template>
  <v-card flat d-flex pa-2 class="card-md card-md-filled">
    <v-skeleton-loader
      v-if="preloadingData"
      height="48"
      type="text"
      class="mx-auto flex-v-center"
    >
    </v-skeleton-loader>
    <v-card-title
      v-else
      class="headline"
      style="font-size: 1.5rem !important;justify-content:center"
    >
      Кого вам найти?
    </v-card-title>
    <form>
      <v-card-text class="card-md">
        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-skeleton-loader
              v-if="preloadingData"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-autocomplete
              ref="cityInput"
              v-on:change="onCityChange"
              v-else
              v-model="city"
              :items="cities"
              :loading="isLoading"
              :search-input.sync="search"
              :no-filter="true"
              chips
              clearable
              filled
              hide-details
              hide-selected
              item-text="title"
              item-value="id"
              label="Город (по умолчанию весь мир)"
              name="someusercity"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title v-if="!search || isLoading">
                    Ближайший крупный <strong>город</strong>...
                  </v-list-item-title>
                  <v-list-item-title v-else class="v-list-item-title-custom">
                    <span
                      v-if="!searchResult"
                      v-html="getMessage('tooltip_find_city')"
                    ></span>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  v-on="on"
                  :input-value="selected"
                  color="white"
                  class="black--text"
                >
                  <span v-text="item.title"></span>&nbsp;
                  <img
                    :src="
                      'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                        item.country.iso +
                        '.svg'
                    "
                    height="15"
                  />
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.country.title"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <img
                    :src="
                      'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                        item.country.iso +
                        '.svg'
                    "
                    height="15"
                  />
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-skeleton-loader
              v-if="preloadingData"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-select
              ref="sexSelect"
              v-on:change="onSexChange"
              v-else
              v-model="sex"
              :hint="`Пол`"
              :items="sexs"
              item-text="sex"
              label="Выберите пол"
              persistent-hint
              return-object
              single-line
              filled
              required
              menu-props="auto, bottom, offset-y"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <v-skeleton-loader
              v-if="preloadingData"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-select
              ref="purposeSelect"
              v-else
              v-model="targets"
              :hint="`Цель знакомства`"
              :items="targetSelect"
              :item-value="targetSelect.value"
              label="Неважно"
              chips
              multiple
              filled
              clearable
              persistent-hint
              return-object
              single-line
              menu-props="auto, bottom, offset-y"
              style="margin-bottom:7px"
            ></v-select>
          </v-col>
          <v-col cols="12" class="col-max-w-300-center">
            <v-skeleton-loader
              v-if="preloadingData"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-range-slider
              v-on:change="onAgeChange"
              v-else
              v-model="rangeAge"
              hint="Выберите возрастной диапазон"
              persistent-hint
              max="85"
              min="18"
              thumb-label="always"
            ></v-range-slider>
          </v-col>
          <v-expansion-panels
            class="expansion-panels-user-stat col-max-w-300-center"
            style="margin:12px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                Параметры Бодиграфа &nbsp;
                <span style="color:#1976d2;font-size:12px"
                  >(необязательно)</span
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col v-if="!isSocialPremium" cols="12" class="text-center">
                    Активируйте премиум аккаунт, чтобы воспользоваться
                    расширенными фильтрами
                    <v-btn color="primary" class="hd-gradient" x-small
                      >активировать</v-btn
                    >
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="typeSelect"
                      v-else
                      v-model="types"
                      :hint="`Тип`"
                      :items="typesSelect"
                      :item-text="'title'"
                      :item-value="'id'"
                      :disabled="!isSocialPremium"
                      label="Выберите тип"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="profilesSelect"
                      v-else
                      v-model="profiles"
                      :hint="`Профиль`"
                      :items="selectProfiles"
                      :disabled="!isSocialPremium"
                      label="Выберите профиль"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="authoritySelect"
                      v-else
                      v-model="authorities"
                      :hint="`Авторитет`"
                      :items="selectAuthorities"
                      :item-text="'title'"
                      :item-value="'id'"
                      :disabled="!isSocialPremium"
                      label="Выберите авторитет"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="definedCentersSelect"
                      v-else
                      v-model="definedCenters"
                      :item-text="'title'"
                      :item-value="'id'"
                      :hint="`Определенные центры`"
                      :items="selectDefinedCenters"
                      :disabled="!isSocialPremium"
                      label="Выберите определенные центры"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="undefinedCentersSelect"
                      v-else
                      v-model="undefinedCenters"
                      :hint="`Неопределенные центры`"
                      :items="selectUndefinedCenters"
                      :item-text="'title'"
                      :item-value="'id'"
                      :disabled="!isSocialPremium"
                      label="Выберите неопределенные центры"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="channelsSelect"
                      v-else
                      v-model="channels"
                      :hint="`Каналы`"
                      :items="selectChannels"
                      :disabled="!isSocialPremium"
                      label="Выберите каналы"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      return-object
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="gatesSelect"
                      v-else
                      v-model="gates"
                      :hint="`Ворота`"
                      :items="selectGates"
                      :disabled="!isSocialPremium"
                      label="Выберите ворота"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      return-object
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="definitionSelect"
                      v-else
                      v-model="definitions"
                      :hint="`Определенность`"
                      :items="selectDefinitions"
                      :item-text="'title'"
                      :item-value="'id'"
                      :disabled="!isSocialPremium"
                      label="Выберите определенность"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <!-- <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="quarterSelect"
                      v-else
                      v-model="quarters"
                      :hint="`четверть`"
                      :items="selectQuarters"
                      :item-text="'title'"
                      :item-value="'id'"
                      :disabled="!isSocialPremium"
                      label="Выберите четверть"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-skeleton-loader
                      v-if="preloadingData"
                      height="64"
                      type="text"
                      class="mx-auto flex-v-center"
                    >
                    </v-skeleton-loader>
                    <v-select
                      ref="angleSelect"
                      v-else
                      v-model="angles"
                      :hint="`крест`"
                      :items="selectAngles"
                      :disabled="!isSocialPremium"
                      label="Выберите крест"
                      chips
                      clearable
                      multiple
                      filled
                      persistent-hint
                      return-object
                      small-chips
                      single-line
                      required
                      menu-props="auto, bottom, offset-y"
                    ></v-select>
                  </v-col> -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="text-center">
            <v-skeleton-loader
              v-if="preloadingData"
              height="35"
              type="button"
              class="mx-auto flex-center"
            >
            </v-skeleton-loader>
            <v-btn
              v-else
              @click="validate"
              rounded
              large
              color="primary"
              class="v-btn-custom hd-gradient v-btn-custom--300"
            >
              Начать поиск&nbsp;<v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </form>
    <v-dialog
      v-model="searchPeopleResultdialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding" style="height:100%">
            <v-row style="height:100%">
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto;height:100%"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Результаты поиска ({{ currentUser }} из
                    {{ User.community.searchMetaResult.totalCount }})
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="closeDialog" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <user-search-result @currentuser="changeCurrentUser" />
                <!-- <User :userId="27408" /> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <progress-dialog :progress="progressdialog" />
    <v-snackbar v-model="emptySearchResult" top color="deep-orange">
      <v-icon color="white">mdi-alert-outline</v-icon>
      &nbsp;Ничего не найдено, измените параметры поиска...
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="emptySearchResult = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { validationMixin } from "vuelidate";
// import { required } from 'vuelidate/lib/validators'
import UserSearchResult from "@/components/community/search/user-search-result";
import ProgressDialog from "@/components/loaders/progress-dialog";

export default {
  components: {
    // Conversation,
    // UserSearchProfile
    UserSearchResult,
    ProgressDialog
  },
  mixins: [validationMixin, commonInitAppMethods],
  validations: {
    // city: { required }
  },
  data() {
    return {
      searchPeopleResultdialog: false,
      preloadingData: false,
      preloading: true,
      isLoading: false,
      progressdialog: false,
      // cities: [],
      city: null,
      search: null,
      searchResult: true,
      isSocialPremium: true,
      sex: { sex: "Неважно", value: 0 },
      sexs: [
        { sex: "Неважно", value: 0 },
        { sex: "Мужской", value: 1 },
        { sex: "Женский", value: 2 }
      ],
      rangeAge: [25, 35],
      targetSelect: [
        { text: "Общение", value: 1 },
        { text: "Изучение Рейв-карт", value: 2 },
        { text: "Интересные встречи", value: 3 },
        { text: "Хочу отношений", value: 4 }
      ],
      targets: [],
      types: [],
      profiles: [],
      authorities: [],
      definedCenters: [],
      undefinedCenters: [],
      channels: [],
      gates: [],
      definitions: [],
      quarters: [],
      angles: [],
      emptySearchResult: false,
      currentUser: 1
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    typesSelect() {
      return this.User.community.searchVocabulary.chart_types;
    },
    selectAuthorities() {
      return this.User.community.searchVocabulary.chart_authorities;
    },
    selectDefinedCenters() {
      return this.User.community.searchVocabulary.chart_centers;
    },
    selectUndefinedCenters() {
      return this.User.community.searchVocabulary.chart_centers;
    },
    selectChannels() {
      return this.User.community.searchVocabulary.chart_channels;
    },
    selectDefinitions() {
      return this.User.community.searchVocabulary.chart_definitions;
    },
    selectGates() {
      return this.User.community.searchVocabulary.chart_gates;
    },
    selectProfiles() {
      return this.User.community.searchVocabulary.chart_profiles;
    },
    selectQuarters() {
      return this.User.community.searchVocabulary.chart_quarters;
    },
    nextPage() {
      return this.User.community.searchNextPage;
    },
    cities: {
      get() {
        return this.User.lastCities;
      },
      set(value) {
        this.$store.commit("account/update_last_cities", value);
      }
    }
    /* selectAngles() {
      return this.User.community.searchVocabulary.chart_angles
    } */
  },
  watch: {
    search(val) {
      if (!val) return;
      this.fetchCitiesDebounced(val);
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "account/call_close_search_people_result_dialog":
          this.searchPeopleResultdialog = false;
          break;
        case "account/call_search_people":
          this.searchPeople();
          break;
      }
    });
    setTimeout(() => {
      this.getProfile();
    }, 0);
    setTimeout(() => {
      if (this.User.isLogged && this.User.community.beforeSelector) {
        this.update_before_people_searching_dialog(true);
      }
    }, 1500);
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog",
      update_before_people_searching_dialog:
        "account/update_before_people_searching_dialog",
      set_search_result: "account/set_search_result",
      set_search_meta_result: "account/set_search_meta_result",
      clear_search_result: "account/clear_search_result",
      call_clear_active_user: "account/call_clear_active_user",
      set_comm_followers: "account/set_comm_followers",
      set_comm_following: "account/set_comm_following",
      set_search_next_page: "account/set_search_next_page"
    }),
    updateCityId(val) {
      // console.log(val)
      this.$store.commit("UPDATE_CITY_ID", val);
    },
    fetchCitiesDebounced(val) {
      this.search = this.searchRequestProcessing(val);
      this.isLoading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchCities(this.search);
        this.isLoading = false;
      }, 500); /* 500ms throttle */
    },
    async fetchCities(searchrequest) {
      if (searchrequest) {
        try {
          const result = await Api.searchCity(searchrequest, this.HttpOptions);
          if (result.success) {
            this.cities = result.cities;
            this.citiesMemoization(result.cities);
            if (!this.cities.length) {
              this.searchResult = false;
            }
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    onCityChange() {
      this.$refs.cityInput.blur();
      this.$refs.sexSelect.activateMenu();
      this.$refs.sexSelect.focus();
    },
    onSexChange() {
      this.$refs.purposeSelect.activateMenu();
      this.$refs.purposeSelect.focus();
    },
    onAgeChange() {
      // console.log(this.rangeAge)
    },
    typeChange() {
      this.$refs.authoritySelect.activateMenu();
      this.$refs.authoritySelect.focus();
    },
    authorityChange() {
      this.$refs.definedCentersSelect.activateMenu();
      this.$refs.definedCentersSelect.focus();
    },
    definedCentersChange() {
      this.$refs.undefinedCentersSelect.activateMenu();
      this.$refs.undefinedCentersSelect.focus();
    },
    undefinedCentersChange() {
      this.$refs.channelsSelect.activateMenu();
      this.$refs.channelsSelect.focus();
    },
    channelsChange() {
      this.$refs.gatesSelect.activateMenu();
      this.$refs.gatesSelect.focus();
    },
    gatesChange() {
      this.$refs.definitionSelect.activateMenu();
      this.$refs.definitionSelect.focus();
    },
    definitionChange() {
      this.$refs.quarterSelect.activateMenu();
      this.$refs.quarterSelect.focus();
    },
    quarterChange() {
      this.$refs.angleSelect.activateMenu();
      this.$refs.angleSelect.focus();
    },
    validate() {
      if (!this.User.isLogged) {
        this.update_login_dialog(true);
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.searchPeople();
        }
      }
    },
    async searchPeople() {
      const lTargets = this.targets.map((item) => {
        return item.value;
      });
      try {
        this.progressdialog = true;
        const optionsPlus = {
          params: {
            city_id: this.city,
            sex: this.sex.value,
            targets: lTargets,
            age: this.rangeAge,
            chart_type_ids: this.types,
            chart_profiles: this.profiles,
            chart_authority_ids: this.authorities,
            chart_definition_ids: this.definitions,
            chart_quarter_ids: this.quarters,
            chart_centers_defined: this.definedCenters,
            chart_centers_undefined: this.undefinedCenters,
            chart_channels: this.channels,
            chart_gates: this.gates,
            page: this.nextPage
          }
        };
        const options = Object.assign(this.HttpOptions, optionsPlus);
        const result = await Api.searchUsers(options);
        if (result.users.length) {
          this.progressdialog = false;
          this.set_comm_followers(0);
          this.set_comm_following(0);
          this.set_search_meta_result(result._meta);
          this.set_search_result(result.users);
          this.searchPeopleResultdialog = true;
        } else {
          this.emptySearchResult = true;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async getProfile() {
      if (this.User.isLogged) {
        this.preloading = true;
        this.$store.dispatch("account/getSocialProfile");
        this.preloading = false;
      } else {
        setTimeout(() => {
          if (this.User.isLogged) {
            this.preloading = true;
            this.$store.dispatch("account/getSocialProfile");
            this.preloading = false;
          } else {
            this.getProfile();
            // this.update_login_dialog(true);
          }
        }, 50);
      }
    },
    closeDialog() {
      this.searchPeopleResultdialog = false;
      this.currentUser = 1;
      this.call_clear_active_user();
      this.set_search_next_page(1);
      this.clear_search_result();
    },
    changeCurrentUser(num) {
      // console.log('currentUser: ', num)
      this.currentUser = num + 1;
    }
  }
};
</script>
<style scoped>
@media screen and (min-width: 320px) and (max-width: 419px) {
  .v-btn-custom--300 {
    width: 300px !important;
  }
  .col-max-w-300-center {
    /* max-width: 300px !important;
    padding: 12px 0 !important;
    margin: 0 auto !important; */
  }
}
</style>
