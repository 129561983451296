<template>
  <div class="sticky-menu">
    <Cabinet />
    <AddEditPost />
    <user-posts :method="'myfavouritesSticky'" :userId="+User.id" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AddEditPost from "@/components/community/posts/add-edit-post";
import UserPosts from "@/components/community/posts/user-posts";
import Cabinet from "@/components/community/posts/cabinet";

export default {
  components: {
    Cabinet,
    AddEditPost,
    UserPosts
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User
    })
  }
};
</script>
<style scoped>
.sticky-menu {
  width: 100%;
  max-width: 950px;
  height: 72px;
  position: absolute !important;
  display: flex;
  justify-content: space-between;
  top: 0;
  z-index: 101;
  border-radius: 10px !important;
  background-color: #fff;
  box-shadow: 0 3px 3px #f0f0f0;
}
.theme--dark .sticky-menu {
  background-color: #143950;
  box-shadow: 0 3px 3px #000;
}
@media screen and (min-width: 320px) and (max-width: 419px) {
  .sticky-menu {
    border-radius: 0 !important;
  }
}
</style>
