<template>
  <div>
    <v-dialog
      :value="dialogSpamReport"
      @input="closeSpamReport()"
      fullscreen
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <v-card class="mx-auto">
                  <v-toolbar class="hd-gradient position-sticky" dark>
                    <v-toolbar-title style="flex-grow:1">
                      Пожаловаться на спам
                    </v-toolbar-title>

                    <v-btn @click="closeSpamReport" icon absolute right>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-footer absolute style="position:sticky;z-index:4">
                    <div
                      style="width:100%;display:flex;align-items:center;justify-content:center"
                    >
                      <v-col
                        cols="12"
                        sm="12"
                        md="10"
                        lg="10"
                        xl="10"
                        style="padding:12px 0 0"
                      >
                        <v-subheader style="justify-content:center">
                          Размещен нежелательный контент?
                        </v-subheader>
                        <v-form
                          ref="formSpam"
                          class="form-comm"
                          style="flex-direction:column"
                        >
                          <v-textarea
                            ref="commDesc"
                            v-model="spamReportMsg"
                            :counter="256"
                            :rules="spamRules"
                            outlined
                            name="input-7-4"
                            rows="1"
                            row-height="10"
                            auto-grow
                            label="Хотите оставить комментарий?"
                            class="form-comm-textarea"
                          ></v-textarea>
                          <div>
                            <v-btn
                              @click="validateSpamReport(idSpamPost)"
                              small
                              color="primary"
                              class="hd-gradient"
                              style="margin-bottom:25px"
                            >
                              Подтвердить
                            </v-btn>
                          </div>
                        </v-form>
                      </v-col>
                    </div>
                  </v-footer>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="spamReportSent" top>
      Ваше сообщение отправлено
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="spamReportSent = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";

export default {
  mixins: [commonErrorsMethods],
  props: {
    dialogSpamReport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      spamReportSent: false,
      spamReportMsg: "",
      spamRules: [
        // (v) => !!v || 'Напишите сообщение.',
        (v) =>
          v.length <= 256 || "Сообщение не должено быть более 256 символов."
      ]
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    idSpamPost: {
      get() {
        return this.$store.getters["account/post_spam_id"];
      },
      set(val) {
        this.set_post_spam_id(val);
      }
    }
  },
  methods: {
    ...mapMutations({
      set_post_spam_id: "account/set_post_spam_id"
    }),
    clearSpamReportForm() {
      this.idSpamPost = null;
      this.spamReportMsg = "";
      this.$refs.formSpam.resetValidation();
    },
    closeSpamReport() {
      this.clearSpamReportForm();
      this.$emit("closeSpamReportDialog");
    },
    validateSpamReport(id) {
      if (this.$refs.formSpam.validate()) {
        this.spamReport(id);
      }
    },
    async spamReport(id) {
      try {
        const data = {
          post_id: id,
          description: this.spamReportMsg
        };
        const result = await Api.complainAboutPost(data, this.HttpOptions);
        if (result.success) {
          this.closeSpamReport();
          this.spamReportSent = true;
        }
      } catch (e) {
        this.errorHandler(e);
      }
    }
  }
};
</script>
