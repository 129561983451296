<template>
  <v-dialog v-model="progresStatus" max-width="370px" style="z-index: 9999">
    <v-card class="modal-pay-report">
      <v-card-text class="no-bottom-padding">
        <v-container class="no-bottom-padding">
          <v-row>
            <v-col
              cols="12"
              class="text-center no-bottom-padding display-0-24-no-line-h"
            >
              <v-progress-circular
                indeterminate
                color="purple"
              ></v-progress-circular>
              <span style="display:block;margin-bottom:33px">
                Подождите идет загрузка...
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ProgressDialog",
  props: {
    progress: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    progresStatus: {
      get() {
        if (this.progress) {
          return this.progress;
        } else {
          return this.$store.state.account.progresStatus;
        }
      },
      set(v) {
        this.$store.commit("account/set_progress_status", v);
      }
    }
  }
};
</script>
