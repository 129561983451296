<template>
  <div>
    <v-dialog
      v-model="beforeSelectorDialog"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding" style="height:100%">
            <v-row style="height:100%">
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto;height:100%"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Подбиратор
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn @click="beforeSearching" icon absolute right>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <div class="pre-posting">
                  <v-list-item>
                    <v-list-item-content class="text-center">
                      <v-img
                        src="https://static.human-design.space/widget/images/social/social_04_1.png"
                        class="stepper-image"
                      ></v-img>
                      <v-list-item-title style="white-space:initial">
                        Привет! Я подберу вам самые классные варианты для
                        знакомств по выбранным фильтрам.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <div class="text-center">
                    <v-btn
                      @click="beforeSearching"
                      class="mx-2 min-width-120 margin-btm-5 hd-gradient"
                      large
                      color="primary"
                      style="margin:25px 0"
                    >
                      <v-icon dark>mdi-magnify</v-icon>&nbsp;Начать
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    beforeSelectorDialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    beforeSearching() {
      this.$emit("closeBeforeSelectorDialog");
    }
  }
};
</script>
