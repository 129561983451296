<template>
  <div>
    <v-dialog
      :value="dialogPrompt"
      @input="closeDialog()"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="closeDialog()" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-bottom-padding">
          <v-container class="no-bottom-padding">
            <v-row>
              <v-col
                cols="12"
                class="text-center no-bottom-padding display-0-24-no-line-h"
              >
                <span
                  v-if="postStatus === 1"
                  style="display:block;margin-bottom:33px"
                >
                  Вы уверены?<br />Публикация будет заблокирована
                </span>
                <span
                  v-if="postStatus === 2"
                  style="display:block;margin-bottom:33px"
                >
                  Публикация будет разблокирована
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="postBlocking(postId)"
                color="success"
                class="padding-0-20 hd-gradient"
              >
                <span v-if="postStatus === 1">Заблокировать</span>
                <span v-if="postStatus === 2">Разблокировать</span>
              </v-btn>
              &nbsp;
              <v-btn
                @click="closeDialog()"
                color="success"
                class="padding-0-20 hd-gradient"
                >Отмена</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/api/api";
import { mapState } from "vuex";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";

export default {
  mixins: [commonErrorsMethods],
  props: {
    dialogPrompt: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options
    }),
    postId() {
      return this.$store.getters["account/post_block_id"];
    },
    postStatus() {
      return this.$store.getters["account/post_block_status"];
    },
    page() {
      return this.$store.getters["account/get_current_page"];
    }
  },
  methods: {
    async postBlocking() {
      // console.log('Done: post ', this.postId, 'blocked')
      const method = this.postStatus === 2 ? Api.unblockPost : Api.blockPost;
      const data = { post_id: this.postId };
      try {
        const result = await method(data, this.HttpOptions);
        if (result.success) {
          this.closeDialog();
          this.getSocialPosts(this.User.id, this.User.community.posts_options);
        }
      } catch (e) {
        this.errorHandler(e);
      }
    },
    getSocialPosts(id, options) {
      const data = { id, options };
      this.$store.dispatch("account/getSocialPosts", data);
    },
    closeDialog() {
      this.$emit("closeBlockPostDialog");
    }
  }
};
</script>
