<template>
  <v-card flat d-flex pa-2 class="card-md card-md-filled">
    <v-card-text class="padding-5">
      <v-row v-if="!User.isLogged" class="margin-top-bottom-0">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card
            class="mx-auto"
            max-width="950"
            style="padding: 15px !important"
          >
            <v-card-text>
              <div v-if="!User.is_subscribed_to_justclick">
                <div
                  v-if="preloading"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="avatar"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="flex-center-column">
                  <img
                    src="https://static.human-design.space/widget/images/bgs/login.png"
                    width="120"
                    class="padding-bottom-40"
                  />
                  <center class="login-confirm-header">
                    Вы не вошли в систему, личный кабинет будет доступен после
                    авторизации
                  </center>
                  <div class="margin-top-40">
                    <v-btn
                      @click="showDialog = true"
                      class="mx-2 hd-gradient"
                      large
                      color="primary accent-4"
                      style="font-size: 0.85rem"
                    >
                      Войти заново&nbsp;&nbsp;<v-icon small>
                        mdi-account-search-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="margin-top-bottom-0">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card class="mx-auto" max-width="950">
            <v-card-text>
              <div v-if="!User.is_subscribed_to_justclick">
                <v-skeleton-loader
                  v-if="preloading"
                  height="64"
                  type="text"
                  class="mx-auto flex-v-center"
                >
                </v-skeleton-loader>
                <div v-else class="flex-center-column">
                  <img
                    src="https://static.human-design.space/widget/images/bgs/span.png"
                    width="120"
                    class="padding-bottom-40 padding-top-25"
                  />
                  <center class="login-confirm-header">
                    Вы вошли в систему, но не подтвердили свой email,
                    расширенные функции пока недоступны. Найдите в своей почте
                    (в спаме) письмо с доступами или запросите новое письмо.
                  </center>
                  <div class="margin-top-40 margin-bottom-25">
                    <v-btn
                      @click="reSendMail"
                      class="mx-2 hd-gradient"
                      large
                      color="primary accent-4"
                      style="font-size: 0.85rem"
                    >
                      Запросить&nbsp;&nbsp;<v-icon small>
                        mdi-email-multiple-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-if="maintenanceWork">
                <v-alert
                  type="warning"
                  class="darken-orange-custm-1"
                  color="lighten-3"
                >
                  Внимание! Проводятся технические работы, некоторые функции
                  личного кабинета могут не работать!
                </v-alert>
              </div>
              <div class="display-0-5 text--primary demo-report-header">
                <div class="display-0-5 text--primary d-none d-sm-block">
                  <span class="display-0-24">
                    Вы наш партнер
                  </span>
                  <span class="display-0-24">
                    <v-icon
                      @click="partnerDialog('partners')"
                      style="font-size:14px;color:#fb8c01"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </span>
                  <div class="user-balance">
                    Заработано:
                    {{ User.total_income
                    }}<v-icon style="font-size:14px">mdi-currency-rub</v-icon>
                    <br />
                    Текущий баланс:
                    {{ User.current_balance
                    }}<v-icon style="font-size:14px">mdi-currency-rub</v-icon>
                    <br />
                    Всего регистраций:
                    {{ User.referrals_count }}
                    <br />
                    CTR покупок: {{ User.referrals_ctr }}%
                    <span class="display-0-24">
                      <v-icon
                        @click="partnerDialog('partners_ctr')"
                        style="font-size:14px;color:#fb8c01"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </span>
                    <br />
                    <v-btn
                      @click="copyToClipboard(1)"
                      :disabled="loading3"
                      class="mx-2 min-width-165 margin-btm-5"
                      small
                      color="primary"
                      style="margin: 0 !important;margin-bottom:5px"
                    >
                      <v-icon dark>mdi-link-variant</v-icon>&nbsp;Ссылка
                    </v-btn>
                    <textarea
                      ref="targetUrl1"
                      :value="getPartnerLink()"
                      style="height:1px;opacity:0"
                    ></textarea>
                    <v-tooltip v-model="copied" bottom attach>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small style="display: none">
                          <span>
                            &nbsp;
                          </span>
                        </v-btn>
                      </template>
                      <span>ссылка скопирована и вы можете ей поделиться</span>
                    </v-tooltip>
                    <br />
                    <v-btn
                      @click="staticDialog = true"
                      class="mx-2 min-width-165 margin-btm-5"
                      small
                      color="primary"
                      style="margin: 0 !important;margin-bottom:5px"
                    >
                      <v-icon dark>mdi-chart-arc</v-icon>&nbsp;Статистика
                    </v-btn>
                    <br />
                    <v-btn
                      v-if="User.current_balance < User.min_withdrawal"
                      @click="withdrawalLess4k"
                      class="mx-2 min-width-165 margin-btm-5"
                      small
                      color="primary"
                      style="margin: 0 !important"
                    >
                      <v-icon dark>mdi-cash-100</v-icon>&nbsp;Вывод
                    </v-btn>
                    <v-btn
                      v-else
                      @click="withdrawalDialog = true"
                      class="mx-2 min-width-165 margin-btm-5"
                      small
                      color="primary"
                      style="margin: 0 !important"
                    >
                      <v-icon dark>mdi-cash-100</v-icon>&nbsp;Вывод
                    </v-btn>
                  </div>
                </div>
                <span class="demo-report-header-name">
                  {{ User.name }}
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="toSettings"
                        fab
                        small
                        text
                        color="orange darken-1"
                      >
                        <v-icon center>mdi-tune-vertical</v-icon>
                      </v-btn>
                    </template>
                    <span>Настройки</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="getLogout"
                        fab
                        small
                        text
                        color="primary accent-4"
                      >
                        <v-icon center>mdi-logout</v-icon>
                      </v-btn>
                    </template>
                    <span>Выход из системы</span>
                  </v-tooltip>
                  <br />
                  <span
                    class="display-0-24"
                    style="padding-right:10px;line-height:inherit"
                  >
                    {{ User.email }}
                  </span>
                  <br />
                  <span
                    style="font-size: 0.9rem;color:#00BA89;line-height:inherit"
                  >
                    Подсказки:
                    {{ Profile.full_tooltips_count }}
                  </span>
                  <span class="display-0-24">
                    <v-icon
                      v-if="Profile.full_tooltips_count > 0"
                      @click="tooltipsCountDialog('tooltip_paid')"
                      style="font-size:14px;padding-right:10px;color:#fb8c01"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                    <v-icon
                      v-else
                      @click="tooltipsCountDialog('tooltip_before_purchase')"
                      style="font-size:14px;padding-right:10px;color:#fb8c01"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </span>
                  <div
                    style="display:flex;justify-content:flex-end;height:30px"
                  >
                    <FullTooltipsSwitcher />
                  </div>
                </span>
              </div>
              <v-expansion-panels
                class="expansion-panels-user-stat d-flex d-sm-none"
                style="margin-top:20px"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="d-none d-sm-block">Статистика</span>
                    <span class="d-flex d-sm-none">
                      Партнерская программа
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="display-0-5 text--primary d-flex d-sm-none">
                      <div class="user-balance">
                        <span class="display-0-24">
                          Вы наш партнер
                        </span>
                        <span class="display-0-24">
                          <v-icon
                            @click="partnerDialog('partners')"
                            style="font-size:14px;color:#fb8c01"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </span>
                        <br />
                        Заработано:
                        {{ User.total_income
                        }}<v-icon style="font-size:14px">
                          mdi-currency-rub
                        </v-icon>
                        <br />
                        Текущий баланс:
                        {{ User.current_balance
                        }}<v-icon style="font-size:14px">
                          mdi-currency-rub
                        </v-icon>
                        <br />
                        Всего регистраций:
                        {{ User.referrals_count }}
                        <br />
                        CTR покупок:
                        {{ User.referrals_ctr }}%
                        <span class="display-0-24">
                          <v-icon
                            @click="partnerDialog('partners_ctr')"
                            style="font-size:14px;color:#fb8c01"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </span>
                        <br />
                        <div style="display:flex;justify-content:space-between">
                          <v-btn
                            @click="copyToClipboard(2)"
                            :disabled="loading3"
                            class="mx-2 min-width-165 margin-btm-5"
                            small
                            color="primary"
                            style="margin: 0 !important;margin-bottom:5px"
                          >
                            <v-icon dark>mdi-link-variant</v-icon>&nbsp;Ссылка
                          </v-btn>
                          <v-tooltip v-model="copied" bottom attach>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" small style="display: none">
                                <span>
                                  &nbsp;
                                </span>
                              </v-btn>
                            </template>
                            <span>
                              ссылка скопирована и вы можете ей поделиться
                            </span>
                          </v-tooltip>
                          <v-btn
                            v-if="User.current_balance < User.min_withdrawal"
                            @click="withdrawalLess4k"
                            class="mx-2 min-width-165 margin-btm-5"
                            small
                            color="primary"
                            style="margin: 0 !important"
                          >
                            <v-icon dark>mdi-cash-100</v-icon>&nbsp;Вывод
                          </v-btn>
                          <v-btn
                            v-else
                            @click="withdrawalDialog = true"
                            class="mx-2 min-width-165 margin-btm-5"
                            small
                            color="primary"
                            style="margin: 0 !important"
                          >
                            <v-icon dark>mdi-cash-100</v-icon>&nbsp;Вывод
                          </v-btn>
                        </div>
                        <textarea
                          ref="targetUrl2"
                          :value="getPartnerLink()"
                          style="height:1px;opacity:0"
                        ></textarea>
                      </div>
                    </div>
                    <div class="partner-stat">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Дата</th>
                              <th class="text-left">
                                <span class="d-none d-sm-block">Событие</span>
                                <span class="d-flex d-sm-none">Соб.</span>
                              </th>
                              <th class="text-left">Сумма</th>
                              <th class="text-left">Статус</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="transactionsAll.length === 0">
                              <td colspan="4" style="text-align:center">
                                Продаж еще не было
                              </td>
                            </tr>
                            <tr v-for="(item, i) in transactionsAll" :key="i">
                              <td>{{ item.payment_date }}</td>
                              <td v-if="item.is_withdrawal">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      class="red--text text--darken-4"
                                    >
                                      mdi-arrow-right-bold
                                    </v-icon>
                                  </template>
                                  <span>вывод средств</span>
                                </v-tooltip>
                              </td>
                              <td v-else>
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      class="green--text-custom"
                                    >
                                      mdi-arrow-left-bold
                                    </v-icon>
                                  </template>
                                  <span>партнерское начисление</span>
                                </v-tooltip>
                              </td>
                              <td
                                v-if="item.is_withdrawal"
                                class="red--text text--darken-3"
                              >
                                {{ item.amount_formatted }}
                              </td>
                              <td v-else class="green--text-custom">
                                {{ item.amount_formatted }}
                              </td>
                              <td v-if="item.status_id === 1">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      class="green--text-custom"
                                    >
                                      mdi-check-circle
                                    </v-icon>
                                  </template>
                                  <span>{{ item.status }}</span>
                                </v-tooltip>
                              </td>
                              <td v-else-if="item.status_id === 0">
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      class="amber--text text--darken-3"
                                    >
                                      mdi-clock-time-four
                                    </v-icon>
                                  </template>
                                  <span>{{ item.status }}</span>
                                </v-tooltip>
                              </td>
                              <td v-else>
                                <v-tooltip right>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      class="red--text text--darken-4"
                                    >
                                      mdi-close-circle
                                    </v-icon>
                                  </template>
                                  <span>{{ item.status }}</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card class="mx-auto" max-width="950">
            <v-list>
              <v-list-item class="">
                <v-list-item-content class="padding-top-0 padding-bottom-0">
                  <v-col
                    cols="4"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="orange darken-1"
                          class="my-prof-controls-flex"
                          href="https://human-design.space/support/"
                          target="_new"
                          style="margin-left:-16px"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon center>mdi-forum</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Поддержка
                          </span>
                        </v-btn>
                      </template>
                      <span>Поддержка</span>
                    </v-tooltip>
                  </v-col>
                  <v-col
                    cols="4"
                    class="flex-center"
                    style="border-right: 1px solid #0000001f"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="#00ba89"
                          class="my-prof-controls-flex"
                          href="/#/feed"
                          target="_new"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon center>mdi-human-male-male</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Соцсеть
                          </span>
                        </v-btn>
                      </template>
                      <span>Соцсеть</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4" class="flex-center padding-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="light-blue darken-1"
                          class="my-prof-controls-flex"
                          href="https://t.me/hdspace1"
                          target="_new"
                          style="margin-right:-16px"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon center>mdi-send</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Telegram
                          </span>
                        </v-btn>
                      </template>
                      <span>Telegram-канал</span>
                    </v-tooltip>
                  </v-col>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <br />
        <v-col
          v-if="User.haveIsOneUnusedUserProducts"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="padding-2-0"
        >
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div v-if="profile.user_products && profile.user_products.length">
                <div
                  v-for="(product, i) in profile.user_products"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row v-if="!product.is_used">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      class="product-help text-center margin-bottom-40"
                    >
                      <div class="display-0-5 text--primary demo-report-header">
                        <p class="display-0-5 text--primary">
                          Активируйте покупку:
                        </p>
                      </div>
                      <div
                        class="display-0-24-no-line-h"
                        style="margin: 10px 0 15px"
                      >
                        <strong style="text-transform: uppercase;">
                          расшифровка: «{{ product.product.title }}» </strong
                        ><br />
                        Чтобы её получить, сделайте расчёт
                      </div>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="payReportDialog(product, 'products')"
                            class="mx-2 v-btn-activation success-custom margin-top-15"
                            rounded
                            large
                            color="accent-4"
                            style="margin-bottom: 15px"
                          >
                            <span class="pulse-item2">сделать расчет</span
                            >&nbsp;<img
                              src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                              width="20"
                            />
                          </v-btn>
                        </template>
                        <span>сделать расчет карты</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div
                v-if="
                  profile.career_user_products &&
                    profile.career_user_products.length
                "
              >
                <div
                  v-for="(product, i) in profile.career_user_products"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row v-if="!product.is_used">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      class="product-help text-center margin-bottom-40"
                    >
                      <div class="display-0-5 text--primary demo-report-header">
                        <p class="display-0-5 text--primary">
                          Активируйте покупку:
                        </p>
                      </div>
                      <div
                        class="display-0-24-no-line-h"
                        style="margin: 10px 0 15px"
                      >
                        <strong style="text-transform: uppercase;">
                          бизнес расшифровка: «{{
                            product.product.title
                          }}» </strong
                        ><br />
                        Чтобы её получить, сделайте расчёт
                      </div>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="payReportDialog(product, 'career')"
                            class="mx-2 v-btn-activation success-custom margin-top-15"
                            rounded
                            large
                            color="accent-4"
                            style="margin-bottom: 15px"
                          >
                            <span class="pulse-item2">сделать расчет</span
                            >&nbsp;<img
                              src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                              width="20"
                            />
                          </v-btn>
                        </template>
                        <span>сделать расчет карты</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div
                v-if="
                  profile.child_user_products &&
                    profile.child_user_products.length
                "
              >
                <div
                  v-for="(product, i) in profile.child_user_products"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row v-if="!product.is_used">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      class="product-help text-center margin-bottom-40"
                    >
                      <div class="display-0-5 text--primary demo-report-header">
                        <p class="display-0-5 text--primary">
                          Активируйте покупку:
                        </p>
                      </div>
                      <div
                        class="display-0-24-no-line-h"
                        style="margin: 10px 0 15px"
                      >
                        <strong style="text-transform: uppercase;">
                          детская расшифровка: «{{
                            product.product.title
                          }}» </strong
                        ><br />
                        Чтобы её получить, сделайте расчёт
                      </div>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="payReportDialog(product, 'products')"
                            class="mx-2 v-btn-activation success-custom margin-top-15"
                            rounded
                            color="accent-4"
                            style="margin-bottom: 15px"
                          >
                            <span class="pulse-item2">сделать расчет</span
                            >&nbsp;<img
                              src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                              width="20"
                            />
                          </v-btn>
                        </template>
                        <span>сделать расчет карты</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div
                v-if="
                  profile.composite_user_products &&
                    profile.composite_user_products.length
                "
              >
                <div
                  v-for="(composite, i) in profile.composite_user_products"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row v-if="!composite.is_used">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      class="product-help text-center margin-bottom-40"
                    >
                      <div class="display-0-5 text--primary demo-report-header">
                        <p class="display-0-5 text--primary">
                          Активируйте покупку:
                        </p>
                      </div>
                      <div
                        class="display-0-24-no-line-h"
                        style="margin: 10px 0 15px"
                      >
                        <strong style="text-transform: uppercase;">
                          совместимость: «{{
                            composite.product.title
                          }}» </strong
                        ><br />
                        Чтобы его получить, сделайте расчёт
                      </div>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="payCompositeDialog(composite)"
                            class="mx-2 v-btn-activation success-custom margin-top-15"
                            rounded
                            large
                            color="accent-4"
                            style="margin-bottom: 15px"
                          >
                            <span class="pulse-item2">сделать расчет</span
                            >&nbsp;<img
                              src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                              width="20"
                            />
                          </v-btn>
                        </template>
                        <span>сделать расчет совместимости</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="profile.pro_reports && profile.pro_reports.length">
                <div
                  v-for="(product, i) in profile.pro_reports"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row v-if="!product.is_used">
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      class="product-help text-center margin-bottom-40"
                    >
                      <div class="display-0-5 text--primary demo-report-header">
                        <p class="display-0-5 text--primary">
                          Активируйте покупку:
                        </p>
                      </div>
                      <div
                        class="display-0-24-no-line-h"
                        style="margin: 10px 0 15px"
                      >
                        <strong style="text-transform: uppercase;">
                          «PRO-подсказки»</strong
                        ><br />
                        Чтобы их получить, сделайте расчёт
                      </div>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="payReportDialog(product, 'pro')"
                            class="mx-2 v-btn-activation success-custom margin-top-15"
                            rounded
                            large
                            color="accent-4"
                            style="margin-bottom: 15px"
                          >
                            <span class="pulse-item2">сделать расчет</span
                            >&nbsp;<img
                              src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                              width="20"
                            />
                          </v-btn>
                        </template>
                        <span>сделать расчет карты</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col
          v-if="
            User.haveIsUsedUserProducts ||
              User.haveIsUsedUserComposites ||
              User.haveIsUsedProReports ||
              User.haveIsUsedChildProducts ||
              User.haveIsUsedCareerProducts
          "
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="padding-2-0"
        >
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div class="display-0-5 text--primary demo-report-header">
                <div class="mob-display-block text-align-center">
                  <div
                    class="margin-top-5 margin-btm-20-mob margin-btm-5 min-width-165-media375 mob-block"
                  >
                    Дарим скидку:<br />
                    <span style="font-size:14px">Используйте ее</span>
                  </div>
                  <span class="no-mobile">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          v-show="
                            User.haveIsUsedUserProducts ||
                              User.haveIsUsedUserComposites ||
                              User.haveIsUsedProReports ||
                              User.haveIsUsedChildProducts ||
                              User.haveIsUsedCareerProducts
                          "
                          :href="salesProdictUrl"
                          target="_blank"
                          class="margin-top-10-mob margin-btm-5 mob-block padding-20-15 primary"
                          small
                          dark
                        >
                          <v-icon left class="mob-no-margin-right">
                            mdi-cart-plus
                          </v-icon>
                          <span style="padding-left:2px">для себя</span>
                        </v-btn>
                      </template>
                      <span>Купить платные расшифровки</span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          v-show="
                            User.haveIsUsedUserProducts ||
                              User.haveIsUsedUserComposites ||
                              User.haveIsUsedProReports ||
                              User.haveIsUsedChildProducts ||
                              User.haveIsUsedCareerProducts
                          "
                          @click="buyProductAsGift"
                          class="margin-top-10-mob margin-btm-5 mob-block padding-20-15 custom-color-green-2"
                          style="margin-left: 5px"
                          small
                          dark
                        >
                          <v-icon left class="mob-no-margin-right">
                            mdi-cart-plus
                          </v-icon>
                          <span style="padding-left:3px">в подарок</span>
                        </v-btn>
                      </template>
                      <span>Подарить платную расшифровку</span>
                    </v-tooltip>
                  </span>
                  <div class="no-desktop">
                    <v-list class="padding-0 margin-btm-10">
                      <v-list-item class="padding-0">
                        <v-list-item-content
                          class="padding-top-0 padding-bottom-0"
                        >
                          <v-col
                            cols="6"
                            class="flex-center padding-0"
                            style="border-right: 1px solid #0000001f"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-show="
                                    User.haveIsUsedUserProducts ||
                                      User.haveIsUsedUserComposites ||
                                      User.haveIsUsedProReports ||
                                      User.haveIsUsedChildProducts ||
                                      User.haveIsUsedCareerProducts
                                  "
                                  :href="salesProdictUrl"
                                  target="_blank"
                                  class="my-prof-controls-flex"
                                  color="primary"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-cart-plus</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    для себя
                                  </span>
                                </v-btn>
                              </template>
                              <span>Купить платные расшифровки</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="6" class="flex-center padding-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-show="
                                    User.haveIsUsedUserProducts ||
                                      User.haveIsUsedUserComposites ||
                                      User.haveIsUsedProReports ||
                                      User.haveIsUsedChildProducts ||
                                      User.haveIsUsedCareerProducts
                                  "
                                  @click="buyProductAsGift"
                                  class="my-prof-controls-flex"
                                  color="#00ba89"
                                  style="margin-right:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-cart-plus</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    в подарок
                                  </span>
                                </v-btn>
                              </template>
                              <span>Подарить платную расшифровку</span>
                            </v-tooltip>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col
          v-if="
            User.haveIsUsedUserProducts ||
              User.haveIsUsedUserComposites ||
              User.haveIsUsedProReports ||
              User.haveIsUsedChildProducts ||
              User.haveIsUsedCareerProducts
          "
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="padding-2-0"
        >
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div v-if="profile.user_products && profile.user_products.length">
                <div
                  v-for="(product, i) in profile.user_products"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="product.is_last_bought_product"
                    style="margin:0;flex-direction:column"
                  >
                    <div
                      class="display-0-5 text--primary demo-report-header mob-demo-report-header"
                    >
                      <div class="mob-display-block">
                        <span
                          class="margin-top-5 margin-btm-5 min-width-165-media375 mob-block"
                        >
                          Последняя покупка
                          <span class="sub-title">Расшифровка</span>
                        </span>
                      </div>
                    </div>
                    <v-row class="no-mobile">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="product.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ product.first_name }}</span>
                          </li>
                          <li v-if="product.email">
                            <v-icon>mdi-at</v-icon>
                            <span>{{ product.email }}</span>
                          </li>
                          <li v-if="product.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ dateFormatting(product.birthday) }}</span>
                          </li>
                          <li v-if="product.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ product.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="product.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ product.chart_type }}</span>
                          </li>
                          <li v-if="product.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ product.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="product-help no-mobile padding-top-35"
                      >
                        <div class="col-pd-lt-10 no-mobile">
                          <div class="product-payed-title">
                            <strong>{{ product.product.part_title }}</strong>
                          </div>
                          <div>
                            <div
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Открыт доступ:&nbsp;
                            </div>
                            <div class="d-flex">
                              <v-btn
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:href="
                                  product.upgrade_url +
                                    '~rf' +
                                    User.ext_reference_code
                                "
                                @click="initPurchase(i)"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                {{ product.product_upgrade_title }}
                              </v-btn>
                              <div
                                v-if="
                                  product.upgrade_url &&
                                    product.is_seen &&
                                    product.product.id !== 3
                                "
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                              >
                                <v-icon
                                  @click="
                                    helPayDialogAdditionSection(
                                      product.upgrade_html_tooltip,
                                      product.upgrade_url +
                                        '~rf' +
                                        User.ext_reference_code
                                    )
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                            <div
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  product.additional_blocks_url
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Вам доступны:&nbsp;
                            </div>
                            <div
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  product.additional_blocks_url
                              "
                              style="display:flex;align-items:baseline"
                            >
                              <v-btn
                                @click="openUpdatesButton"
                                :href="product.additional_blocks_url"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                {{
                                  product.additional_blocks_title
                                    ? product.additional_blocks_title
                                    : "обновления"
                                }}
                              </v-btn>
                              &nbsp;&nbsp;
                              <v-chip
                                :class="{ 'pulse-item': firstOpenUpdates }"
                                color="#ffcdd2"
                                text-color="red"
                                label
                                small
                              >
                                NEW
                              </v-chip>
                            </div>
                            <div
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  !product.additional_blocks_url
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Вам доступна:&nbsp;
                            </div>
                            <v-btn
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  !product.additional_blocks_url
                              "
                              href="/#/consultations"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              консультация
                            </v-btn>
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-if="product.upgrade_url"
                                  :disabled="loading2"
                                  :loading="loading2"
                                  @click="checkPurchase()"
                                  v-bind:class="{
                                    visible: $store.state.Purchases.id === i
                                  }"
                                  color="primary warning accent-4"
                                  class="margin-btm-10 novisible"
                                  small
                                >
                                  проверить статус
                                </v-btn>
                              </template>
                              <span>Проверить статус покупки</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        class="padding-top-35"
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                      >
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="product.view_url"
                              v-bind:class="{
                                btn_visible:
                                  product.view_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                            </v-btn>
                          </template>
                          <span>Посмотреть</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="product.pdf_url"
                              v-bind:class="{
                                btn_visible:
                                  product.pdf_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                            >
                              <v-icon dark>mdi-file-pdf-box</v-icon
                              >&nbsp;Скачать
                            </v-btn>
                          </template>
                          <span>Скачать PDF</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                getBodygraphFromReports(i, 1, 'user_products')
                              "
                              v-bind:class="{
                                btn_visible:
                                  product.view_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                              dark
                              color="orange darken-1"
                              style="justify-content:space-between"
                            >
                              <v-icon dark>
                                mdi-file-table-box-multiple-outline</v-icon
                              >&nbsp;Карта&nbsp;&nbsp;&nbsp;
                            </v-btn>
                          </template>
                          <span>Посмотреть карту</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              v-bind:class="{
                                btn_visible:
                                  !product.is_used ||
                                  !product.first_name ||
                                  !product.city
                              }"
                              @click="payReportDialog(product, 'products')"
                              class="mx-2"
                              small
                              color="primary warning accent-4 btn_novisible"
                            >
                              <span class="custom-blink-2">сделать расчет</span>
                            </v-btn>
                          </template>
                          <span>сделать расчет карты</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row class="no-desktop">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-bottom-0"
                      >
                        <div class="product-payed-title mob-padding-bottom-10">
                          <strong>{{ product.product.part_title }}</strong>
                        </div>
                        <div class="mob-display-flex">
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="product.first_name">
                                <v-icon>mdi-account</v-icon>
                                <span>{{ product.first_name }}</span>
                              </li>
                              <li v-if="product.email">
                                <v-icon>mdi-at</v-icon>
                                <span>{{ product.email }}</span>
                              </li>
                              <li v-if="product.birthday">
                                <v-icon>mdi-calendar-today</v-icon>
                                <span>
                                  {{ dateFormatting(product.birthday) }}
                                </span>
                              </li>
                              <li v-if="product.city">
                                <v-icon>mdi-city-variant</v-icon>
                                <span>{{ product.city.title }}</span>
                              </li>
                            </ul>
                          </v-col>
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="product.chart_type">
                                <v-icon>mdi-account-box-outline</v-icon>
                                <span>{{ product.chart_type }}</span>
                              </li>
                              <li v-if="product.chart_profile">
                                <v-icon>mdi-account-circle</v-icon>
                                <span>
                                  Профиль {{ product.chart_profile }}
                                </span>
                              </li>
                            </ul>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div class="col-pd-lt-10">
                          <div style="display:flex;align-items:baseline">
                            <div
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Открыт доступ:&nbsp;
                            </div>
                            <div class="d-flex">
                              <v-btn
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:href="
                                  product.upgrade_url +
                                    '~rf' +
                                    User.ext_reference_code
                                "
                                @click="initPurchase(i)"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                {{ product.product_upgrade_title }}
                              </v-btn>
                              <div
                                v-if="
                                  product.upgrade_url &&
                                    product.is_seen &&
                                    product.product.id !== 3
                                "
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                              >
                                <v-icon
                                  @click="
                                    helPayDialogAdditionSection(
                                      product.upgrade_html_tooltip,
                                      product.upgrade_url +
                                        '~rf' +
                                        User.ext_reference_code
                                    )
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                            <div
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  product.additional_blocks_url
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Доступны:&nbsp;
                            </div>
                            <v-btn
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  product.additional_blocks_url
                              "
                              @click="openUpdatesButton"
                              :href="product.additional_blocks_url"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              {{
                                product.additional_blocks_title
                                  ? product.additional_blocks_title
                                  : "обновления"
                              }}
                            </v-btn>
                            <span
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  product.additional_blocks_url
                              "
                            >
                              &nbsp;&nbsp;
                            </span>
                            <v-chip
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  product.additional_blocks_url
                              "
                              :class="{ 'pulse-item': firstOpenUpdates }"
                              color="#ffcdd2"
                              text-color="red"
                              label
                              small
                            >
                              NEW
                            </v-chip>
                            <div
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  !product.additional_blocks_url
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Вам доступна:&nbsp;
                            </div>
                            <v-btn
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7 &&
                                  !product.additional_blocks_url
                              "
                              href="/#/consultations"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              консультация
                            </v-btn>
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-if="product.upgrade_url"
                                  :disabled="loading2"
                                  :loading="loading2"
                                  @click="checkPurchase()"
                                  v-bind:class="{
                                    visible: $store.state.Purchases.id === i
                                  }"
                                  color="primary warning accent-4"
                                  class="margin-btm-10 novisible"
                                  small
                                >
                                  проверить статус
                                </v-btn>
                              </template>
                              <span>Проверить статус покупки</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-12-5-27-5"
                      >
                        <v-list class="padding-0">
                          <v-list-item class="padding-0">
                            <v-list-item-content
                              class="padding-top-0 padding-bottom-0"
                            >
                              <v-col
                                cols="4"
                                class="flex-center padding-0"
                                style="border-right: 1px solid #0000001f"
                              >
                                <v-btn
                                  @click="updateProducts"
                                  v-bind:href="product.pdf_url"
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.pdf_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="download-pdf2 my-prof-controls-flex btn_novisible"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-file-pdf-box</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Скачать
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col
                                cols="4"
                                class="flex-center padding-0"
                                style="border-right: 1px solid #0000001f"
                              >
                                <v-btn
                                  @click="
                                    getBodygraphFromReports(
                                      i,
                                      1,
                                      'user_products'
                                    )
                                  "
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.view_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="orange darken-1"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>
                                    mdi-file-table-box-multiple-outline</v-icon
                                  >
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Карта
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="4" class="flex-center padding-0">
                                <v-btn
                                  @click="updateProducts"
                                  v-bind:href="product.view_url"
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.view_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="primary"
                                  style="margin-right:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-eye</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Открыть
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="4" class="flex-center padding-0">
                                <v-btn
                                  v-bind:class="{
                                    btn_visible:
                                      !product.is_used ||
                                      !product.first_name ||
                                      !product.city
                                  }"
                                  @click="payReportDialog(product, 'products')"
                                  class="my-prof-controls-flex btn_novisible margin-top-25"
                                  color="warning accent-4"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark
                                    >mdi-checkbox-marked-circle-outline</v-icon
                                  >
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    расчет
                                  </span>
                                </v-btn>
                              </v-col>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </div>
              <div
                v-if="
                  profile.career_user_products &&
                    profile.career_user_products.length
                "
              >
                <div
                  v-for="(product, i) in profile.career_user_products"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="product.is_last_bought_product"
                    style="margin:0;flex-direction:column"
                  >
                    <div
                      class="display-0-5 text--primary demo-report-header mob-demo-report-header"
                    >
                      <div class="mob-display-block">
                        <span
                          class="margin-top-5 margin-btm-5 min-width-165-media375 mob-block"
                        >
                          Последняя покупка
                          <span class="sub-title">Бизнес расшифровка</span>
                        </span>
                      </div>
                    </div>
                    <v-row class="no-mobile">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="product.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ product.first_name }}</span>
                          </li>
                          <li v-if="product.email">
                            <v-icon>mdi-at</v-icon>
                            <span>{{ product.email }}</span>
                          </li>
                          <li v-if="product.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ dateFormatting(product.birthday) }}</span>
                          </li>
                          <li v-if="product.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ product.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="product.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ product.chart_type }}</span>
                          </li>
                          <li v-if="product.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ product.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="product-help no-mobile padding-top-35"
                      >
                        <div class="col-pd-lt-10">
                          <div class="product-payed-title">
                            <strong>{{ product.product.part_title }}</strong>
                          </div>
                          <div>
                            <div class="d-flex">
                              <v-btn
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:href="product.upgrade_url"
                                @click="initPurchase(i + 'cr')"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i + 'cr'
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                докупить
                              </v-btn>
                              <div
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i + 'cr'
                                }"
                              >
                                <v-icon
                                  @click="
                                    helPayDialog(product.upgrade_html_tooltip)
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                            <v-btn
                              v-if="product.upgrade_url"
                              :disabled="loading2"
                              :loading="loading2"
                              @click="checkPurchase()"
                              v-bind:class="{
                                visible: $store.state.Purchases.id === i + 'cr'
                              }"
                              color="primary warning accent-4"
                              class="margin-btm-10 novisible"
                              small
                            >
                              проверить статус
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        class="padding-top-35"
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                      >
                        <div class="no-mobile">
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                @click="updateProducts"
                                v-bind:href="product.view_url"
                                v-bind:class="{
                                  btn_visible:
                                    product.view_url &&
                                    product.first_name &&
                                    product.city
                                }"
                                target="_blank"
                                class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                                small
                                color="primary"
                              >
                                <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                              </v-btn>
                            </template>
                            <span>Посмотреть</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                @click="updateProducts"
                                v-bind:href="product.pdf_url"
                                v-bind:class="{
                                  btn_visible:
                                    product.pdf_url &&
                                    product.first_name &&
                                    product.city
                                }"
                                target="_blank"
                                class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                                small
                              >
                                <v-icon dark>mdi-file-pdf-box</v-icon
                                >&nbsp;Скачать
                              </v-btn>
                            </template>
                            <span>Скачать PDF</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                @click="
                                  getBodygraphFromReports(
                                    i,
                                    1,
                                    'career_user_products'
                                  )
                                "
                                v-bind:class="{
                                  btn_visible:
                                    product.view_url &&
                                    product.first_name &&
                                    product.city
                                }"
                                target="_blank"
                                class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                                small
                                dark
                                color="orange darken-1"
                                style="justify-content:space-between"
                              >
                                <v-icon dark>
                                  mdi-file-table-box-multiple-outline</v-icon
                                >&nbsp;Карта&nbsp;&nbsp;&nbsp;
                              </v-btn>
                            </template>
                            <span>Посмотреть карту</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                v-bind:class="{
                                  btn_visible:
                                    !product.is_used ||
                                    !product.first_name ||
                                    !product.city
                                }"
                                @click="payReportDialog(product, 'career')"
                                class="mx-2"
                                small
                                color="primary warning accent-4 btn_novisible"
                              >
                                <span class="custom-blink-2"
                                  >сделать расчет</span
                                >
                              </v-btn>
                            </template>
                            <span>сделать расчет карты</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="no-desktop">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-bottom-0"
                      >
                        <div class="product-payed-title mob-padding-bottom-10">
                          <strong>{{ product.product.part_title }}</strong>
                        </div>
                        <div class="mob-display-flex">
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="product.first_name">
                                <v-icon>mdi-account</v-icon>
                                <span>{{ product.first_name }}</span>
                              </li>
                              <li v-if="product.email">
                                <v-icon>mdi-at</v-icon>
                                <span>{{ product.email }}</span>
                              </li>
                              <li v-if="product.birthday">
                                <v-icon>mdi-calendar-today</v-icon>
                                <span>
                                  {{ dateFormatting(product.birthday) }}
                                </span>
                              </li>
                              <li v-if="product.city">
                                <v-icon>mdi-city-variant</v-icon>
                                <span>{{ product.city.title }}</span>
                              </li>
                            </ul>
                          </v-col>
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="product.chart_type">
                                <v-icon>mdi-account-box-outline</v-icon>
                                <span>{{ product.chart_type }}</span>
                              </li>
                              <li v-if="product.chart_profile">
                                <v-icon>mdi-account-circle</v-icon>
                                <span>
                                  Профиль {{ product.chart_profile }}
                                </span>
                              </li>
                            </ul>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div class="col-pd-lt-10">
                          <div>
                            <div class="d-flex">
                              <v-btn
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:href="product.upgrade_url"
                                @click="initPurchase(i + 'cr')"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i + 'cr'
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                докупить
                              </v-btn>
                              <div
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i + 'cr'
                                }"
                              >
                                <v-icon
                                  @click="
                                    helPayDialog(product.upgrade_html_tooltip)
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                            <v-btn
                              v-if="product.upgrade_url"
                              :disabled="loading2"
                              :loading="loading2"
                              @click="checkPurchase()"
                              v-bind:class="{
                                visible: $store.state.Purchases.id === i + 'cr'
                              }"
                              color="primary warning accent-4"
                              class="margin-btm-10 novisible"
                              small
                            >
                              проверить статус
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-12-5-27-5"
                      >
                        <v-list class="padding-0">
                          <v-list-item class="padding-0">
                            <v-list-item-content
                              class="padding-top-0 padding-bottom-0"
                            >
                              <v-col
                                cols="4"
                                class="flex-center padding-0"
                                style="border-right: 1px solid #0000001f"
                              >
                                <v-btn
                                  @click="updateProducts"
                                  v-bind:href="product.pdf_url"
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.pdf_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="download-pdf2 my-prof-controls-flex btn_novisible"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-file-pdf-box</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Скачать
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col
                                cols="4"
                                class="flex-center padding-0"
                                style="border-right: 1px solid #0000001f"
                              >
                                <v-btn
                                  @click="
                                    getBodygraphFromReports(
                                      i,
                                      1,
                                      'career_user_products'
                                    )
                                  "
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.view_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="orange darken-1"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>
                                    mdi-file-table-box-multiple-outline</v-icon
                                  >
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Карта
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="4" class="flex-center padding-0">
                                <v-btn
                                  @click="updateProducts"
                                  v-bind:href="product.view_url"
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.view_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="primary"
                                  style="margin-right:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-eye</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Открыть
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="4" class="flex-center padding-0">
                                <v-btn
                                  v-bind:class="{
                                    btn_visible:
                                      !product.is_used ||
                                      !product.first_name ||
                                      !product.city
                                  }"
                                  @click="payReportDialog(product, 'career')"
                                  class="my-prof-controls-flex btn_novisible margin-top-25"
                                  color="warning accent-4"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark
                                    >mdi-checkbox-marked-circle-outline</v-icon
                                  >
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    расчет
                                  </span>
                                </v-btn>
                              </v-col>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </div>
              <div
                v-if="
                  profile.child_user_products &&
                    profile.child_user_products.length
                "
              >
                <div
                  v-for="(product, i) in profile.child_user_products"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="product.is_last_bought_product"
                    style="margin:0;flex-direction:column"
                  >
                    <div
                      class="display-0-5 text--primary demo-report-header mob-demo-report-header"
                    >
                      <div class="mob-display-block">
                        <span
                          class="margin-top-5 margin-btm-5 min-width-165-media375 mob-block"
                        >
                          Последняя покупка
                          <span class="sub-title">Детская расшифровка</span>
                        </span>
                      </div>
                    </div>
                    <v-row class="no-mobile">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="product.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ product.first_name }}</span>
                          </li>
                          <li v-if="product.email">
                            <v-icon>mdi-at</v-icon>
                            <span>{{ product.email }}</span>
                          </li>
                          <li v-if="product.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ dateFormatting(product.birthday) }}</span>
                          </li>
                          <li v-if="product.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ product.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="product.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ product.chart_type }}</span>
                          </li>
                          <li v-if="product.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ product.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="product-help padding-top-35"
                      >
                        <div class="col-pd-lt-10">
                          <div class="product-payed-title">
                            <span class="d-sm-none status-report-title"
                              >Статус расчета:</span
                            >
                            <strong>{{ product.product.part_title }}</strong>
                          </div>
                          <div>
                            <div
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                              class="display-0-24"
                            >
                              Открыт доступ:&nbsp;
                            </div>
                            <div class="d-flex">
                              <v-btn
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:href="
                                  product.upgrade_url +
                                    '~rf' +
                                    User.ext_reference_code
                                "
                                @click="initPurchase(i + 'ch')"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i + 'ch'
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                {{ product.product_upgrade_title }}
                              </v-btn>
                              <div
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i + 'ch'
                                }"
                              >
                                <v-icon
                                  @click="
                                    helPayDialogChild(
                                      product.upgrade_html_tooltip,
                                      product.upgrade_url +
                                        '~rf' +
                                        User.ext_reference_code
                                    )
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                            <div
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                              class="display-0-24"
                            >
                              Вам доступна:&nbsp;
                            </div>
                            <v-btn
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7
                              "
                              href="/#/consultations"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              консультация
                            </v-btn>
                            <v-btn
                              v-if="product.upgrade_url"
                              :disabled="loading2"
                              :loading="loading2"
                              @click="checkPurchase()"
                              v-bind:class="{
                                visible: $store.state.Purchases.id === i + 'ch'
                              }"
                              color="primary warning accent-4"
                              class="margin-btm-10 novisible"
                              small
                            >
                              проверить статус
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="product.view_url"
                              v-bind:class="{
                                btn_visible:
                                  product.view_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                            </v-btn>
                          </template>
                          <span>Посмотреть</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="product.pdf_url"
                              v-bind:class="{
                                btn_visible:
                                  product.pdf_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                            >
                              <v-icon dark>mdi-file-pdf-box</v-icon
                              >&nbsp;Скачать
                            </v-btn>
                          </template>
                          <span>Скачать PDF</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                getBodygraphFromReports(
                                  i,
                                  1,
                                  'child_user_products'
                                )
                              "
                              v-bind:class="{
                                btn_visible:
                                  product.view_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                              dark
                              color="orange darken-1"
                              style="justify-content:space-between"
                            >
                              <v-icon dark>
                                mdi-file-table-box-multiple-outline</v-icon
                              >&nbsp;Карта&nbsp;&nbsp;&nbsp;
                            </v-btn>
                          </template>
                          <span>Посмотреть карту</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              v-bind:class="{
                                btn_visible:
                                  !product.is_used ||
                                  !product.first_name ||
                                  !product.city
                              }"
                              @click="payReportDialog(product, 'products')"
                              class="mx-2"
                              small
                              color="primary warning accent-4 btn_novisible"
                            >
                              <span class="custom-blink-2">сделать расчет</span>
                            </v-btn>
                          </template>
                          <span>сделать расчет карты</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row class="no-desktop">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-bottom-0"
                      >
                        <div class="product-payed-title mob-padding-bottom-10">
                          <strong>{{ product.product.part_title }}</strong>
                        </div>
                        <div class="mob-display-flex">
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="product.first_name">
                                <v-icon>mdi-account</v-icon>
                                <span>{{ product.first_name }}</span>
                              </li>
                              <li v-if="product.email">
                                <v-icon>mdi-at</v-icon>
                                <span>{{ product.email }}</span>
                              </li>
                              <li v-if="product.birthday">
                                <v-icon>mdi-calendar-today</v-icon>
                                <span>
                                  {{ dateFormatting(product.birthday) }}
                                </span>
                              </li>
                              <li v-if="product.city">
                                <v-icon>mdi-city-variant</v-icon>
                                <span>{{ product.city.title }}</span>
                              </li>
                            </ul>
                          </v-col>
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="product.chart_type">
                                <v-icon>mdi-account-box-outline</v-icon>
                                <span>{{ product.chart_type }}</span>
                              </li>
                              <li v-if="product.chart_profile">
                                <v-icon>mdi-account-circle</v-icon>
                                <span>
                                  Профиль {{ product.chart_profile }}
                                </span>
                              </li>
                            </ul>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div class="col-pd-lt-10">
                          <div style="display:flex;align-items:baseline">
                            <div
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                              class="display-0-24"
                            >
                              Открыт доступ:&nbsp;
                            </div>
                            <div class="d-flex">
                              <v-btn
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:href="
                                  product.upgrade_url +
                                    '~rf' +
                                    User.ext_reference_code
                                "
                                @click="initPurchase(i + 'ch')"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i + 'ch'
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                {{ product.product_upgrade_title }}
                              </v-btn>
                              <div
                                v-if="product.upgrade_url && product.is_seen"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i + 'ch'
                                }"
                              >
                                <v-icon
                                  @click="
                                    helPayDialogChild(
                                      product.upgrade_html_tooltip,
                                      product.upgrade_url +
                                        '~rf' +
                                        User.ext_reference_code
                                    )
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                            <div
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                              class="display-0-24"
                            >
                              Вам доступна:&nbsp;
                            </div>
                            <v-btn
                              v-if="
                                !product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 7
                              "
                              href="/#/consultations"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              консультация
                            </v-btn>
                            <v-btn
                              v-if="product.upgrade_url"
                              :disabled="loading2"
                              :loading="loading2"
                              @click="checkPurchase()"
                              v-bind:class="{
                                visible: $store.state.Purchases.id === i + 'ch'
                              }"
                              color="primary warning accent-4"
                              class="margin-btm-10 novisible"
                              small
                            >
                              проверить статус
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-12-5-27-5"
                      >
                        <v-list class="padding-0">
                          <v-list-item class="padding-0">
                            <v-list-item-content
                              class="padding-top-0 padding-bottom-0"
                            >
                              <v-col
                                cols="4"
                                class="flex-center padding-0"
                                style="border-right: 1px solid #0000001f"
                              >
                                <v-btn
                                  @click="updateProducts"
                                  v-bind:href="product.pdf_url"
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.pdf_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="download-pdf2 my-prof-controls-flex btn_novisible"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-file-pdf-box</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Скачать
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col
                                cols="4"
                                class="flex-center padding-0"
                                style="border-right: 1px solid #0000001f"
                              >
                                <v-btn
                                  @click="
                                    getBodygraphFromReports(
                                      i,
                                      1,
                                      'child_user_products'
                                    )
                                  "
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.view_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="orange darken-1"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>
                                    mdi-file-table-box-multiple-outline</v-icon
                                  >
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Карта
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="4" class="flex-center padding-0">
                                <v-btn
                                  @click="updateProducts"
                                  v-bind:href="product.view_url"
                                  v-bind:class="{
                                    btn_visible:
                                      product.is_used &&
                                      product.view_url &&
                                      product.first_name &&
                                      product.city
                                  }"
                                  target="_blank"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="primary"
                                  style="margin-right:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-eye</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Открыть
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="4" class="flex-center padding-0">
                                <v-btn
                                  v-bind:class="{
                                    btn_visible:
                                      !product.is_used ||
                                      !product.first_name ||
                                      !product.city
                                  }"
                                  @click="payReportDialog(product, 'products')"
                                  class="my-prof-controls-flex btn_novisible margin-top-25"
                                  color="primary warning accent-4"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark
                                    >mdi-checkbox-marked-circle-outline</v-icon
                                  >
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    расчет
                                  </span>
                                </v-btn>
                              </v-col>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </div>
              <div
                v-if="
                  profile.composite_user_products &&
                    profile.composite_user_products.length
                "
              >
                <div
                  v-for="(composite, i) in profile.composite_user_products"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="composite.is_last_bought_product && composite.is_used"
                    style="margin:0;flex-direction:column"
                  >
                    <div
                      class="display-0-5 text--primary demo-report-header mob-demo-report-header"
                    >
                      <div class="mob-display-block">
                        <span
                          class="margin-top-5 margin-btm-5 min-width-165-media375 mob-block"
                        >
                          Последняя покупка
                          <span class="sub-title">Совместимость</span>
                        </span>
                      </div>
                    </div>
                    <v-row class="no-mobile">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style min-height-75-nomob">
                          <li v-if="composite.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ composite.first_name }}</span>
                          </li>
                          <li v-if="composite.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{
                              dateFormatting(composite.birthday)
                            }}</span>
                          </li>
                          <li v-if="composite.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ composite.city.title }}</span>
                          </li>
                        </ul>
                        <ul class="ul-no-li-style">
                          <li class="composite-plus-18">
                            &nbsp;
                          </li>
                          <li v-if="composite.first_name2">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ composite.first_name2 }}</span>
                          </li>
                          <li v-if="composite.birthday2">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{
                              dateFormatting(composite.birthday2)
                            }}</span>
                          </li>
                          <li v-if="composite.city2">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ composite.city2.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style min-height-75-nomob">
                          <li class="composite-plus-18">
                            &nbsp;
                          </li>
                          <li v-if="composite.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ composite.chart_type }}</span>
                          </li>
                          <li v-if="composite.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ composite.chart_profile }}</span>
                          </li>
                        </ul>
                        <ul class="ul-no-li-style">
                          <li class="composite-plus-18">
                            &nbsp;
                          </li>
                          <li v-if="composite.chart_type2">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ composite.chart_type2 }}</span>
                          </li>
                          <li v-if="composite.chart_profile2">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ composite.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="product-help  padding-top-35"
                      >
                        <div class="col-pd-lt-10">
                          <div class="product-payed-title">
                            <strong>{{ composite.product.part_title }}</strong>
                          </div>
                          <div>
                            <div class="d-flex">
                              <v-btn
                                v-if="
                                  composite.upgrade_url && composite.is_seen
                                "
                                v-bind:href="composite.upgrade_url"
                                @click="initPurchase(i)"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                докупить
                              </v-btn>
                              <div
                                v-if="
                                  composite.upgrade_url && composite.is_seen
                                "
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                              >
                                <v-icon
                                  @click="
                                    helPayDialog(composite.upgrade_html_tooltip)
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                            <div
                              v-if="
                                !composite.upgrade_url &&
                                  composite.is_seen &&
                                  composite.product.id === 7 &&
                                  !composite.additional_blocks_url
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Вам доступна:
                            </div>
                            <v-btn
                              v-if="
                                !composite.upgrade_url &&
                                  composite.is_seen &&
                                  composite.product.id === 7 &&
                                  !composite.additional_blocks_url
                              "
                              href="/#/consultations"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              консультация
                            </v-btn>
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-if="composite.upgrade_url"
                                  :disabled="loading2"
                                  :loading="loading2"
                                  @click="checkPurchase()"
                                  v-bind:class="{
                                    visible: $store.state.Purchases.id === i
                                  }"
                                  color="primary warning accent-4"
                                  class="margin-btm-10 novisible"
                                  small
                                >
                                  проверить статус
                                </v-btn>
                              </template>
                              <span>Проверить статус покупки</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-bottom-25 padding-top-35"
                      >
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="composite.view_url"
                              v-bind:class="{
                                btn_visible:
                                  composite.view_url &&
                                  composite.first_name &&
                                  composite.city
                              }"
                              target="_blank"
                              class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                            </v-btn>
                          </template>
                          <span>Посмотреть</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="composite.pdf_url"
                              v-bind:class="{
                                btn_visible:
                                  composite.pdf_url &&
                                  composite.first_name &&
                                  composite.city
                              }"
                              target="_blank"
                              class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                            >
                              <v-icon dark>mdi-file-pdf-box</v-icon
                              >&nbsp;Скачать
                            </v-btn>
                          </template>
                          <span>Скачать PDF</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              v-bind:class="{
                                btn_visible:
                                  !composite.is_used ||
                                  !composite.first_name ||
                                  !composite.city
                              }"
                              @click="payCompositeDialog(composite)"
                              class="mx-2"
                              small
                              color="primary warning accent-4 btn_novisible"
                            >
                              <span class="custom-blink-2">сделать расчет</span>
                            </v-btn>
                          </template>
                          <span>сделать расчет совместимости</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row class="no-desktop">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-bottom-0"
                      >
                        <div class="product-payed-title mob-padding-bottom-10">
                          <strong>{{ composite.product.part_title }}</strong>
                        </div>
                        <div class="mob-display-flex">
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="composite.first_name">
                                <v-icon>mdi-account</v-icon>
                                <span>{{ composite.first_name }}</span>
                              </li>
                              <li v-if="composite.birthday">
                                <v-icon>mdi-calendar-today</v-icon>
                                <span>{{
                                  dateFormatting(composite.birthday)
                                }}</span>
                              </li>
                              <li v-if="composite.city">
                                <v-icon>mdi-city-variant</v-icon>
                                <span>{{ composite.city.title }}</span>
                              </li>
                            </ul>
                            <ul class="ul-no-li-style">
                              <li class="composite-plus-18">
                                &nbsp;
                              </li>
                              <li v-if="composite.first_name2">
                                <v-icon>mdi-account</v-icon>
                                <span>{{ composite.first_name2 }}</span>
                              </li>
                              <li v-if="composite.birthday2">
                                <v-icon>mdi-calendar-today</v-icon>
                                <span>{{
                                  dateFormatting(composite.birthday2)
                                }}</span>
                              </li>
                              <li v-if="composite.city2">
                                <v-icon>mdi-city-variant</v-icon>
                                <span>{{ composite.city2.title }}</span>
                              </li>
                            </ul>
                          </v-col>
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li class="composite-plus-18">&nbsp;</li>
                              <li v-if="composite.chart_type">
                                <v-icon>mdi-account-box-outline</v-icon>
                                <span>{{ composite.chart_type }}</span>
                              </li>
                              <li v-if="composite.chart_profile">
                                <v-icon>mdi-account-circle</v-icon>
                                <span>
                                  Профиль {{ composite.chart_profile }}
                                </span>
                              </li>
                            </ul>
                            <ul class="ul-no-li-style">
                              <li class="composite-plus-18">
                                &nbsp;
                              </li>
                              <li v-if="composite.chart_type2">
                                <v-icon>mdi-account-box-outline</v-icon>
                                <span>{{ composite.chart_type2 }}</span>
                              </li>
                              <li v-if="composite.chart_profile2">
                                <v-icon>mdi-account-circle</v-icon>
                                <span>
                                  Профиль {{ composite.chart_profile }}
                                </span>
                              </li>
                            </ul>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div class="col-pd-lt-10">
                          <div style="display:flex;align-items:baseline">
                            <div class="d-flex">
                              <v-btn
                                v-if="
                                  composite.upgrade_url && composite.is_seen
                                "
                                v-bind:href="composite.upgrade_url"
                                @click="initPurchase(i)"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                докупить
                              </v-btn>
                              <div
                                v-if="
                                  composite.upgrade_url && composite.is_seen
                                "
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                              >
                                <v-icon
                                  @click="
                                    helPayDialog(composite.upgrade_html_tooltip)
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                            <div
                              v-if="
                                !composite.upgrade_url &&
                                  composite.is_seen &&
                                  composite.product.id === 7 &&
                                  composite.additional_blocks_url
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Вам доступны:&nbsp;
                            </div>
                            <div
                              v-if="
                                !composite.upgrade_url &&
                                  composite.is_seen &&
                                  composite.product.id === 7 &&
                                  composite.additional_blocks_url
                              "
                              style="display:flex;align-items:baseline"
                            >
                              <v-btn
                                @click="openCompUpdatesButton"
                                :href="composite.additional_blocks_url"
                                v-bind:class="{
                                  none: $store.state.Purchases.id === i
                                }"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                {{
                                  composite.additional_blocks_title
                                    ? composite.additional_blocks_title
                                    : "обновления"
                                }}
                              </v-btn>
                              <span
                                v-if="
                                  !composite.upgrade_url &&
                                    composite.is_seen &&
                                    composite.product.id === 7 &&
                                    composite.additional_blocks_url
                                "
                              >
                                &nbsp;&nbsp;
                              </span>
                              <v-chip
                                :class="{ 'pulse-item': firstOpenCompUpdates }"
                                color="#ffcdd2"
                                text-color="red"
                                label
                                small
                              >
                                NEW
                              </v-chip>
                            </div>
                            <div
                              v-if="
                                !composite.upgrade_url &&
                                  composite.is_seen &&
                                  composite.product.id === 7 &&
                                  !composite.additional_blocks_url
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              class="display-0-24"
                            >
                              Вам доступна:
                            </div>
                            <v-btn
                              v-if="
                                !composite.upgrade_url &&
                                  composite.is_seen &&
                                  composite.product.id === 7 &&
                                  !composite.additional_blocks_url
                              "
                              href="/#/consultations"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              консультация
                            </v-btn>
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-if="composite.upgrade_url"
                                  :disabled="loading2"
                                  :loading="loading2"
                                  @click="checkPurchase()"
                                  v-bind:class="{
                                    visible: $store.state.Purchases.id === i
                                  }"
                                  color="primary warning accent-4"
                                  class="margin-btm-10 novisible"
                                  small
                                >
                                  проверить статус
                                </v-btn>
                              </template>
                              <span>Проверить статус покупки</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-12-5-27-5"
                      >
                        <v-list class="padding-0">
                          <v-list-item class="padding-0">
                            <v-list-item-content
                              class="padding-top-0 padding-bottom-0"
                            >
                              <v-col
                                cols="6"
                                class="flex-center padding-0"
                                style="border-right: 1px solid #0000001f"
                              >
                                <v-btn
                                  @click="updateProducts"
                                  v-bind:href="composite.pdf_url"
                                  v-bind:class="{
                                    btn_visible:
                                      composite.is_used ||
                                      composite.first_name ||
                                      composite.city
                                  }"
                                  target="_blank"
                                  class="download-pdf2 my-prof-controls-flex btn_novisible"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-file-pdf-box</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Скачать
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="6" class="flex-center padding-0">
                                <v-btn
                                  @click="updateProducts"
                                  v-bind:href="composite.view_url"
                                  v-bind:class="{
                                    btn_visible:
                                      composite.is_used ||
                                      composite.first_name ||
                                      composite.city
                                  }"
                                  target="_blank"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="primary"
                                  style="margin-right:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-eye</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Открыть
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col
                                cols="4"
                                class="flex-center padding-0"
                                style="border-left: 1px solid #0000001f"
                              >
                                <v-btn
                                  v-bind:class="{
                                    btn_visible:
                                      !composite.is_used ||
                                      !composite.first_name ||
                                      !composite.city
                                  }"
                                  @click="payCompositeDialog(composite)"
                                  class="my-prof-controls-flex btn_novisible margin-top-25"
                                  color="warning accent-4"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark
                                    >mdi-checkbox-marked-circle-outline</v-icon
                                  >
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    расчет
                                  </span>
                                </v-btn>
                              </v-col>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </div>
              <div v-if="profile.pro_reports && profile.pro_reports.length">
                <div
                  v-for="(product, i) in profile.pro_reports"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="product.is_last_bought_product"
                    style="margin:0;flex-direction:column"
                  >
                    <div
                      class="display-0-5 text--primary demo-report-header mob-demo-report-header"
                    >
                      <div class="mob-display-block">
                        <span
                          class="margin-top-5 margin-btm-5 min-width-165-media375 mob-block"
                        >
                          Последняя покупка
                          <span class="sub-title">Pro-подсказки</span>
                        </span>
                      </div>
                    </div>
                    <v-row class="no-mobile">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="product.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ product.first_name }}</span>
                          </li>
                          <li v-if="product.email">
                            <v-icon>mdi-at</v-icon>
                            <span>{{ product.email }}</span>
                          </li>
                          <li v-if="product.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ dateFormatting(product.birthday) }}</span>
                          </li>
                          <li v-if="product.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ product.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="product.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ product.chart_type }}</span>
                          </li>
                          <li v-if="product.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ product.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="product-help padding-top-35"
                      >
                        <div class="col-pd-lt-10">
                          <div class="product-payed-title">
                            <strong>PRO-подсказки</strong>
                          </div>
                          <div
                            v-if="!product.has_user_product"
                            class="divider-pro"
                          >
                            Заказать:
                          </div>
                          <div v-else class="divider-pro">
                            Куплен PDF-отчет
                          </div>
                          <div class="d-flex">
                            <div>
                              <v-btn
                                v-if="!product.has_user_product"
                                @click="buy(product.id)"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                PDF-отчет
                              </v-btn>
                            </div>
                            <div v-if="!product.has_user_product">
                              <v-icon
                                @click="
                                  helPayDialog2('dialog_txt_profile_proreport')
                                "
                                class="product-help-icon"
                                >mdi-help-circle-outline</v-icon
                              >
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="padding-top-35"
                      >
                        <div class="no-mobile">
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                @click="getBodygraphFromReports(i, 1, 'pro')"
                                v-bind:class="{
                                  btn_visible: product.is_used
                                }"
                                class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                                small
                                color="primary"
                              >
                                <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                              </v-btn>
                            </template>
                            <span>Посмотреть</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                v-bind:class="{ btn_visible: !product.is_used }"
                                @click="payReportDialog(product, 'pro')"
                                class="mx-2"
                                small
                                color="primary warning accent-4 btn_novisible"
                              >
                                <span class="custom-blink-2"
                                  >сделать расчет</span
                                >
                              </v-btn>
                            </template>
                            <span>сделать расчет карты</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="no-desktop">
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-bottom-0"
                      >
                        <div class="product-payed-title mob-padding-bottom-10">
                          <strong>PRO-подсказки</strong>
                        </div>
                        <div class="mob-display-flex">
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="product.first_name">
                                <v-icon>mdi-account</v-icon>
                                <span>{{ product.first_name }}</span>
                              </li>
                              <li v-if="product.email">
                                <v-icon>mdi-at</v-icon>
                                <span>{{ product.email }}</span>
                              </li>
                              <li v-if="product.birthday">
                                <v-icon>mdi-calendar-today</v-icon>
                                <span>
                                  {{ dateFormatting(product.birthday) }}
                                </span>
                              </li>
                              <li v-if="product.city">
                                <v-icon>mdi-city-variant</v-icon>
                                <span>{{ product.city.title }}</span>
                              </li>
                            </ul>
                          </v-col>
                          <v-col cols="6" sm="6" class="nopadding">
                            <ul class="ul-no-li-style">
                              <li v-if="product.chart_type">
                                <v-icon>mdi-account-box-outline</v-icon>
                                <span>{{ product.chart_type }}</span>
                              </li>
                              <li v-if="product.chart_profile">
                                <v-icon>mdi-account-circle</v-icon>
                                <span>
                                  Профиль {{ product.chart_profile }}
                                </span>
                              </li>
                            </ul>
                          </v-col>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                        <div class="col-pd-lt-10">
                          <div style="display:flex;align-items:baseline">
                            <div
                              v-if="!product.has_user_product"
                              class="display-0-24"
                            >
                              Заказать:&nbsp;
                            </div>
                            <div v-else class="display-0-24">
                              Куплен PDF-отчет&nbsp;
                            </div>
                            <div class="d-flex">
                              <div>
                                <v-btn
                                  v-if="!product.has_user_product"
                                  @click="buy(product.id)"
                                  color="accent-4"
                                  class="margin-btm-10 success-custom xs-small"
                                  small
                                >
                                  PDF-отчет
                                </v-btn>
                              </div>
                              <div v-if="!product.has_user_product">
                                <v-icon
                                  @click="
                                    helPayDialog2(
                                      'dialog_txt_profile_proreport'
                                    )
                                  "
                                  class="product-help-icon"
                                  >mdi-help-circle-outline</v-icon
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        lg="3"
                        xl="3"
                        class="mob-padding-12-5-27-5"
                      >
                        <v-list class="padding-0">
                          <v-list-item class="padding-0">
                            <v-list-item-content
                              class="padding-top-0 padding-bottom-0"
                            >
                              <v-col cols="12" class="flex-center padding-0">
                                <v-btn
                                  @click="getBodygraphFromReports(i, 1, 'pro')"
                                  v-bind:class="{
                                    btn_visible: product.is_used
                                  }"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="primary"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-eye</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Открыть
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" class="flex-center padding-0">
                                <v-btn
                                  v-bind:class="{
                                    btn_visible: !product.is_used
                                  }"
                                  @click="payReportDialog(product, 'pro')"
                                  class="my-prof-controls-flex btn_novisible"
                                  color="warning accent-4"
                                  style="margin-left:-16px"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark
                                    >mdi-checkbox-marked-circle-outline</v-icon
                                  >
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    расчет
                                  </span>
                                </v-btn>
                              </v-col>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div class="display-0-5-left text--primary demo-report-header">
                <div class="mob-display-block">
                  <span
                    :class="{ 'mob-margin-top-15': !profile.user_products }"
                    class="margin-top-5 margin-rt-40-nomob min-width-165-media375 mob-block"
                  >
                    Расшифровки:
                  </span>
                </div>
              </div>
              <div
                v-if="profile.user_products && profile.user_products.length > 7"
                class="reports-search-input"
              >
                <v-text-field
                  v-model="searchUserProducts"
                  @input="
                    searchFromReports(searchUserProducts, 'user_products')
                  "
                  label="Поиск по имени"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <div v-if="profile.user_products && profile.user_products.length">
                <div
                  v-for="(product, i) in userProducts"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="product.is_used && !product.is_last_bought_product"
                    class="no-mobile"
                  >
                    <v-col
                      v-if="product.was_upgraded_recently"
                      cols="12"
                      class="text-center padding-bottom-0 margin-bottom--20 mob-margin-bottom--30"
                    >
                      <v-chip
                        class="ma-2"
                        color="#ffcdd2"
                        label
                        text-color="red"
                      >
                        <v-icon start>
                          mdi-file-table-box-multiple-outline
                        </v-icon>
                        &nbsp;&nbsp;Ваша расшифровка была обновлена
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="product.first_name">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ product.first_name }}</span>
                        </li>
                        <li v-if="product.email">
                          <v-icon>mdi-at</v-icon>
                          <span>{{ product.email }}</span>
                        </li>
                        <li v-if="product.birthday">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(product.birthday) }}</span>
                        </li>
                        <li v-if="product.city">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ product.city.title }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="product.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ product.chart_type }}</span>
                        </li>
                        <li v-if="product.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ product.chart_profile }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="product-help padding-top-35"
                    >
                      <div class="col-pd-lt-10">
                        <div class="product-payed-title">
                          <strong>{{ product.product.part_title }}</strong>
                        </div>
                        <div>
                          <div
                            v-if="product.upgrade_url && product.is_seen"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Открыт доступ:&nbsp;
                          </div>
                          <div class="d-flex">
                            <v-btn
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:href="
                                product.upgrade_url +
                                  '~rf' +
                                  User.ext_reference_code
                              "
                              @click="initPurchase(i)"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              {{ product.product_upgrade_title }}
                            </v-btn>
                            <div
                              v-if="
                                product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 3
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                            >
                              <v-icon
                                @click="
                                  helPayDialogAdditionSection(
                                    product.upgrade_html_tooltip,
                                    product.upgrade_url +
                                      '~rf' +
                                      User.ext_reference_code
                                  )
                                "
                                class="product-help-icon"
                                >mdi-help-circle-outline</v-icon
                              >
                            </div>
                          </div>
                          <div
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                product.additional_blocks_url
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Вам доступны:&nbsp;
                          </div>
                          <div
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                product.additional_blocks_url
                            "
                            style="display:flex;align-items:baseline"
                          >
                            <v-btn
                              @click="openUpdatesButton"
                              :href="product.additional_blocks_url"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              {{
                                product.additional_blocks_title
                                  ? product.additional_blocks_title
                                  : "обновления"
                              }}
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-chip
                              :class="{ 'pulse-item': firstOpenUpdates }"
                              color="#ffcdd2"
                              text-color="red"
                              label
                              small
                            >
                              NEW
                            </v-chip>
                          </div>
                          <div
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                !product.additional_blocks_url
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Вам доступна:&nbsp;
                          </div>
                          <v-btn
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                !product.additional_blocks_url
                            "
                            href="/#/consultations"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            консультация
                          </v-btn>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                v-if="product.upgrade_url"
                                :disabled="loading2"
                                :loading="loading2"
                                @click="checkPurchase()"
                                v-bind:class="{
                                  visible: $store.state.Purchases.id === i
                                }"
                                color="primary warning accent-4"
                                class="margin-btm-10 novisible"
                                small
                              >
                                проверить статус
                              </v-btn>
                            </template>
                            <span>Проверить статус покупки</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="updateProducts"
                            v-bind:href="product.view_url"
                            v-bind:class="{
                              btn_visible:
                                product.view_url &&
                                product.first_name &&
                                product.city
                            }"
                            target="_blank"
                            class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                            color="primary"
                          >
                            <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                          </v-btn>
                        </template>
                        <span>Посмотреть</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="updateProducts"
                            v-bind:href="product.pdf_url"
                            v-bind:class="{
                              btn_visible:
                                product.pdf_url &&
                                product.first_name &&
                                product.city
                            }"
                            target="_blank"
                            class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                          >
                            <v-icon dark>mdi-file-pdf-box</v-icon>&nbsp;Скачать
                          </v-btn>
                        </template>
                        <span>Скачать PDF</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="
                              getBodygraphFromReports(i, 1, 'user_products')
                            "
                            v-bind:class="{
                              btn_visible:
                                product.view_url &&
                                product.first_name &&
                                product.city
                            }"
                            target="_blank"
                            class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                            dark
                            color="orange darken-1"
                            style="justify-content:space-between"
                          >
                            <v-icon dark>
                              mdi-file-table-box-multiple-outline</v-icon
                            >&nbsp;Карта&nbsp;&nbsp;&nbsp;
                          </v-btn>
                        </template>
                        <span>Посмотреть карту</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            v-bind:class="{
                              btn_visible:
                                !product.is_used ||
                                !product.first_name ||
                                !product.city
                            }"
                            @click="payReportDialog(product, 'products')"
                            class="mx-2"
                            small
                            color="primary warning accent-4 btn_novisible"
                          >
                            <span class="custom-blink-2">сделать расчет</span>
                          </v-btn>
                        </template>
                        <span>сделать расчет карты</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="product.is_used && !product.is_last_bought_product"
                    class="no-desktop"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-0"
                    >
                      <div class="product-payed-title mob-padding-bottom-10">
                        <strong>{{ product.product.part_title }}</strong>
                      </div>
                      <div class="mob-display-flex">
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="product.first_name">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ product.first_name }}</span>
                            </li>
                            <li v-if="product.email">
                              <v-icon>mdi-at</v-icon>
                              <span>{{ product.email }}</span>
                            </li>
                            <li v-if="product.birthday">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>
                                {{ dateFormatting(product.birthday) }}
                              </span>
                            </li>
                            <li v-if="product.city">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ product.city.title }}</span>
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="product.chart_type">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ product.chart_type }}</span>
                            </li>
                            <li v-if="product.chart_profile">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>Профиль {{ product.chart_profile }}</span>
                            </li>
                          </ul>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div class="col-pd-lt-10">
                        <div style="display:flex;align-items:baseline">
                          <div
                            v-if="product.upgrade_url && product.is_seen"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Открыт доступ:&nbsp;
                          </div>
                          <div class="d-flex">
                            <v-btn
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:href="
                                product.upgrade_url +
                                  '~rf' +
                                  User.ext_reference_code
                              "
                              @click="initPurchase(i)"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              {{ product.product_upgrade_title }}
                            </v-btn>
                            <div
                              v-if="
                                product.upgrade_url &&
                                  product.is_seen &&
                                  product.product.id !== 3
                              "
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                            >
                              <v-icon
                                @click="
                                  helPayDialogAdditionSection(
                                    product.upgrade_html_tooltip,
                                    product.upgrade_url +
                                      '~rf' +
                                      User.ext_reference_code
                                  )
                                "
                                class="product-help-icon"
                                >mdi-help-circle-outline</v-icon
                              >
                            </div>
                          </div>
                          <div
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                product.additional_blocks_url
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Доступны:&nbsp;
                          </div>
                          <v-btn
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                product.additional_blocks_url
                            "
                            @click="openUpdatesButton"
                            :href="product.additional_blocks_url"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            target="_blank"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            {{
                              product.additional_blocks_title
                                ? product.additional_blocks_title
                                : "обновления"
                            }}
                          </v-btn>
                          <span
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                product.additional_blocks_url
                            "
                          >
                            &nbsp;&nbsp;
                          </span>
                          <v-chip
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                product.additional_blocks_url
                            "
                            :class="{ 'pulse-item': firstOpenUpdates }"
                            color="#ffcdd2"
                            text-color="red"
                            label
                            small
                          >
                            NEW
                          </v-chip>
                          <div
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                !product.additional_blocks_url
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Вам доступна:&nbsp;
                          </div>
                          <v-btn
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7 &&
                                !product.additional_blocks_url
                            "
                            href="/#/consultations"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            консультация
                          </v-btn>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                v-if="product.upgrade_url"
                                :disabled="loading2"
                                :loading="loading2"
                                @click="checkPurchase()"
                                v-bind:class="{
                                  visible: $store.state.Purchases.id === i
                                }"
                                color="primary warning accent-4"
                                class="margin-btm-10 novisible"
                                small
                              >
                                проверить статус
                              </v-btn>
                            </template>
                            <span>Проверить статус покупки</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-12-5-27-5"
                    >
                      <v-list class="padding-0">
                        <v-list-item class="padding-0">
                          <v-list-item-content
                            class="padding-top-0 padding-bottom-0"
                          >
                            <v-col
                              cols="4"
                              class="flex-center padding-0"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="updateProducts"
                                      v-bind:href="product.pdf_url"
                                      v-bind:class="{
                                        btn_visible:
                                          product.pdf_url &&
                                          product.first_name &&
                                          product.city
                                      }"
                                      target="_blank"
                                      class="download-pdf2 my-prof-controls-flex"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-file-pdf-box</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Скачать
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Скачать PDF</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="4"
                              class="flex-center padding-0"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="
                                      getBodygraphFromReports(
                                        i,
                                        1,
                                        'user_products'
                                      )
                                    "
                                    v-bind:class="{
                                      btn_visible:
                                        product.view_url &&
                                        product.first_name &&
                                        product.city
                                    }"
                                    target="_blank"
                                    class="my-prof-controls-flex"
                                    color="orange darken-1"
                                    fab
                                    small
                                    icon
                                    dark
                                  >
                                    <v-icon dark>
                                      mdi-file-table-box-multiple-outline</v-icon
                                    >
                                    <span
                                      class="d-flex"
                                      style="font-size:11px;margin-top:5px"
                                    >
                                      Карта
                                    </span>
                                  </v-btn>
                                </template>
                                <span>Посмотреть карту</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="updateProducts"
                                      v-bind:href="product.view_url"
                                      v-bind:class="{
                                        btn_visible:
                                          product.view_url &&
                                          product.first_name &&
                                          product.city
                                      }"
                                      target="_blank"
                                      class="my-prof-controls-flex"
                                      color="primary"
                                      style="margin-right:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-eye</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Открыть
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Посмотреть</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      v-bind:class="{
                                        btn_visible:
                                          !product.is_used ||
                                          !product.first_name ||
                                          !product.city
                                      }"
                                      @click="
                                        payReportDialog(product, 'products')
                                      "
                                      class="my-prof-controls-flex btn_novisible margin-top-25"
                                      color="warning accent-4"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark
                                        >mdi-checkbox-marked-circle-outline</v-icon
                                      >
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        расчет
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>сделать расчет карты</span>
                              </v-tooltip>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="preloading"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div
                        class="display-0-24 text-center no-desktop margin-btm-12-mob"
                      >
                        Выберите действие
                      </div>
                      <v-skeleton-loader
                        height="64"
                        type="avatar"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else
                  :class="{
                    'mob-margin-btm-15': !profile.user_products
                  }"
                  class="mob-padding-bottom-5 margin-top-15"
                >
                  У вас пока нет купленных расшифровок.<br />
                  Смотреть
                  <v-btn
                    :href="reportsUrl"
                    target="_blank"
                    color="accent-4"
                    class="margin-btm-10 success-custom xs-small"
                    small
                  >
                    PDF-отчет
                  </v-btn>
                  <br />
                  <div class="mob-padding-bottom-5" style="margin-top:10px">
                    Подарите расшифровку Дизайна своим<br />
                    близким
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="buyProductAsGift"
                          class="margin-top-10-mob margin-btm-5 mob-block success-custom"
                          small
                          dark
                        >
                          <span style="padding-left:3px">выбрать</span>
                        </v-btn>
                      </template>
                      <span>Подарить платную расшифровку</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div class="display-0-5-left text--primary demo-report-header">
                <div class="mob-display-block">
                  <span
                    :class="{
                      'mob-margin-top-15': !profile.career_user_products
                    }"
                    class="margin-top-5 margin-rt-40-nomob-wrap min-width-165-media375"
                  >
                    Бизнес расшифровки:
                  </span>
                  <span class="no-mobile">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          v-show="User.haveIsUsedCareerProducts"
                          :href="careerProductUrl"
                          target="_blank"
                          class="mx-2 margin-top-10-mob margin-btm-5 mob-block"
                          small
                          dark
                          color="orange darken-1"
                        >
                          <v-icon left class="mob-no-margin-right">
                            mdi-cart-plus
                          </v-icon>
                          <span class="no-mobile">купить ещё -10%</span>
                        </v-btn>
                      </template>
                      <span>Купить ещё бизнес расшифровку</span>
                    </v-tooltip>
                  </span>
                  <div class="no-desktop margin-top-10">
                    <v-list
                      v-if="User.haveIsUsedCareerProducts"
                      class="padding-0"
                    >
                      <v-list-item class="padding-0">
                        <v-list-item-content
                          class="padding-top-0 padding-bottom-0"
                        >
                          <v-col
                            cols="12"
                            class="flex-center margin-top-10 padding-0"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-show="User.haveIsUsedCareerProducts"
                                  :href="careerProductUrl"
                                  target="_blank"
                                  class="my-prof-controls-flex"
                                  color="orange darken-1"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-cart-plus</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    купить ещё -10%
                                  </span>
                                </v-btn>
                              </template>
                              <span>Купить ещё бизнес расшифровку</span>
                            </v-tooltip>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  profile.career_user_products &&
                    profile.career_user_products.length > 7
                "
                class="reports-search-input"
              >
                <v-text-field
                  v-model="searchСareerUserProducts"
                  @input="
                    searchFromReports(
                      searchСareerUserProducts,
                      'career_user_products'
                    )
                  "
                  label="Поиск по имени"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <div
                v-if="
                  profile.career_user_products &&
                    profile.career_user_products.length
                "
              >
                <div
                  v-for="(product, i) in careerUserProducts"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="product.is_used && !product.is_last_bought_product"
                    class="no-mobile"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="product.first_name">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ product.first_name }}</span>
                        </li>
                        <li v-if="product.email">
                          <v-icon>mdi-at</v-icon>
                          <span>{{ product.email }}</span>
                        </li>
                        <li v-if="product.birthday">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(product.birthday) }}</span>
                        </li>
                        <li v-if="product.city">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ product.city.title }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="product.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ product.chart_type }}</span>
                        </li>
                        <li v-if="product.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ product.chart_profile }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="product-help padding-top-35"
                    >
                      <div class="col-pd-lt-10">
                        <div class="product-payed-title">
                          <strong>{{ product.product.part_title }}</strong>
                        </div>
                        <div>
                          <div class="d-flex">
                            <v-btn
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:href="product.upgrade_url"
                              @click="initPurchase(i + 'cr')"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'cr'
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              докупить
                            </v-btn>
                          </div>
                          <v-btn
                            v-if="product.upgrade_url"
                            :disabled="loading2"
                            :loading="loading2"
                            @click="checkPurchase()"
                            v-bind:class="{
                              visible: $store.state.Purchases.id === i + 'cr'
                            }"
                            color="primary warning accent-4"
                            class="margin-btm-10 novisible"
                            small
                          >
                            проверить статус
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="updateProducts"
                            v-bind:href="product.view_url"
                            v-bind:class="{
                              btn_visible:
                                product.view_url &&
                                product.first_name &&
                                product.city
                            }"
                            target="_blank"
                            class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                            color="primary"
                          >
                            <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                          </v-btn>
                        </template>
                        <span>Посмотреть</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="updateProducts"
                            v-bind:href="product.pdf_url"
                            v-bind:class="{
                              btn_visible:
                                product.pdf_url &&
                                product.first_name &&
                                product.city
                            }"
                            target="_blank"
                            class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                          >
                            <v-icon dark>mdi-file-pdf-box</v-icon>
                            &nbsp;Скачать
                          </v-btn>
                        </template>
                        <span>Скачать PDF</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="
                              getBodygraphFromReports(
                                i,
                                1,
                                'career_user_products'
                              )
                            "
                            v-bind:class="{
                              btn_visible:
                                product.view_url &&
                                product.first_name &&
                                product.city
                            }"
                            target="_blank"
                            class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                            dark
                            color="orange darken-1"
                            style="justify-content:space-between"
                          >
                            <v-icon dark>
                              mdi-file-table-box-multiple-outline</v-icon
                            >&nbsp;Карта&nbsp;&nbsp;&nbsp;
                          </v-btn>
                        </template>
                        <span>Посмотреть карту</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            v-bind:class="{
                              btn_visible:
                                !product.is_used ||
                                !product.first_name ||
                                !product.city
                            }"
                            @click="payReportDialog(product, 'career')"
                            class="mx-2"
                            small
                            color="primary warning accent-4 btn_novisible"
                          >
                            <span class="custom-blink-2">сделать расчет</span>
                          </v-btn>
                        </template>
                        <span>сделать расчет карты</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="product.is_used && !product.is_last_bought_product"
                    class="no-desktop"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-0"
                    >
                      <div class="product-payed-title mob-padding-bottom-10">
                        <strong>{{ product.product.part_title }}</strong>
                      </div>
                      <div class="mob-display-flex">
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="product.first_name">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ product.first_name }}</span>
                            </li>
                            <li v-if="product.email">
                              <v-icon>mdi-at</v-icon>
                              <span>{{ product.email }}</span>
                            </li>
                            <li v-if="product.birthday">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>
                                {{ dateFormatting(product.birthday) }}
                              </span>
                            </li>
                            <li v-if="product.city">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ product.city.title }}</span>
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="product.chart_type">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ product.chart_type }}</span>
                            </li>
                            <li v-if="product.chart_profile">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>Профиль {{ product.chart_profile }}</span>
                            </li>
                          </ul>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div class="col-pd-lt-10">
                        <div style="display:flex;align-items: baseline">
                          <div class="d-flex">
                            <v-btn
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:href="product.upgrade_url"
                              @click="initPurchase(i + 'cr')"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'cr'
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              докупить
                            </v-btn>
                          </div>
                          <v-btn
                            v-if="product.upgrade_url"
                            :disabled="loading2"
                            :loading="loading2"
                            @click="checkPurchase()"
                            v-bind:class="{
                              visible: $store.state.Purchases.id === i + 'cr'
                            }"
                            color="primary warning accent-4"
                            class="margin-btm-10 novisible"
                            small
                          >
                            проверить статус
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-12-5-27-5"
                    >
                      <v-list class="padding-0">
                        <v-list-item class="padding-0">
                          <v-list-item-content
                            class="padding-top-0 padding-bottom-0"
                          >
                            <v-col
                              cols="4"
                              class="flex-center padding-0"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="updateProducts"
                                      v-bind:href="product.pdf_url"
                                      v-bind:class="{
                                        btn_visible:
                                          product.pdf_url &&
                                          product.first_name &&
                                          product.city
                                      }"
                                      target="_blank"
                                      class="download-pdf2 my-prof-controls-flex"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-file-pdf-box</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Скачать
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Скачать PDF</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="4"
                              class="flex-center padding-0"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="
                                      getBodygraphFromReports(
                                        i,
                                        1,
                                        'career_user_products'
                                      )
                                    "
                                    v-bind:class="{
                                      btn_visible:
                                        product.view_url &&
                                        product.first_name &&
                                        product.city
                                    }"
                                    target="_blank"
                                    class="my-prof-controls-flex"
                                    color="orange darken-1"
                                    fab
                                    small
                                    icon
                                    dark
                                  >
                                    <v-icon dark>
                                      mdi-file-table-box-multiple-outline</v-icon
                                    >
                                    <span
                                      class="d-flex"
                                      style="font-size:11px;margin-top:5px"
                                    >
                                      Карта
                                    </span>
                                  </v-btn>
                                </template>
                                <span>Посмотреть карту</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="updateProducts"
                                      v-bind:href="product.view_url"
                                      v-bind:class="{
                                        btn_visible:
                                          product.view_url &&
                                          product.first_name &&
                                          product.city
                                      }"
                                      target="_blank"
                                      class="my-prof-controls-flex"
                                      color="primary"
                                      style="margin-right:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-eye</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Открыть
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Посмотреть</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      v-bind:class="{
                                        btn_visible:
                                          !product.is_used ||
                                          !product.first_name ||
                                          !product.city
                                      }"
                                      @click="
                                        payReportDialog(product, 'career')
                                      "
                                      class="my-prof-controls-flex btn_novisible margin-top-25"
                                      color="warning accent-4"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark
                                        >mdi-checkbox-marked-circle-outline</v-icon
                                      >
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        расчет
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>сделать расчет карты</span>
                              </v-tooltip>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="preloading"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div
                        class="display-0-24 text-center no-desktop margin-btm-12-mob"
                      >
                        Выберите действие
                      </div>
                      <v-skeleton-loader
                        height="64"
                        type="avatar"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else
                  :class="{
                    'mob-margin-btm-15': !profile.career_user_products
                  }"
                  class="mob-padding-bottom-5 margin-top-10"
                >
                  У вас пока нет купленных бизнес расшифровок.<br />
                  Смотреть
                  <v-btn
                    :href="noSalesCareerProductUrl"
                    target="_blank"
                    color="accent-4"
                    class="margin-btm-10 success-custom xs-small"
                    small
                  >
                    PDF-отчет
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div class="display-0-5-left text--primary demo-report-header">
                <div class="mob-display-block">
                  <span
                    :class="{
                      'mob-margin-top-15': !profile.child_user_products
                    }"
                    class="margin-top-5 margin-rt-40-nomob-wrap min-width-165-media375"
                  >
                    Детские расшифровки:
                  </span>
                  <span class="no-mobile">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          v-show="User.haveIsUsedChildProducts"
                          :href="childProductUrl"
                          target="_blank"
                          class="mx-2 margin-top-10-mob margin-btm-5 mob-block"
                          small
                          dark
                          color="orange darken-1"
                        >
                          <v-icon left class="mob-no-margin-right">
                            mdi-cart-plus
                          </v-icon>
                          <span class="no-mobile">купить ещё</span>
                        </v-btn>
                      </template>
                      <span>Купить ещё детскую расшифровку</span>
                    </v-tooltip>
                  </span>
                  <div class="no-desktop margin-top-10">
                    <v-list
                      v-if="User.haveIsUsedChildProducts"
                      class="padding-0"
                    >
                      <v-list-item class="padding-0">
                        <v-list-item-content
                          class="padding-top-0 padding-bottom-0"
                        >
                          <v-col
                            cols="12"
                            class="flex-center margin-top-10 padding-0"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-show="User.haveIsUsedChildProducts"
                                  :href="childProductUrl"
                                  target="_blank"
                                  class="my-prof-controls-flex"
                                  color="orange darken-1"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-cart-plus</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    купить ещё
                                  </span>
                                </v-btn>
                              </template>
                              <span>Купить ещё детскую расшифровку</span>
                            </v-tooltip>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  profile.child_user_products &&
                    profile.child_user_products.length > 7
                "
                class="reports-search-input"
              >
                <v-text-field
                  v-model="searchСhildUserProducts"
                  @input="
                    searchFromReports(
                      searchСhildUserProducts,
                      'child_user_products'
                    )
                  "
                  label="Поиск по имени"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <div
                v-if="
                  profile.child_user_products &&
                    profile.child_user_products.length
                "
              >
                <div
                  v-for="(product, i) in childUserProducts"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="product.is_used && !product.is_last_bought_product"
                    class="no-mobile"
                  >
                    <v-col
                      v-if="product.was_upgraded_recently"
                      cols="12"
                      class="text-center padding-bottom-0 margin-bottom--20 mob-margin-bottom--30"
                    >
                      <v-chip
                        class="ma-2"
                        color="#ffcdd2"
                        label
                        text-color="red"
                      >
                        <v-icon start>
                          mdi-file-table-box-multiple-outline
                        </v-icon>
                        &nbsp;&nbsp;Ваша расшифровка была обновлена
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="product.first_name">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ product.first_name }}</span>
                        </li>
                        <li v-if="product.email">
                          <v-icon>mdi-at</v-icon>
                          <span>{{ product.email }}</span>
                        </li>
                        <li v-if="product.birthday">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(product.birthday) }}</span>
                        </li>
                        <li v-if="product.city">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ product.city.title }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="product.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ product.chart_type }}</span>
                        </li>
                        <li v-if="product.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ product.chart_profile }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="product-help no-mobile padding-top-35"
                    >
                      <div class="col-pd-lt-10">
                        <div class="product-payed-title">
                          <strong>{{ product.product.part_title }}</strong>
                        </div>
                        <div>
                          <div
                            v-if="product.upgrade_url && product.is_seen"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i + 'ch'
                            }"
                            class="display-0-24"
                          >
                            Доступны:&nbsp;
                          </div>
                          <div class="d-flex">
                            <v-btn
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:href="
                                product.upgrade_url +
                                  '~rf' +
                                  User.ext_reference_code
                              "
                              @click="openChildUpdatesButton"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              {{
                                product.product_upgrade_title === "2 часть"
                                  ? "Обновления"
                                  : product.product_upgrade_title
                              }}
                            </v-btn>
                            <div
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                            >
                              <v-icon
                                @click="
                                  helPayDialogChild(
                                    product.upgrade_html_tooltip,
                                    product.upgrade_url +
                                      '~rf' +
                                      User.ext_reference_code
                                  )
                                "
                                class="product-help-icon"
                                >mdi-help-circle-outline</v-icon
                              >
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <v-chip
                              v-if="product.upgrade_url && product.is_seen"
                              :class="{ 'pulse-item': firstOpenChildUpdates }"
                              color="#ffcdd2"
                              text-color="red"
                              label
                              small
                            >
                              NEW
                            </v-chip>
                          </div>
                          <div
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i + 'ch'
                            }"
                            class="display-0-24"
                          >
                            Вам доступна:&nbsp;
                          </div>
                          <v-btn
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7
                            "
                            href="/#/consultations"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i + 'ch'
                            }"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            консультация
                          </v-btn>
                          <v-btn
                            v-if="product.upgrade_url"
                            :disabled="loading2"
                            :loading="loading2"
                            @click="checkPurchase()"
                            v-bind:class="{
                              visible: $store.state.Purchases.id === i + 'ch'
                            }"
                            color="primary warning accent-4"
                            class="margin-btm-10 novisible"
                            small
                          >
                            проверить статус
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-25 padding-top-35"
                    >
                      <div class="no-mobile">
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="product.view_url"
                              v-bind:class="{
                                btn_visible:
                                  product.view_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                            </v-btn>
                          </template>
                          <span>Посмотреть</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="product.pdf_url"
                              v-bind:class="{
                                btn_visible:
                                  product.pdf_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                            >
                              <v-icon dark>mdi-file-pdf-box</v-icon
                              >&nbsp;Скачать
                            </v-btn>
                          </template>
                          <span>Скачать PDF</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                getBodygraphFromReports(
                                  i,
                                  1,
                                  'child_user_products'
                                )
                              "
                              v-bind:class="{
                                btn_visible:
                                  product.view_url &&
                                  product.first_name &&
                                  product.city
                              }"
                              target="_blank"
                              class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                              dark
                              color="orange darken-1"
                              style="justify-content:space-between"
                            >
                              <v-icon dark>
                                mdi-file-table-box-multiple-outline</v-icon
                              >&nbsp;Карта&nbsp;&nbsp;&nbsp;
                            </v-btn>
                          </template>
                          <span>Посмотреть карту</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              v-bind:class="{
                                btn_visible:
                                  !product.is_used ||
                                  !product.first_name ||
                                  !product.city
                              }"
                              @click="payReportDialog(product, 'products')"
                              class="mx-2"
                              small
                              color="primary warning accent-4 btn_novisible"
                            >
                              <span class="custom-blink-2">сделать расчет</span>
                            </v-btn>
                          </template>
                          <span>сделать расчет карты</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="product.is_used && !product.is_last_bought_product"
                    class="no-desktop"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-0"
                    >
                      <div class="product-payed-title mob-padding-bottom-10">
                        <strong>{{ product.product.part_title }}</strong>
                      </div>
                      <div class="mob-display-flex">
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="product.first_name">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ product.first_name }}</span>
                            </li>
                            <li v-if="product.email">
                              <v-icon>mdi-at</v-icon>
                              <span>{{ product.email }}</span>
                            </li>
                            <li v-if="product.birthday">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>
                                {{ dateFormatting(product.birthday) }}
                              </span>
                            </li>
                            <li v-if="product.city">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ product.city.title }}</span>
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="product.chart_type">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ product.chart_type }}</span>
                            </li>
                            <li v-if="product.chart_profile">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>Профиль {{ product.chart_profile }}</span>
                            </li>
                          </ul>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div class="col-pd-lt-10">
                        <div style="display:flex;align-items:baseline">
                          <div
                            v-if="product.upgrade_url && product.is_seen"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i + 'ch'
                            }"
                            class="display-0-24"
                          >
                            Доступны:&nbsp;
                          </div>
                          <div class="d-flex">
                            <v-btn
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:href="
                                product.upgrade_url +
                                  '~rf' +
                                  User.ext_reference_code
                              "
                              @click="openChildUpdatesButton"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              {{
                                product.product_upgrade_title === "2 часть"
                                  ? "Обновления"
                                  : product.product_upgrade_title
                              }}
                            </v-btn>
                            <div
                              v-if="product.upgrade_url && product.is_seen"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i + 'ch'
                              }"
                            >
                              <v-icon
                                @click="
                                  helPayDialogChild(
                                    product.upgrade_html_tooltip,
                                    product.upgrade_url +
                                      '~rf' +
                                      User.ext_reference_code
                                  )
                                "
                                class="product-help-icon"
                                >mdi-help-circle-outline</v-icon
                              >
                            </div>
                            <span v-if="product.upgrade_url && product.is_seen">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                            <v-chip
                              v-if="product.upgrade_url && product.is_seen"
                              :class="{ 'pulse-item': firstOpenChildUpdates }"
                              color="#ffcdd2"
                              text-color="red"
                              label
                              small
                            >
                              NEW
                            </v-chip>
                          </div>
                          <div
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i + 'ch'
                            }"
                            class="display-0-24"
                          >
                            Вам доступна:&nbsp;
                          </div>
                          <v-btn
                            v-if="
                              !product.upgrade_url &&
                                product.is_seen &&
                                product.product.id !== 7
                            "
                            href="/#/consultations"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i + 'ch'
                            }"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            консультация
                          </v-btn>
                          <v-btn
                            v-if="product.upgrade_url"
                            :disabled="loading2"
                            :loading="loading2"
                            @click="checkPurchase()"
                            v-bind:class="{
                              visible: $store.state.Purchases.id === i + 'ch'
                            }"
                            color="primary warning accent-4"
                            class="margin-btm-10 novisible"
                            small
                          >
                            проверить статус
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-12-5-27-5"
                    >
                      <v-list class="padding-0">
                        <v-list-item class="padding-0">
                          <v-list-item-content
                            class="padding-top-0 padding-bottom-0"
                          >
                            <v-col
                              cols="4"
                              class="flex-center padding-0"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="updateProducts"
                                      v-bind:href="product.pdf_url"
                                      v-bind:class="{
                                        btn_visible:
                                          product.pdf_url &&
                                          product.first_name &&
                                          product.city
                                      }"
                                      target="_blank"
                                      class="download-pdf2 my-prof-controls-flex"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-file-pdf-box</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Скачать
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Скачать PDF</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="4"
                              class="flex-center padding-0"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="
                                      getBodygraphFromReports(
                                        i,
                                        1,
                                        'child_user_products'
                                      )
                                    "
                                    v-bind:class="{
                                      btn_visible:
                                        product.view_url &&
                                        product.first_name &&
                                        product.city
                                    }"
                                    target="_blank"
                                    class="my-prof-controls-flex"
                                    color="orange darken-1"
                                    fab
                                    small
                                    icon
                                    dark
                                  >
                                    <v-icon dark>
                                      mdi-file-table-box-multiple-outline</v-icon
                                    >
                                    <span
                                      class="d-flex"
                                      style="font-size:11px;margin-top:5px"
                                    >
                                      Карта
                                    </span>
                                  </v-btn>
                                </template>
                                <span>Посмотреть карту</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="updateProducts"
                                      v-bind:href="product.view_url"
                                      v-bind:class="{
                                        btn_visible:
                                          product.view_url &&
                                          product.first_name &&
                                          product.city
                                      }"
                                      target="_blank"
                                      class="my-prof-controls-flex"
                                      color="primary"
                                      style="margin-right:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-eye</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Открыть
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Посмотреть</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      v-bind:class="{
                                        btn_visible:
                                          !product.is_used ||
                                          !product.first_name ||
                                          !product.city
                                      }"
                                      @click="
                                        payReportDialog(product, 'products')
                                      "
                                      class="my-prof-controls-flex btn_novisible margin-top-25"
                                      color="warning accent-4"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark
                                        >mdi-checkbox-marked-circle-outline</v-icon
                                      >
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        расчет
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>сделать расчет карты</span>
                              </v-tooltip>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="preloading"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div
                        class="display-0-24 text-center no-desktop margin-btm-12-mob"
                      >
                        Выберите действие
                      </div>
                      <v-skeleton-loader
                        height="64"
                        type="avatar"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else
                  :class="{
                    'mob-margin-btm-15': !profile.child_user_products
                  }"
                  class="mob-padding-bottom-5 margin-top-10"
                >
                  У вас пока нет купленных детских расшифровок.<br />
                  Смотреть
                  <v-btn
                    :href="noSalesChildProductUrl"
                    target="_blank"
                    color="accent-4"
                    class="margin-btm-10 success-custom xs-small"
                    small
                  >
                    PDF-отчет
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div class="display-0-5-left text--primary demo-report-header">
                <div class="mob-display-block">
                  <span
                    :class="{
                      'mob-margin-top-15': !profile.composite_user_products
                    }"
                    class="margin-top-5 margin-rt-40-nomob min-width-165-media375"
                  >
                    Совместимости:
                  </span>
                  <span class="no-mobile">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          v-show="User.haveIsUsedUserComposites"
                          :href="compositeProductUrl"
                          target="_blank"
                          class="mx-2 margin-top-10-mob margin-btm-5 mob-block"
                          small
                          dark
                          color="orange darken-1"
                        >
                          <v-icon left class="mob-no-margin-right">
                            mdi-cart-plus
                          </v-icon>
                          <span class="no-mobile">
                            купить ещё -10%
                          </span>
                        </v-btn>
                      </template>
                      <span>Купить ещё платную совместимость</span>
                    </v-tooltip>
                  </span>
                  <div class="no-desktop margin-top-10">
                    <v-list
                      v-if="User.haveIsUsedUserComposites"
                      class="padding-0"
                    >
                      <v-list-item class="padding-0">
                        <v-list-item-content
                          class="padding-top-0 padding-bottom-0"
                        >
                          <v-col
                            cols="12"
                            class="flex-center margin-top-10 padding-0"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-show="User.haveIsUsedUserComposites"
                                  :href="compositeProductUrl"
                                  target="_blank"
                                  class="my-prof-controls-flex"
                                  color="orange darken-1"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-cart-plus</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    купить ещё -10%
                                  </span>
                                </v-btn>
                              </template>
                              <span>Купить ещё платную совместимость</span>
                            </v-tooltip>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  profile.composite_user_products &&
                    profile.composite_user_products.length > 7
                "
                class="reports-search-input"
              >
                <v-text-field
                  v-model="searchСompositeUserProducts"
                  @input="
                    searchFromReports(
                      searchСompositeUserProducts,
                      'composite_user_products'
                    )
                  "
                  label="Поиск по имени"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <div
                v-if="
                  profile.composite_user_products &&
                    profile.composite_user_products.length
                "
              >
                <div
                  v-for="(composite, i) in compositeUserProducts"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="
                      composite.is_used && !composite.is_last_bought_product
                    "
                    class="no-mobile"
                  >
                    <v-col
                      v-if="composite.was_upgraded_recently"
                      cols="12"
                      class="text-center padding-bottom-0 margin-bottom--20 mob-margin-bottom--30"
                    >
                      <v-chip
                        class="ma-2"
                        color="#ffcdd2"
                        label
                        text-color="red"
                      >
                        <v-icon start>
                          mdi-file-table-box-multiple-outline
                        </v-icon>
                        &nbsp;&nbsp;Ваша расшифровка была обновлена
                      </v-chip>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style min-height-75-nomob">
                        <li v-if="composite.first_name">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ composite.first_name }}</span>
                        </li>
                        <li v-if="composite.birthday">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(composite.birthday) }}</span>
                        </li>
                        <li v-if="composite.city">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ composite.city.title }}</span>
                        </li>
                      </ul>
                      <ul class="ul-no-li-style min-height-75-nomob no-desktop">
                        <li v-if="composite.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ composite.chart_type }}</span>
                        </li>
                        <li v-if="composite.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ composite.chart_profile }}</span>
                        </li>
                      </ul>
                      <ul class="ul-no-li-style">
                        <li class="composite-plus-18">
                          &nbsp;
                        </li>
                        <li v-if="composite.first_name2">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ composite.first_name2 }}</span>
                        </li>
                        <li v-if="composite.birthday2">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(composite.birthday2) }}</span>
                        </li>
                        <li v-if="composite.city2">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ composite.city2.title }}</span>
                        </li>
                      </ul>
                      <ul class="ul-no-li-style no-desktop">
                        <li v-if="composite.chart_type2">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ composite.chart_type2 }}</span>
                        </li>
                        <li v-if="composite.chart_profile2">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ composite.chart_profile }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="no-mobile padding-top-35"
                    >
                      <ul class="ul-no-li-style min-height-75-nomob">
                        <li class="composite-plus-18">
                          &nbsp;
                        </li>
                        <li v-if="composite.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ composite.chart_type }}</span>
                        </li>
                        <li v-if="composite.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ composite.chart_profile }}</span>
                        </li>
                      </ul>
                      <ul class="ul-no-li-style no-mobile">
                        <li class="composite-plus-18">
                          &nbsp;
                        </li>
                        <li v-if="composite.chart_type2">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ composite.chart_type2 }}</span>
                        </li>
                        <li v-if="composite.chart_profile2">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ composite.chart_profile2 }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="product-help no-mobile padding-top-35"
                    >
                      <div class="col-pd-lt-10">
                        <div class="product-payed-title">
                          <strong>{{ composite.product.part_title }}</strong>
                        </div>
                        <div>
                          <div class="d-flex">
                            <v-btn
                              v-if="composite.upgrade_url && composite.is_seen"
                              v-bind:href="composite.upgrade_url"
                              @click="initPurchase(i)"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              докупить
                            </v-btn>
                            <div
                              v-if="composite.upgrade_url && composite.is_seen"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                            >
                              <v-icon
                                @click="
                                  helPayDialog(composite.upgrade_html_tooltip)
                                "
                                class="product-help-icon"
                                >mdi-help-circle-outline</v-icon
                              >
                            </div>
                          </div>
                          <div
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                composite.additional_blocks_url
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Доступны:&nbsp;
                          </div>
                          <div
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                composite.additional_blocks_url
                            "
                            style="display:flex;align-items:baseline"
                          >
                            <v-btn
                              @click="openCompUpdatesButton"
                              :href="composite.additional_blocks_url"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              {{
                                composite.additional_blocks_title
                                  ? composite.additional_blocks_title
                                  : "обновления"
                              }}
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-chip
                              :class="{ 'pulse-item': firstOpenCompUpdates }"
                              color="#ffcdd2"
                              text-color="red"
                              label
                              small
                            >
                              NEW
                            </v-chip>
                          </div>
                          <div
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                !composite.additional_blocks_url
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Вам доступна:
                          </div>
                          <v-btn
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                !composite.additional_blocks_url
                            "
                            href="/#/consultations"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            консультация
                          </v-btn>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                v-if="composite.upgrade_url"
                                :disabled="loading2"
                                :loading="loading2"
                                @click="checkPurchase()"
                                v-bind:class="{
                                  visible: $store.state.Purchases.id === i
                                }"
                                color="primary warning accent-4"
                                class="margin-btm-10 novisible"
                                small
                              >
                                проверить статус
                              </v-btn>
                            </template>
                            <span>Проверить статус покупки</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-25 padding-top-35"
                    >
                      <div class="no-mobile">
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="composite.view_url"
                              v-bind:class="{
                                btn_visible:
                                  composite.view_url &&
                                  composite.first_name &&
                                  composite.city
                              }"
                              target="_blank"
                              class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                            </v-btn>
                          </template>
                          <span>Посмотреть</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="updateProducts"
                              v-bind:href="composite.pdf_url"
                              v-bind:class="{
                                btn_visible:
                                  composite.pdf_url &&
                                  composite.first_name &&
                                  composite.city
                              }"
                              target="_blank"
                              class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                              small
                            >
                              <v-icon dark>mdi-file-pdf-box</v-icon
                              >&nbsp;Скачать
                            </v-btn>
                          </template>
                          <span>Скачать PDF</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              v-bind:class="{
                                btn_visible:
                                  !composite.is_used ||
                                  !composite.first_name ||
                                  !composite.city
                              }"
                              @click="payCompositeDialog(composite)"
                              class="mx-2"
                              small
                              color="primary warning accent-4 btn_novisible"
                            >
                              <span class="custom-blink-2">сделать расчет</span>
                            </v-btn>
                          </template>
                          <span>сделать расчет совместимости</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      composite.is_used && !composite.is_last_bought_product
                    "
                    class="no-desktop"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-0"
                    >
                      <div class="product-payed-title mob-padding-bottom-10">
                        <strong>{{ composite.product.part_title }}</strong>
                      </div>
                      <div class="mob-display-flex">
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="composite.first_name">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ composite.first_name }}</span>
                            </li>
                            <li v-if="composite.birthday">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>{{
                                dateFormatting(composite.birthday)
                              }}</span>
                            </li>
                            <li v-if="composite.city">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ composite.city.title }}</span>
                            </li>
                          </ul>
                          <ul class="ul-no-li-style">
                            <li class="composite-plus-18">
                              &nbsp;
                            </li>
                            <li v-if="composite.first_name2">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ composite.first_name2 }}</span>
                            </li>
                            <li v-if="composite.birthday2">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>{{
                                dateFormatting(composite.birthday2)
                              }}</span>
                            </li>
                            <li v-if="composite.city2">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ composite.city2.title }}</span>
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li class="composite-plus-18">&nbsp;</li>
                            <li v-if="composite.chart_type">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ composite.chart_type }}</span>
                            </li>
                            <li v-if="composite.chart_profile">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>
                                Профиль {{ composite.chart_profile }}
                              </span>
                            </li>
                          </ul>
                          <ul class="ul-no-li-style">
                            <li class="composite-plus-18">
                              &nbsp;
                            </li>
                            <li v-if="composite.chart_type2">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ composite.chart_type2 }}</span>
                            </li>
                            <li v-if="composite.chart_profile2">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>
                                Профиль {{ composite.chart_profile }}
                              </span>
                            </li>
                          </ul>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div class="col-pd-lt-10">
                        <div style="display:flex;align-items:baseline">
                          <div class="d-flex">
                            <v-btn
                              v-if="composite.upgrade_url && composite.is_seen"
                              v-bind:href="composite.upgrade_url"
                              @click="initPurchase(i)"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              докупить
                            </v-btn>
                            <div
                              v-if="composite.upgrade_url && composite.is_seen"
                              v-bind:class="{
                                none: $store.state.Purchases.id === i
                              }"
                            >
                              <v-icon
                                @click="
                                  helPayDialog(composite.upgrade_html_tooltip)
                                "
                                class="product-help-icon"
                                >mdi-help-circle-outline</v-icon
                              >
                            </div>
                          </div>
                          <div
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                composite.additional_blocks_url
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Доступны:&nbsp;
                          </div>
                          <v-btn
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                composite.additional_blocks_url
                            "
                            @click="openCompUpdatesButton"
                            :href="composite.additional_blocks_url"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            target="_blank"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            {{
                              composite.additional_blocks_title
                                ? composite.additional_blocks_title
                                : "обновления"
                            }}
                          </v-btn>
                          <span
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                composite.additional_blocks_url
                            "
                          >
                            &nbsp;&nbsp;
                          </span>
                          <v-chip
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                composite.additional_blocks_url
                            "
                            :class="{ 'pulse-item': firstOpenCompUpdates }"
                            color="#ffcdd2"
                            text-color="red"
                            label
                            small
                          >
                            NEW
                          </v-chip>
                          <div
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                !composite.additional_blocks_url
                            "
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            class="display-0-24"
                          >
                            Вам доступна:&nbsp;
                          </div>
                          <v-btn
                            v-if="
                              !composite.upgrade_url &&
                                composite.is_seen &&
                                composite.product.id === 7 &&
                                !composite.additional_blocks_url
                            "
                            href="/#/consultations"
                            v-bind:class="{
                              none: $store.state.Purchases.id === i
                            }"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            консультация
                          </v-btn>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                v-if="composite.upgrade_url"
                                :disabled="loading2"
                                :loading="loading2"
                                @click="checkPurchase()"
                                v-bind:class="{
                                  visible: $store.state.Purchases.id === i
                                }"
                                color="primary warning accent-4"
                                class="margin-btm-10 novisible"
                                small
                              >
                                проверить статус
                              </v-btn>
                            </template>
                            <span>Проверить статус покупки</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-12-5-27-5"
                    >
                      <v-list class="padding-0">
                        <v-list-item class="padding-0">
                          <v-list-item-content
                            class="padding-top-0 padding-bottom-0"
                          >
                            <v-col
                              cols="6"
                              class="flex-center padding-0"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="updateProducts"
                                      v-bind:href="composite.pdf_url"
                                      v-bind:class="{
                                        btn_visible:
                                          composite.pdf_url &&
                                          composite.first_name &&
                                          composite.city
                                      }"
                                      target="_blank"
                                      class="download-pdf2 my-prof-controls-flex"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-file-pdf-box</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Скачать
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Скачать PDF</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="6" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="updateProducts"
                                      v-bind:href="composite.view_url"
                                      v-bind:class="{
                                        btn_visible:
                                          composite.view_url &&
                                          composite.first_name &&
                                          composite.city
                                      }"
                                      target="_blank"
                                      class="my-prof-controls-flex"
                                      color="primary"
                                      style="margin-right:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-eye</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Открыть
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Посмотреть</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="4" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      v-bind:class="{
                                        btn_visible:
                                          !composite.is_used ||
                                          !composite.first_name ||
                                          !composite.city
                                      }"
                                      @click="payCompositeDialog(composite)"
                                      class="my-prof-controls-flex btn_novisible margin-top-25"
                                      color="warning accent-4"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark
                                        >mdi-checkbox-marked-circle-outline</v-icon
                                      >
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        расчет
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>сделать расчет совместимости</span>
                              </v-tooltip>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="preloading"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <!-- <div class="display-0-24">Исходные данные</div> -->
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <!-- <div class="display-0-24 no-mobile">Human Design</div> -->
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <!-- <div class="display-0-24">Статус расчета:</div> -->
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <!-- <div class="display-0-24 no-mobile">Действия</div> -->
                      <div
                        class="display-0-24 text-center no-desktop margin-btm-12-mob"
                      >
                        Выберите действие
                      </div>
                      <v-skeleton-loader
                        height="64"
                        type="avatar"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else
                  :class="{
                    'mob-margin-btm-15': !profile.composite_user_products
                  }"
                  class="mob-padding-bottom-5 margin-top-10"
                >
                  У вас пока нет купленного продукта.<br />
                  Смотреть
                  <v-btn
                    :href="noSalesCompositeProductUrl"
                    target="_blank"
                    color="accent-4"
                    class="margin-btm-10 success-custom xs-small"
                    small
                  >
                    PDF-отчет
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col
          v-if="User.haveIsUsedProReports"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="padding-2-0"
        >
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div class="display-0-5-left text--primary demo-report-header">
                <div class="mob-display-block">
                  <span
                    :class="{ 'mob-margin-top-15': !profile.pro_reports }"
                    class="margin-top-5 margin-rt-40-nomob min-width-165-media375"
                  >
                    Pro-подсказки:
                  </span>
                  <span class="no-mobile">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          v-show="User.haveIsUsedProReports"
                          :href="ConfApp.tooltips_shop_url"
                          target="_blank"
                          class="mx-2 margin-top-10-mob margin-btm-5 mob-block-a"
                          small
                          dark
                          color="orange darken-1"
                        >
                          <v-icon left class="mob-no-margin-right">
                            mdi-cart-plus
                          </v-icon>
                          <span class="no-mobile">купить ещё</span>
                        </v-btn>
                      </template>
                      <span>Купить ещё Pro-подсказки</span>
                    </v-tooltip>
                  </span>
                  <div class="no-desktop margin-top-10">
                    <v-list v-if="User.haveIsUsedProReports" class="padding-0">
                      <v-list-item class="padding-0">
                        <v-list-item-content
                          class="padding-top-0 padding-bottom-0"
                        >
                          <v-col
                            cols="12"
                            class="flex-center margin-top-10 padding-0"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  v-show="User.haveIsUsedProReports"
                                  :href="ConfApp.tooltips_shop_url"
                                  target="_blank"
                                  class="my-prof-controls-flex"
                                  color="orange darken-1"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-cart-plus</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    купить ещё
                                  </span>
                                </v-btn>
                              </template>
                              <span>Купить ещё Pro-подсказки</span>
                            </v-tooltip>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
              <div
                v-if="profile.pro_reports && profile.pro_reports.length > 7"
                class="reports-search-input"
              >
                <v-text-field
                  v-model="searchProReports"
                  @input="searchFromReports(searchProReports, 'pro_reports')"
                  label="Поиск по имени"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <div v-if="profile.pro_reports && profile.pro_reports.length">
                <div
                  v-for="(product, i) in proReports"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row
                    v-if="product.is_used && !product.is_last_bought_product"
                    class="no-mobile"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <div class="col-pd-lt-10 no-desktop mob-padding-top-35">
                        <div class="product-payed-title">
                          <strong>PRO-подсказки</strong>
                        </div>
                        <div style="display:flex;align-items:baseline">
                          <div
                            v-if="!product.has_user_product"
                            class="display-0-24"
                          >
                            Заказать:&nbsp;
                          </div>
                          <div v-else class="display-0-24">
                            Куплен PDF-отчет&nbsp;
                          </div>
                          <div class="d-flex">
                            <div>
                              <v-btn
                                v-if="!product.has_user_product"
                                :href="reportsUrl"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                PDF-отчет
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul class="ul-no-li-style">
                        <li v-if="product.first_name">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ product.first_name }}</span>
                        </li>
                        <li v-if="product.email">
                          <v-icon>mdi-at</v-icon>
                          <span>{{ product.email }}</span>
                        </li>
                        <li v-if="product.birthday">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(product.birthday) }}</span>
                        </li>
                        <li v-if="product.city">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ product.city.title }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="product.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ product.chart_type }}</span>
                        </li>
                        <li v-if="product.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ product.chart_profile }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="product-help no-mobile padding-top-35"
                    >
                      <div class="col-pd-lt-10">
                        <div class="product-payed-title">
                          <strong>PRO-подсказки</strong>
                        </div>
                        <div
                          v-if="!product.has_user_product"
                          class="divider-pro"
                        >
                          Заказать:
                        </div>
                        <div v-else class="divider-pro">
                          Куплен PDF-отчет
                        </div>
                        <div class="d-flex">
                          <div>
                            <v-btn
                              v-if="!product.has_user_product"
                              :href="reportsUrl"
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              PDF-отчет
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-25 padding-top-35"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="getBodygraphFromReports(i, 1, 'pro')"
                            v-bind:class="{
                              btn_visible: product.is_used
                            }"
                            class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                            color="primary"
                          >
                            <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                          </v-btn>
                        </template>
                        <span>Посмотреть</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            v-bind:class="{ btn_visible: !product.is_used }"
                            @click="payReportDialog(product, 'pro')"
                            class="mx-2"
                            small
                            color="primary warning accent-4 btn_novisible"
                          >
                            <span class="custom-blink-2">сделать расчет</span>
                          </v-btn>
                        </template>
                        <span>сделать расчет карты</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="product.is_used && !product.is_last_bought_product"
                    class="no-desktop"
                  >
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-0"
                    >
                      <div class="product-payed-title mob-padding-bottom-10">
                        <strong>PRO-подсказки</strong>
                      </div>
                      <div class="mob-display-flex">
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="product.first_name">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ product.first_name }}</span>
                            </li>
                            <li v-if="product.email">
                              <v-icon>mdi-at</v-icon>
                              <span>{{ product.email }}</span>
                            </li>
                            <li v-if="product.birthday">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>
                                {{ dateFormatting(product.birthday) }}
                              </span>
                            </li>
                            <li v-if="product.city">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ product.city.title }}</span>
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="product.chart_type">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ product.chart_type }}</span>
                            </li>
                            <li v-if="product.chart_profile">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>Профиль {{ product.chart_profile }}</span>
                            </li>
                          </ul>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div class="col-pd-lt-10">
                        <div style="display:flex;align-items: baseline">
                          <div
                            v-if="!product.has_user_product"
                            class="display-0-24"
                          >
                            Заказать:&nbsp;
                          </div>
                          <div v-else class="display-0-24">
                            Куплен PDF-отчет&nbsp;
                          </div>
                          <div class="d-flex">
                            <div>
                              <v-btn
                                v-if="!product.has_user_product"
                                :href="reportsUrl"
                                target="_blank"
                                color="accent-4"
                                class="margin-btm-10 success-custom xs-small"
                                small
                              >
                                PDF-отчет
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-12-5-27-5"
                    >
                      <v-list class="padding-0">
                        <v-list-item class="padding-0">
                          <v-list-item-content
                            class="padding-top-0 padding-bottom-0"
                          >
                            <v-col cols="12" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      @click="
                                        getBodygraphFromReports(i, 1, 'pro')
                                      "
                                      v-bind:class="{
                                        btn_visible: product.is_used
                                      }"
                                      class="my-prof-controls-flex"
                                      color="primary"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-eye</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Открыть
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>Посмотреть</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="12" class="flex-center padding-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <div>
                                    <v-btn
                                      v-on="on"
                                      v-bind:class="{
                                        btn_visible: !product.is_used
                                      }"
                                      @click="payReportDialog(product, 'pro')"
                                      class="my-prof-controls-flex btn_novisible margin-top-25"
                                      color="warning accent-4"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark
                                        >mdi-checkbox-marked-circle-outline</v-icon
                                      >
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        расчет
                                      </span>
                                    </v-btn>
                                  </div>
                                </template>
                                <span>сделать расчет карты</span>
                              </v-tooltip>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="preloading"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div
                        class="display-0-24 text-center no-desktop margin-btm-12-mob"
                      >
                        Выберите действие
                      </div>
                      <v-skeleton-loader
                        height="64"
                        type="avatar"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else
                  :class="{
                    'mob-margin-btm-15': !profile.pro_reports
                  }"
                  class="mob-padding-bottom-5 margin-top-10"
                >
                  У вас пока нет pro-подсказок.<br />
                  Купить
                  <v-btn
                    :href="ConfApp.tooltips_sale_shop_url"
                    target="_blank"
                    color="accent-4"
                    class="margin-btm-10 success-custom xs-small"
                    small
                  >
                    PRO-подсказки
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div class="display-0-5 text--primary demo-report-header">
                <div class="mob-display-block">
                  <span
                    :class="{
                      'mob-margin-top-15': !profile.reports
                    }"
                    class="margin-top-5 margin-rt-40-nomob min-width-165-media375"
                  >
                    Демо-расчеты:
                  </span>
                  <span class="no-mobile">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="newReport"
                          class="mx-2 margin-top-10-mob margin-btm-5 mob-block"
                          small
                          color="primary accent-4"
                        >
                          <v-icon left class="mob-no-margin-right">
                            mdi-plus
                          </v-icon>
                          <span class="no-mobile">Новый расчет</span>
                        </v-btn>
                      </template>
                      <span>Создать новый Демо-расчет</span>
                    </v-tooltip>
                  </span>
                  <div class="no-desktop margin-top-10">
                    <v-list class="padding-0">
                      <v-list-item class="padding-0">
                        <v-list-item-content
                          class="padding-top-0 padding-bottom-0"
                        >
                          <v-col cols="12" class="flex-center padding-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  @click="newReport"
                                  class="my-prof-controls-flex"
                                  color="primary accent-4"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-plus</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Новый расчет
                                  </span>
                                </v-btn>
                              </template>
                              <span>Создать новый Демо-расчет</span>
                            </v-tooltip>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
              <div
                v-if="profile.reports && profile.reports.length > 7"
                class="reports-search-input"
              >
                <v-text-field
                  v-model="searchReports"
                  @input="searchFromReports(searchReports, 'demo')"
                  label="Поиск по имени"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <div v-if="profile.reports && profile.reports.length">
                <div
                  v-for="(report, i) in demoProducts"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row v-if="report.is_stored" class="no-mobile">
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="report.first_name">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ report.first_name }}</span>
                        </li>
                        <li v-if="report.email">
                          <v-icon>mdi-at</v-icon>
                          <span>{{ report.email }}</span>
                        </li>
                        <li v-if="report.birthday">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(report.birthday) }}</span>
                        </li>
                        <li v-if="report.city">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ report.city.title }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style">
                        <li v-if="report.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ report.chart_type }}</span>
                        </li>
                        <li v-if="report.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ report.chart_profile }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35 no-mobile"
                    >
                      <div class="col-pd-lt-10">
                        <div class="divider-pro">
                          Поделиться:
                        </div>
                        <div>
                          <v-btn
                            @click="shareReport(report.id)"
                            color="accent-4"
                            class="hd-gradient success-custom xs-small min-width-100"
                            small
                          >
                            Ссылка
                          </v-btn>
                        </div>
                        <!-- <div
                          v-if="!report.has_user_product"
                          class="divider-pro"
                        >
                          Заказать:
                        </div>
                        <div v-else class="divider-pro">
                          Куплен PDF-отчет
                        </div>
                        <div>
                          <v-btn
                            v-if="!report.has_user_product"
                            :href="reportsUrl"
                            target="_blank"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small min-width-100"
                            small
                          >
                            PDF-отчет
                          </v-btn>
                        </div> -->
                      </div>
                    </v-col>
                    <v-col
                      class="padding-top-35"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :href="report.demo_report_view_url"
                            v-bind:class="{
                              btn_visible:
                                report.demo_report_view_url &&
                                report.first_name &&
                                report.city
                            }"
                            class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            target="_blank"
                            small
                            color="primary"
                          >
                            <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                          </v-btn>
                        </template>
                        <span>Посмотреть</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="getBodygraphFromReports(i, 1)"
                            class="mx-2 min-width-120 profile-dsk-btn margin-btm-5"
                            small
                            dark
                            color="orange darken-1"
                            style="justify-content:space-between"
                          >
                            <v-icon dark>
                              mdi-file-table-box-multiple-outline</v-icon
                            >&nbsp;Карта
                          </v-btn>
                        </template>
                        <span>Посмотреть карту</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :href="report.demo_report_shop_url"
                            v-bind:class="{
                              btn_visible: !report.demo_report_pdf_url
                            }"
                            target="_blank"
                            class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                          >
                            <v-icon dark>mdi-file-pdf-box</v-icon>&nbsp;Купить
                          </v-btn>
                        </template>
                        <span>Купить PDF</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :href="report.demo_report_pdf_url"
                            v-bind:class="{
                              btn_visible:
                                report.demo_report_pdf_url &&
                                report.first_name &&
                                report.city
                            }"
                            target="_blank"
                            class="download-pdf mx-2 theme--dark min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                          >
                            <v-icon dark>mdi-file-pdf-box</v-icon>&nbsp;Скачать
                          </v-btn>
                        </template>
                        <span>Скачать PDF</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="removeReport(report.id)"
                            v-bind:class="{
                              btn_visible: !report.demo_report_pdf_url
                            }"
                            class="mx-2 min-width-120 profile-dsk-btn margin-btm-5 btn_novisible"
                            small
                            color="error"
                          >
                            <v-icon dark>mdi-delete</v-icon>&nbsp;Удалить
                          </v-btn>
                        </template>
                        <span>Удалить</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row v-if="report.is_stored" class="no-desktop">
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-0"
                    >
                      <div
                        class="product-payed-title mob-padding-bottom-10"
                      ></div>
                      <div class="mob-display-flex">
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="report.first_name">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ report.first_name }}</span>
                            </li>
                            <li v-if="report.email">
                              <v-icon>mdi-at</v-icon>
                              <span>{{ report.email }}</span>
                            </li>
                            <li v-if="report.birthday">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>
                                {{ dateFormatting(report.birthday) }}
                              </span>
                            </li>
                            <li v-if="report.city">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ report.city.title }}</span>
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="report.chart_type">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ report.chart_type }}</span>
                            </li>
                            <li v-if="report.chart_profile">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>Профиль {{ report.chart_profile }}</span>
                            </li>
                          </ul>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div class="col-pd-lt-10">
                        <div style="display:flex;align-items:center">
                          <div class="display-0-24" style="width:110px">
                            Поделиться:&nbsp;
                          </div>
                          <div>
                            <v-btn
                              @click="shareReport(report.id)"
                              color="accent-4"
                              class="hd-gradient success-custom xs-small min-width-100"
                              small
                            >
                              Ссылка
                            </v-btn>
                          </div>
                        </div>
                        <!-- <div style="display:flex;align-items:center">
                          <div
                            v-if="!report.has_user_product"
                            class="display-0-24"
                            style="width:110px"
                          >
                            Заказать:&nbsp;
                          </div>
                          <div v-else class="display-0-24">
                            Куплен PDF-отчет&nbsp;
                          </div>
                          <div>
                            <v-btn
                              v-if="!report.has_user_product"
                              :href="reportsUrl"
                              target="_blank"
                              color="accent-4"
                              class="success-custom xs-small min-width-100"
                              small
                            >
                              PDF-отчет
                            </v-btn>
                          </div>
                        </div> -->
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-12-5-27-5"
                    >
                      <v-list class="padding-0">
                        <v-list-item class="padding-0">
                          <v-list-item-content
                            class="padding-top-0 padding-bottom-0"
                          >
                            <v-col
                              cols="4"
                              class="flex-center padding-0 novisible"
                              v-bind:class="{
                                visible_flex:
                                  report.demo_report_pdf_url &&
                                  report.first_name &&
                                  report.city
                              }"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-btn
                                :href="report.demo_report_pdf_url"
                                target="_blank"
                                class="download-pdf2 my-prof-controls-flex"
                                style="margin-left:-16px"
                                fab
                                small
                                icon
                                dark
                              >
                                <v-icon dark>mdi-file-pdf-box</v-icon>
                                <span
                                  class="d-flex"
                                  style="font-size:11px;margin-top:5px"
                                >
                                  Скачать
                                </span>
                              </v-btn>
                            </v-col>
                            <v-col
                              cols="4"
                              class="flex-center padding-0 novisible"
                              v-bind:class="{
                                visible_flex: !report.demo_report_pdf_url
                              }"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="removeReport(report.id)"
                                    class="my-prof-controls-flex"
                                    color="error"
                                    style="margin-left:-16px"
                                    fab
                                    small
                                    icon
                                    dark
                                  >
                                    <v-icon dark>mdi-delete</v-icon>
                                    <span
                                      class="d-flex"
                                      style="font-size:11px;margin-top:5px"
                                    >
                                      Удалить
                                    </span>
                                  </v-btn>
                                </template>
                                <span>Удалить отчет</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="4"
                              class="flex-center padding-0"
                              style="border-right: 1px solid #0000001f"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    @click="getBodygraphFromReports(i, 1)"
                                    class="my-prof-controls-flex"
                                    color="orange darken-1"
                                    fab
                                    small
                                    icon
                                    dark
                                  >
                                    <v-icon dark>
                                      mdi-file-table-box-multiple-outline</v-icon
                                    >
                                    <span
                                      class="d-flex"
                                      style="font-size:11px;margin-top:5px"
                                    >
                                      Карта
                                    </span>
                                  </v-btn>
                                </template>
                                <span>Посмотреть карту</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="4"
                              v-bind:class="{
                                visible_flex:
                                  report.demo_report_view_url &&
                                  report.first_name &&
                                  report.city
                              }"
                              class="flex-center padding-0 novisible"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    :href="report.demo_report_view_url"
                                    class="my-prof-controls-flex min-width-120 profile-dsk-btn"
                                    target="_blank"
                                    color="primary accent-4"
                                    style="margin-right:-16px"
                                    fab
                                    small
                                    icon
                                    dark
                                  >
                                    <v-icon dark>mdi-eye</v-icon>
                                    <span
                                      class="d-flex"
                                      style="font-size:11px;margin-top:5px"
                                    >
                                      Открыть
                                    </span>
                                  </v-btn>
                                </template>
                                <span>Посмотреть</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="4"
                              v-bind:class="{
                                visible_flex: !report.demo_report_pdf_url
                              }"
                              class="flex-center padding-0 novisible"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    :href="report.demo_report_shop_url"
                                    class="download-pdf2 my-prof-controls-flex"
                                    target="_blank"
                                    color="accent-4"
                                    style="margin-right:-16px"
                                    fab
                                    small
                                    icon
                                    dark
                                  >
                                    <v-icon dark>mdi-file-pdf-box</v-icon>
                                    <span
                                      class="d-flex"
                                      style="font-size:11px;margin-top:5px"
                                    >
                                      Купить
                                    </span>
                                  </v-btn>
                                </template>
                                <span>Купить демо-расшифовку</span>
                              </v-tooltip>
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="preloading"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col
                      class="mob-padding-bottom-5"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <div
                        class="display-0-24 text-center no-desktop margin-btm-12-mob"
                      >
                        Выберите действие
                      </div>
                      <v-skeleton-loader
                        height="64"
                        type="avatar"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else
                  :class="{
                    'mob-margin-btm-15': !profile.reports
                  }"
                  class="mob-padding-bottom-5 margin-top-15"
                >
                  У вас пока нет демо-расчетов.
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <br />
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="padding-2-0">
          <v-card class="mx-auto padding-15-mob-30-desk" max-width="950">
            <v-card-text class="padding-0">
              <div class="display-0-5 text--primary demo-report-header">
                <div class="mob-display-block">
                  <span
                    :class="{
                      'mob-margin-top-15': !profile.composites
                    }"
                    class="margin-top-5 margin-rt-25-nomob min-width-165-media375"
                  >
                    Совместимость:
                  </span>
                  <span class="no-mobile">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="newComposite"
                          class="mx-2 margin-top-10-mob margin-btm-5 mob-block"
                          small
                          color="primary accent-4"
                        >
                          <v-icon left class="mob-no-margin-right">
                            mdi-plus
                          </v-icon>
                          <span class="no-mobile">Новый расчет</span>
                        </v-btn>
                      </template>
                      <span>Создать новую Совместимость</span>
                    </v-tooltip>
                  </span>
                  <div class="no-desktop margin-top-10">
                    <v-list class="padding-0">
                      <v-list-item class="padding-0">
                        <v-list-item-content
                          class="padding-top-0 padding-bottom-0"
                        >
                          <v-col cols="12" class="flex-center padding-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  @click="newComposite"
                                  class="my-prof-controls-flex"
                                  color="primary accent-4"
                                  fab
                                  small
                                  icon
                                  dark
                                >
                                  <v-icon dark>mdi-plus</v-icon>
                                  <span
                                    class="d-flex"
                                    style="font-size:11px;margin-top:5px"
                                  >
                                    Новый расчет
                                  </span>
                                </v-btn>
                              </template>
                              <span>Создать новую Совместимость</span>
                            </v-tooltip>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </div>
              <div
                v-if="profile.composites && profile.composites.length > 7"
                class="reports-search-input"
              >
                <v-text-field
                  v-model="searchReportsComposite"
                  @input="
                    searchFromReports(searchReportsComposite, 'composites')
                  "
                  label="Поиск по имени"
                  append-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <div v-if="profile.composites">
                <div
                  v-for="(composite, i) in composites"
                  :key="i"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row v-if="composite.is_stored" class="no-mobile">
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <ul class="ul-no-li-style min-height-75-nomob">
                        <li v-if="composite.first_name">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ composite.first_name }}</span>
                        </li>
                        <li v-if="composite.birthday">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(composite.birthday) }}</span>
                        </li>
                        <li v-if="composite.city">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ composite.city.title }}</span>
                        </li>
                      </ul>
                      <ul class="ul-no-li-style min-height-75-nomob no-desktop">
                        <li class="composite-plus-18">
                          &nbsp;
                        </li>
                        <li v-if="composite.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ composite.chart_type }}</span>
                        </li>
                        <li v-if="composite.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ composite.chart_profile }}</span>
                        </li>
                      </ul>
                      <ul class="ul-no-li-style">
                        <li class="composite-plus-18">
                          &nbsp;
                        </li>
                        <li v-if="composite.first_name2">
                          <v-icon>mdi-account</v-icon>
                          <span>{{ composite.first_name2 }}</span>
                        </li>
                        <li v-if="composite.birthday2">
                          <v-icon>mdi-calendar-today</v-icon>
                          <span>{{ dateFormatting(composite.birthday2) }}</span>
                        </li>
                        <li v-if="composite.city2">
                          <v-icon>mdi-city-variant</v-icon>
                          <span>{{ composite.city2.title }}</span>
                        </li>
                      </ul>
                      <ul class="ul-no-li-style no-desktop">
                        <li v-if="composite.chart_type2">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ composite.chart_type2 }}</span>
                        </li>
                        <li v-if="composite.chart_profile2">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ composite.chart_profile2 }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="no-mobile padding-top-35"
                    >
                      <ul class="ul-no-li-style min-height-75-nomob">
                        <li class="composite-plus-18">
                          &nbsp;
                        </li>
                        <li v-if="composite.chart_type">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ composite.chart_type }}</span>
                        </li>
                        <li v-if="composite.chart_profile">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ composite.chart_profile }}</span>
                        </li>
                      </ul>
                      <ul class="ul-no-li-style">
                        <li class="composite-plus-18">
                          &nbsp;
                        </li>
                        <li v-if="composite.chart_type2">
                          <v-icon>mdi-account-box-outline</v-icon>
                          <span>{{ composite.chart_type2 }}</span>
                        </li>
                        <li v-if="composite.chart_profile2">
                          <v-icon>mdi-account-circle</v-icon>
                          <span>Профиль {{ composite.chart_profile2 }}</span>
                        </li>
                      </ul>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="padding-top-35"
                    >
                      <div class="col-pd-lt-10">
                        <div
                          v-if="!composite.has_user_product"
                          class="divider-pro"
                        >
                          Заказать:
                        </div>
                        <div v-else class="divider-pro">
                          Куплен PDF-отчет
                        </div>
                        <div>
                          <v-btn
                            v-if="!composite.has_user_product"
                            :href="
                              compositeShopUrl +
                                composite.id +
                                '~rf' +
                                User.ext_reference_code
                            "
                            target="_blank"
                            color="accent-4"
                            class="margin-btm-10 success-custom xs-small"
                            small
                          >
                            PDF-отчет
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      class="padding-top-35"
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="getBodygraphByComposite(composite.id)"
                            class="mx-2 min-width-120 margin-btm-5 profile-dsk-btn"
                            small
                            color="primary"
                          >
                            <v-icon dark>mdi-eye</v-icon>&nbsp;Открыть
                          </v-btn>
                        </template>
                        <span>Посмотреть</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="removeComposite(composite.id)"
                            class="mx-2 min-width-120 margin-btm-5 profile-dsk-btn"
                            small
                            color="error"
                          >
                            <v-icon dark>mdi-delete</v-icon>&nbsp;Удалить
                          </v-btn>
                        </template>
                        <span>Удалить</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row v-if="composite.is_stored" class="no-desktop">
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-bottom-0"
                    >
                      <div
                        class="product-payed-title mob-padding-bottom-10"
                      ></div>
                      <div class="mob-display-flex">
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li v-if="composite.first_name">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ composite.first_name }}</span>
                            </li>
                            <li v-if="composite.birthday">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>{{
                                dateFormatting(composite.birthday)
                              }}</span>
                            </li>
                            <li v-if="composite.city">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ composite.city.title }}</span>
                            </li>
                          </ul>
                          <ul class="ul-no-li-style">
                            <li class="composite-plus-18">
                              &nbsp;
                            </li>
                            <li v-if="composite.first_name2">
                              <v-icon>mdi-account</v-icon>
                              <span>{{ composite.first_name2 }}</span>
                            </li>
                            <li v-if="composite.birthday2">
                              <v-icon>mdi-calendar-today</v-icon>
                              <span>{{
                                dateFormatting(composite.birthday2)
                              }}</span>
                            </li>
                            <li v-if="composite.city2">
                              <v-icon>mdi-city-variant</v-icon>
                              <span>{{ composite.city2.title }}</span>
                            </li>
                          </ul>
                        </v-col>
                        <v-col cols="6" sm="6" class="nopadding">
                          <ul class="ul-no-li-style">
                            <li class="composite-plus-18">&nbsp;</li>
                            <li v-if="composite.chart_type">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ composite.chart_type }}</span>
                            </li>
                            <li v-if="composite.chart_profile">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>
                                Профиль {{ composite.chart_profile }}
                              </span>
                            </li>
                          </ul>
                          <ul class="ul-no-li-style">
                            <li class="composite-plus-18">
                              &nbsp;
                            </li>
                            <li v-if="composite.chart_type2">
                              <v-icon>mdi-account-box-outline</v-icon>
                              <span>{{ composite.chart_type2 }}</span>
                            </li>
                            <li v-if="composite.chart_profile2">
                              <v-icon>mdi-account-circle</v-icon>
                              <span>
                                Профиль {{ composite.chart_profile }}
                              </span>
                            </li>
                          </ul>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div class="col-pd-lt-10">
                        <div style="display:flex;align-items:baseline">
                          <div
                            v-if="!composite.has_user_product"
                            class="display-0-24"
                          >
                            Заказать:&nbsp;
                          </div>
                          <div v-else class="display-0-24">
                            Куплен PDF-отчет&nbsp;
                          </div>
                          <div>
                            <v-btn
                              v-if="!composite.has_user_product"
                              :href="
                                compositeShopUrl +
                                  composite.id +
                                  '~rf' +
                                  User.ext_reference_code
                              "
                              target="_blank"
                              color="accent-4"
                              class="margin-btm-10 success-custom xs-small"
                              small
                            >
                              PDF-отчет
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      xl="3"
                      class="mob-padding-12-5-27-5"
                    >
                      <v-list class="padding-0">
                        <v-list class="padding-0">
                          <v-list-item class="padding-0">
                            <v-list-item-content
                              class="padding-top-0 padding-bottom-0"
                            >
                              <v-col
                                cols="6"
                                class="flex-center padding-0"
                                style="border-right: 1px solid #0000001f"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      @click="removeComposite(composite.id)"
                                      class="my-prof-controls-flex"
                                      color="error"
                                      style="margin-left:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-delete</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Удалить
                                      </span>
                                    </v-btn>
                                  </template>
                                  <span>Удалить</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="6" class="flex-center padding-0">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      @click="
                                        getBodygraphByComposite(composite.id)
                                      "
                                      class="my-prof-controls-flex"
                                      color="primary accent-4"
                                      style="margin-right:-16px"
                                      fab
                                      small
                                      icon
                                      dark
                                    >
                                      <v-icon dark>mdi-eye</v-icon>
                                      <span
                                        class="d-flex"
                                        style="font-size:11px;margin-top:5px"
                                      >
                                        Открыть
                                      </span>
                                    </v-btn>
                                  </template>
                                  <span>Посмотреть</span>
                                </v-tooltip>
                              </v-col>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="preloading"
                  class="text--primary d-flex flex-no-wrap justify-space-between display-mob-b-bt"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <v-skeleton-loader
                        height="64"
                        type="paragraph"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                      <div
                        class="display-0-24 text-center no-desktop margin-btm-12-mob"
                      >
                        Выберите действие
                      </div>
                      <v-skeleton-loader
                        height="64"
                        type="avatar"
                        class="mx-auto flex-v-center"
                      >
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-else
                  :class="{
                    'mob-margin-btm-15': !profile.composites
                  }"
                  class="mob-padding-bottom-5 margin-top-15"
                >
                  У вас пока нет демо-расчета.
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <div v-if="!User.isLogged" style="height:280px"></div>
    <v-dialog
      v-model="payWarningReportDialog"
      persistent
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <!-- <v-btn @click="payWarningReportDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <span
                  v-html="getMessage('pay_warning_report_dialog')"
                  style="display:block;"
                >
                </span>
                <div
                  v-html="allPayReportInfo"
                  class="all-pay-report-info"
                ></div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="10" sm="4" md="4" lg="4" xl="5" class="text-center">
                <v-checkbox
                  v-model="checkboxAgree"
                  label="Проверил(а) и подтверждаю"
                  class="checkbox-text-nowrap"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              v-if="isPayCompositeReport"
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="
                  payWarningReportDialog = false;
                  isNumComp = 1;
                  payCompositeDialog(tempComposite);
                "
                color="primary accent-4"
                class="small-med mx-2 hd-gradient min-width-235"
                style="margin-bottom:10px"
                >Вернуться назад</v-btn
              >
              <v-btn
                :disabled="!checkboxAgree"
                @click="
                  payWarningReportDialog = false;
                  $v.$reset();
                  validateCompReport();
                "
                color="primary accent-4"
                class="small-med mx-2 hd-gradient min-width-235"
                style="margin-bottom:10px"
              >
                <span style="display:flex;align-items:center">
                  Создать PDF-отчёт&nbsp;<img
                    src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                    width="20"
                  />
                </span>
              </v-btn>
            </v-col>
            <v-col
              v-else
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="
                  payWarningReportDialog = false;
                  payreportdialog = true;
                "
                color="primary accent-4"
                class="small-med mx-2 hd-gradient min-width-235"
                style="margin-bottom:10px"
                >Вернуться назад</v-btn
              >
              <v-btn
                :disabled="!checkboxAgree"
                @click="
                  payWarningReportDialog = false;
                  $v.$reset();
                  validateNewReport();
                "
                color="primary accent-4"
                class="small-med mx-2 hd-gradient min-width-235"
                style="margin-bottom:10px"
              >
                <span
                  v-if="method === 'pro'"
                  style="display:flex;align-items:center"
                >
                  Построить карту&nbsp;<img
                    src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                    width="20"
                  />
                </span>
                <span v-else style="display:flex;align-items:center">
                  Создать PDF-отчёт&nbsp;<img
                    src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                    width="20"
                  />
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="payreportdialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline-d-blue modal-header" color="">
            Данные для расшифровки
          </span>
          <v-btn @click="payreportdialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <form>
                <v-card-text>
                  <v-row>
                    <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                      <v-select
                        v-on:change="onDayChange"
                        v-model="day"
                        :hint="`день`"
                        :items="days"
                        :disabled="isUsed"
                        item-text="day"
                        label="Выберите день"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                      <v-select
                        ref="monthSelect"
                        v-on:change="onMonthChange"
                        v-model="month"
                        :hint="`месяц`"
                        :items="months"
                        :disabled="isUsed"
                        item-text="month"
                        label="Выберите месяц"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                      <v-select
                        ref="yearSelect"
                        v-on:change="onYearChange"
                        v-model="year"
                        :hint="`год`"
                        :items="years"
                        :disabled="isUsed"
                        item-text="year"
                        label="Выберите год"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="2" md="2" lg="2" xl="2">
                      <v-select
                        ref="hourSelect"
                        v-on:change="onHourChange"
                        v-model="hour"
                        :hint="`часы`"
                        :items="hours"
                        :disabled="isUsed"
                        item-text="hour"
                        label="Выберите час"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="2" md="2" lg="2" xl="2">
                      <v-select
                        ref="minuteSelect"
                        v-on:change="onMinuteChange"
                        v-model="minute"
                        :hint="`минуты`"
                        :items="minutes"
                        :disabled="isUsed"
                        item-text="minute"
                        label="Выберите минуту"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <v-autocomplete
                        ref="cityInput"
                        v-on:change="onCityChange"
                        v-model="city"
                        :items="cities"
                        :loading="isLoading"
                        :search-input.sync="search"
                        :error-messages="cityErrors"
                        :no-filter="true"
                        @input="$v.city.$touch()"
                        @blur="$v.city.$touch()"
                        chips
                        clearable
                        hide-details
                        hide-selected
                        item-text="title"
                        item-value="id"
                        label="Город рождения"
                        required
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title v-if="!search || isLoading">
                              Ближайший крупный <strong>город</strong>...
                            </v-list-item-title>
                            <v-list-item-title
                              v-else
                              class="v-list-item-title-custom"
                            >
                              <span
                                v-if="!searchResult"
                                v-html="getMessage('tooltip_find_city')"
                              ></span>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            v-on="on"
                            v-bind="attr"
                            :change="onCityChangeItem(item)"
                            :input-value="selected"
                            color="white"
                            class="black--text"
                          >
                            <span v-text="item.title"></span>&nbsp;
                            <img
                              :src="
                                'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                  item.country.iso +
                                  '.svg'
                              "
                              height="15"
                            />
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.country.title"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <img
                              :src="
                                'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                  item.country.iso +
                                  '.svg'
                              "
                              height="15"
                            />
                          </v-list-item-action>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-text-field
                        ref="firstnameInput"
                        v-model="firstname"
                        :error-messages="nameErrors"
                        :counter="35"
                        @input="$v.firstname.$touch()"
                        @blur="$v.firstname.$touch()"
                        height="42"
                        label="Имя"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row class="d-flex justify-center">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="text-center"
                    >
                      <v-btn
                        @click="
                          payreportdialog = false;
                          payWarningReportDialog = true;
                        "
                        rounded
                        large
                        color="primary"
                        class="v-btn-custom v-btn-custom--bg-f width-100-mob"
                      >
                        Далее...
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </form>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="paycompositedialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span
            v-if="isNumComp === 1"
            class="headline-d-blue modal-header2 сolor-comp-chart1"
          >
            Введите данные для первой карты
          </span>
          <span
            v-if="isNumComp === 2"
            class="headline-d-blue modal-header2 сolor-comp-chart2"
          >
            Теперь укажите данные для второго человека
          </span>
          <v-btn
            @click="
              paycompositedialog = false;
              clearFormFields();
            "
            icon
            absolute
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <form>
                <v-card-text>
                  <v-row>
                    <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                      <v-select
                        v-on:change="onDayChange"
                        v-model="day"
                        :hint="`день`"
                        :items="days"
                        :disabled="isUsed"
                        item-text="day"
                        label="Выберите день"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                      <v-select
                        ref="monthSelect"
                        v-on:change="onMonthChange"
                        v-model="month"
                        :hint="`месяц`"
                        :items="months"
                        :disabled="isUsed"
                        item-text="month"
                        label="Выберите месяц"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                      <v-select
                        ref="yearSelect"
                        v-on:change="onYearChange"
                        v-model="year"
                        :hint="`год`"
                        :items="years"
                        :disabled="isUsed"
                        item-text="year"
                        label="Выберите год"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="2" md="2" lg="2" xl="2">
                      <v-select
                        ref="hourSelect"
                        v-on:change="onHourChange"
                        v-model="hour"
                        :hint="`часы`"
                        :items="hours"
                        :disabled="isUsed"
                        item-text="hour"
                        label="Выберите час"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="2" md="2" lg="2" xl="2">
                      <v-select
                        ref="minuteSelect"
                        v-on:change="onMinuteChange"
                        v-model="minute"
                        :hint="`минуты`"
                        :items="minutes"
                        :disabled="isUsed"
                        item-text="minute"
                        label="Выберите минуту"
                        persistent-hint
                        return-object
                        single-line
                        required
                        menu-props="auto, bottom, offset-y"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <v-autocomplete
                        ref="cityInput"
                        v-on:change="onCityChange"
                        v-model="city"
                        :items="cities"
                        :disabled="isUsed"
                        :loading="isLoading"
                        :search-input.sync="search"
                        :error-messages="cityErrors"
                        :no-filter="true"
                        @input="$v.city.$touch()"
                        @blur="$v.city.$touch()"
                        chips
                        clearable
                        hide-details
                        hide-selected
                        item-text="title"
                        item-value="id"
                        label="Город рождения"
                        required
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title v-if="!search || isLoading">
                              Ближайший крупный <strong>город</strong>...
                            </v-list-item-title>
                            <v-list-item-title
                              v-else
                              class="v-list-item-title-custom"
                            >
                              <span
                                v-if="!searchResult"
                                v-html="getMessage('tooltip_find_city')"
                              ></span>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            v-on="on"
                            v-bind="attr"
                            :change="onCityChangeItem(item)"
                            :input-value="selected"
                            color="white"
                            class="black--text"
                          >
                            <span v-text="item.title"></span>&nbsp;
                            <img
                              :src="
                                'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                  item.country.iso +
                                  '.svg'
                              "
                              height="15"
                            />
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.country.title"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <!-- <img
                              :src="
                                'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                  item.country.iso +
                                  '.svg'
                              "
                              height="15"
                            /> -->
                          </v-list-item-action>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="2"></v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-text-field
                        ref="firstnameInput"
                        v-model="firstname"
                        :error-messages="nameErrors"
                        :counter="35"
                        :disabled="isUsed"
                        @input="$v.firstname.$touch()"
                        @blur="$v.firstname.$touch()"
                        height="42"
                        label="Имя"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row class="d-flex justify-center">
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="text-center"
                    >
                      <v-btn
                        v-if="isNumComp === 1"
                        @click="validateCompReport"
                        rounded
                        large
                        color="primary"
                        class="v-btn-custom v-btn-custom--bg-f width-100-mob"
                      >
                        Далее...
                      </v-btn>
                      <v-btn
                        v-if="isNumComp === 2"
                        @click="
                          paycompositedialog = false;
                          payWarningReportDialog = true;
                        "
                        rounded
                        large
                        color="primary"
                        class="v-btn-custom v-btn-custom--bg-f width-100-mob"
                      >
                        Далее...
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </form>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="helpaydialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      max-width="370px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="helpaydialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center padding-0 helpaydialog display-0-24-no-line-h"
              >
                <div
                  v-if="DarkTheme.enable"
                  v-html="helpaydialogmessage"
                  class="product-tooltip product-tooltip-dark"
                  style="display:block;text-align:left;margin-bottom:33px"
                ></div>
                <div
                  v-else
                  v-html="helpaydialogmessage"
                  class="product-tooltip"
                  style="display:block;text-align:left;margin-bottom:33px"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="helpaydialogAddSec"
      scrollable
      max-width="650px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="helpaydialogAddSec = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center padding-0 helpaydialog display-0-24-no-line-h"
              >
                <div
                  v-if="DarkTheme.enable"
                  v-html="helpaydialogmessage"
                  class="product-tooltip product-tooltip-dark"
                  style="display:block;text-align:left;font-size:16px;font-weight:300"
                ></div>
                <div
                  v-else
                  v-html="helpaydialogmessage"
                  class="product-tooltip"
                  style="display:block;text-align:left;font-size:16px;font-weight:300"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="helpaydialogAddSec = false"
                :href="helpayDialogAddSecLink"
                target="_blank"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Купить</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="helpaydialog2"
      scrollable
      max-width="650px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="helpaydialog2 = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center padding-0 helpaydialog display-0-24-no-line-h"
              >
                <div
                  v-if="DarkTheme.enable"
                  v-html="helpaydialogmessage"
                  class="product-tooltip product-tooltip-dark"
                  style="display:block;text-align:center;margin:30px 0 33px 0"
                ></div>
                <div
                  v-else
                  v-html="helpaydialogmessage"
                  class="product-tooltip"
                  style="display:block;text-align:center;margin:30px 0 33px 0"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                href="/#/products"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Смотреть</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="helpayDialogChildDialog"
      scrollable
      max-width="650px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="helpayDialogChildDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center padding-0 helpaydialog display-0-24-no-line-h"
              >
                <div
                  v-if="DarkTheme.enable"
                  v-html="helpaydialogmessage"
                  class="product-tooltip product-tooltip-dark"
                  style="display:block;text-align:center;font-weight:300;font-size:16px;margin:30px 0 33px 0"
                ></div>
                <div
                  v-else
                  v-html="helpaydialogmessage"
                  class="product-tooltip"
                  style="display:block;text-align:center;font-weight:300;font-size:16px;margin:30px 0 33px 0"
                ></div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="helpayDialogChildDialog = false"
                :href="helpayDialogChildLink"
                target="_blank"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Купить</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="proReportResultDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="proReportResultDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-no-line-h"
              >
                <span
                  v-html="proReportResultMessage"
                  style="display:block;margin-bottom:33px"
                >
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                v-if="proReportResultUrl"
                @click="proReportResultDialog = false"
                :href="proReportResultUrl"
                target="_blank"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Открыть</v-btn
              >
              <v-btn
                v-if="proReportAc.length && !proReportResultUrl"
                @click="
                  getBodygraphFromReports(0, 1, 'pro_activated'),
                    (proReportResultDialog = false)
                "
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Открыть</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="withdrawalDialog"
      max-width="450px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="withdrawalDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-form ref="wdform" lazy-validation style="width:100%">
                <v-col
                  cols="12"
                  class="text-center no-top-bottom-padding display-0-24-no-line-h"
                >
                  <v-col cols="12" sm="12">
                    <v-subheader class="pl-0">Выберите сумму:</v-subheader>
                    <v-slider
                      v-model="slider"
                      :max="max"
                      :min="min"
                      step="100"
                      hide-details
                      class="align-center"
                    >
                      <template v-slot:append>
                        <v-text-field
                          v-model="slider"
                          :max="max"
                          :min="min"
                          step="100"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                        ></v-text-field>
                      </template>
                    </v-slider>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="12">
                    <v-select
                      v-model="wdmethod"
                      :items="withdrawalMethod"
                      :rules="[(v) => !!v || 'Выберите способ вывода']"
                      required
                      label="Выберите способ вывода"
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="12" md="12">
                    <v-textarea
                      v-model="wddetail"
                      :rules="[(v) => !!v || 'Укажите реквизиты вывода']"
                      required
                      name="input-7-1"
                      label="Уточните куда:"
                      value=""
                      rows="2"
                      hint="Укажите в свободной форме реквизиты (номер карты / кошелька и т. п.)"
                    ></v-textarea>
                  </v-col>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="validateWithdrawal"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Подать завяку</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="tooltipsCountInfoDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="tooltipsCountInfoDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
              >
                <span
                  v-html="tooltipsCountInfoText"
                  style="display:block;margin:31px 0 0 0;font-size:16px;line-height:20px"
                >
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                v-if="Profile.full_tooltips_count > 0"
                @click="(tooltipsCountInfoDialog = false), toHome()"
                target="_blank"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Сделать расчет</v-btn
              >
              <v-btn
                v-else
                @click="tooltipsCountInfoDialog = false"
                :href="tooltipsCountInfoUrl"
                target="_blank"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Подробнее</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="BuyProductAsGiftDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="BuyProductAsGiftDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-form ref="giftform" lazy-validation style="width:100%">
              <v-row>
                <v-col
                  cols="12"
                  class="text-center no-top-bottom-padding display-0-24-mob-p-d"
                >
                  <v-col class="d-flex margin-0-auto" cols="11" sm="6">
                    <span
                      v-html="getMessage('pay_report_as_gift_dialog')"
                      style="display:block;text-align:left"
                    >
                    </span>
                  </v-col>
                  <v-col class="d-flex margin-0-auto" cols="11" sm="6">
                    <v-select
                      v-model="reportType"
                      :items="reportTypeItems"
                      :rules="[
                        (v) =>
                          !!v ||
                          'Выберите тип расшифровки, которую хотите подарить'
                      ]"
                      item-color="primary"
                      required
                      label="Выберите расшифровку"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.text }} — {{ data.item.price }}₽
                      </template>
                      <template slot="item" slot-scope="data">
                        <div class="custom-v-select-item">
                          <span>{{ data.item.text }}</span>
                          <span>{{ data.item.price }}₽</span>
                        </div>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="d-flex margin-0-auto" cols="11" sm="6">
                    <v-text-field
                      v-model="giftsEmail"
                      :rules="[rules.required, rules.emailGift]"
                      required
                      label="Укажите email:"
                      hint="Укажите email, куда отправится подарок"
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="text-center padding-bottom-52"
            >
              <v-btn
                @click="validateGiftForm"
                color="primary accent-4"
                class="small-med mx-2 hd-gradient"
                >Подарить</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="staticDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      max-width="900px"
      content-class="v-dialog-max-height-800"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span class="headline" style="font-size: 1.3rem !important"
            >&nbsp;</span
          >
          <v-btn @click="staticDialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="no-top-bottom-padding">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center no-top-bottom-padding display-0-24-mob-p-d"
              >
                <div class="partner-stat">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Дата</th>
                          <th class="text-left">
                            <span class="d-none d-sm-block">Событие</span>
                            <span class="d-flex d-sm-none">Соб.</span>
                          </th>
                          <th class="text-left">Сумма</th>
                          <th class="text-left">Статус</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="transactionsAll.length === 0">
                          <td colspan="4" style="text-align:center">
                            Продаж еще не было
                          </td>
                        </tr>
                        <tr v-for="(item, i) in transactionsAll" :key="i">
                          <td>{{ item.payment_date }}</td>
                          <td v-if="item.is_withdrawal">
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="red--text text--darken-4"
                                >
                                  mdi-arrow-right-bold
                                </v-icon>
                              </template>
                              <span>вывод средств</span>
                            </v-tooltip>
                          </td>
                          <td v-else>
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="green--text-custom"
                                >
                                  mdi-arrow-left-bold
                                </v-icon>
                              </template>
                              <span>партнерское начисление</span>
                            </v-tooltip>
                          </td>
                          <td
                            v-if="item.is_withdrawal"
                            class="red--text text--darken-3"
                          >
                            {{ item.amount_formatted }}
                          </td>
                          <td v-else class="green--text-custom">
                            {{ item.amount_formatted }}
                          </td>
                          <td v-if="item.status_id === 1">
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="green--text-custom"
                                >
                                  mdi-check-circle
                                </v-icon>
                              </template>
                              <span>{{ item.status }}</span>
                            </v-tooltip>
                          </td>
                          <td v-else-if="item.status_id === 0">
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="amber--text text--darken-3"
                                >
                                  mdi-clock-time-four
                                </v-icon>
                              </template>
                              <span>{{ item.status }}</span>
                            </v-tooltip>
                          </td>
                          <td v-else>
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="red--text text--darken-4"
                                >
                                  mdi-close-circle
                                </v-icon>
                              </template>
                              <span>{{ item.status }}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LoginDialog :showDialog.sync="showDialog" @close="closeDialog" />
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import FullTooltipsSwitcher from "@/components/parts/FullTooltipsSwitcher.vue";

export default {
  name: "Profile",
  components: { LoginDialog, FullTooltipsSwitcher },
  mixins: [
    commonInitAppMethods,
    commonReportingMethods,
    commonErrorsMethods,
    validationMixin
  ],
  validations: {
    firstname: { required, maxLength: maxLength(35) },
    day: { required },
    month: { required },
    year: { required },
    hour: { required },
    minute: { required },
    city: { required }
  },
  data() {
    return {
      showDialog: false,
      demoProducts: [],
      composites: [],
      userProducts: [],
      careerUserProducts: [],
      childUserProducts: [],
      compositeUserProducts: [],
      proReports: [],
      loading2: false,
      loading3: true,
      loader: null,
      preloading: true,
      selection: 1,
      withdrawalDialog: false,
      withdrawalMethod: [
        "Банковская карта",
        "Яндекс.деньги",
        "Qiwi кошелек",
        "Webmoney"
      ],
      wdmethod: null,
      wddetail: null,
      transactionsAll: [],
      copied: false,
      staticDialog: false,
      proReportResultDialog: false,
      proReportResultMessage: "",
      proReportResultUrl: "",
      proReportAc: [],
      payreportdialog: false,
      payWarningReportDialog: false,
      checkboxAgree: false,
      isPayCompositeReport: false,
      paycompositedialog: false,
      tempComposite: {},
      BuyProductAsGiftDialog: false,
      tooltipsCountInfoDialog: false,
      tooltipsCountInfoText: null,
      tooltipsCountInfoUrl:
        "https://shop.human-design.space/order/demo/today/?utm_medium=organic&utm_source=kabinet",
      isNumComp: 1,
      compReport1: {},
      compTempData: {},
      helpaydialog: false,
      helpaydialogAddSec: false,
      helpaydialog2: false,
      helpayDialogChildDialog: false,
      /* helpayDialogChildLink:
        "https://shop.human-design.space/order/babypart" +
        "?tag=rf" +
        this.User.ext_reference_code, */
      helpaydialogmessage: "",
      helpayDialogAddSecLink: "",
      userProductId: null,
      isUsed: false,
      reportId: null,
      isLoading: false,
      cities: [],
      city: null,
      city1Title: "",
      city2Title: "",
      search: null,
      searchResult: true,
      searchReports: null,
      searchReportsComposite: null,
      searchUserProducts: null,
      searchСareerUserProducts: null,
      searchСhildUserProducts: null,
      searchСompositeUserProducts: null,
      searchProReports: null,
      openTooltips: {},
      method: "",
      maintenanceWork: false,
      products: [],
      reportType: null,
      reportTypeItems: [],
      BuyProductAsGiftUrl: "",
      salesProdictUrl:
        "https://shop.human-design.space/order/elektronnaya_basa:rasshirenie_rasshifrovki:best_rasshifrovka:career:sovmestimost:babychart/network/",
      careerProductUrl: "https://shop.human-design.space/order/career/network/",
      noSalesCareerProductUrl: "https://shop.human-design.space/order/career",
      childProductUrl:
        "https://shop.human-design.space/order/babychart/network/",
      noSalesChildProductUrl:
        "https://shop.human-design.space/order/babychart/",
      compositeProductUrl:
        "https://shop.human-design.space/order/sovmestimost/network/",
      noSalesCompositeProductUrl:
        "https://shop.human-design.space/order/sovmestimost/",
      reportsUrl:
        "https://human-design.space/rasshifrovka-vashego-bodigrafa/?utm_medium=organic&utm_source=kabup",
      giftsEmail: "",
      slider: this.calcSlider(),
      rules: {
        required: (value) => !!value || "Обязательный параметр.",
        emailGift: (value) => {
          if (value) value = value.trim();
          if (value !== this.User.email) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Неверный e-mail.";
          } else {
            return "Подарочный email не должен совпадать с вашим.";
          }
        }
      }
    };
  },
  computed: {
    ...mapState(["dateTime"]),
    ...mapState({
      User: (state) => state.account.User,
      Profile: (state) => state.account.Profile,
      ConfApp: (state) => state.conf.ConfApp,
      Messages: (state) => state.conf.Messages,
      HttpOptions: (state) => state.conf.http_options,
      DarkTheme: (state) => state.conf.DarkTheme
    }),
    days() {
      return this.dateTime.days;
    },
    months() {
      return this.dateTime.months;
    },
    years() {
      return this.cutAllYearsMoreCurrent(this.dateTime.years);
    },
    hours() {
      return this.dateTime.hours;
    },
    minutes() {
      return this.dateTime.minutes;
    },
    reportidPro: {
      get() {
        return this.User.reportidPro;
      },
      set(num) {
        this.$store.commit("account/update_report_id_pro", num);
      }
    },
    firstname: {
      get() {
        return this.User.name;
      },
      set(value) {
        this.$store.commit("account/update_name", value);
      }
    },
    day: {
      get() {
        return this.User.day;
      },
      set(value) {
        this.$store.commit("account/update_day", value);
      }
    },
    month: {
      get() {
        return this.User.month;
      },
      set(value) {
        this.$store.commit("account/update_month", value);
      }
    },
    year: {
      get() {
        return this.User.year;
      },
      set(value) {
        this.$store.commit("account/update_year", value);
      }
    },
    hour: {
      get() {
        return this.User.hour;
      },
      set(value) {
        this.$store.commit("account/update_hour", value);
      }
    },
    minute: {
      get() {
        return this.User.minute;
      },
      set(value) {
        this.$store.commit("account/update_minute", value);
      }
    },
    min: {
      get() {
        return this.User.min_withdrawal;
      },
      set(value) {
        this.$store.commit("account/update_min", value);
      }
    },
    max: {
      get() {
        return Math.floor((this.User.current_balance / 100) * 100);
      },
      set(value) {
        this.$store.commit("account/update_max", value);
      }
    },
    /* slider: {
      get() {
        return this.User.current_balance - this.User.min_withdrawal;
      },
      set(value) {
        this.$store.commit("account/update_slider", value);
      }
    }, */
    profile: {
      get() {
        return this.Profile;
      },
      set(v) {
        this.$store.commit("account/set_profile", v);
      }
    },
    firstOpenUpdates: {
      get() {
        return this.ConfApp.firstOpenUpdates;
      },
      set(v) {
        this.$store.commit("conf/update_conf_app_first_open_updates", v);
      }
    },
    firstOpenCompUpdates: {
      get() {
        return this.ConfApp.firstOpenCompUpdates;
      },
      set(v) {
        this.$store.commit("conf/update_conf_app_first_open_comp_updates", v);
      }
    },
    firstOpenChildUpdates: {
      get() {
        return this.ConfApp.firstOpenChildUpdates;
      },
      set(v) {
        this.$store.commit("conf/update_conf_app_first_open_child_updates", v);
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) return errors;
      !this.$v.firstname.maxLength &&
        errors.push("Имя должно быть не более 35 символов.");
      !this.$v.firstname.required && errors.push("Введите имя.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("Выберите город.");
      return errors;
    },
    compositeShopUrl() {
      return this.ConfApp.composite_shop_url + "&tag=r";
    },
    allPayReportInfo() {
      let info;
      if (this.method === "composite") {
        info = `<b>Вы ввели следующие данные:</b><br><br><b>Первая карта:</b><br><b>Имя - ${this.compReport1.first_name}</b><br><b>Город - ${this.city1Title}</b><br><br><b>Дата и время рождения - ${this.compReport1.birthday}</b><br><br><b>Вторая карта:</b><br><b>Имя - ${this.firstname}</b><br><b>Город - ${this.city2Title}</b><br><br><b>Дата и время рождения - ${this.day.day}-${this.month.id}-${this.year.year} ${this.hour.hour}:${this.minute.minute}</b>`;
      } else {
        info = `<b>Вы ввели следующие данные:</b><br><br><b>Имя - ${this.firstname}</b><br><b>Город - ${this.city1Title}</b><br><br><b>Дата и время рождения - ${this.day.day}-${this.month.id}-${this.year.year} ${this.hour.hour}:${this.minute.minute}</b>`;
      }
      return info;
    },
    helpayDialogChildLink() {
      return (
        "https://shop.human-design.space/order/babypart" +
        "?tag=rf" +
        this.User.ext_reference_code
      );
    }
  },
  watch: {
    search(val) {
      if (!val) return;
      this.fetchCitiesDebounced(val);
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      const data = () => {
        this[l] = false;
        this.getGetProfile();
        this.$store.commit("UPDATE_PURCHASES", null);
      };
      setTimeout(() => data(), 1500);
      this.loader = null;
    },
    reportType() {
      this.mathProductAsGiftUrl();
    },
    giftsEmail() {
      this.mathProductAsGiftUrl();
    },
    payreportdialog() {
      if (!this.payreportdialog) {
        this.checkboxAgree = false;
      }
    },
    paycompositedialog() {
      if (!this.paycompositedialog) {
        this.checkboxAgree = false;
      }
    },
    payWarningReportDialog() {
      if (!this.payWarningReportDialog) {
        this.checkboxAgree = false;
      }
    }
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "UPDATE_IS_STORED":
          this.getGetProfile();
          break;
        case "CALL_UPDATE_PROFILE":
          this.getGetProfile();
          break;
        case "account/login_success":
          this.getGetProfile();
          break;
      }
    });
    this.verify();
    this.$store.commit("account/update_name", localStorage.getItem("namereg"));
    // console.log("111: ", Object.keys(this.Profile).length);
    this.getProducts();
    if (
      !Object.keys(this.Profile).length ||
      Object.keys(this.Profile).length <= 3 // hack: The state.Profile always have 3 empty property
    ) {
      // console.log("222");
      this.getGetProfile();
    } else {
      this.putDataFromProfile();
    }
    this.getSettings();
    this.initConfApp();
    this.$store.commit("account/update_path_history");
    setTimeout(() => {
      if (!this.User.isLogged) {
        this.$store.commit("conf/update_login_dialog", true);
      }
      this.preloading = false;
    }, 1500);
  },
  methods: {
    helPayDialog(html) {
      this.helpaydialog = true;
      this.helpaydialogmessage = html;
    },
    helPayDialogAdditionSection(html, url) {
      this.helpaydialogAddSec = true;
      this.helpaydialogmessage = html;
      this.helpayDialogAddSecLink = url;
    },
    helPayDialogChild(html, paylink) {
      this.helpayDialogChildDialog = true;
      this.helpaydialogmessage = html;
      this.helpayDialogChildLink = paylink;
    },
    helPayDialog2(key) {
      this.helpaydialog2 = true;
      this.helpaydialogmessage = this.Messages[key];
    },
    reserve() {
      this.loading = true;
      // setTimeout(() => (this.loading = false), 2000)
    },
    updateProducts() {
      setTimeout(() => this.getGetProfile(), 3500);
      this.clearSearchQuery();
    },
    async getGetProfile() {
      if (this.User.isLogged) {
        const successResult = await this.$store.dispatch("account/getProfile");
        if (successResult) {
          this.demoProducts = this.profile.reports;
          this.composites = this.profile.composites;
          this.userProducts = this.profile.user_products;
          this.careerUserProducts = this.profile.career_user_products;
          this.childUserProducts = this.profile.child_user_products;
          this.compositeUserProducts = this.profile.composite_user_products;
          this.proReports = this.profile.pro_reports;
          this.updateProfile(this.profile);
        }
      }
    },
    putDataFromProfile() {
      this.demoProducts = this.profile.reports;
      this.composites = this.profile.composites;
      this.userProducts = this.profile.user_products;
      this.careerUserProducts = this.profile.career_user_products;
      this.childUserProducts = this.profile.child_user_products;
      this.compositeUserProducts = this.profile.composite_user_products;
      this.proReports = this.profile.pro_reports;
      this.updateProfile(this.profile);
    },
    updateProfile(profile) {
      if (profile.user_firstname) {
        this.$store.commit("account/update_name", profile.user_firstname);
      }
      let val = false;
      if (Array.isArray(profile.career_user_products)) {
        for (const item of profile.career_user_products) {
          if (!item.is_used) {
            val = true;
            break;
          }
        }
        for (const item of profile.career_user_products) {
          if (item.is_used) {
            this.$store.commit("account/have_is_used_career_product", true);
            break;
          }
        }
      }
      if (Array.isArray(profile.child_user_products)) {
        for (const item of profile.child_user_products) {
          if (!item.is_used) {
            val = true;
            break;
          }
        }
        for (const item of profile.child_user_products) {
          if (item.is_used) {
            this.$store.commit("account/have_is_used_child_products", true);
            break;
          }
        }
      }
      if (Array.isArray(profile.composite_user_products)) {
        for (const item of profile.composite_user_products) {
          if (!item.is_used) {
            val = true;
            break;
          }
        }
        for (const item of profile.composite_user_products) {
          if (item.is_used) {
            this.$store.commit("account/have_is_used_user_composites", true);
            break;
          }
        }
      }
      if (Array.isArray(profile.pro_reports)) {
        for (const item of profile.pro_reports) {
          if (!item.is_used) {
            val = true;
            break;
          }
        }
        for (const item of profile.pro_reports) {
          if (item.is_used) {
            this.$store.commit("account/have_is_used_pro_reports", true);
            break;
          }
        }
      }
      if (Array.isArray(profile.user_products)) {
        for (const item of profile.user_products) {
          if (!item.is_used) {
            val = true;
            break;
          }
        }
        for (const item of profile.user_products) {
          if (item.is_used) {
            this.$store.commit("account/have_is_used_user_products", true);
            break;
          }
        }
      }
      this.$store.commit("account/have_is_one_unused_user_products", val);
      // this.preloading = false;
      if (!localStorage.getItem("email")) {
        localStorage.setItem("email", profile.email);
        this.$store.commit("account/update_email", profile.email);
      } else {
        this.$store.commit("account/update_email", profile.email);
      }
      if (profile.total_income) {
        this.$store.commit(
          "account/update_user_total_income",
          +profile.total_income
        );
      }
      if (profile.current_balance) {
        this.$store.commit(
          "account/update_user_current_balance",
          +profile.current_balance
        );
      }
      if (profile.reference_code) {
        this.$store.commit("account/update_user_ref", profile.reference_code);
      }
      if (profile.transactions_all) {
        this.transactionsAll = profile.transactions_all;
      }
      if (profile.referrals_count) {
        this.$store.commit(
          "account/update_user_referals_count",
          +profile.referrals_count
        );
      }
      if (profile.referrals_ctr) {
        this.$store.commit(
          "account/update_user_referals_ctr",
          profile.referrals_ctr
        );
      }
      this.loading3 = false;
    },
    onDayChange() {
      this.$refs.monthSelect.activateMenu();
      this.$refs.monthSelect.focus();
    },
    onMonthChange() {
      this.$refs.yearSelect.activateMenu();
      this.$refs.yearSelect.focus();
    },
    onYearChange() {
      this.$refs.hourSelect.activateMenu();
      this.$refs.hourSelect.focus();
    },
    onHourChange() {
      this.$refs.minuteSelect.activateMenu();
      this.$refs.minuteSelect.focus();
    },
    onMinuteChange() {
      this.$refs.cityInput.focus();
    },
    onCityChange() {
      this.$refs.cityInput.blur();
      this.$refs.firstnameInput.focus();
    },
    onCityChangeItem(city) {
      if (this.isNumComp === 1) {
        this.city1Title = city.title;
      } else {
        this.city2Title = city.title;
      }
    },
    validateNewReport() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.calcPayReport(this.method);
      }
    },
    validateCompReport() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.paycompositedialog = false;
        if (this.isNumComp === 1) {
          this.compReport1 = {};
          this.compReport1.birthday =
            this.year.year +
            "-" +
            this.month.id +
            "-" +
            this.day.day +
            " " +
            this.hour.hour +
            ":" +
            this.minute.minute;
          this.compReport1.first_name = this.firstname;
          this.compReport1.city_id = this.city;
          this.isNumComp = 2;
          this.payCompositeDialog(this.compTempData);
        } else if (this.isNumComp === 2) {
          this.calcPayComposite();
          this.tempComposite = {};
        }
      }
    },
    validateWithdrawal() {
      if (this.$refs.wdform.validate()) {
        this.withdrawal("withdrawal");
      }
    },
    buy(id) {
      if (id) {
        this.$store.commit("UPDATE_REPORT_ID_PRO", id);
      }
      this.$router.push("/products");
    },
    payReportDialog(product, method) {
      this.isPayCompositeReport = false;
      this.isUsed = false;
      if (method === "pro") {
        this.method = method;
        this.reportId = product.id;
      } else {
        this.method = method;
        if (product.is_used) this.isUsed = true;
        this.userProductId = product.id;
      }
      this.payreportdialog = true;
      // this.payWarningReportDialog = !this.payWarningReportDialog
      if (product.first_name) this.firstname = product.first_name;
      if (product.city) {
        this.cities = [product.city];
        this.city1Title = product.city.title;
        this.city = product.city.id;
      }
      if (product.birthday) {
        this.year = {
          year: product.birthday.slice(0, 4)
        };
        this.month = this.months[product.birthday.slice(5, 7) - 1];
        this.day = {
          day: product.birthday.slice(8, 10)
        };
        this.hour = {
          hour: product.birthday.slice(11, 13)
        };
        this.minute = {
          minute: product.birthday.slice(14, 16)
        };
      }
    },
    payCompositeDialog(composite) {
      this.tempComposite = composite;
      this.method = "composite";
      this.userProductId = composite.id;
      this.isUsed = false;
      if (composite.is_used) this.isUsed = true;
      this.compTempData = composite;
      if (this.isNumComp === 1) {
        if (composite.first_name) {
          this.firstname = composite.first_name;
        } else {
          this.firstname = "";
        }
        if (composite.city) {
          this.cities = [composite.city];
          this.city1Title = composite.city.title;
          this.city = composite.city.id;
        } else {
          this.cities = [];
          this.city = "";
        }
        if (composite.birthday) {
          this.year = {
            year: composite.birthday.slice(0, 4)
          };
          this.month = this.months[composite.birthday.slice(5, 7) - 1];
          this.day = {
            day: composite.birthday.slice(8, 10)
          };
          this.hour = {
            hour: composite.birthday.slice(11, 13)
          };
          this.minute = {
            minute: composite.birthday.slice(14, 16)
          };
        } else {
          this.day = { day: "15" };
          this.month = { month: "Июнь (6)", id: "06" };
          this.year = { year: "1990" };
          this.hour = { hour: "12" };
          this.minute = { minute: "30" };
        }
        this.isPayCompositeReport = true;
        // this.payWarningReportDialog = !this.payWarningReportDialog
        this.paycompositedialog = true;
      } else if (this.isNumComp === 2) {
        this.$v.$reset();
        if (composite.first_name2) {
          this.firstname = composite.first_name2;
        } else {
          this.firstname = "";
        }
        if (composite.city2) {
          this.cities = [composite.city2];
          this.city2Title = composite.city2.title;
          this.city = composite.city2.id;
        } else {
          this.cities = [];
          this.city = null;
        }
        if (composite.birthday2) {
          this.year = {
            year: composite.birthday2.slice(0, 4)
          };
          this.month = this.months[composite.birthday2.slice(5, 7) - 1];
          this.day = {
            day: composite.birthday2.slice(8, 10)
          };
          this.hour = {
            hour: composite.birthday2.slice(11, 13)
          };
          this.minute = {
            minute: composite.birthday2.slice(14, 16)
          };
        } else {
          this.day = { day: "15" };
          this.month = { month: "Июнь (6)", id: "06" };
          this.year = { year: "1990" };
          this.hour = { hour: "12" };
          this.minute = { minute: "30" };
        }
        this.paycompositedialog = true;
      }
    },
    async calcPayReport() {
      let id;
      const reportParams = {
        birthday:
          this.year.year +
          "-" +
          this.month.id +
          "-" +
          this.day.day +
          " " +
          this.hour.hour +
          ":" +
          this.minute.minute,
        city_id: this.city,
        first_name: this.firstname,
        email: this.profile.email
      };
      if (this.method === "pro") {
        id = this.reportId;
        reportParams.report_id = id;
      } else {
        id = this.userProductId;
        reportParams.user_product_id = id;
      }
      try {
        const result =
          this.method === "pro"
            ? await Api.useReport(reportParams, this.HttpOptions)
            : await Api.useProduct(reportParams, this.HttpOptions);
        if (result.success) {
          let isUsed;
          if (this.method === "pro") {
            isUsed = result.report.is_used;
            this.proReportResultMessage = this.getMessage(
              "dialog_txt_pro_tooltips_activate"
            );
            this.proReportAc = [result.report];
            this.proReportResultUrl = "";
            this.proReportResultDialog = true;
          } else {
            isUsed = result.user_product.is_used;
            this.proReportResultMessage = this.getMessage(
              "dialog_txt_rave_activate"
            );
            this.proReportResultUrl = result.user_product.view_url;
            this.proReportResultDialog = true;
          }
          if (isUsed) {
            this.getGetProfile();
            this.payreportdialog = false;
            this.payWarningReportDialog = false;
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async calcPayComposite() {
      const id = this.userProductId;
      const reportParams = {
        user_product_id: id,
        birthday: this.compReport1.birthday,
        city_id: this.compReport1.city_id,
        first_name: this.compReport1.first_name,
        birthday2:
          this.year.year +
          "-" +
          this.month.id +
          "-" +
          this.day.day +
          " " +
          this.hour.hour +
          ":" +
          this.minute.minute,
        city2_id: this.city,
        first_name2: this.firstname,
        email: this.profile.email
      };
      try {
        const result = await Api.useProduct(reportParams, this.HttpOptions);
        if (result.success) {
          if (result.user_product.is_used) {
            this.getGetProfile();
            this.paycompositedialog = false;
            this.isNumComp = 1;
            this.proReportResultMessage = this.getMessage(
              "dialog_txt_composite_activate"
            );
            this.proReportResultUrl = result.user_product.view_url;
            this.proReportResultDialog = true;
            this.clearFormFields();
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    fetchCitiesDebounced(val) {
      this.search = this.searchRequestProcessing(val);
      this.isLoading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchCities(this.search);
        this.isLoading = false;
      }, 500); /* 500ms throttle */
    },
    async fetchCities(searchrequest) {
      if (searchrequest) {
        try {
          const result = await Api.searchCity(searchrequest, this.HttpOptions);
          if (result.success) {
            this.cities = result.cities;
            this.citiesMemoization(result.cities);
            if (!this.cities.length) {
              this.searchResult = false;
            }
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    async getBodygraphFromReports(id, tab, method) {
      let reports;
      let reportId;
      let endpoint;
      if (method === "pro") {
        reports = this.proReports;
        reportId = reports[id].id;
        endpoint = "getRaveReportById";
      } else if (method === "pro_activated") {
        reports = this.proReportAc;
        reportId = reports[id].id;
        endpoint = "getRaveReportById";
      } else if (method === "user_products") {
        reports = this.userProducts;
        reportId = reports[id].id;
        endpoint = "getReportFromUserProductById";
      } else if (method === "child_user_products") {
        reports = this.childUserProducts;
        reportId = reports[id].id;
        endpoint = "getReportFromUserProductById";
      } else if (method === "career_user_products") {
        reports = this.careerUserProducts;
        reportId = reports[id].id;
        endpoint = "getReportFromUserProductById";
      } else {
        reports = this.demoProducts;
        reportId = reports[id].id;
        endpoint = "getRaveReportById";
      }
      // Storing User Product ID/Hash
      if (reports[id].user_product_hash) {
        localStorage.setItem("reportHash", reports[id].user_product_hash);
        localStorage.setItem("userProductId", reports[id].id);
        this.$store.commit("account/update_user_product_id", reports[id].id);
        this.$store.commit(
          "account/update_report_hash",
          localStorage.getItem("reportHash")
        );
        localStorage.setItem("userProductHash", true);
        this.$store.commit("account/is_user_product_hash", true);
      }
      // Remove User Product ID/Hash
      if (endpoint === "getRaveReportById") {
        this.$store.dispatch("account/removeUserProduct");
      }
      const successResult = await this.$store.dispatch(
        `account/${endpoint}`,
        reportId
      );
      if (successResult) {
        this.$router.push("/bodygraph");
        // Модалка поясняющая про 'user-products'
        if (method === "user_products")
          this.$store.commit("conf/update_service_modal", {
            message: this.getMessage("user_product_additional_info")
          });
      }
      /* try {
        const result = await endPoint(
          reportParams,
          this.HttpOptions
        );
        if (result.success) {
          // Refresh localStorage & appStorage: chartBodygraph, Tooltips
          localStorage.setItem(
            "chartBodygraph",
            JSON.stringify(result.report.body_graph)
          );
          localStorage.setItem(
            "toolTips",
            JSON.stringify(result.report.tooltips)
          );
          // this.$store.commit('UPDATE_CHART_BODYGRAPH', result.bodyGraph.result)
          // this.$store.commit('UPDATE_TOOLTIPS', result.bodyGraph.tooltips)
          localStorage.setItem(
            "tooltip_button_type",
            result.report.tooltip_button_type
          );
          this.$store.commit(
            "UPDATE_TOOLTIPS_BUTTON_TYPE",
            result.report.tooltip_button_type
          );
          this.$store.commit("UPDATE_CITY", result.report.city.title);
          // blinkFirstime
          if (!localStorage.getItem("blinkFirstime")) {
            localStorage.setItem("blinkFirstime", true);
            this.$store.commit("UPDATE_BLINK_FIRST_TIME", false);
          }
          // Set Age
          if (result.report.age >= 0) {
            localStorage.setItem("age", result.report.age);
            this.$store.commit("UPDATE_USER_AGE", result.report.age);
          }
          // Модалка поясняющая про 'user-products'
          if (method === "user_products")
            this.$store.commit("conf/update_service_modal", {
              message: this.getMessage("user_product_additional_info")
            });
          this.changeBodygraph(id, tab, method);
          this.clearSearchQuery();
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      } */
    },
    async getBodygraphByComposite(id) {
      const successResult = await this.$store.dispatch(
        "account/getCompositeReportById",
        id
      );
      if (successResult) {
        this.$router.push("/composite");
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
          this.scrollToTop();
        }, 1500);
      }
    },
    changeBodygraph(id, tab, method) {
      let reports;
      if (method === "pro" || method === "pro_activated") {
        // reports = this.profile.pro_reports
        reports = this.proReports;
        this.$store.commit("IS_RPO_TOOLTIPS", true);
        localStorage.setItem("isProTooltips", true);
        localStorage.setItem("userProductId", "");
        this.$store.commit("UPDATE_USER_PRODUCT_ID", "");
      } else if (method === "user_products") {
        // reports = this.profile.user_products
        reports = this.userProducts;
        this.$store.commit("IS_RPO_TOOLTIPS", false);
        localStorage.setItem("isProTooltips", false);
      } else if (method === "child_user_products") {
        // reports = this.profile.child_user_products
        reports = this.childUserProducts;
        this.$store.commit("IS_RPO_TOOLTIPS", false);
        localStorage.setItem("isProTooltips", false);
      } else if (method === "career_user_products") {
        // reports = this.profile.career_user_products
        reports = this.careerUserProducts;
        this.$store.commit("IS_RPO_TOOLTIPS", false);
        localStorage.setItem("isProTooltips", false);
      } else {
        // reports = this.profile.reports
        reports = this.demoProducts;
        this.$store.commit("IS_RPO_TOOLTIPS", false);
        localStorage.setItem("isProTooltips", false);
        localStorage.setItem("userProductId", "");
        this.$store.commit("UPDATE_USER_PRODUCT_ID", "");
      }
      localStorage.setItem("name", reports[id].first_name);
      localStorage.setItem(
        "day",
        JSON.stringify({ day: reports[id].birthday.slice(8, 10) })
      );
      localStorage.setItem(
        "month",
        JSON.stringify(this.months[reports[id].birthday.slice(5, 7) - 1])
      );
      localStorage.setItem(
        "year",
        JSON.stringify({ year: reports[id].birthday.slice(0, 4) })
      );
      localStorage.setItem(
        "hour",
        JSON.stringify({
          hour: reports[id].birthday.slice(11, 13)
        })
      );
      localStorage.setItem(
        "minute",
        JSON.stringify({
          minute: reports[id].birthday.slice(14, 16)
        })
      );
      localStorage.setItem("cityId", JSON.stringify(reports[id].city.id));
      localStorage.setItem("lastCities", JSON.stringify([reports[id].city]));
      localStorage.setItem("reportId", reports[id].id);
      if (reports[id].report_hash) {
        localStorage.setItem("reportHash", reports[id].report_hash);
        this.$store.commit(
          "UPDATE_REPORT_HASH",
          localStorage.getItem("reportHash")
        );
        localStorage.removeItem("userProductHash");
        this.$store.commit("account/is_user_product_hash", false);
      } else if (reports[id].user_product_hash) {
        localStorage.setItem("reportHash", reports[id].user_product_hash);
        localStorage.setItem("userProductId", reports[id].id);
        this.$store.commit("UPDATE_USER_PRODUCT_ID", reports[id].id);
        this.$store.commit(
          "UPDATE_REPORT_HASH",
          localStorage.getItem("reportHash")
        );
        // localStorage.setItem("userProductHash", true);
        this.$store.commit("account/is_user_product_hash", true);
      } else {
        localStorage.setItem("reportHash", "");
        this.$store.commit("UPDATE_REPORT_HASH", "");
        // localStorage.removeItem("userProductHash");
        this.$store.commit("account/is_user_product_hash", false);
      }
      if (reports[id].is_stored) {
        localStorage.setItem("is_stored", reports[id].is_stored);
        this.$getItemFromLocalStorage("is_stored", "UPDATE_IS_STORED");
      } else {
        localStorage.setItem("is_stored", "");
        this.$getItemFromLocalStorage("is_stored", "UPDATE_IS_STORED");
      }
      localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
      this.$store.commit("account/update_name", localStorage.getItem("name"));
      // this.$store.commit('account/update_name', localStorage.getItem('namereg'))
      this.$getItemFromLocalStorage("day", "UPDATE_DAY");
      this.$getItemFromLocalStorage("month", "UPDATE_MONTH");
      this.$getItemFromLocalStorage("year", "UPDATE_YEAR");
      this.$getItemFromLocalStorage("hour", "UPDATE_HOUR");
      this.$getItemFromLocalStorage("minute", "UPDATE_MINUTE");
      this.$getItemFromLocalStorage("lastCities", "account/update_last_cities");
      this.$getItemFromLocalStorage("city", "UPDATE_CITY");
      this.$getItemFromLocalStorage("cityId", "UPDATE_CITY_ID");
      this.$getItemFromLocalStorage("chartBodygraph", "UPDATE_CHART_BODYGRAPH");
      this.$getItemFromLocalStorage("toolTips", "UPDATE_TOOLTIPS");
      this.$getItemFromLocalStorage("reportId", "UPDATE_REPORT_ID");
      this.$getItemFromLocalStorage("userProductId", "UPDATE_USER_PRODUCT_ID");
      this.$matchChannels();
      // this.$store.commit("СALL_MAGICK_ZOOM");
    },
    async removeReport(id) {
      const reportParams = {
        report_id: id
      };
      try {
        const result = await Api.unstoreRaveReport(
          reportParams,
          this.HttpOptions
        );
        if (result.success) {
          localStorage.setItem("is_stored", result.report.is_stored);
          this.$store.commit(
            "account/update_is_stored",
            result.report.is_stored
          );
          this.getGetProfile();
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async removeComposite(id) {
      const reportParams = {
        report_id: id
      };
      try {
        const result = await Api.unstoreCompositeReport(
          reportParams,
          this.HttpOptions
        );
        if (result.success) {
          this.$store.commit(
            "account/update_composite_is_stored",
            result.report.is_stored
          );
          this.getGetProfile();
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    toSettings() {
      this.$router.push("/settings");
    },
    toHome() {
      this.$router.push("/");
    },
    initPurchase(id) {
      this.$store.commit("UPDATE_PURCHASES", id);
    },
    checkPurchase() {
      this.loader = "loading2";
    },
    async reSendMail() {
      try {
        const result = await Api.getJustclickSubscription(this.HttpOptions);
        if (result.success) {
          this.$store.commit("account/update_justclick", result.justclick);
          this.$store.commit("conf/update_service_modal", {
            message: result.justclick
              ? "Спасибо! Подписка активирована.<br />"
              : "Письмо отправлено на вашу почту. Проверьте все папки.<br />"
          });
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async getLogout() {
      try {
        const result = await Api.logout({}, this.HttpOptions);
        if (result.success) {
          this.logout();
          this.preloading = false;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async withdrawal(key) {
      try {
        this.withdrawalDialog = false;
        const data = {
          amount: this.slider,
          payment_type: this.wdmethod,
          comments: this.wddetail
        };
        const result = await Api.requestWithdrawal(data, this.HttpOptions);
        if (result.success) {
          this.getGetProfile();
          this.$store.commit("conf/update_service_modal", {
            message: this.getMessage(key),
            authDialog: true
          });
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    partnerDialog(key) {
      this.$store.commit("account/set_partner_info_text", this.getMessage(key));
      this.$store.commit("account/call_partner_dialog");
    },
    tooltipsCountDialog(key) {
      this.tooltipsCountInfoText = this.getMessage(key);
      this.tooltipsCountInfoDialog = true;
    },
    copyToClipboard(num) {
      this.copied = true;
      setTimeout(() => (this.copied = false), 5000);
      if (num === 1) {
        this.$refs.targetUrl1.select();
      } else if (num === 2) {
        this.$refs.targetUrl2.select();
      }
      document.execCommand("copy");
      this.callToAppEvents(1);
    },
    async callToAppEvents(type) {
      try {
        const data = { event_type: type };
        await Api.appEvents(data, this.HttpOptions);
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    getPartnerLink() {
      const url = this.ConfApp.partner_url;
      const returnUrl = url.includes("?")
        ? `${url}&rave=${this.User.reference_code}`
        : `${url}#/?rave=${this.User.reference_code}`;
      return returnUrl;
    },
    async getProducts() {
      try {
        const result = await Api.getProducts(this.HttpOptions);
        if (result.success) {
          this.products = result.products;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    buyProductAsGift() {
      let url, price;
      this.products.forEach((item) => {
        url =
          this.User.haveIsUsedUserProducts || this.User.haveIsUsedUserComposites
            ? item.external_url_gift_regular
            : item.external_url_gift;
        price =
          this.User.haveIsUsedUserProducts || this.User.haveIsUsedUserComposites
            ? item.price_gift_regular
            : item.price_gift;
        this.reportTypeItems.push({
          text: item.title,
          price,
          value: url
        });
      });
      this.BuyProductAsGiftDialog = true;
    },
    mathProductAsGiftUrl() {
      if (this.reportType && this.giftsEmail)
        this.BuyProductAsGiftUrl = this.reportType.includes("?")
          ? this.reportType + "&tag=fe:" + this.giftsEmail.trim()
          : this.reportType + "?tag=fe:" + this.giftsEmail.trim();
    },
    validateGiftForm() {
      if (this.$refs.giftform.validate()) {
        this.openGiftUrl();
      }
    },
    openGiftUrl() {
      window.open(this.BuyProductAsGiftUrl, "_blank");
      this.BuyProductAsGiftDialog = false;
      this.reportType = null;
      this.giftsEmail = "";
      this.$refs.giftform.reset();
    },
    withdrawalLess4k() {
      this.$store.commit("conf/update_service_modal", {
        message: "Вывод средств доступен при накоплении 4000₽ на балансе..."
      });
    },
    clearFormFields() {
      this.firstname = "";
      this.cities = [];
      this.city = "";
      this.day = { day: "15" };
      this.month = { month: "Июнь (6)", id: "06" };
      this.year = { year: "1990" };
      this.hour = { hour: "12" };
      this.minute = { minute: "30" };
      this.clearFormFields = {};
      this.method = "";
    },
    dateFormatting(date, notime) {
      const year = date.slice(0, 4);
      const month = date.slice(5, 7);
      const day = date.slice(8, 10);
      const time = date.slice(11);
      const newDate = notime
        ? `${day}-${month}-${year}`
        : `${day}-${month}-${year} ${time}`;
      // console.log(newDate)
      return newDate;
    },
    searchFromReports(str, type) {
      if (str === null) str = "";
      let result;
      switch (type) {
        // Demo-reports
        case "demo":
          result = this.profile.reports.filter((report) => {
            return report.first_name
              .toString()
              .toLocaleLowerCase()
              .includes(str.toLocaleLowerCase());
          });
          // console.log('result: ', result)
          this.demoProducts = result;
          break;
        // Demo-composites
        case "composites":
          result = this.profile.composites.filter((report) => {
            return (
              report.first_name
                .toString()
                .toLocaleLowerCase()
                .includes(str.toLocaleLowerCase()) ||
              report.first_name2
                .toString()
                .toLocaleLowerCase()
                .includes(str.toLocaleLowerCase())
            );
          });
          this.composites = result;
          break;
        // User Products
        case "user_products":
          result = this.profile.user_products.filter((report) => {
            return report.first_name
              .toString()
              .toLocaleLowerCase()
              .includes(str.toLocaleLowerCase());
          });
          this.userProducts = result;
          break;
        // Career User Products
        case "career_user_products":
          result = this.profile.career_user_products.filter((report) => {
            return report.first_name
              .toString()
              .toLocaleLowerCase()
              .includes(str.toLocaleLowerCase());
          });
          this.careerUserProducts = result;
          break;
        // Child User Products
        case "child_user_products":
          result = this.profile.child_user_products.filter((report) => {
            return report.first_name
              .toString()
              .toLocaleLowerCase()
              .includes(str.toLocaleLowerCase());
          });
          this.childUserProducts = result;
          break;
        // Composite User Products
        case "composite_user_products":
          result = this.profile.composite_user_products.filter((report) => {
            return (
              report.first_name
                .toString()
                .toLocaleLowerCase()
                .includes(str.toLocaleLowerCase()) ||
              report.first_name2
                .toString()
                .toLocaleLowerCase()
                .includes(str.toLocaleLowerCase())
            );
          });
          this.compositeUserProducts = result;
          break;
        // Pro Reports
        case "pro_reports":
          result = this.profile.pro_reports.filter((report) => {
            return report.first_name
              .toString()
              .toLocaleLowerCase()
              .includes(str.toLocaleLowerCase());
          });
          this.proReports = result;
          break;
      }
      // no return
      // Deny update frame data
    },
    clearSearchQuery() {
      this.searchResult = true;
      this.searchReports = null;
      this.searchReportsComposite = null;
      this.searchUserProducts = null;
      this.searchСareerUserProducts = null;
      this.searchСhildUserProducts = null;
      this.searchСompositeUserProducts = null;
      this.searchProReports = null;
    },
    openUpdatesButton() {
      this.firstOpenUpdates = false;
      localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
    },
    openCompUpdatesButton() {
      this.firstOpenCompUpdates = false;
      localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
    },
    openChildUpdatesButton() {
      this.firstOpenChildUpdates = false;
      localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
    },
    closeDialog(val) {
      // console.log("emitReg: ", val);
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
    },
    getSettings() {
      if (Object.keys(this.$store.state.conf.Messages).length === 0) {
        this.$store.dispatch("conf/getSettings");
      }
    },
    calcSlider() {
      setTimeout(() => {
        const v = this.User.current_balance - this.User.min_withdrawal;
        return v;
      }, 0);
    },
    shareReport(id, userProduct) {
      const data = { id, userProduct };
      this.$store.dispatch("account/shareReport", data);
    } /* ,
    async verify() {
      // authToken init before calling verify user status
      if (localStorage.getItem("authToken")) {
        this.$store.commit(
          "account/update_token",
          localStorage.getItem("authToken")
        );
        this.$store.commit("conf/set_token", localStorage.getItem("authToken"));
      }
      try {
        const result = await Api.verify(
          {},
          this.HttpOptions
        );
        // console.log(result)
        if (result.success && result.access_token) {
          // Запись профиля в стор
          this.$store.commit("account/set_profile", result.profile);
          // АВТОЛОГИН
          this.$store.commit("account/login_success", true);
          this.$store.commit("account/update_token", result.access_token);
          this.$store.commit("conf/set_token", result.access_token);
          localStorage.setItem("authToken", result.access_token);
          localStorage.setItem(
            "is_subscribed_to_justclick",
            result.profile.is_subscribed_to_justclick
          );
          this.$store.commit(
            "account/update_justclick",
            result.profile.is_subscribed_to_justclick
          );
          /* if (this.User.is_subscribed_to_justclick) {
            this.$store.commit("UPDATE_REGISTERED", true);
          } else {
            this.$store.commit("UPDATE_REGISTERED", false);
          } */ /* localStorage.setItem("user_id", result.profile.id);
          localStorage.setItem("namereg", result.profile.first_name);
          localStorage.setItem("email", result.profile.email);
          this.$store.commit("account/update_email", result.profile.email);
          if (!Object.keys(this.User.profile).length) {
            this.updateProfile();
          } else {
            this.updateProfileFromStore();
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    } */
  }
};
</script>
<style scoped>
.all-pay-report-info {
  display: block;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  max-width: 300px;
  margin: 0 auto;
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  .all-pay-report-info {
    max-width: 270px;
  }
  .modal-pay-report .v-card__text {
    padding: 0 8px 10px !important;
  }
}
</style>
