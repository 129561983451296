<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="previewProfileDialod = true"
          color="primary"
          class="my-prof-controls-flex"
          fab
          x-large
          icon
          dark
        >
          <v-icon center>mdi-eye</v-icon>
          <span class="d-flex" style="font-size:11px;margin-top:5px">
            Предпросмотр
          </span>
        </v-btn>
      </template>
      <span>Предпросмотр профиля</span>
    </v-tooltip>
    <v-dialog
      v-model="previewProfileDialod"
      scrollable
      fullscreen
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-text class="no-top-bottom-padding mob-nopadding">
          <v-container class="no-top-bottom-padding" style="height:100%">
            <v-row style="height:100%">
              <v-col
                cols="12"
                class="no-top-bottom-padding display-0-24-mob-p-d"
                style="overflow:hidden;overflow-y:auto;height:100%"
              >
                <v-toolbar class="hd-gradient position-sticky" dark>
                  <v-spacer></v-spacer>

                  <v-toolbar-title>
                    Предпросмотр профиля
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn
                    @click="previewProfileDialod = false"
                    icon
                    absolute
                    right
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <User :userId="+User.id" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import User from "@/components/community/user/user";

export default {
  components: { User },
  data() {
    return {
      previewProfileDialod: false
    };
  },
  computed: {
    ...mapState({
      User: (state) => state.account.User,
      HttpOptions: (state) => state.conf.http_options,
      ConfApp: (state) => state.conf.ConfApp
    })
  }
};
</script>
